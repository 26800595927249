import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VendorSearch from '../../components/Common/VendorSearch';
import './index.css';
import Seo from '../../components/Seo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../utils/common';
import CategItem from '../../components/Home/PopularMenus/CategItem';
import { Config } from '../../constants';

const Categories = (props) => {
  const {
    foodCategories
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onVendorSearch = (data) => {
    console.log('onVendorSearch', data);

    let params = [];
    if (!isEmpty(data.curLoc.latitude == null ? null : '' + data.curLoc.latitude)) {
      params.push(`latitude=${data.curLoc.latitude}`);
    }
    if (!isEmpty(data.curLoc.longitude == null ? null : '' + data.curLoc.longitude)) {
      params.push(`longitude=${data.curLoc.longitude}`);
    }
    if (!isEmpty(data.curLoc.formatted_address)) {
      params.push(`formatted_address=${data.curLoc.formatted_address}`);
    }
    if (!isEmpty(data.vendorType)) {
      params.push(`vendor_type=${data.vendorType}`);
    }
    if (!isEmpty(data.orderMethod)) {
      params.push(`order_type=${data.orderMethod}`);
    }
    if (!isEmpty(data.searchTerm)) {
      params.push(`searchTerm=${data.searchTerm}`);
    }

    navigate(`/stores?${params.join('&')}` );
  };


  const goCategory = (category) => {
    let params = [];
    params.push(`category=${category.id}`);
    params.push(`latitude=${Config.TIRANA_CITY_LOCATION.latitude}`);
    params.push(`longitude=${Config.TIRANA_CITY_LOCATION.longitude}`);
    params.push(`formatted_address=${Config.TIRANA_CITY_LOCATION.street} ${Config.TIRANA_CITY_LOCATION.city}, ${Config.TIRANA_CITY_LOCATION.country}`);
    navigate(`/stores?${params.join('&')}`);
  };

  return (
    <div data-testid='view-categories' className={'view-categories'}>
      <Seo title={t('web_title.default')} />
      <div className={'flex_1'}>
        <div className={'align-col-middle header-bg'}>
          <h4>{t('vendors_list.header1')}<br/>{t('vendors_list.header2')}</h4>
        </div>
        <div className={'search-view'}>
          <VendorSearch
            onVendorSearch={onVendorSearch}
          />
        </div>
        <div className={'flex_1 main'}>
          <div className={'w100'}>
            <p className={'subject-title'}>{t('vendors_list.explore_by_category')} <span>({foodCategories.length} {t('vendors_list.categories')})</span></p>
          </div>
          <div className={'w100 list'}>
            {foodCategories.map((menu, index) => (
              <CategItem key={index} category={menu} onSelect={() => goCategory(menu)} />
            ))}
          </div>
        </div>
      </div>
      <div style={{ height: 120 }} />
    </div>
  );
};


const mapStateToProps = ({ app }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn,
  hasLocation: app.hasLocation,
  coordinates: app.coordinates,
  address: app.address || {},
  language: app.language,
  foodCategories: app.foodCategories
});

Categories.propTypes = {
  hasLocation: PropTypes.bool,
  foodCategories: PropTypes.array,
  address: PropTypes.object,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  user: PropTypes.shape({
    photo: PropTypes.string,
    cashback_amount: PropTypes.number
  }),
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool
};

export default connect(mapStateToProps, {})(Categories);
