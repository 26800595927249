import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/sq';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import querystring from 'query-string';
import { saveAddress, getAddresses, setTmpLocationPicked } from '../../../store/actions/app';
import { setDeliveryInfoCart } from '../../../store/actions/shop';
import { convertTimeString2Hours, isEmpty } from '../../../utils/common';
import { Theme } from '../../../assets';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import { DotBorderButton } from '../../Buttons';
import { AuthInput, CommentInput } from '../../Inputs';
import AddressItem from '../../Cart/AddressItem';
import { TextSelector } from '../../DropdownSelectors';
import PropTypes from 'prop-types';
import './index.css';
import { Config, ROUTES_NAMES } from '../../../constants';
import GoogleMapReact from 'google-map-react';
import Svg_marker from '../../../assets/svgs/markers/ic_locpin.svg';
import VendorMarker from '../../Common/SnapfooderMap/VendorMarker';
import {
  AddressMapModal,
  ChangeAddressConfirmModal,
  DeliveryAddressModal,
  NewAddressModal,
  PickupTimeModal,
  ReservationTimeModal
} from '../../Modals';
import ScheduleTab from '../../TabSelector/ScheduleTab';
import CartScheduleModal from '../../Modals/CartScheduleModal';

const DeliveryInfo = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const parsed = querystring.parse(location.search) || {};

  const dates = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const [order_methods, setOrderMethods] = useState([]);
  const [isScheduleModal, setScheduleModal] = useState(false);
  const [availReseveDays, setAvailReseveDays] = useState([]);
  const [availPickupDays, setAvailPickupDays] = useState([]);

  const [isDeliveryAddressModal, OpenDeliveryAddressModal] = useState(false);
  const [isNewAddressModal, OpenNewAddressModal] = useState(false);
  const [isAddressMapModal, OpenAddressMapModal] = useState(false);

  const [isPickupTimeModal, OpenPickupTimeModal] = useState(false);
  const [isReservationTimeModal, OpenReservationTimeModal] = useState(false);
  const [isChangeAddressModal, OpenChangeAddressModal] = useState(false);

  useEffect(() => {
    if (parsed.modal == 'addresses' && isDeliveryAddressModal == false) {
      OpenDeliveryAddressModal(true);
    } else {
      OpenDeliveryAddressModal(false);
    }

    if (parsed.modal == 'new-address' && isNewAddressModal == false) {
      OpenNewAddressModal(true);
    } else {
      OpenNewAddressModal(false);
    }

    if (parsed.modal == 'find-on-map' && isAddressMapModal == false) {
      OpenAddressMapModal(true);
    } else {
      OpenAddressMapModal(false);
    }

    if (parsed.modal == 'reservation-time' && isReservationTimeModal == false) {
      OpenReservationTimeModal(true);
    } else {
      OpenReservationTimeModal(false);
    }
    if (parsed.modal == 'pickup-time' && isPickupTimeModal == false) {
      OpenPickupTimeModal(true);
    } else {
      OpenPickupTimeModal(false);
    }
  }, [parsed.modal]);

  useEffect(() => {
    if (props.addresses != null && props.addresses.length > 0) {
      let comments = '';
      if (props.delivery_info.handover_method == OrderType_Delivery) {
        comments = props.addresses[0].notes;
      }
      props.setDeliveryInfoCart({
        address: props.addresses[0],
        comments: comments
      });
    } else {
      props.setDeliveryInfoCart({
        address: {}
      });
    }
  }, [props.addresses]);

  useEffect(() => {
    if (props.vendorData.order_method != null) {
      const supported_order_methods = props.vendorData.order_method.split('-');

      let avail_order_methods = [];
      for (let i = 0; i < supported_order_methods.length; i++) {
        if (supported_order_methods[i] == OrderType_Delivery) {
          avail_order_methods.push(OrderType_Delivery);
        } else if (supported_order_methods[i] == OrderType_Reserve) {
          let days = [];
          if (props.vendorData.reservation_hours) {
            for (let j = 0; j < props.vendorData.reservation_hours.length; j++) {
              const reserveDay = props.vendorData.reservation_hours[j];
              const _index = days.findIndex((d) => d.week_day == reserveDay.week_day);
              let _times = [];
              if (_index != -1) {
                _times = days[_index].times ? [...days[_index].times] : [];
              }

              const time_open = convertTimeString2Hours(reserveDay.time_open);
              const time_close = convertTimeString2Hours(reserveDay.time_close);

              for (let k = time_open; k <= time_close; k = k + 0.5) {
                let _time = `${Math.floor(k)}:00`;
                if (k - Math.floor(k) >= 0.5) {
                  _time = `${Math.floor(k)}:30`;
                }
                if (_times.findIndex((t) => t == _time) == -1) {
                  _times.push(_time);
                }
              }

              if (_times.length > 0) {
                if (_index != -1) {
                  days[_index].times = _times;
                } else {
                  days.push({
                    week_day: reserveDay.week_day,
                    times: _times
                  });
                }
              }
            }
          }

          let availDays = [];
          for (let ii = 0; ii < 7; ii++) {
            let _date = moment(new Date()).add(ii, 'days').toDate();
            let foundIndex = days.findIndex(
              (d) =>
                d.week_day ==
                (_date.getDay() - 1 < 0 ? _date.getDay() + 6 : _date.getDay() - 1).toString()
            );
            if (foundIndex != -1) {
              availDays.push({
                ...days[foundIndex],
                date: moment(_date).format('YYYY-MM-DD'),
                day: ii == 0 ? 'Today' : ii == 1 ? 'Tomorrow' : dates[_date.getDay()]
              });
            }
          }

          if (availDays.length > 0) {
            setAvailReseveDays(availDays);
            avail_order_methods.push(OrderType_Reserve);
          }
        } else if (supported_order_methods[i] == OrderType_Pickup) {
          const start_pickup = convertTimeString2Hours(props.vendorData.start_pickup);
          const end_pickup = convertTimeString2Hours(props.vendorData.end_pickup);

          let _times = [];
          for (let j = start_pickup; j <= end_pickup; j = j + 0.5) {
            let _time = `${Math.floor(j)}:00`;
            if (j - Math.floor(j) >= 0.5) {
              _time = `${Math.floor(j)}:30`;
            }
            if (_times.findIndex((t) => t == _time) == -1) {
              _times.push(_time);
            }
          }

          let availDays = [];
          if (_times.length > 0) {
            for (let ii = 0; ii < 7; ii++) {
              let _date = moment(new Date()).add(ii, 'days').toDate();
              availDays.push({
                week_day: ii.toString(),
                times: _times,
                date: moment(_date).format('YYYY-MM-DD'),
                day: ii == 0 ? 'Today' : ii == 1 ? 'Tomorrow' : dates[_date.getDay()]
              });
            }
          }

          if (availDays.length > 0) {
            setAvailPickupDays(availDays);
            avail_order_methods.push(OrderType_Pickup);
          }
        }
      }

      if (avail_order_methods.length > 0) {
        setOrderMethods(avail_order_methods);
      }
    }
  }, [props.vendorData.id]);

  useEffect(() => {
    if (props.delivery_info.handover_method == OrderType_Reserve) {
      if (
        availReseveDays.length > 0 &&
        availReseveDays[0].times &&
        availReseveDays[0].times.length > 0
      ) {
        props.setDeliveryInfoCart({
          pickup_date: availReseveDays[0].date,
          pickup_time: availReseveDays[0].times[0]
        });
      }
    } else if (props.delivery_info.handover_method == OrderType_Pickup) {
      if (
        availPickupDays.length > 0 &&
        availPickupDays[0].times &&
        availPickupDays[0].times.length > 0
      ) {
        props.setDeliveryInfoCart({
          pickup_date: availPickupDays[0].date,
          pickup_time: availPickupDays[0].times[0]
        });
      }
    }
  }, [props.delivery_info.handover_method]);

  useEffect(() => {
    if (order_methods.length > 0) {
      console.log('order_methods ', order_methods, props.delivery_info.handover_method);
      if (order_methods.findIndex((i) => i == props.delivery_info.handover_method) == -1) {
        props.setDeliveryInfoCart({
          handover_method: order_methods[0]
        });
      }
    }
  }, [order_methods]);


  const _renderScheduleInfo = () => {
    return (
      <div className={'flex_between w100 schedule-info-view'} >
        <div className={'subject-title mr2'}>{t('cart.schedule_order_desc')}</div>
        <div className={'align-col-middle'} style={{ marginBottom: 10 }}>
          {!isEmpty(props.delivery_info.schedule_time) && props.delivery_info.is_schedule === 1 && (
            <div className={'align-middle'} style={{ width: 216 }}>
              <div className={'flex_1'} />
              <div className={'flex_1 schedule-time-label'}>
                {moment(props.delivery_info.schedule_time).locale(props.language).format('dddd')}
                {' - '}
                {moment(props.delivery_info.schedule_time).format('h:mm A')}
              </div>
            </div>
          )}
          <ScheduleTab
            curitem={props.delivery_info.is_schedule === 0 ? 'now' : 'schedule'}
            onSelect={(item) => {
              if (item === 'schedule') {
                setScheduleModal(true);
              } else {
                props.setDeliveryInfoCart({
                  is_schedule: 0,
                  schedule_time: null
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  const _renderHandover = () => {
    return (
      <div className={'flex_between w100 handover-method mt2'} >
        <div className={'subject-title mr2'}>{t('vendor_profile.handover_method')}</div>
        <TextSelector
          style={{ width: 150, height: 46 }}
          values={order_methods}
          value={props.delivery_info.handover_method}
          handleChange={(method) => {
            let comments = '';
            if (method === OrderType_Delivery && props.delivery_info.address != null) {
              comments = props.delivery_info.address.notes;
            }
            props.setDeliveryInfoCart({
              handover_method: method,
              comments: comments
            });
          }}
        />
      </div>
    );
  };

  const _renderDeliveryInfo = () => {
    const onGoAddress = () => {
      navigate(ROUTES_NAMES.checkout + '?modal=addresses');
    };

    const apiIsLoaded = (map, maps) => {
      const vendorline = new maps.Polyline({
        strokeColor: '#23CBD8'
      });
      const directionsService = new maps.DirectionsService();
      const directionsRenderer = new maps.DirectionsRenderer({
        suppressMarkers: true,
        preserveViewport: true,
        polylineOptions: vendorline
      });
      directionsRenderer.setMap(map);

      const origin = {
        lat: parseFloat(props.delivery_info.address.lat),
        lng: parseFloat(props.delivery_info.address.lng)
      };
      const destination = {
        lat: parseFloat(props.vendorData.latitude),
        lng: parseFloat(props.vendorData.longitude)
      };

      console.log('origin dest ', origin, destination);
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    };

    return (
      <div className={'mt2'}>
        <div className={'address-view'}>
          {props.delivery_info.address != null && props.delivery_info.address.id != null ? (
            <AddressItem
              hasRadio={false}
              data={props.delivery_info.address}
              onEdit={onGoAddress}
              edit_div={t('cart.change_address')}
              outOfDeliveryArea={props.outOfDeliveryArea == true}
            />
          ) : (
            <DotBorderButton
              title={t('address_list.add_new_address')}
              style={{ width: '100%' }}
              onPress={onGoAddress}
            />
          )}
        </div>
        <div className={'map-view flex_1 mt2'}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
            defaultCenter={[
              Config.TIRANA_CITY_LOCATION.latitude,
              Config.TIRANA_CITY_LOCATION.longitude
            ]}
            defaultZoom={12}
            options={{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              styles: Config.SocialMapScreenStyles
            }}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}>
            {props.delivery_info.address != null &&
            props.delivery_info.address.lat != null &&
            props.delivery_info.address.lng && (
              <img
                src={Svg_marker}
                lat={parseFloat(props.delivery_info.address.lat)}
                lng={parseFloat(props.delivery_info.address.lng)}
              />
            )}
            {props.vendorData.latitude != null && props.vendorData.longitude != null && (
              <VendorMarker
                lat={parseFloat(props.vendorData.latitude)}
                lng={parseFloat(props.vendorData.longitude)}
                vendor_id={props.vendorData.id}
                vendor={props.vendorData}
              />
            )}
          </GoogleMapReact>
        </div>
      </div>
    );
  };

  const _renderPickupInfo = () => {
    return (
      <div className={'align-col-middle pickup-info'}>
        {isEmpty(props.delivery_info.pickup_date) || isEmpty(props.delivery_info.pickup_time) ? (
          <Link className={'noti-text fs5 mt2'} to={ROUTES_NAMES.checkout + '?modal=pickup-time'}>
            {t('cart.choose_pickup_time')}
          </Link>
        ) : (
          <Link className={'noti-text fs4 mt2'} to={ROUTES_NAMES.checkout + '?modal=pickup-time'}>
            {t('cart.pickup_after_30') +
            `  ${props.delivery_info.pickup_date}   ${props.delivery_info.pickup_time}`}
          </Link>
        )}
        <div className={'w100 mt2'}>
          <div className={'subject-title '}>{t('cart.vendor_address')}</div>
          <div className={'vendor-address mt2'}>
            <p className={'fs3'}>{props.vendorData.phone_number}</p>
            <p className={'fs3'} style={{ marginTop: 6 }}>
              {props.vendorData.address}
            </p>
          </div>
        </div>
        <div className={'w100 mt2'}>
          <div className={'subject-title fs4 mb2'}>{t('cart.pickup_note')}</div>
          <CommentInput
            placeholder={t('cart.add_your_note')}
            text={props.delivery_info.comments}
            onChange={(text) => {
              props.setDeliveryInfoCart({
                comments: text
              });
            }}
          />
        </div>
      </div>
    );
  };

  const _renderReserveInfo = () => {
    return (
      <div className={'align-col-middle reservation-info'}>
        <div className={'subject-title mt3 w100'}>{t('cart.reservation_info')}</div>
        {isEmpty(props.delivery_info.pickup_date) || isEmpty(props.delivery_info.pickup_time) ? (
          <Link
            className={'noti-text fs5 mt2'}
            to={ROUTES_NAMES.checkout + '?modal=reservation-time'}>
            {t('cart.choose_reservation_time')}
          </Link>
        ) : (
          <Link
            className={'noti-text fs4 mt2'}
            to={ROUTES_NAMES.checkout + '?modal=reservation-time'}>
            {moment(
              `${props.delivery_info.pickup_date} ${props.delivery_info.pickup_time}`,
              'YYYY-MM-DD HH:mm'
            ).locale(props.language).format('ddd, DD MMM, YYYY  ')}

            {moment(
              `${props.delivery_info.pickup_date} ${props.delivery_info.pickup_time}`,
              'YYYY-MM-DD HH:mm'
            ).locale('en').format('hh:mm A')}
          </Link>
        )}
        <div className={'w100 mt2'}>
          <div className={'subject-title '}>{t('cart.vendor_address')}</div>
          <div className={'vendor-address mt2'}>
            <p className={'fs3'}>{props.vendorData.phone_number}</p>
            <p className={'fs3'} style={{ marginTop: 6 }}>
              {props.vendorData.address}
            </p>
          </div>
        </div>
        {_renderNumGuests()}
      </div>
    );
  };

  const _renderNumGuests = () => {
    return (
      <div className={'flex_between num_guest w100 mt2'}>
        <div className={'subject-title'}>{t('cart.num_guests')}</div>
        <AuthInput
          style={{
            width: 120,
            height: 46,
            marginBottom: 0,
            borderWidth: 1,
            borderColor: Theme.colors.gray6,
            backgroundColor: Theme.colors.white,
            textAlign: 'center'
          }}
          textAlign='center'
          type={'number'}
          text={props.delivery_info.num_guests == 0 ? '' : `${props.delivery_info.num_guests}`}
          onChange={(t) => {
            let int_val = t !== '' ? parseInt(t) : 0;
            if (int_val >= 0) {
              props.setDeliveryInfoCart({
                num_guests: int_val
              });
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className={'cart-delivery-info mt2'}>
      {props.delivery_info.handover_method == OrderType_Delivery &&
      props.vendorData.can_schedule == 1 &&
      _renderScheduleInfo()}
      {props.vendorData.order_method != 'Delivery' && order_methods.length > 0 && _renderHandover()}
      {props.delivery_info.handover_method == OrderType_Delivery && _renderDeliveryInfo()}
      {props.delivery_info.handover_method == OrderType_Pickup && _renderPickupInfo()}
      {props.delivery_info.handover_method == OrderType_Reserve && _renderReserveInfo()}
      <DeliveryAddressModal isOpen={isDeliveryAddressModal} />
      <NewAddressModal isOpen={isNewAddressModal} />
      <AddressMapModal isOpen={isAddressMapModal} />
      <ReservationTimeModal isOpen={isReservationTimeModal} availReseveDays={availReseveDays} />
      <PickupTimeModal isOpen={isPickupTimeModal} availPickupDays={availPickupDays} />

      <ChangeAddressConfirmModal
        isOpen={isChangeAddressModal}
        fee={30}
        onOk={() => {
          OpenChangeAddressModal(false);
        }}
        onCancel={() => {
          OpenChangeAddressModal(false);
        }}
      />
      <CartScheduleModal
        showModal={isScheduleModal}
        onClose={() => setScheduleModal(false)}
      />
    </div>
  );
};

DeliveryInfo.propTypes = {
  outOfDeliveryArea: PropTypes.bool,
  addresses: PropTypes.array,
  user: PropTypes.shape({
    username: PropTypes.string,
    full_name: PropTypes.string,
    phone: PropTypes.string
  }),
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    }),
    num_guests: PropTypes.number,
    is_schedule: PropTypes.number,
    schedule_time: PropTypes.string,
    pickup_date: PropTypes.string,
    pickup_time: PropTypes.string
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    order_method: PropTypes.string,
    can_schedule: PropTypes.number,
    reservation_hours: PropTypes.array,
    start_pickup: PropTypes.string,
    end_pickup: PropTypes.string,
    phone_number: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  language: PropTypes.string,
  setDeliveryInfoCart: PropTypes.func,
  onMinus: PropTypes.func,
  onDelete: PropTypes.func,
  setTmpLocationPicked: PropTypes.func
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  addresses: app.addresses,
  language: app.language,
  delivery_info: shop.delivery_info,
  vendorData: shop.vendorData || {}
});

export default connect(mapStateToProps, {
  saveAddress,
  getAddresses,
  setTmpLocationPicked,
  setDeliveryInfoCart
})(DeliveryInfo);
