import React from 'react';
import PropTypes from 'prop-types';
import Svg_loc from '../../../assets/svgs/markers/ic_locpin.svg';

const UserMarker = () => {
  return (
    <div className={'map-marker user-marker'}>
      <div className={'marker-body'}>
        <img src={Svg_loc} />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return !(prevProps.lat !== nextProps.lat || prevProps.lng !== nextProps.lng);
}

UserMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number
};
export default React.memo(UserMarker, arePropsEqual);
