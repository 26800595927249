import React, {useEffect, useState, useRef} from 'react';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Seo from '../../../components/Seo';
import SnapfooderMarker from '../../../components/Common/SnapfooderMap/SnapfooderMarker';
import SnapfooderGroupMarker from '../../../components/Common/SnapfooderMap/SnapfooderGroupMarker';
import VendorMarker from '../../../components/Common/SnapfooderMap/VendorMarker';
import UserMarker from '../../../components/Common/SnapfooderMap/UserMarker';
import SnapfoodersGroupModal from '../../../components/Modals/SnapfoodersGroupModal';
import MapSettingModal from '../../../components/Modals/MapSettingModal';
import './index.css';
import { Config } from '../../../constants';
import SnapfooderModal from '../../../components/Modals/SnapfooderModal';
import apiFactory from '../../../services/apiFactory';
import { checkLocationPermission, getCurrentLocation } from '../../../services/location';
import { setVendorCart } from '../../../store/actions/shop'
import { createSingleChannel, findSingleChannel } from '../../../services/chat';
import { TIRANA_CITY_LOCATION } from '../../../constants/config';
import SettingIcon from '../../../assets/images/icon/map_settings.png';

const MIN_DELTA = 0.03;
const SnapfoodMap = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const _isMounted = useRef(true);

  const [showSnapfooder, setShowSnapfooder] = useState(false);
  const [groupMembers, setGroupMembers] = useState([]);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showSettingModal, setShowSettingModal] = useState(false);

  const _snapfooder = useRef(null);

  const [state, setState] = useState({
    loading: false,
    isCreatingChannel: false,
    groupSize: 3,
    snapfooders: [],
    vendors: []
  });

  const [myLoc, setMyLoc] = useState({
    latitude : TIRANA_CITY_LOCATION.latitude,
    longitude : TIRANA_CITY_LOCATION.longitude,
  });
  const [mapPos, setMapPos] = useState({
    center : {
      lat : TIRANA_CITY_LOCATION.latitude,
      lng : TIRANA_CITY_LOCATION.longitude,
    },
    zoom : 15
  });

  useEffect(() => {
    _setCurrentLocation();

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const _setCurrentLocation = async () => {
    let location_flag = false;
    try {
      let hasPermission = await checkLocationPermission();
      if (hasPermission) {
        let location = await getCurrentLocation();
        if (location) {
          location_flag = true;
          setMyLocation(location.latitude, location.longitude);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    if (location_flag !== true) {
      setMyLocation(props.coordinates.latitude, props.coordinates.longitude);
    }
  };

  const setMyLocation = async (latitude, longitude) => {
    if (latitude && longitude) {
      setMyLoc({
        latitude: latitude,
        longitude: longitude
      });
      loadData(
        {
          latitude: latitude,
          longitude: longitude,
          latitudeDelta: MIN_DELTA,
          longitudeDelta: MIN_DELTA
        },
        {
          center: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
          },
          zoom: 15
        },
        true
      );
    }
  };

  const onGoVendor = (restaurant) => {
    props.setVendorCart(restaurant);
    navigate(`/store/${restaurant.hash_id}/${restaurant.slug}/${restaurant.order_method}`);
  };

  const onEnterChannel = async (partner) => {
    let found_channel = await findSingleChannel(props.user.id, partner.id);
    if (found_channel != null) {
      navigate(`/social/chat/${found_channel.id}`);
    } else {
      setState({ ...state, isCreatingChannel: true });
      let channelID = await createSingleChannel(props.user, partner);
      setState({ ...state, isCreatingChannel: false });
      if (channelID != null) {
        navigate(`/social/chat/${channelID}`);
      } else {
        console.error(t('alerts.error'), t('checkout.something_is_wrong'));
      }
    }
  };

  const onBoundChange = ({ bounds }) => {
    if (myLoc == null) {
      return;
    }

    const size = {
      width: window.innerWidth, // Map width in pixels
      height: window.innerHeight // Map height in pixels
    };

    const { center, zoom } = fitBounds({ ne: bounds.ne, sw: bounds.sw }, size);

    let latitudeDelta = Math.abs(bounds.ne.lat - bounds.se.lat);
    let longitudeDelta = Math.abs(bounds.nw.lng - bounds.ne.lng);

    let center_lat = center.lat;
    let center_lng = center.lng;
    if (center_lng < -180) {
      center_lng = center_lng + 360;
    }

    const changed_region = {
      latitude: center_lat,
      longitude: center_lng,
      latitudeDelta: latitudeDelta || MIN_DELTA,
      longitudeDelta: longitudeDelta || MIN_DELTA
    };
    loadData(
      changed_region,
      {
        center,
        zoom
      },
      false
    );
  };



  const loadData = (region, map_pos) => {
    let groupSize = calculateGroupSize(map_pos.zoom)
    if (groupSize == 1) {
      if (
        Math.abs(region.latitude - mapPos.center.lat) <= region.latitudeDelta / 2 &&
        Math.abs(region.longitude - mapPos.center.lng) <= region.longitudeDelta / 2
      ) {
        console.log('ignored');
        return;
      }
    }
    else {
      if (groupSize == state.groupSize) {
        console.log('ignored by same group');
        return;
      }
    }

    if (state.loading) {
      console.log('ignored by loaading');
      return;
    }
    state.loading = true;
    console.log("group", groupSize)
    apiFactory
      .post(`users/snapfood-map-data-with-distance-with-maplocation-with-lastactive-users`, {
        user_latitude: parseFloat(myLoc.latitude),
        user_longitude: parseFloat(myLoc.longitude),
        latitude: region.latitude,
        longitude: region.longitude,
        latitudeDelta: region.latitudeDelta,
        longitudeDelta: region.longitudeDelta,
        zoom: map_pos.zoom,
        group_size: groupSize,
        distance: 20000.00
      })
      .then(
        ({ data }) => {
          console.log("snapfooders has harrived ", data)
          setState({
            ...state,
            loading: false,
            snapfooders: data.snapfooders || [],
            vendors: data.vendors || [],
            groupSize: groupSize
          });
          setMapPos(map_pos);
        },
        (error) => {
          const message = error.message || t('generic_error');
          setState({
            ...state,
            loading: false
          });
          setMapPos(map_pos);
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
        }
      );
  };


  const calculateGroupSize = (zoom) => {
    if (zoom >= 16.5) {
      return 1;
    }
    if (zoom >= 15 && zoom < 16.5) {
      return 2;
    }
    if (zoom >= 14 && zoom < 15) {
      return 3;
    }
    if (zoom < 14) {
      return 4;
    }
    return 2;
  }

  return (
    <div data-testid="view-snapfood-map" className={'view-snapfood-map'}>
      <Seo title={t('web_title.snapfood_map')} />
      <div className={'map-view'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: Config.SocialMapScreenStyles
          }}
          zoom={mapPos.zoom}
          center={mapPos.center}
          onChange={onBoundChange}>
          <UserMarker key={'me'} showPopup={true} lat={parseFloat(myLoc.latitude)} lng={parseFloat(myLoc.longitude)} />
          {state.snapfooders.map((value, index) => {
            if (value.users.length == 0) {
              return null;
            } else if (value.users.length == 1) {
              return (
                <SnapfooderMarker
                  key={'snapfooders_' + value.users[0].id}
                  lat={parseFloat(value.users[0].latitude)}
                  lng={parseFloat(value.users[0].longitude)}
                  user_id={value.users[0].id}
                  user={value.users[0]}
                  is_friend={value.users[0].is_friend}
                  onGoUserProfile={() => {
                    console.log(value.users[0].latitude, value.users[0].longitude)
                    _snapfooder.current = value.users[0].id;
                    setShowSnapfooder(true)
                  }}
                  onChat={() => {
                    onEnterChannel(value.users[0]);
                  }}
                />
              );
            } else {
              return (
                <SnapfooderGroupMarker
                  key={'snapfooders_group_' + index}
                  lat={parseFloat(value.latitude)}
                  lng={parseFloat(value.longitude)}
                  count={value.users.length}
                  onMarkerPress={() => {
                    setGroupMembers(value.users);
                    setShowGroupModal(true);
                  }}
                />
              );
            }
          })}
          {state.vendors.map((value) => (
            <VendorMarker
              key={'vendors_' + value.id}
              lat={parseFloat(value.latitude)}
              lng={parseFloat(value.longitude)}
              vendor_id={value.id}
              vendor={value}
              makerType={'social'}
              onGoVendor={() => {
                onGoVendor(value);
              }}
            />
          ))}
        </GoogleMapReact>
      </div>
      <img className={'setting-icon'} src={SettingIcon} onClick={()=> setShowSettingModal(true)}/>
      <SnapfooderModal
        isOpen={showSnapfooder}
        snapfooder_id={_snapfooder.current}
        onClose={()=>{
          setShowSnapfooder(false)
        }}
      />
      <SnapfoodersGroupModal
        showModal={showGroupModal}
        users={groupMembers || []}
        onShowDetail={(item)=>{
          console.log('onShowDetail ', item)
          _snapfooder.current = item.id;
          setShowSnapfooder(true)
        }}
        onClose={()=>{
          setShowGroupModal(false)
        }}
      />
      {
        showSettingModal &&
        <MapSettingModal
          onClose={()=>{
            setShowSettingModal(false)
          }}
        />
      }
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  coordinates: app.coordinates,
  tmpSnapfoodMapData: app.tmpSnapfoodMapData
});

SnapfoodMap.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  setVendorCart: PropTypes.func,
};

export default connect(mapStateToProps, {
  setVendorCart,
})(SnapfoodMap);
