import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import VendorSearchHome from '../../Common/VendorSearchHome';
import './index.css';
import { ROUTES_NAMES } from '../../../constants';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="home-banner ph4 pv7">
      <div className="content pt10">
        <h1 style={{visibility: 'hidden', position: 'absolute'}}>{t('landing.slogan_title')}</h1>
        <h2>{t('landing.slogan_title')}</h2>
        <h4>{t('landing.slogan_desc')}</h4>
        <VendorSearchHome />
      </div>
      <p className='wanna-work-description'>
          {t('landing.wanna_work_with_us')}
          <Link to={ROUTES_NAMES.becomePartner}>{t('landing.signup_shop')}</Link>
          {t('or')}
          <Link to={ROUTES_NAMES.becomeCourier}>{t('landing.singup_deliver')}</Link>
        </p>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(Banner, arePropsEqual);
