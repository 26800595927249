import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const PartnerInput = ({ prefix, name, value, placeholder, isRequired = false, style, onChange }) => {
  return (
    <div style={style} className='input partner-input'>
      {prefix != null && <div className={'prefix'}>{prefix}</div>}
      <input name={name} placeholder={placeholder} required={isRequired} type={'text'}
             value={value}
             onChange={(e) => {
               onChange(e.target.value);
             }} />
    </div>
  );
};

PartnerInput.propTypes = {
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  value : PropTypes.string,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func
};
export default PartnerInput;
