import React from 'react';
import './index.css';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import Svg_google from '../../assets/svgs/auth/google.svg';

const LoginGoogle = ({ onLoginResponse }) => {
  const responseGoogle = (response) => {
    onLoginResponse(response.tokenId);
  };

  const failureGoogle = () => {};

  return (
    <GoogleLogin
      clientId="82651546395-4r336st98l1570pb45idtp498fmnklcp.apps.googleusercontent.com"
      render={(renderProps) => (
        <button
          className={'socialBtn'}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}>
          <img src={Svg_google} style={{ width: 19, height: 19, objectFit: 'contain' }} />
        </button>
      )}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={failureGoogle}
      cookiePolicy={'single_host_origin'}
    />
  );
};

LoginGoogle.propTypes = {
  onLoginResponse: PropTypes.func
};
export default LoginGoogle;
