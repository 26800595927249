import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddProduct2Cart, setDeliveryInfoCart, updateCartItems } from '../../../store/actions/shop';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import { setTmpFood } from '../../../store/actions/app';
import { AuthBtn } from '../../../components/Buttons';
import CommentInput from '../../../components/Inputs/CommentInput';
import CartItem from '../CartItem';
import PropTypes from 'prop-types';
import '../../Modals/index.css';
import './index.css';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import { calculateCartTotal } from '../../../utils/common';
import { ROUTES_NAMES } from '../../../constants';
import { OrderType_Delivery } from '../../../constants/config';

const CartModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isOpen, onClose } = props;
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState('');
  const [handover_method, setHandoverMethod] = React.useState(props.handover_method || OrderType_Delivery);

  const cartSubTotal = React.useMemo(() => calculateCartTotal(props.cartItems), [props.cartItems]);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) {
      setHandoverMethod(props.handover_method || OrderType_Delivery)
    }
  }, [isOpen]);

  const onPlusItem = async (p_id) => {
    try {
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id == p_id);
      if (foundIndex != -1) {
        tmp[foundIndex].quantity = tmp[foundIndex].quantity + 1;
        await props.updateCartItems(tmp);
        console.log('onPlusItem', p_id);
      }
    } catch (error) {
      console.log('onPlusItem', error);
    }
  };

  const onMinusItem = async (p_id) => {
    try {
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id == p_id);
      if (foundIndex != -1) {
        tmp[foundIndex].quantity = tmp[foundIndex].quantity - 1;
        await props.updateCartItems(tmp);
        console.log('onMinusItem', p_id);
      }
    } catch (error) {
      console.log('onMinusItem', error);
    }
  };

  const onRemoveItem = async (p_id) => {
    try {
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id == p_id);
      if (foundIndex != -1) {
        tmp.splice(foundIndex, 1);
        await props.updateCartItems(tmp);
      }
    } catch (error) {
      console.log('onRemoveItem', error);
    }
  };

  // const onAddItem = async (p_id) => {
  //   try {
  //     if (state.promoFreeObj && state.promoFreeObj.product) {
  //       let tmp = props.cartItems.slice(0, props.cartItems.length);
  //
  //       let cartItem = state.promoFreeObj.product;
  //       cartItem.quantity = 1;
  //       cartItem.comments = '';
  //       cartItem.options = [];
  //
  //       tmp.push(cartItem);
  //       await props.updateCartItems(tmp);
  //     }
  //   } catch (error) {
  //     console.log('onPlusItem', error);
  //   }
  // };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal cart-view-modal '}>
      <div className={'align-col-start content'} style={{ padding: 20 }}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={onClose} />
          <h4 className={'title'}>{t('cart.title')}</h4>
        </div>
        <div style={{ width: '100%', marginTop: 20 }}>
          {props.cartItems.map((item) => (
            <CartItem
              key={item.id}
              data={item}
              onMinus={onMinusItem}
              onPlus={onPlusItem}
              onDelete={onRemoveItem}
            />
          ))}
          <div className={'divider'} />
          <div className={'subject-title'}>{t('cart.comment')}</div>
          <div className={'w100'}>
            <CommentInput
              placeholder={t('cart.add_comment')}
              text={comments}
              onChange={(text) => setComments(text)}
            />
          </div>
          <div className={'flex_between cart-btns'}>
            <div className={'mr4'}>
              <p>{t('cart.total')}</p>
              <h4>{cartSubTotal}L</h4>
            </div>
            <div style={{ flex: 1 }}>
              <AuthBtn
                title={t('cart.go_to_checkout')}
                style={{ width: '100%' }}
                onClick={() => {
                  props.setDeliveryInfoCart({
                    handover_method: handover_method
                  });
                  navigate(ROUTES_NAMES.checkout);
                }}
                isDisabled={props.vendorData.is_open != 1 || props.cartItems == null || props.cartItems.length == 0}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  tmpFoodData: app.tmpFoodData,
  coordinates: app.coordinates,
  cartItems: shop.items,
  vendorData: shop.vendorData
});

CartModal.propTypes = {
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  handover_method: PropTypes.string,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    is_open: PropTypes.number,
    title: PropTypes.string,
    hash_id: PropTypes.string,
    slug: PropTypes.string
  }),
  tmpFoodData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    vendor_id: PropTypes.number,
    price: PropTypes.number,
    isFav: PropTypes.number,
    image_path: PropTypes.string,
    product_options: PropTypes.array
  }),
  setTmpFood: PropTypes.func,
  AddProduct2Cart: PropTypes.func,
  updateCartItems: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setDeliveryInfoCart: PropTypes.func
};

export default connect(mapStateToProps, {
  AddProduct2Cart,
  updateCartItems,
  setTmpFood,
  setDeliveryInfoCart
})(CartModal);
