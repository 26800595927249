import React from 'react';
import { useTranslation } from 'react-i18next';
import Seo from '../../components/Seo';

const Careers = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="view-careers" className={'view-careers'}>
      <Seo title={t('web_title.careers')} />
      <h1>careers</h1>
    </div>
  );
};

export default Careers;
