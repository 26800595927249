import { Storage } from './index';
import { getCurrentLocation, locationPermissionEnabled, getAddressByCoordinates } from './location';
import { isEmpty } from '../utils/common';
import { TIRANA_CITY_LOCATION } from '../constants/config';

export const loadUserSetting = async (props, logged_user_data) => {
  try {
    let address = null;
    let location = Storage.getLastCoordinates();

    if (logged_user_data != null && location == null) {
      try {
        let hasPermission = await locationPermissionEnabled();
        if (hasPermission === 'granted') {
          location = await getCurrentLocation();
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    if (location) {
      address = await getAddressByCoordinates(location);

      await props.setAddress({
        coordinates: {
          latitude: location.latitude,
          longitude: location.longitude
        },
        address
      });
    } else {
      location = {
        latitude: TIRANA_CITY_LOCATION.latitude,
        longitude: TIRANA_CITY_LOCATION.longitude
      };
      address = {
        country: TIRANA_CITY_LOCATION.country,
        city: TIRANA_CITY_LOCATION.city,
        street: TIRANA_CITY_LOCATION.city
      };
      await props.setAddress({
        coordinates: {
          latitude: location.latitude,
          longitude: location.longitude
        },
        address
      });
    }

    if (logged_user_data != null && location) {
      if (isEmpty(logged_user_data.latitude) || isEmpty(logged_user_data.longitude)) {
        // for old users
        await props.updateProfileDetails({
          latitude: location.latitude,
          longitude: location.longitude
        });
      }
      if (address != null) {
        let address_data = {
          lat: location.latitude,
          lng: location.longitude,
          country: address.country || TIRANA_CITY_LOCATION.country,
          city: address.city || TIRANA_CITY_LOCATION.city,
          street: address.street || TIRANA_CITY_LOCATION.city
        };
        await props.addDefaultAddress(address_data);
      }
    }
    if (logged_user_data != null) {
      props.getAddresses();
      props.setAsLoggedIn();
    }
    // eslint-disable-next-line no-empty
  } catch (e) {
    console.log('load user setting ', e);
  }
};
