import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from '../layout';
import Seo from '../../../components/Seo';
import { AuthInput } from '../../../components/Inputs';
import Spacing from '../../../components/Spacing';
import { AuthBtn } from '../../../components/Buttons';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import { extractErrorMessage, isEmpty } from '../../../utils/common';
import { setHasVerifiedPhone } from '../../../store/actions/auth';
import { connect } from 'react-redux';
import apiFactory from '../../../services/apiFactory';
import { ROUTES_NAMES } from '../../../constants';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const Verification = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const type = 'sms';

  const [loading, setLoading] = useState(false);
  const [loadingResend, setResendLoading] = useState(false);

  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const [code5, setCode5] = useState('');
  const [code6, setCode6] = useState('');

  let input1 = useRef(null);
  let input2 = useRef(null);
  let input3 = useRef(null);
  let input4 = useRef(null);
  let input5 = useRef(null);
  let input6 = useRef(null);

  useEffect(() => {
    if (props.hasVerifiedPhone) {
      navigate(ROUTES_NAMES.home);
    }
    if (isEmpty(props.user?.phone)) {
      navigate(ROUTES_NAMES.edit_phone);
    }
  }, []);

  const verify = async () => {
    setLoading(true);
    apiFactory.post('verify-code', { code: code1 + code2 + code3 + code4 + code5 + code6 }).then(
      async () => {
        identifyDevice();
        trackEvent('VerifyCode', {
          action_category: 'Verify Code',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        await props.setHasVerifiedPhone(true);
        setLoading(false);
        navigate(ROUTES_NAMES.home);
      },
      async () => {
        setLoading(false);
        setCode1('');
        setCode2('');
        setCode3('');
        setCode4('');
        setCode5('');
        setCode6('');
        confirmAlert({
          title: t('attention'),
          message: t('auth_verification.wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const resend = async () => {
    const { user } = props;
    setResendLoading(true);
    apiFactory.post('send-verification-code', { phone: user.phone }).then(
      () => {
        identifyDevice();
        trackEvent('SendVerificationCode', {
          action_category: 'Send Verification Code',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setResendLoading(false);
      },
      (error) => {
        identifyDevice();
        trackEvent('SendVerificationCode', {
          action_category: 'Send Verification Code',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setResendLoading(false);
        confirmAlert({
          title: t('attention'),
          message: t(extractErrorMessage(error)),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <AuthLayout testId="view-verification" className={'view-verification'}>
      <div className="auth-form">
        <Seo title={t('web_title.verification')} />
        <h1>{t('auth_verification.header')}</h1>
        <h3>
          {type === 'sms' ? t('auth_verification.header1') : t('auth_verification.call_header1')}
          <Link to={ROUTES_NAMES.edit_phone} className={'phone-link'}>
            {props.user.phone}
          </Link>
        </h3>
        <div className={'align-middle vcode-inputs'}>
          <AuthInput
            setRef={input1}
            text={code1}
            onChange={(text) => {
              console.log(text);
              if (text == null) {
                return;
              }
              setCode1(text.slice(text.length - 1, text.length));
              if (text.length >= 1 && input2.current) {
                input2.current.focus();
              }
            }}
          />
          <AuthInput
            setRef={input2}
            text={code2}
            onChange={(text) => {
              if (text == null) {
                return;
              }
              setCode2(text.slice(text.length - 1, text.length));
              if (text.length >= 1 && input3.current) {
                input3.current.focus();
              }
            }}
          />
          <AuthInput
            setRef={input3}
            text={code3}
            onChange={(text) => {
              if (text == null) {
                return;
              }
              setCode3(text.slice(text.length - 1, text.length));
              if (text.length >= 1 && input4.current) {
                input4.current.focus();
              }
            }}
          />
          <AuthInput
            setRef={input4}
            text={code4}
            onChange={(text) => {
              if (text == null) {
                return;
              }
              setCode4(text.slice(text.length - 1, text.length));
              if (text.length >= 1 && input5.current) {
                input5.current.focus();
              }
            }}
          />
          <AuthInput
            setRef={input5}
            text={code5}
            onChange={(text) => {
              if (text == null) {
                return;
              }
              setCode5(text.slice(text.length - 1, text.length));
              if (text.length >= 1 && input6.current) {
                input6.current.focus();
              }
            }}
          />
          <AuthInput
            setRef={input6}
            text={code6}
            onChange={(text) => {
              if (text == null) {
                return;
              }
              setCode6(text.slice(text.length - 1, text.length));
            }}
          />
        </div>

        <Spacing height={32} />
        <div className={'center-align ph-20'}>
          <AuthBtn
            isLoading={loading}
            title={t('auth_verification.button')}
            onClick={() => {
              verify();
            }}
          />
        </div>
        <Spacing height={40} />
        {/*<div className={'center-align'}>*/}
        {/*  <div*/}
        {/*    className={'text-btn'}*/}
        {/*    onClick={() => {*/}
        {/*      setType(type === 'sms' ? 'call' : 'sms');*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {type === 'sms'*/}
        {/*      ? t('auth_verification.call_me_instead')*/}
        {/*      : t('auth_verification.text_me_instead')}*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Spacing height={40} />
        <div className={'center-align auth-bottom-link'}>
          <div className={'text'}>
            {type === 'sms'
              ? t('auth_verification.not_received')
              : t('auth_verification.not_called')}
          </div>
          {loadingResend ? (
            <div className={'pl2'}>
              <CircularProgress size={16} />
            </div>
          ) : (
            <div className={'btn'} onClick={() => resend()}>
              {type === 'sms' ? t('auth_verification.resend') : t('auth_verification.recall')}
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

Verification.propTypes = {
  hasVerifiedPhone: PropTypes.bool,
  user: PropTypes.shape({
    phone: PropTypes.string
  }),
  setHasVerifiedPhone: PropTypes.func
};
function mapStateToProps({ app }) {
  return {
    user: app.user,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {
  setHasVerifiedPhone
})(Verification);
