import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Seo from '../../components/Seo';
import './index.css';
import ApiFactory from '../../services/apiFactory';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/Spinner';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

const PrivacyPolicyMain = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    setLoading(true);
    ApiFactory.get(`privacy`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load Privacy Policy', {
          action_category: 'Load screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        setContent(data.page_data?.content || '');
        setLoading(false);
      },
      () => {
        identifyDevice();
        trackEvent('Load Privacy Policy', {
          action_category: 'Load screen',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        setLoading(false);
      }
    );
  }, []);

  return (
    <div data-testid="view-terms" className={'align-col-middle view-terms'}>
      <Seo title={t('web_title.privacy_policy')} />
      {loading === true ? (
        <div className={'flex_1'}>
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ width: '100%', maxWidth: 800 }}>
          <h1 style={{ width: '100%', textAlign: 'left' }}>{t('privacy_policy')}</h1>
          {ReactHtmlParser(content)}
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicyMain;
