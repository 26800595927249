import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Search } from '@styled-icons/evil';
import { CircularProgress } from '@mui/material';
import UserItem from '../../../../components/Chats/UserItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import './index.css';
import { SearchInput } from '../../../../components/Inputs';
import { Theme } from '../../../../assets';
import { ApiFactory } from '../../../../services';
import RoundIconBtn from '../../../../components/Buttons/RoundIconBtn';
import { ChevronLeft } from '@mui/icons-material';
import { setInvitationPickedUser } from '../../../../store/actions/app';
import PropTypes from 'prop-types';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';

const EarnInviteSnapfooders = (props) => {
  const _isMounted = useRef(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: null,
    snapfooders: [],
    page: 1,
    totalPages: 1
  });

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getSnapfooders(searchTerm, state.page);
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getSnapfooders = async (search, page) => {
    const params = [`name=${search}`, `page=${page}`];
    if (state.loading == true) {
      return;
    }
    setState({
      ...state,
      loading: true
    });
    ApiFactory.get(`users/snapfooders?${params.join('&')}`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load snapfooders', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const res_snapfooders = data['snapfooders'];
        if (_isMounted.current === true) {
          if (page > 1) {
            const currentSnapfooderIds = state.snapfooders.map((x) => x.id);
            const newItems = res_snapfooders['data'].filter(
              (x) => currentSnapfooderIds.indexOf(x.id) === -1
            );
            setState({
              ...state,
              loading: false,
              snapfooders: state.snapfooders.concat(newItems),
              page: res_snapfooders['current_page'],
              totalPages: res_snapfooders['last_page']
            });
          } else {
            setState({
              ...state,
              loading: false,
              snapfooders: res_snapfooders['data'] || [],
              page: res_snapfooders['current_page'],
              totalPages: res_snapfooders['last_page']
            });
          }
        }
      },
      (error) => {
        console.log(error);
        setState({
          ...state,
          loading: false
        });
      }
    );
  };

  return (
    <div style={{ flex: 1 }} className={'earninvite-snapfooders-screen'}>
      <div className={'align-middle header-view'}>
        <RoundIconBtn
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={'title flex_1'}>{t('invitation_earn.invite_user')}</div>
        <div style={{ width: 32 }} />
      </div>
      <div className={'align-col-middle w100 list'}>
        <div className={'align-middle searchview'}>
          <SearchInput
            placeholder={t('social.search.snapfooders')}
            icon={<Search size={20} color={Theme.colors.gray5} />}
            style={{ flex: 1, height: 45 }}
            value={searchTerm}
            onChange={(text) => {
              setSearchTerm(text);
              getSnapfooders(text, 1);
            }}
          />
        </div>
        <div className={'scrollview'}>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              if (state.page < state.totalPages) {
                getSnapfooders(searchTerm, state.page + 1);
              }
            }}
            hasMore={state.page < state.totalPages}
            loader={
              <div className="align-col-middle mt12 mb20" key={0}>
                <CircularProgress size={20} color="primary" />
              </div>
            }
            useWindow={true}>
            {state.loading === false && state.snapfooders.length === 0 ? (
              <NoFriendList
                title={t('social.no_snapfooders')}
                desc={t('social.no_snapfooders_desc')}
              />
            ) : (
              state.snapfooders.map((item) => (
                <UserItem
                  key={item.id}
                  item={item}
                  id={item.id}
                  invite_status={item.invite_status}
                  onClick={() => {
                    props.setInvitationPickedUser({ ...item, isFriend: false });
                    navigate(-1);
                  }}
                />
              ))
            )}
            <div style={{ height: 40 }} />
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

EarnInviteSnapfooders.propTypes = {
  setInvitationPickedUser: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn
});

export default connect(mapStateToProps, { setInvitationPickedUser })(EarnInviteSnapfooders);
