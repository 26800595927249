import React from 'react';
import { useTranslation } from 'react-i18next';
import Seo from '../../../components/Seo';
import ReactHtmlParser from 'react-html-parser';
import './index.css';

const PartnerPrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div data-testid='view-terms' className={'align-col-middle view-terms'}>
      <Seo title={t('web_title.partner_privacy_policy')} />
      <div style={{ width: '100%', maxWidth : 800 }}>
        <h1 style={{width: '100%', textAlign: 'left'}}>
          {t('privacy_merchant')}
        </h1>
        <div>
          {ReactHtmlParser(t('privacy.vendor'))}
        </div>
      </div>
    </div>
  );
};

export default PartnerPrivacyPolicy;
