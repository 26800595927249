import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES_NAMES } from '../../constants';
import Home from '../../views/home';
import Login from '../../views/auth/login';
import ResetPassword from '../../views/auth/resetpassword';
import ResetPasswordDone from '../../views/auth/resetpassword/done';
import Verification from '../../views/auth/verification';
import Contact from '../../views/contact';
import Courier from '../../views/courier';
import DownloadApp from '../../views/download';
import Partner from '../../views/partner';
import CourierPrivacyPolicy from '../../views/privacyPolicy/courierPrivacyPolicy';
import PartnerPrivacyPolicy from '../../views/privacyPolicy/partnerPrivacyPolicy';
import PrivacyPolicyMain from '../../views/privacy';
import Help from '../../views/help';
import CourierSupport from '../../views/help/courierSupport';
import CustomerSupport from '../../views/help/customerSupport';
import PartnerSupport from '../../views/help/partnerSupport';
import Careers from '../../views/careers';
import Blog from '../../views/blog';
import BlogDetails from '../../views/blog/details';
import Vendors from '../../views/vendors';
import VendorDetails from '../../views/vedorDetails';
import Categories from '../../views/categories';
import Social from '../../views/authenticated/social';
import ChatScreen from '../../views/authenticated/social/chat';
import MessagesScreen from '../../components/Common/Messages';
import MyFriends from '../../views/authenticated/social/myfriends';
import Snapfooders from '../../views/authenticated/social/snapfooders';
import Invitations from '../../views/authenticated/social/invitations';
import Snapfooder from '../../views/authenticated/social/snapfooder';
import Favorites from '../../views/authenticated/favorites';
import Promotions from '../../views/authenticated/promotions';
import Orders from '../../views/authenticated/orders';
import Profile from '../../views/authenticated/profile';
import Addresses from '../../views/authenticated/profile/addresses';
import NewAddressScreen from '../../views/authenticated/profile/add-address';
import PaymentMethods from '../../views/authenticated/profile/payment-methods';
import NewCardScreen from '../../views/authenticated/profile/add-card';
import EarnScreen from '../../views/authenticated/profile/earn-invitation';
import EarnInvitationHistory from '../../views/authenticated/profile/earninvite-history';
import EarnIniviteDetailsScreen from '../../views/authenticated/profile/earn-invitation-details';
import EarnIniviteFriendsScreen from '../../views/authenticated/profile/earninvite-friends';
import EarnIniviteSnapfoodersScreen from '../../views/authenticated/profile/earninvite-snapfooders';
import Settings from '../../views/authenticated/settings';
import SnapfoodMap from '../../views/authenticated/snapfoodMap';
import NotFound from '../../views/notFound';
import TermsConditions from '../../views/terms-conditions';
import CheckoutScreen from '../../views/checkout';
import OrderSummScreen from '../../views/authenticated/ordersumm';
import VendorsMap from '../../views/vendorsMap';
import Wallet from '../../views/authenticated/profile/wallet';
import VendorPastOrders from '../../views/authenticated/vendor-pastorders';
import AuthMiddleware from './authMiddleware';
import DeleteAccountHelp from '../../views/delete-account-help';
import Editphone from '../../views/auth/editphone';
import { defaultPayload } from '../../views/vendors/data';
import VendorsId from '../../views/vendor-id/vendors-id';

const AppRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route exact path={ROUTES_NAMES.home} element={<Home />} />
        <Route exact path={ROUTES_NAMES.login} element={<Login />} />
        <Route exact path={ROUTES_NAMES.resetPass} element={<ResetPassword />} />
        <Route exact path={ROUTES_NAMES.resetPassDone} element={<ResetPasswordDone />} />
        <Route exact path={ROUTES_NAMES.verification} element={<Verification />} />
        <Route exact path={ROUTES_NAMES.edit_phone} element={<Editphone />} />
        <Route exact path={ROUTES_NAMES.contact} element={<Contact />} />
        <Route exact path={ROUTES_NAMES.becomeCourier} element={<Courier />} />
        <Route exact path={ROUTES_NAMES.downloadApp} element={<DownloadApp />} />
        <Route exact path={ROUTES_NAMES.becomePartner} element={<Partner />} />
        <Route exact path={ROUTES_NAMES.privacyPolicy.courier} element={<CourierPrivacyPolicy />} />
        <Route exact path={ROUTES_NAMES.privacyPolicy.partner} element={<PartnerPrivacyPolicy />} />
        <Route
          exact
          path={ROUTES_NAMES.privacyPolicy.privacy_main}
          element={<PrivacyPolicyMain />}
        />
        <Route exact path={ROUTES_NAMES.help.index} element={<Help />} />
        <Route exact path={ROUTES_NAMES.help.courier} element={<CourierSupport />} />
        <Route exact path={ROUTES_NAMES.help.customer} element={<CustomerSupport />} />
        <Route exact path={ROUTES_NAMES.help.partner} element={<PartnerSupport />} />
        <Route exact path={ROUTES_NAMES.careers} element={<Careers />} />
        <Route exact path={ROUTES_NAMES.blog} element={<Blog />} />
        <Route exact path={ROUTES_NAMES.blogDetails} element={<BlogDetails />} />
        <Route exact path={ROUTES_NAMES.categories} element={<Categories />} />
        <Route exact path={ROUTES_NAMES.vendors} element={<Vendors />} />
        <Route exact path={ROUTES_NAMES.landingVendor} element={<VendorsId />} />
        <Route
          exact
          path={ROUTES_NAMES.search}
          element={<Vendors defaultPayload={defaultPayload} />}
        />
        <Route exact path={ROUTES_NAMES.vendorDetails} element={<VendorDetails />} />
        <Route exact path={'/restaurant/:vendor_hash/:vendor_name'} element={<VendorDetails />} />
        <Route exact path={ROUTES_NAMES.vendorsMap} element={<VendorsMap />} />
        <Route exact path={ROUTES_NAMES.terms_conditions} element={<TermsConditions />} />
        <Route exact path={ROUTES_NAMES.delete_account_help} element={<DeleteAccountHelp />} />

        {/* Restricted Route */}
        <Route
          exact
          path={ROUTES_NAMES.snapfoodMap}
          element={
            <AuthMiddleware>
              <SnapfoodMap />
            </AuthMiddleware>
          }
        />
        <Route
          exact
          path={ROUTES_NAMES.vendorPastOrder}
          element={
            <AuthMiddleware>
              <VendorPastOrders />
            </AuthMiddleware>
          }
        />
        <Route
          exact
          path={ROUTES_NAMES.checkout}
          element={
            <AuthMiddleware>
              <CheckoutScreen />
            </AuthMiddleware>
          }
        />
        <Route
          path={ROUTES_NAMES.social}
          element={
            <AuthMiddleware>
              <Social />
            </AuthMiddleware>
          }>
          <Route index element={<ChatScreen />} />
          <Route path={ROUTES_NAMES.chat} element={<ChatScreen />}>
            <Route path={ROUTES_NAMES.messages} element={<MessagesScreen />} />
          </Route>
          <Route path={ROUTES_NAMES.snapfooders} element={<Snapfooders />}>
            <Route path={ROUTES_NAMES.snapfooder} element={<Snapfooder />} />
          </Route>
          <Route path={ROUTES_NAMES.friends} element={<MyFriends />}>
            <Route path={ROUTES_NAMES.snapfooder} element={<Snapfooder />} />
          </Route>
          <Route path={ROUTES_NAMES.invitations} element={<Invitations />}>
            <Route path={ROUTES_NAMES.snapfooder} element={<Snapfooder />} />
          </Route>
        </Route>
        <Route
          exact
          path={ROUTES_NAMES.favorites}
          element={
            <AuthMiddleware>
              <Favorites />
            </AuthMiddleware>
          }
        />
        <Route
          exact
          path={ROUTES_NAMES.promotions}
          element={
            <AuthMiddleware>
              <Promotions />
            </AuthMiddleware>
          }
        />
        <Route
          exact
          path={ROUTES_NAMES.orders}
          element={
            <AuthMiddleware>
              <Orders />
            </AuthMiddleware>
          }
        />
        <Route
          exact
          path={ROUTES_NAMES.orderSummary}
          element={
            <AuthMiddleware>
              <OrderSummScreen />
            </AuthMiddleware>
          }
        />
        <Route
          exact
          path={ROUTES_NAMES.settings}
          element={
            <AuthMiddleware>
              <Settings />
            </AuthMiddleware>
          }
        />
        <Route
          exact
          path={ROUTES_NAMES.profile}
          element={
            <AuthMiddleware>
              <Profile />
            </AuthMiddleware>
          }>
          <Route index element={<Addresses />} />
          <Route path={ROUTES_NAMES.addresses} element={<Addresses />} />
          <Route path={ROUTES_NAMES.address} element={<NewAddressScreen />} />
          <Route path={ROUTES_NAMES.paymentmethods} element={<PaymentMethods />} />
          <Route path={ROUTES_NAMES.paymentmethod} element={<NewCardScreen />} />
          <Route path={ROUTES_NAMES.wallet} element={<Wallet />} />
          <Route path={ROUTES_NAMES.earnInvitation} element={<EarnScreen />} />
          <Route path={ROUTES_NAMES.earnInvitationHist} element={<EarnInvitationHistory />} />
          <Route path={ROUTES_NAMES.earnInvitationDetails} element={<EarnIniviteDetailsScreen />} />
          <Route path={ROUTES_NAMES.earnInviteFriend} element={<EarnIniviteFriendsScreen />} />
          <Route
            path={ROUTES_NAMES.earnInviteSnapfooder}
            element={<EarnIniviteSnapfoodersScreen />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />

        {/* Temporary redirects */}
        <Route
          path="/page/10/courier-privacy-policy"
          element={<Navigate to="/courier/privacy-policy" />}
        />
        <Route path="/page/2/privacy-policy" element={<Navigate to="/privacy-policy" />} />
        <Route path="/merchant" element={<Navigate to="/partner" />} />
        <Route path="/about" element={<Navigate to="/download-app" />} />
        <Route path="/page/4/politika-e-privatesise" element={<Navigate to="/privacy-policy" />} />
        <Route path="/page/1/terms-conditions" element={<Navigate to="/terms-conditions" />} />
        <Route
          path="/page/8/delete-snapfood-account-help"
          element={<Navigate to="/delete-account-help" />}
        />
        <Route path="/page/3/termat-dhe-kushtet " element={<Navigate to="/terms-conditions" />} />
        <Route path="/page/6/termat-dhe-kushtet " element={<Navigate to="/terms-conditions" />} />
        <Route path="/page/7/terms-conditions" element={<Navigate to="/terms-conditions" />} />
        <Route path="/auth/login" element={<Navigate to="/login?view=forgot-pass" />} />
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;
