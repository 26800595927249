import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SnapfooderAvatar from '../../../../components/Chats/SnapfooderAvatar';
import SuggestedUserItem from '../../../../components/Chats/SuggestedUser';
import { MainBtn } from '../../../../components/Buttons';
import './index.css';
import { Theme } from '../../../../assets';
import MapView from '../../../../components/Chats/MapView';
import { TIRANA_CITY_LOCATION } from '../../../../constants/config';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiFactory } from '../../../../services';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import RoundIconBtn from '../../../../components/Buttons/RoundIconBtn';
import { ChevronLeft } from '@mui/icons-material';
import LoadingSpinner from '../../../../components/Spinner';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';

const Snapfooder = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { snapfooder_id } = useParams();

  const [state, setState] = useState({
    loading: null,
    user: {}
  });
  const [isFriend, setIsFriend] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (snapfooder_id != null) {
      getSnapfoodDetail(snapfooder_id, true);
      checkFriend(snapfooder_id);
    }
    return () => {};
  }, [snapfooder_id]);

  const getSnapfoodDetail = (user_id, initLoading = false) => {
    if (initLoading == true) {
      setState({ ...state, loading: true });
    }
    ApiFactory.get(`users/snapfooders/${user_id}`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load snapfooder', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const res_snapfooder = data['snapfooder'];
        setState({
          ...state,
          user: res_snapfooder,
          loading: false
        });
      },
      () => {
        identifyDevice();
        trackEvent('UserSnapfooders', {
          action_category: 'Retrieve Snapfooders',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        setState({ ...state, loading: false });
      }
    );
  };

  const checkFriend = (snapfooder_id) => {
    ApiFactory.post(`users/friends/check`, {
      user_id: props.user.id,
      friend_id: snapfooder_id
    }).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Check Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setIsFriend(data.success == true);
      },
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Check Invites',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
      }
    );
  };

  const onSendInvitation = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/update`, {
      user_id: props.user.id,
      friend_id: state.user.id,
      status: 'invited'
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Update Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        getSnapfoodDetail(state.user.id);
        setBtnLoading(false);
      },
      (error) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Update Invites',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        setBtnLoading(false);
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: state.user.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        getSnapfoodDetail(state.user.id);
        setBtnLoading(false);
      },
      (error) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        setBtnLoading(false);
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onRemoveFriend = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: state.user.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        checkFriend(state.user.id);
        setBtnLoading(false);
      },
      (error) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        setBtnLoading(false);
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div
      className={'align-col-start  snapfooder-screen'}
      style={{ backgroundColor: Theme.colors.white }}>
      <div className={'align-middle header w100 mb4'}>
        <RoundIconBtn
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          style={{ width: 40, height: 40 }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={'flex_1'} />
      </div>
      {state.loading === true ? (
        <div className={'scroll_view flex_1'}>
          <LoadingSpinner />
        </div>
      ) : (
        <div className={'scroll_view'}>
          <SnapfooderAvatar
            full_name={state.user.username || state.user.full_name}
            photo={state.user.photo}
            birthdate={state.user.birthdate}
            country={state.user.country}
          />
          <div style={{ marginTop: 28, marginBottom: 20 }}>
            <MapView
              lat={parseFloat(state.user.latitude) || TIRANA_CITY_LOCATION.latitude}
              lng={parseFloat(state.user.longitude) || TIRANA_CITY_LOCATION.longitude}
            />
          </div>
          {state.user.suggested_users != null && state.user.suggested_users.length > 0 && (
            <div className={'align-col-start suggested-hlist'}>
              <div className={'divider'} />
              <div className={'subject-title'}>{t('chat.suggested_users')}</div>
              <div className={'suggested-list'}>
                {state.user.suggested_users.slice(0, 10).map((item) => (
                  <SuggestedUserItem
                    key={item.id}
                    id={item.id}
                    full_name={item.username || item.full_name}
                    photo={item.photo}
                    onViewProfile={() => {
                      getSnapfoodDetail(item.id);
                      checkFriend(item.id);
                    }}
                  />
                ))}
              </div>
              {/*<div className={'scrollview_hider'} />*/}
            </div>
          )}
          <div style={{ width: '100%', paddingHorizontal: 20, marginTop: 20, marginBottom: 40 }}>
            {isFriend ? (
              <div className={'remove-friend'} onClick={onRemoveFriend}>
                {t('friend_related.remove_friend')}
              </div>
            ) : (
              <MainBtn
                loading={btnLoading}
                className={state.user.invite_status == 'invited' ? 'gray7_bg' : 'cyan2_bg'}
                title={
                  state.user.invite_status == 'invited'
                    ? t('friend_related.cancel_invitation')
                    : t('friend_related.add_friend')
                }
                onClick={
                  state.user.invite_status == 'invited' ? onCancelInvitation : onSendInvitation
                }
              />
            )}
          </div>
          <div style={{ height: 20 }} />
        </div>
      )}
    </div>
  );
};

Snapfooder.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  })
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {})(Snapfooder);
