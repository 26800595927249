import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MainBtn, SwitchBtn } from '../../Buttons';
import { LocationInput, } from '../../Inputs';
import './index.css';
import { TextSelector } from '../../DropdownSelectors';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import { isEmpty } from '../../../utils/common';
import AutoSuggestionSearchInput from '../../DropdownSelectors/AutoSuggestionSearchInput';

const VendorSearchHome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ORDER_METHODS = [OrderType_Delivery, OrderType_Pickup, OrderType_Reserve];

  const [curLoc, setCurLoc] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [orderMethod, setOrderMethod] = useState(OrderType_Delivery);
  const [vendorType, setVendorType] = useState('vendors');

  const [hasEmptyLoc, setHasEmptyLoc] = useState(false);

  const onSearch = () => {
    if (curLoc.latitude == null || curLoc.longitude == null || curLoc.formatted_address == null || curLoc.formatted_address == '') {
      setHasEmptyLoc(true);
      return;
    }
    setHasEmptyLoc(false);

    let params = [];
    if (!isEmpty(curLoc.latitude == null ? null : '' + curLoc.latitude)) {
      params.push(`latitude=${curLoc.latitude}`);
    }
    if (!isEmpty(curLoc.longitude == null ? null : '' + curLoc.longitude)) {
      params.push(`longitude=${curLoc.longitude}`);
    }
    if (!isEmpty(curLoc.formatted_address)) {
      params.push(`formatted_address=${curLoc.formatted_address}`);
    }
    if (!isEmpty(vendorType)) {
      params.push(`vendor_type=${vendorType}`);
    }
    if (!isEmpty(orderMethod)) {
      params.push(`order_type=${orderMethod}`);
    }
    if (!isEmpty(searchTerm)) {
      params.push(`searchTerm=${searchTerm}`);
    }

    navigate(`/stores?${params.join('&')}`);
  };

  return (
    <div className={`vendor-search-form ${hasEmptyLoc ? 'error' : ''}`} >
      <div className={'row w100 flex_wrap'}  style={{padding: 0}}>
        <div className={'sm-flex-100 mt2'}>
          <LocationInput
            defaultInput={curLoc.formattedAddress}
            placeholder={t('vendor_search.search_by_location')}
            onChange={(place) => {
              setCurLoc(place);
            }}
            onChangeText={()=>{
              setHasEmptyLoc(false)
            }}
          />
        </div>
        <div style={{ width: 16 }} />
        <div className={'sm-flex-100  mt2'}>
          <AutoSuggestionSearchInput
            handleChange={(text) => {
              setSearchTerm(text);
            }}
          />
        </div>
      </div>
      <div className={'row w100 flex_wrap'} style={{padding: 0}}>
        <div className={'sm-flex-100 mt2'}>
          <SwitchBtn
            items={['vendors', 'grocery']}
            item={vendorType}
            onChange={(type) => {
              setVendorType(type);
            }}
          />
        </div>
        <div style={{ width: 16 }} />
        <div className={'sm-flex-100 mt2'}>
          <TextSelector
            values={ORDER_METHODS}
            value={orderMethod}
            handleChange={(text) => {
              setOrderMethod(text);
            }}
          />
        </div>
        <div style={{ width: 16 }} />
        <div className={'sm-flex-100 mt2'}>
          <MainBtn title={t('btn-search')} onClick={onSearch} />
        </div>
      </div>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(VendorSearchHome, arePropsEqual);
