import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button, Slider } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Filter } from '@styled-icons/bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CheckBoxBtn from '../../Buttons/CheckBoxBtn';
import './index.css';
import { RadioBtn } from '../../Buttons';
import { setHomeVendorFilter, setHomeVendorSort } from '../../../store/actions/app';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_NAMES } from '../../../constants';
import {
  VSort_Title,
  VSort_Closest,
  VSort_FastDelivery,
  VSort_HighRating,
  VSort_PopularFirst
} from '../../../constants/config';
import RoundIconBtn from '../../Buttons/RoundIconBtn';
import { Theme } from '../../../assets';
import VendorFilterModal from '../../Modals/VendorFilterModal';

const MAX_DELIVERY_FEE = 300;

const FilterView = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [isFilterModal, setFilterModal] = useState(false);
  const [slideValues, setSlideValues] = useState([
    props.home_vendor_filter.low_fee || 0,
    props.home_vendor_filter.high_fee || MAX_DELIVERY_FEE
  ]);
  const [isSlideExpanded, setSlideExpanded] = useState((props.home_vendor_filter.low_fee != null) || (props.home_vendor_filter.high_fee != null));
  const [isSortExpanded, setSortExpanded] = useState(props.home_vendor_sort != VSort_Title);

  useEffect(() => {
    setSlideValues([
      props.home_vendor_filter.low_fee || 0,
      props.home_vendor_filter.high_fee || MAX_DELIVERY_FEE
    ]);
    setSlideExpanded((props.home_vendor_filter.low_fee != null) || (props.home_vendor_filter.high_fee != null));
  }, [props.home_vendor_filter.low_fee, props.home_vendor_filter.high_fee]);

  useEffect(() => {
    setSortExpanded(props.home_vendor_sort != VSort_Title);
  }, [props.home_vendor_sort]);

  const FILTER_MENUS = [
    { id: 'is_meal', label: t('vendor_filter.meal') },
    { id: 'is_dietary', label: t('vendor_filter.dietary') },
    { id: 'ongoing_offer', label: t('vendor_filter.ongoing_offer') },
    { id: 'open_now', label: t('vendor_filter.open_now') },
    { id: 'online_payment', label: t('vendor_filter.accept_online_payment') },
    { id: 'delivery_by_snapfood', label: t('vendor_filter.delivery_by_snapfood') }
  ];

  const SORT_MENUS = [
    {
      id: VSort_FastDelivery,
      name: t('vendor_filter.fastest')
    },
    {
      id: VSort_HighRating,
      name: t('vendor_filter.high_rating')
    },
    {
      id: VSort_Closest,
      name: t('vendor_filter.closest')
    },
    {
      id: VSort_PopularFirst,
      name: t('vendor_filter.most_popular_first'),
      type: 'radio'
    }
  ];

  return (
    <Box role='presentation' className={'vendor-filter'}>
      <div>
        <Button
          variant='outlined'
          className='app-btn outline-btn map-btn'
          onClick={(e) => {
            e.preventDefault();
            navigate(ROUTES_NAMES.vendorsMap + location.search);
          }}>
          {t('vendor_filter.open_map')}
        </Button>
      </div>
      <div className={'flex_between w100 mv3 filter-header'}>
        <h1>{t('vendor_filter.filter_stores')}</h1>
        <RoundIconBtn
          icon={<Filter size={22} color={Theme.colors.text} />}
          style={{ width: 40, height: 40 }}
          onClick={() => {
            setFilterModal(true);
          }}
        />
      </div>
      <div className={'w100 filter-body'}>
        <List>
          {FILTER_MENUS.map((item) => (
            <ListItem
              button
              key={item.id}
              className={'list-btn'}
              onClick={(e) => {
                e.preventDefault();
                props.setHomeVendorFilter({
                  [item.id]: props.home_vendor_filter[item.id] === true ? false : true
                });
              }}>
              <ListItemText primary={item.label} />
              <CheckBoxBtn
                checked={props.home_vendor_filter[item.id] === true}
                onClick={() => {
                  props.setHomeVendorFilter({
                    [item.id]: props.home_vendor_filter[item.id] === true ? false : true
                  });
                }}
              />
            </ListItem>
          ))}
        </List>
        <Accordion
          expanded={isSlideExpanded}
          onChange={(event, newExpanded) => {
            setSlideExpanded(newExpanded);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'>
            <span>{t('vendor_filter.max_delivery_fee')}</span>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className={'w100'}
              style={{
                height: 65,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center'
              }}>
              <Slider
                max={MAX_DELIVERY_FEE}
                getAriaLabel={() => t('vendor_filter.max_delivery_fee')}
                value={slideValues}
                onChange={(event, newValue) => {
                  setSlideValues(newValue);
                }}
                onChangeCommitted={(event, newValue) => {
                  props.setHomeVendorFilter({
                    low_fee: newValue[0] === 0 ? null : newValue[0],
                    high_fee: newValue[1] === MAX_DELIVERY_FEE ? null : newValue[1]
                  });
                }}
                valueLabelDisplay='on'
                disableSwap
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <div style={{ height: 15 }} />
        <Accordion expanded={isSortExpanded}
                   onChange={(event, newExpanded) => {
                     setSortExpanded(newExpanded);
                   }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'>
            <span>{t('vendor_filter.sort')}</span>
          </AccordionSummary>
          <AccordionDetails>
            {SORT_MENUS.map((menu) => (
              <div
                key={menu.id}
                className={'flex_between w100 sort-btn'}
                onClick={() => {
                  props.setHomeVendorSort(menu.id);
                }}>
                <span>{menu.name}</span>
                <RadioBtn
                  checked={menu.id === props.home_vendor_sort}
                  onClick={() => {
                    props.setHomeVendorSort(menu.id);
                  }}
                />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
      {
        isFilterModal &&
        <VendorFilterModal
          isOpen={isFilterModal}
          onClose={() => {
            setFilterModal(false);
          }}
        />
      }
    </Box>
  );
};

FilterView.propTypes = {
  onFilterChange: PropTypes.func,
  home_vendor_filter: PropTypes.shape({
    is_meal: PropTypes.bool,
    is_dietary: PropTypes.bool,
    ongoing_offer: PropTypes.bool,
    open_now: PropTypes.bool,
    online_payment: PropTypes.bool,
    delivery_by_snapfood: PropTypes.bool,
    low_fee: PropTypes.number,
    high_fee: PropTypes.number
  }),
  home_vendor_sort: PropTypes.string,
  setHomeVendorFilter: PropTypes.func,
  setHomeVendorSort: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_vendor_filter: app.home_vendor_filter,
  home_vendor_sort: app.home_vendor_sort
});

export default connect(mapStateToProps, { setHomeVendorFilter, setHomeVendorSort })(FilterView);
