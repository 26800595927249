import React, { useEffect, useState } from 'react';
import Seo from '../../components/Seo';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';
import { SocialMapScreenStyles } from '../../constants/config';
import './index.css';
import LocationSvg from '../../assets/images/contact/location.svg';
import EmailSvg from '../../assets/images/contact/email.svg';
import PhoneSvg from '../../assets/images/contact/phone.svg';
import ApiFactory from '../../services/apiFactory';
import LocationMarker from '../../components/Markers/LocationMarker';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty, validateEmailAddress } from '../../utils/common';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';
import { Checkbox, FormControlLabel } from '@mui/material';
import { handleFbService } from '../../services/fb-service';

const Contact = () => {
  const { t } = useTranslation();
  const [info, setInfo] = useState({});
  const [contactInfo, setContactInfo] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    subscribe: false
  });

  useEffect(() => {
    ApiFactory.get(`about-us`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load About Us', {
          action_category: 'Load screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        setInfo(data.data || {});
      },
      () => {
        identifyDevice();
        trackEvent('Load About Us', {
          action_category: 'Load screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
      }
    );
  }, []);

  const onSubmit = () => {
    handleFbService({
      email: contactInfo.email,
      event_name: 'SnapFood Contact Form Submission',
      name: contactInfo.name
    });
    if (
      isEmpty(contactInfo.name) ||
      !validateEmailAddress(contactInfo.email) ||
      isEmpty(contactInfo.subject) ||
      isEmpty(contactInfo.message)
    ) {
      return confirmAlert({
        title: t('alerts.error'),
        message: t('account_details.required_fields'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    ApiFactory.post(`contact-us`, contactInfo).then(
      () => {
        identifyDevice();
        trackEvent('Contact Us', {
          action_category: 'Submit Form',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        confirmAlert({
          title: t('contact.form_title_success'),
          message: t('contact.form_message_success'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
        setContactInfo({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      },
      () => {
        identifyDevice();
        trackEvent('Contact Us', {
          action_category: 'Submit Form',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const handleCheckboxChange = (e) => {
    setContactInfo((info) => ({ ...info, subscribe: e.target.checked }));
  };

  return (
    <div data-testid="view-contact" className={'view-contact'}>
      <Seo title={t('web_title.contact')} />
      <div className="contact_wrapper">
        <div className="contact_wrapper_left">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAEe-vcJ-r8w9FQdVEskAozi1v9cWy6YAA' }}
            center={[
              info.pointer_latitude || 41.326361080392196,
              info.pointer_longitude || 19.806220329110282
            ]}
            defaultZoom={15}
            options={{
              styles: SocialMapScreenStyles
            }}>
            <LocationMarker
              lat={info.pointer_latitude || 41.326361080392196}
              lng={info.pointer_longitude || 19.806220329110282}
            />
          </GoogleMapReact>
        </div>
        <div className="contact_wrapper_right">
          <div className={'contact_form'}>
            <p>{t('contact.about_us')}</p>
            <input
              className="input"
              required
              placeholder={t('contact.name')}
              value={contactInfo.name}
              onChange={(e) => setContactInfo({ ...contactInfo, name: e.target.value })}
            />
            <input
              className="input"
              required
              type="email"
              placeholder={t('contact.email')}
              value={contactInfo.email}
              onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
            />
            <input
              className="input"
              required
              placeholder={t('contact.subject')}
              value={contactInfo.subject}
              onChange={(e) => setContactInfo({ ...contactInfo, subject: e.target.value })}
            />
            <textarea
              required
              minLength={60}
              placeholder={t('contact.message')}
              value={contactInfo.message}
              onChange={(e) => setContactInfo({ ...contactInfo, message: e.target.value })}
            />
            <div className="checkbox-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    name="subscribe"
                    checked={contactInfo?.subscribe}
                    onChange={handleCheckboxChange}
                  />
                }
                label={t('subscribe.contact')}
                style={{
                  textAlign: 'left',
                  alignItems: 'flex-start'
                }}
              />
            </div>
            <button className="" onClick={onSubmit}>
              {t('contact.submit')}
            </button>
          </div>
        </div>
      </div>
      <div className="contact_wrapper find_us">
        <h2>{t('contact.find_us')}</h2>
        <div className="contact_address">
          <img src={LocationSvg} />
          Rruga &#34;Sami Frashëri&#34;, Qendra Metropol
        </div>
        <div className="contact_address">
          <a href="mailto:info@snapfood.al">
            <img src={EmailSvg} />
            {info.company_email || 'info@snapfood.al'}
          </a>
        </div>
        <div className="contact_address">
          <a href="tel:+355697777653">
            <img src={PhoneSvg} />
            {info.company_phone || '+355697777653'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
