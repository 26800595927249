import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { MainBtn } from '../../Buttons';
import { LocationInput, } from '../../Inputs';
import { TextSelector } from '../../DropdownSelectors';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import './index.css';
import PropTypes from 'prop-types';
import AutoSuggestionSearchInput from '../../DropdownSelectors/AutoSuggestionSearchInput';

const VendorSearch = ({
  latitude,
  longitude,
  formatted_address,
  search_term,
  order_method = OrderType_Delivery,
  vendor_type = 'vendors',
  onVendorSearch
}) => {
  const { t } = useTranslation();
  const ORDER_METHODS = [OrderType_Delivery, OrderType_Pickup, OrderType_Reserve];

  const [curLoc, setCurLoc] = useState({
    latitude,
    longitude,
    formatted_address
  });
  const [formattedAddress, setFormattedAddress] = useState('');
  const [searchTerm, setSearchTerm] = useState(search_term);
  const [orderMethod, setOrderMethod] = useState(OrderType_Delivery);
  const [vendorType, setVendorType] = useState('vendors');

  const [hasEmptyLoc, setHasEmptyLoc] = useState(false);

  useEffect(() => {
    setCurLoc({
      latitude,
      longitude,
      formatted_address
    })
    setFormattedAddress(formatted_address);
  }, [latitude, longitude, formatted_address]);

  useEffect(()=>{
    setSearchTerm(search_term || '');
  }, [search_term])

  useEffect(() => {
    if (order_method != orderMethod) {
      setOrderMethod(order_method);
    }
    if (vendor_type != vendorType) {
      setVendorType(vendor_type);
    }
  }, [order_method, vendor_type]);

  const onSearch = () => {
    if (curLoc.latitude == null || curLoc.longitude == null || curLoc.formatted_address == null || curLoc.formatted_address == '') {
      setHasEmptyLoc(true);
      return;
    }
    setHasEmptyLoc(false);

    onVendorSearch({
      curLoc,
      searchTerm,
      orderMethod,
      vendorType
    });
  };

  return (
    <div className={`vendor-search-view ${hasEmptyLoc ? 'error' : ''}`} >
      <div className={'row w100 flex_wrap'}  style={{padding: 0}}>
        <div className={'sm-flex-100 mt2'}>
          <LocationInput
            defaultInput={formattedAddress}
            placeholder={t('vendor_search.search_by_location')}
            onChange={(place) => {
              setCurLoc(place);
            }}
            onChangeText={()=>{
              setHasEmptyLoc(false)
            }}
          />
        </div>
        <div style={{ width: 16 }} />
        <div className={'sm-flex-100  mt2'}>
          <AutoSuggestionSearchInput
            value={searchTerm}
            handleChange={(text) => {
              setSearchTerm(text);
            }}
          />
        </div>
        <div style={{ width: 16 }} />
        <div className={'sm-flex-100  mt2'}>
          <TextSelector
            values={ORDER_METHODS}
            value={orderMethod}
            handleChange={(text) => {
              setOrderMethod(text);
            }}
          />
        </div>
      </div>
      <div className={'row w100 flex_wrap'}  style={{padding: 0}}>
        <div className={'sm-flex-100  mt2'}>
          <RadioGroup
            className={'vendor_type_radios'}
            row
            value={vendorType}
            name="vendor_type"
            onChange={(event) => {
              setVendorType(event.target.value);
            }}>
            <FormControlLabel value="vendors" control={<Radio />} label={t('vendors')} />
            <FormControlLabel value="grocery" control={<Radio />} label={t('grocery')} />
          </RadioGroup>
        </div>
        <div style={{ width: 16 }} />
        <div className={'sm-flex-100  mt2'}>
          <MainBtn title={t('btn-search')} onClick={onSearch} />
        </div>
      </div>
    </div>
  );
};

VendorSearch.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  formatted_address: PropTypes.string,
  search_term : PropTypes.string,
  order_method: PropTypes.string,
  vendor_type: PropTypes.string,
  onVendorSearch: PropTypes.func
};

export default VendorSearch;
