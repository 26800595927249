import React from 'react';
import Quiz from 'react-quiz-component';
import { useTranslation } from 'react-i18next';
import './index.css';
import PropTypes from 'prop-types';

const QuizComponent = ({ handleSubmit, questions }) => {
  const { t } = useTranslation();
  const index = (answers) => answers.findIndex((answer) => answer.is_correct === 1);
  const quizQuestions = questions.quiz.quiz.questions.map((item) => {
    return {
      question: item.question_text ?? '',
      questionType: 'text',
      answerSelectionType: 'single',
      correctAnswer: String(Number(index(item.answers)) + 1),
      answers: item.answers.map((ans) => ans.answer_text),
      point: questions.credits_per_correct_answer ?? '0.0'
    };
  });

  const quiz = {
    quizTitle: t('quiz.rewards_title'),
    quizSynopsis: `${t('quiz.rewards_desc1')} ${Number(questions.max_earn)} ${t(
      'quiz.rewards_desc2'
    )}`,
    nrOfQuestions: '3',
    questions: quizQuestions,
    appLocale: {
      landingHeaderText: '<questionLength> ' + t('quiz.questions'),
      question: t('quiz.question'),
      startQuizBtn: t('quiz.start_quiz'),
      resultFilterAll: t('quiz.all'),
      resultFilterCorrect: t('quiz.correct'),
      resultFilterIncorrect: t('quiz.incorrect'),
      prevQuestionBtn: t('quiz.prev'),
      nextQuestionBtn: t('quiz.next')
    }
  };
  return (
    <div className="quiz-container">
      <Quiz
        quiz={quiz}
        customStyles={{ answerOption: 'quiz-answer-dropdown' }}
        showDefaultResult={false}
        onComplete={handleSubmit}
      />
    </div>
  );
};

QuizComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questions: PropTypes.any
};

export default QuizComponent;
