import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Svg_male from '../../../assets/svgs/markers/snapfooder_male.svg';
import Svg_female from '../../../assets/svgs/markers/snapfooder_female.svg';
import { useTextWidth } from '@tag0/use-text-width';
import Svg_chat from '../../../assets/svgs/chats/chat_inactive.svg';
import { getImageFullURL, findZodiacSign } from '../../../utils/common';
import { useTranslation } from 'react-i18next';
import './index.css';
import { connect } from 'react-redux';
import { setActiveSnapfoodMarker } from '../../../store/actions/app';

const SnapfooderMarker = ({
  user_id,
  user,
  is_friend,
  activeMarker,
  setActiveSnapfoodMarker,
  onGoUserProfile,
  onChat
}) => {
  const width = useTextWidth({ text: user.username || user.full_name, font: '14px Yellix-Bold' });

  const { t } = useTranslation();
  const [isPopup, ShowPopup] = useState(false);

  useEffect(() => {
    if (activeMarker.id == user_id && activeMarker.type == 'snapfood-marker') {
      ShowPopup(true);
    } else {
      ShowPopup(false);
    }
  }, [activeMarker.id, activeMarker.type]);

  return (
    <div className={'map-marker snapfooder-marker'}>
      <div className={'marker-body'}>
        <div className={['popup', !isPopup && 'hidden'].join(' ')}>
          <div className={'align-middle flex_1 user-info'}>
            <img
              src={getImageFullURL(user.photo)}
              alt={''}
              className={'avatar'}
              onClick={onGoUserProfile}
            />
            <div className={'align-start'} style={{ marginRight: 4 }} onClick={onGoUserProfile}>
              <p style={{ width: isNaN(width) ? 60 : width + 4 }}>
                {user.username || user.full_name}
              </p>
            </div>
            {user.birthdate != null && (
              <img src={findZodiacSign(user.birthdate)} className={'zodiac'} />
            )}
            {is_friend == 1 && (
              <div className={'align-middle chat-link'} style={{ marginLeft: 8 }} onClick={onChat}>
                <img src={Svg_chat} style={{ marginRight: 6 }} />
                <span>{t('bottom-chat')}</span>
              </div>
            )}
          </div>
          <div className={'anchor'} />
        </div>
        <img
          src={user.sex === 'female' ? Svg_female : Svg_male}
          onClick={(e) => {
            e.preventDefault();
            if (isPopup) {
              setActiveSnapfoodMarker(null);
            } else {
              setActiveSnapfoodMarker({
                type: 'snapfood-marker',
                id: user_id
              });
            }
          }}
        />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.user_id === nextProps.user_id &&
    prevProps.is_friend === nextProps.is_friend &&
    prevProps.lat === nextProps.lat &&
    prevProps.lng === nextProps.lng &&
    prevProps.activeMarker.id === nextProps.activeMarker.id &&
    prevProps.activeMarker.type === nextProps.activeMarker.type
  );
}

SnapfooderMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  user_id: PropTypes.number,
  user: PropTypes.shape({
    sex: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    birthdate: PropTypes.string
  }),
  activeMarker: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.number
  }),
  setActiveSnapfoodMarker: PropTypes.func,
  is_friend: PropTypes.number,
  onGoUserProfile: PropTypes.func,
  onChat: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  activeMarker: app.activeMarker
});

export default connect(mapStateToProps, {
  setActiveSnapfoodMarker
})(React.memo(SnapfooderMarker, arePropsEqual));
