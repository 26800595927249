import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './ScheduleTab.css';
import Svg_now from '../../assets/svgs/checkout/now.svg';
import Svg_schedule from '../../assets/svgs/checkout/schedule.svg';
import Svg_now_active from '../../assets/svgs/checkout/now_active.svg';
import Svg_schedule_active from '../../assets/svgs/checkout/schedule_active.svg';

const ScheduleTab = ({ curitem, onSelect }) => {
  const { t } = useTranslation();
 
  return (
    <div className={'align-middle schedule-tab'}>
      <div
        onClick={() => {
          onSelect('now');
        }}
        className={['align-middle btn', curitem === 'now' ? 'btn-active' : 'btn-inactive'].join(
          ' '
        )}>
        <img src={curitem === 'now' ? Svg_now_active : Svg_now} style={{ marginRight: 4 }} />
        <div className={['btn-txt', curitem === 'now' ? 'active_txt' : 'inactive_txt'].join(' ')}>
          {t('vendor_item.now')}
        </div>
      </div>
      <div
        onClick={() => {
          onSelect('schedule');
        }}
        className={[
          'align-middle btn',
          curitem === 'schedule' ? 'btn-active' : 'btn-inactive'
        ].join(' ')}>
        <img
          src={curitem === 'schedule' ? Svg_schedule_active : Svg_schedule}
          style={{ marginRight: 4 }}
        />
        <div
          className={['btn-txt', curitem === 'schedule' ? 'active_txt' : 'inactive_txt'].join(' ')}>
          {t('vendor_item.schedule')}
        </div>
      </div>
    </div>
  );
};

ScheduleTab.propTypes = {
  curitem: PropTypes.string,
  onSelect: PropTypes.func
};

export default ScheduleTab;
