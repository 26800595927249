import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import apiFactory from '../../../services/apiFactory';
import Svg_close from '../../../assets/svgs/profile/close.svg';
import './index.css';
import '../index.css';
import { setShowWhereHeardFeedbackModal } from '../../../store/actions/app';
import RadioBtn from '../../Buttons/RadioBtn';
import { AuthBtn } from '../../Buttons';

const WhereHeardModal = (props) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    if (props.feedback_where_tags && props.feedback_where_tags.length > 0) {
      setSelectedTag(props.feedback_where_tags[0]);
    }
  }, [props.feedback_where_tags]);

  const onClose = () => {
    props.setShowWhereHeardFeedbackModal(false);
  };

  const onSubmit = () => {
    if (selectedTag == null) {
      return;
    }
    setLoading(true);
    apiFactory
      .post(`feedback/add-feedback`, {
        tag: props.language == 'en' ? selectedTag.tag_en : selectedTag.tag_sq,
        comment: ''
      })
      .then(
        () => {
          setLoading(false);
          props.setShowWhereHeardFeedbackModal(false);
        },
        (error) => {
          setLoading(false);
          console.log('onSubmit ', error);
        }
      );
  };

  return (
    <div className={'align-col-middle  modal where-heard-feedback-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'align-middle titleview ph2 pv3'}>
          <div className={'align-middle w100'}>
            <h2>{t('feedback.where_heard')}</h2>
            <div style={{ flex: 1 }} />
            <img
              src={Svg_close}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
        <div className={'align-col-middle w100 scrollview'}>
          {props.feedback_where_tags.map((tag, index) => (
            <div key={index} onClick={() => setSelectedTag(tag)} className={'sectionContent w100'}>
              <div className={'sectionCol'}>
                <div className={'sectionRadioTitle'}>
                  {props.language == 'en' ? tag.tag_en : tag.tag_sq}
                </div>
              </div>
              <RadioBtn
                onClick={() => setSelectedTag(tag)}
                checked={selectedTag != null && selectedTag.id == tag.id}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={'w100 align-col-middle pv2 ph2 mb2'}>
        <AuthBtn
          isLoading={isLoading}
          title={t('feedback.submit')}
          onClick={onSubmit}
          className={'save-btn'}
        />
      </div>
    </div>
  );
};

WhereHeardModal.propTypes = {
  show_feedback_where_heard_modal: PropTypes.bool,
  language: PropTypes.string,
  feedback_where_tags: PropTypes.array,
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  setShowWhereHeardFeedbackModal: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  language: app.language,
  show_feedback_where_heard_modal: app.show_feedback_where_heard_modal,
  feedback_where_tags: app.feedback_where_tags
});

export default connect(mapStateToProps, {
  setShowWhereHeardFeedbackModal
})(WhereHeardModal);
