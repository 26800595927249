import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import './index.css';
import {  setShowAppSnackbar } from '../../../store/actions/app';

const AppSnackBar = (props) => {
  const { visible, description } = props;
  const handleClose  = () => {
    props.setShowAppSnackbar({visible: false, description: ''});
  };
  return (
    <Snackbar
      className={'app-snackbar-message'}
      anchorOrigin={{ vertical:'top', horizontal: 'center' }}
      open={visible}
      onClose={handleClose}
      message={description}
    />
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.visible == nextProps.visible && prevProps.description == nextProps.description
}

AppSnackBar.propTypes = {
  visible: PropTypes.bool,
  description: PropTypes.string,
  setShowAppSnackbar: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  visible: (app.appSnackbarMsg == null ? false : app.appSnackbarMsg.visible),
  description: (app.appSnackbarMsg == null ? '' : app.appSnackbarMsg.description),
});

export default connect(mapStateToProps, {
  setShowAppSnackbar
})(React.memo(AppSnackBar, arePropsEqual));