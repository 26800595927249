import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import { Link } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import { RoundIconBtn } from '../../Buttons';
import { Share } from '@styled-icons/entypo';
import { Theme } from '../../../assets';
import CartBtn from '../CartBtn';
import { Heart } from '@styled-icons/foundation';
import VendorService from '../../../services/apiVendor';
import { setVendorCart } from '../../../store/actions/shop';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const ActionMenu = (props) => {
  const { showPastOrderLink, onPressCart } = props;
  const { t } = useTranslation();

  const onPressFav = () => {
    VendorService.toggleFavourite(props.vendorData.id, props.vendorData.isFav === 1 ? 0 : 1)
      .then(() => {
        props.setVendorCart({ ...props.vendorData, isFav: props.vendorData.isFav === 1 ? 0 : 1 });
          identifyDevice();
          let eventName = props.vendorData.isFav === 1 ? 'Add Vendor To Favorite' : 'Remove Vendor From Favorite'
          trackEvent(eventName, {
          action_category: 'Action Menu',
          action_outcome: 'success',
          interaction_element: 'toggle',
          source: 'sf_web'
        });
      })
      .catch(() => {
        identifyDevice();
        trackEvent('Vendor Favorite', {
          action_category: 'Action Menu',
          action_outcome: 'fail',
          interaction_element: 'toggle',
          source: 'sf_web'
        });
      });
  };

  return (
    <div className={'action-menu align-middle'} style={{ alignItems: 'flex-end' }}>
      {showPastOrderLink && (
        <Link
          to={`/past-order/${props.vendorData.hash_id}`}
          className={'align-middle past-order-link'}>
          <span>{t('vendor_profile.my_past_orders')}</span>
        </Link>
      )}
      <RWebShare
        data={{
          title: 'Snapfood Vendor',
          url: `https://snapfood.al/store/${props.vendorData['hash_id']}/${props.vendorData['slug']}/${props.vendorData['order_method']}`
        }}
        onClick={() => {}}>
        <RoundIconBtn
          style={styles.headerBtn}
          icon={<Share size={20} color={Theme.colors.text} />}
          onClick={() => {}}
        />
      </RWebShare>
      <div
        style={{
          width: 33,
          height: 48,
          marginLeft: 10,
          justifyContent: 'flex-end',
          display: 'flex',
          alignItems: 'flex-end',
          position: 'relative'
        }}>
        <CartBtn goCart={() => onPressCart()} />
        {props.cartItems != null && props.cartItems.length > 0 && <div style={styles.cartBadge} />}
      </div>
      {props.isLoggedIn && (
        <RoundIconBtn
          style={{ ...styles.headerBtn, marginLeft: 10 }}
          icon={
            <Heart
              size={24}
              color={props.vendorData.isFav === 1 ? Theme.colors.cyan2 : Theme.colors.gray5}
            />
          }
          onClick={() => onPressFav()}
        />
      )}
    </div>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  topLogoView: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  topLogo: { width: 26, height: 26 },
  cartview: { position: 'fixed', bottom: 40, left: 0 },
  cartBadge: {
    position: 'absolute',
    top: 11,
    right: -3,
    width: 11,
    height: 11,
    borderRadius: 5.5,
    backgroundColor: Theme.colors.red1
  }
};

ActionMenu.propTypes = {
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  showPastOrderLink: PropTypes.bool,
  onPressCart: PropTypes.func,
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    isFav: PropTypes.number,
    profile_path: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    is_open: PropTypes.number,
    order_method: PropTypes.string,
    categories: PropTypes.array,
    hash_id: PropTypes.string,
    slug: PropTypes.string,
    vendor_type: PropTypes.string
  }),
  setVendorCart: PropTypes.func
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  language: app.language,
  vendorData: shop.vendorData,
  cartItems: shop.items
});
export default connect(mapStateToProps, { setVendorCart })(ActionMenu);
