import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Svg_close from '../../../assets/svgs/profile/close.svg';
import Svg_restaurant from '../../../assets/svgs/rate_restaurant.svg';
import Svg_dishes from '../../../assets/svgs/rate_dishes.svg';
import Svg_rider from '../../../assets/svgs/rate_rider.svg';
import Img_finish from '../../../assets/svgs/finish.png';
import './index.css';
import './index.css';
import { AuthBtn } from '../../Buttons';
import { confirmAlert } from 'react-confirm-alert';
import ReviewInput from './ReviewInput';
import apiFactory from '../../../services/apiFactory';
import { getOrderDetail } from '../../../store/actions/orders';
import { setTmpOrder } from '../../../store/actions/app';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const OrderReviewModal = (props) => {
  const { order_id, showModal, user, onClose } = props;
  const { t } = useTranslation();
  const data = [
    {
      icon: Svg_restaurant,
      type: 'restaurant',
      question: t('order_review.rate_restaurant'),
      placeholder: t('order_review.restaurant_comment'),
      options: [
        t('order_review.great_menu'),
        t('order_review.reasonable_price'),
        t('order_review.good_service')
      ]
    },
    {
      icon: Svg_dishes,
      type: 'dish',
      question: t('order_review.rate_dish'),
      placeholder: t('order_review.dishes_comment'),
      options: [
        t('order_review.great_dish'),
        t('order_review.tasty'),
        t('order_review.perfectly_cooked')
      ]
    },
    {
      icon: Svg_rider,
      type: 'rider',
      question: t('order_review.rate_rider'),
      placeholder: t('order_review.rider_comment'),
      options: [
        t('order_review.fast_accurate'),
        t('order_review.hot_meal'),
        t('order_review.friendly_handover')
      ]
    }
  ];
  const [step, setStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [rate_restaurant, setRateRestaurant] = useState({});
  const [rate_dishes, setRateDishes] = useState({});
  const [rate_rider, setRateRider] = useState({});

  React.useEffect(() => {
    setStep(0);
    setOpen(showModal);
  }, [showModal]);

  const getCategTxt = (options) => {
    let txt = '';
    if (options.length === 0) {
      return txt;
    }
    options.slice(0, options.length - 1).map((i) => {
      txt = txt + i + ',';
    });
    txt = txt + options[options.length - 1];
    return txt;
  };

  const onSubmitReview = () => {
    let order_review = {
      vendor_rating: rate_restaurant.rating,
      dish_rating: rate_dishes.rating,
      rider_rating: rate_rider.rating,

      vendor_categ: getCategTxt(rate_restaurant.options || []),
      dish_categ: getCategTxt(rate_dishes.options || []),
      rider_categ: getCategTxt(rate_rider.options || []),

      vendor_comment: rate_restaurant.comment,
      dish_comment: rate_dishes.comment,
      rider_comment: rate_rider.comment,

      customer_name: user.username || user.full_name
    };

    setLoading(true);
    apiFactory.post(`orders/${order_id}/review`, order_review).then(
      () => {
        identifyDevice();
        trackEvent('OrderReview', {
          action_category: 'Submit Order Review',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setLoading(false);
        getOrderDetail(order_id)
          .then((order_data) => {
            props.setTmpOrder(order_data);
          })
          .catch(() => {});
        setStep(3);
      },
      () => {
        identifyDevice();
        trackEvent('OrderReview', {
          action_category: 'Submit Order Review',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setLoading(false);
        confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onSubmit = () => {
    if (step == 0) {
      if (rate_restaurant.rating == null) {
        return confirmAlert({
          title: t('attention'),
          message: t('order_review.add_restaurant_rating'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
      setStep(1);
    } else if (step == 1) {
      if (rate_dishes.rating == null) {
        return confirmAlert({
          title: t('attention'),
          message: t('order_review.add_dishes_rating'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
      setStep(2);
    } else if (step === 2) {
      if (rate_rider.rating == null) {
        return confirmAlert({
          title: t('attention'),
          message: t('order_review.add_courier_rating'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
      onSubmitReview();
    } else if (step === 3) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal order-review-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'align-middle titleview'}>
          <img src={Svg_close} onClick={onClose} className="close-pop" />
          <h2>{t('order_summary.review_order')}</h2>
        </div>
        {step < 3 ? (
          <ReviewInput
            key={data[step].type}
            type={data[step].type}
            data={data[step]}
            changeReview={(result) => {
              if (data[step].type === 'restaurant') {
                setRateRestaurant(result);
              } else if (data[step].type === 'dish') {
                setRateDishes(result);
              } else if (data[step].type === 'rider') {
                setRateRider(result);
              }
            }}
          />
        ) : (
          <>
            <img className={'review-done-img'} src={Img_finish} />
            <p className={'review-done'}>{t('order_review.review_done')}</p>
          </>
        )}
        <div className={'flex_1 align-middle w100 actions mt12'}>
          <AuthBtn
            isLoading={loading}
            title={step < 3 ? t('order_review.next') : t('order_review.finish')}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal && prevProps.order_id === nextProps.order_id;
}

OrderReviewModal.propTypes = {
  order_id: PropTypes.number,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string,
    full_name: PropTypes.string
  }),
  changeReview: PropTypes.func,
  setTmpOrder: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, { setTmpOrder })(
  React.memo(OrderReviewModal, arePropsEqual)
);
