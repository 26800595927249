import React from 'react';
import { Link } from 'react-router-dom';
import AuthLayout from '../layout';
import Seo from '../../../components/Seo';
import { ROUTES_NAMES } from '../../../constants';
import Spacing from '../../../components/Spacing';
import { useTranslation } from 'react-i18next';
import Img_done from '../../../assets/images/reset_pass_done.png';
import './index.css';

const ResetPasswordDone = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout testId='view-login' className={'view-login'}>
      <Seo title={'Reset Password | SnapFood- Savor and Socialize'} />
      <div className='align-col-middle auth-form'>
        <img src={Img_done} className={'reset-done'} />
        <Spacing height={10} />
        <h3>{t('reset_password.success')}</h3>
        <Link to={ROUTES_NAMES.login} className={'return-login'} >{t('reset_password.return_login')}</Link>
        <Spacing height={40} />
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordDone;
