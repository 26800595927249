export const defaultPayload = {
  lat: 41.3177519,
  lng: 19.8146872,
  page: 1,
  per_page: 15,
  ordering_attribute: 'title',
  ordering_order: 1,
  vendor_type: 'Restaurant',
  order_method: 'Delivery'
};

export const expectedBlocks = [
  { key: 'suggested', icon: 'top' },
  { key: 'new', icon: 'new' },
  { key: 'exclusive', icon: 'collision' },
  { key: 'is_grocery', icon: null },
  { key: 'free_delivery', icon: null }
  // { key: 'all', icon: null },
];
