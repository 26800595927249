import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css'
import Svg_close from '../../../assets/svgs/profile/close.svg';
import Svg_curloc from '../../../assets/svgs/chats/current_location.svg';
import Svg_map from '../../../assets/svgs/chats/map.svg';
import { Theme } from '../../../assets';
import { ChevronRight } from '@styled-icons/bootstrap';

const LocationMsgOptionsModal = ({ isOpen, onClose, onCurrentLoc, onPickMap }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal location-msg-option-modal'}>
      <div className={'content'}>
        <div className={'w100  pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={onClose} />
          <h1>{t('chat.share_location')}</h1>
        </div>
        <div onClick={onCurrentLoc} className={'flex_between mt3 w100 item-btn'}>
          <div className={'align-middle'}>
            <img src={Svg_curloc} />
            <div className={'btn-text'}>{t('chat.current_location')}</div>
          </div>
          <ChevronRight size={14} color={Theme.colors.text} />
        </div>
        <div onClick={onPickMap} className={'flex_between w100 item-btn'}>
          <div className={'align-middle'}>
            <img src={Svg_map} />
            <div className={'btn-text'}>{t('chat.find_location')}</div>
          </div>
          <ChevronRight size={14} color={Theme.colors.text} />
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}

LocationMsgOptionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCurrentLoc: PropTypes.func,
  onPickMap: PropTypes.func
};

export default React.memo(LocationMsgOptionsModal, arePropsEqual);
