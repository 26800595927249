import React from 'react';
import Vendors from '../../../assets/svgs/ourapp/vendors.svg';
import Order from '../../../assets/svgs/ourapp/order.svg';
import Cart from '../../../assets/svgs/ourapp/cart.svg';

import { useTranslation } from 'react-i18next';
import './index.css';

const FoodTabs = () => {
  const { t } = useTranslation();

  return (
    <div className={'food-tabs mt10'}>
      <div className="food-tabs-left mt5"></div>
      <div className="food-tabs-right mt5">
        <h2 className={'mb10'}>{t('landing.get_your_food')}</h2>
        <div className="tab-section mb6">
          <div className="tab-img">
            <img src={Vendors} />
          </div>
          <div>
            <h5>{t('landing.explore_vendors_title')}</h5>
            <p>{t('landing.explore_vendors_description')}</p>
          </div>
        </div>
        <div className="tab-section mb6">
          <div className="tab-img">
            <img src={Cart} />
          </div>
          <div>
            <h5>{t('landing.add_cart_title')}</h5>
            <p>{t('landing.add_cart_description')}</p>
          </div>
        </div>
        <div className="tab-section mb6">
          <div className="tab-img">
            <img src={Order} />
          </div>
          <div>
            <h5>{t('landing.submit_order_title')}</h5>
            <p>{t('landing.submit_order_description')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodTabs;
