import React from 'react';
import { RadioBtn } from '../../Buttons';
import { Theme } from '../../../assets';
import './index.css';
import PropTypes from 'prop-types';

const PayMethodItem = ({ title, checked, onClick, style }) => {
  return (
    <div
      className={'align-col-middle w100 payment-method-item'}
      onClick={onClick ? onClick : () => {}}
      style={style}>
      <div className={'flex_between w100'}>
        <div className={'title fs4'} style={checked ? { fontFamily: Theme.fonts.semiBold } : {}}>
          {title}
        </div>
        <RadioBtn checked={checked} onClick={onClick ? onClick : () => {}} />
      </div>
    </div>
  );
};

PayMethodItem.propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object
};
export default PayMethodItem;
