import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css'
import { StarFill } from '@styled-icons/bootstrap';
import { Theme } from '../../../../assets';
import { Grid, Rating } from '@mui/material';
import ReviewTagItem from './TagItem';
import { CommentInput } from '../../../Inputs';

const ReviewInput = ({ data, changeReview }) => {
  const [reviewData, setReviewData] = useState({
    rating: 0,
    options: [],
    comment: ''
  });

  const isCheckedOption = (option) => {
    let found_index = reviewData.options.findIndex((i) => i === option);
    if (found_index === -1) {
      return false;
    }
    return true;
  };

  return (
    <div className={'align-col-middle order-review-input'}>
      <div className={'align-col-middle w100 mb20'}>
        <img src={data.icon} />
        <Grid container spacing={{ xs: 2, md: 2 }} style={{ margin: 0, width: '100%' }}>
          <Grid item xs={12} md={6} style={{ paddingLeft: 0 }}>
            <h3>{data.question}</h3>
          </Grid>
          <Grid item xs={12} lg={6} style={{ paddingRight: 0 }} className={'align-end'}>
            <Rating
              value={reviewData.rating}
              icon={<StarFill color={Theme.colors.red1} size={22} style={{ marginRight: 4 }} />}
              emptyIcon={
                <StarFill color={Theme.colors.gray7} size={22} style={{ marginRight: 4 }} />
              }
              onChange={(event, newValue) => {
                event.preventDefault();
                setReviewData({
                  ...reviewData,
                  rating: newValue
                });
                changeReview({
                  ...reviewData,
                  rating: newValue
                });
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={'align-row-start flex-wrap mv2'}>
        {data.options.map((item, index) => (
          <ReviewTagItem
            key={index}
            onSelect={(name) => {
              let tmp = reviewData.options.slice(0, reviewData.options.length);
              let found_index = tmp.findIndex((i) => i === name);
              if (found_index === -1) {
                tmp.push(name);
              } else {
                tmp.splice(found_index, 1);
              }
              setReviewData({
                ...reviewData,
                options: tmp
              });
              changeReview({
                ...reviewData,
                options: tmp
              });
            }}
            name={item}
            isChecked={isCheckedOption(item)}
          />
        ))}
      </div>
      <CommentInput
        placeholder={data.placeholder}
        text={reviewData.comment}
        onChange={(comment) => {
          setReviewData({
            ...reviewData,
            comment
          });
          changeReview({
            ...reviewData,
            comment
          });
        }}
      />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.type === nextProps.type;
}

ReviewInput.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({
    icon: PropTypes.element,
    question: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string
  }),
  changeReview: PropTypes.func
};
export default React.memo(ReviewInput, arePropsEqual);
