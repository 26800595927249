import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutline } from '@styled-icons/evaicons-outline';
import { Theme } from '../../../assets';
import './index.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setOrderFor } from '../../../store/actions/shop';
import ChooseFriendModal from '../../Modals/ChooseFriendModal';

const OrderForInput = (props) => {
  const { t } = useTranslation();
  const [isModal, showModal] = useState(false);

  return (
    <div className={'flex_between order-for-row mv2'}>
      <div className={'subject-title fs4'}>{t('cart.order_for')}</div>
      {
        props.order_for != null ?
          <div className={'align-middle'} >
            <span className={'choosed-friend'} >{props.order_for.username || props.order_for.full_name}</span>
            <div onClick={() => {
              props.setOrderFor(null);
            }}>
              <CloseOutline size={23} color={Theme.colors.gray7} />
            </div>
          </div>
          :
          <div
            onClick={() => {
              showModal(true);
            }}
            className={'choose-btn'}
          >
            {t('select_friend')}
          </div>
      }
      {
        isModal &&
        <ChooseFriendModal
          isOpen={isModal}
          type={'order-for-friend'}
          onClose={(friend)=>{
            showModal(false)
            props.setOrderFor(friend);
          }}
        />
      }
    </div>
  );
};


const mapStateToProps = ({ shop }) => ({
  order_for: shop.order_for
});

OrderForInput.propTypes = {
  order_for: PropTypes.object,
  setOrderFor: PropTypes.func
};

export default connect(mapStateToProps, {
  setOrderFor
})(OrderForInput);
