import { ACTION_TYPES } from '../../constants';

const initialState = {
  type: 'success',
  msg: ''
};

const alert = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_ALERT:
      return action.payload;
    case ACTION_TYPES.DISMISS_ALERT:
      return { type: 'success', msg: '' };
    default:
      return state;
  }
};

export default alert;
