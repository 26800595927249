import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthBtn } from '../../../components/Buttons';
import { AuthInput } from '../../../components/Inputs';
import Spacing from '../../../components/Spacing';
import {
  login,
  setAsLoggedIn,
  updateProfileDetails,
  facebookLogin,
  appleLogin,
  googleLogin
} from '../../../store/actions/auth';
import { addDefaultAddress, getAddresses, setAddress } from '../../../store/actions/app';
import { validateEmailAddress, extractErrorMessage, getDeviceType } from '../../../utils/common';
import './index.css';
import { ROUTES_NAMES } from '../../../constants';
import { confirmAlert } from 'react-confirm-alert';
import { loadUserSetting } from '../../../services/user';
import FaceBook from '../../../components/SocialLogin/FacebookLogin';
import Google from '../../../components/SocialLogin/GoogleLogin';
import Apple from '../../../components/SocialLogin/AppleLogin';

const LoginForm = (props) => {
  const { onChangeView } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const session_id = location?.state?.session_id;

  const [loading, setLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: ''
  });

  const _handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    });
  };

  const _login = async (e) => {
    e.preventDefault();
    const { email, password } = state;
    if (email == '' || password == '') {
      return confirmAlert({
        title: t('attention'),
        message: t('alerts.fill_all_fields'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    if (validateEmailAddress(email) === false) {
      return confirmAlert({
        title: t('attention'),
        message: t('alerts.wrong_email_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    setLoading(true);
    try {
      const logged_user_data = await props.login({
        email,
        password,
        session_id: session_id ?? undefined
      });

      await loadUserSetting(props, logged_user_data);
      setLoading(false);
      if (logged_user_data?.verified_by_mobile != true) {
        navigate(ROUTES_NAMES.verification);
      } else {
        navigate(ROUTES_NAMES.home);
      }
    } catch (e) {
      setLoading(false);
      const message = extractErrorMessage(e);

      return confirmAlert({
        title: t('restaurant_details.we_are_sorry'),
        message: t(message),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const handleFbLogin = async (accessToken) => {
    setSocialLoading(true);
    props.facebookLogin(accessToken.toString()).then(
      async (logged_user_data) => {
        await loadUserSetting(props, logged_user_data);
        setSocialLoading(false);
        if (logged_user_data?.verified_by_mobile != true) {
          navigate(ROUTES_NAMES.verification);
        } else {
          navigate(ROUTES_NAMES.home);
        }
      },
      (error) => {
        setSocialLoading(false);
        confirmAlert({
          title: t('attention'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const handleAppleLogin = async (appleAuthRequestResponse) => {
    const { user, identityToken, email, fullName } = appleAuthRequestResponse;
    setSocialLoading(true);
    props.appleLogin({ user, identityToken, email, fullName }).then(
      async (logged_user_data) => {
        await loadUserSetting(props, logged_user_data);
        navigate(ROUTES_NAMES.home);
        setSocialLoading(false);
        if (logged_user_data?.verified_by_mobile != true) {
          navigate(ROUTES_NAMES.verification);
        } else {
          navigate(ROUTES_NAMES.home);
        }
      },
      (error) => {
        setSocialLoading(false);
        confirmAlert({
          title: t('attention'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const handleGoogleLogin = (id_token) => {
    setSocialLoading(true);
    props.googleLogin(id_token).then(
      async (logged_user_data) => {
        await loadUserSetting(props, logged_user_data);
        setSocialLoading(false);
        if (logged_user_data?.verified_by_mobile != true) {
          navigate(ROUTES_NAMES.verification);
        } else {
          navigate(ROUTES_NAMES.home);
        }
      },
      (error) => {
        setSocialLoading(false);
        confirmAlert({
          title: t('attention'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const _renderSocialBtns = () => {
    if (socialLoading) {
      return (
        <div className={'align-col-middle loading-wrapper'} style={{ height: 80 }}>
          <CircularProgress size={36} color="primary" />
        </div>
      );
    } else {
      return (
        <div className={'socials align-middle'}>
          <FaceBook onLoginResponse={handleFbLogin} />
          <div style={{ width: 20 }} />
          <Google onLoginResponse={handleGoogleLogin} />
          {getDeviceType() == 'iOS' && (
            <>
              <div style={{ width: 20 }} />
              <Apple onLoginResponse={handleAppleLogin} />
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div className="auth-form">
      <h1>{t('auth_login.header')}</h1>
      {/*<h3>{t('auth_login.header1')}</h3>*/}
      <AuthInput
        name={'email'}
        placeholder={t('auth_login.email')}
        onChange={(v) => {
          _handleChange('email', v);
        }}
      />
      <AuthInput
        name={'password'}
        placeholder={t('auth_login.password')}
        isSecure={true}
        onChange={(v) => _handleChange('password', v)}
      />
      <div className={'forgot-pass'}>
        <div
          className={'forgot-pass-btn'}
          onClick={() => {
            onChangeView('forgot-pass');
          }}>
          {t('auth_login.forgot_pass')}
        </div>
      </div>
      <Spacing height={32} />
      <AuthBtn isLoading={loading} title={t('auth_login.submit')} onClick={_login} />
      <Spacing height={20} />
      {_renderSocialBtns()}
      <Spacing height={40} />
      <div className={'center-align w100 auth-bottom-link'}>
        <div className={'text mt2'}>{t('auth_login.dont_have_account')}</div>
        <div className="btn mt2" onClick={() => onChangeView('register')}>
          {t('auth_login.create_account')}
        </div>
      </div>
      <Spacing height={60} />
    </div>
  );
};

LoginForm.propTypes = {
  onChangeView: PropTypes.func,
  login: PropTypes.func,
  setAsLoggedIn: PropTypes.func,
  facebookLogin: PropTypes.func,
  appleLogin: PropTypes.func,
  googleLogin: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    user: app.user,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {
  login,
  setAsLoggedIn,
  updateProfileDetails,
  addDefaultAddress,
  getAddresses,
  setAddress,
  facebookLogin,
  appleLogin,
  googleLogin
})(LoginForm);
