import { combineReducers } from 'redux';
// import reducers
import alert from './alert';
import app from './app';
import shop from './shop';
import chat from './chat';

export default combineReducers({
  alert,
  app,
  shop,
  chat
});
