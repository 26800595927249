import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Svg_al_flag from '../../../assets/svgs/al_flag.svg';
import Svg_uk_flag from '../../../assets/svgs/uk_flag.svg';
import './index.css';

const CountrySelector = ({ country, style }) => {
  const [isOpen, setOpen] = useState(false);
  const [curValue, setValue] = useState(country);

  const countries = ['AL'];
  const { t } = useTranslation();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleText = (ev) => {
    if (ev.currentTarget.textContent === t('EN')) {
      setValue('EN');
    } else {
      setValue('AL');
    }
  };

  const itemList = () => {
    const list = countries.map((item) => (
      <div
        onClick={handleText}
        className="country-dropdown-item flex_between"
        key={item.toString()}
      >
        <img src={item === 'EN' ? Svg_uk_flag : Svg_al_flag} />
        <h1>{t(item)}</h1>
      </div>
    ));

    return <div className="country-dropdown-items"> {list} </div>;
  };

  return (
    <div
      ref={ref}
      className={isOpen ? 'country-dropdown active' : 'country-dropdown'}
      onClick={() => {
        setOpen(!isOpen);
      }}
      style={style}
    >
      <div className="country-drop-btn">
        <img src={curValue === 'EN' ? Svg_uk_flag : Svg_al_flag} />
        <span>{t(curValue)}</span>
      </div>
      {itemList()}
    </div>
  );
};

CountrySelector.propTypes = {
  country: PropTypes.string,
  style: PropTypes.object
};

export default CountrySelector;
