import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AuthBtn } from '../../components/Buttons';
import PartnerInput from '../../components/Inputs/PartnerInput';
import { CountrySelector } from '../../components/DropdownSelectors';
import DateSelector from '../../components/DropdownSelectors/DateSelector';
import { ROUTES_NAMES } from '../../constants';
import { isEmpty, validateEmailAddress, validatePhoneNumber1 } from '../../utils/common';
import VehicleType from '../../components/TabSelector/VehicleType';
import './ApplyForm.css';
import { AuthInput } from '../../components/Inputs';
import { confirmAlert } from 'react-confirm-alert';
import apiFactory from '../../services/apiFactory';

const ApplyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const phoneCode = { AL: '+355' };
  const [selected_country, setSelectCountry] = useState('AL');

  const [isLoading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    vehicle_type: 'Bike',
    full_name: '',
    gender: 'male',
    phone: '',
    email: '',
    password: '',
    year: null,
    month: null,
    day: null
  });

  const onSubmit = () => {
    if (isEmpty(info.full_name) || isEmpty(info.phone) || isEmpty(info.email) || isEmpty(info.password) || isEmpty(info.year) || isEmpty(info.month) || isEmpty(info.day)) {
      return confirmAlert({
        title: null,
        message: t('alerts.fill_all_fields'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }
    if (validateEmailAddress(info.email) === false) {
      return confirmAlert({
        title: null,
        message: t('auth_login.validate_email_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }
    if (validatePhoneNumber1(info.phone) === false) {
      return confirmAlert({
        title: null,
        message: t('auth_register.validate_cell_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }

    setLoading(true);
    apiFactory
      .post(`apply-courier`, {
        ...info,
        birthdate: `${info.year}-${parseInt(info.month) + 1}-${info.day}`
      })
      .then(
        () => {
          setLoading(false);
          confirmAlert({
            title: t('partner.apply_success'),
            message: t('partner.apply_success_desc'),
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {
                  navigate('/');
                }
              }
            ]
          });
        },
        (error) => {
          console.log(error);
          setLoading(false);
          const message = error.message || t('checkout.something_is_wrong');
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {
                }
              }
            ]
          });
        }
      );
  };


  return (
    <div className='apply-form'>
      <h2>{t('courier.apply_now')}</h2>
      <div className={'row w100 flex_wrap'}>
        <div className={'label'}>{t('courier.your_info')}</div>
        <div className={'input-wrapper'}>
          <PartnerInput
            placeholder={t('courier.full_name')}
            onChange={(value) => {
              setInfo({ ...info, full_name: value });
            }}
          />
          <div className={'w100 mt2'}>
            <RadioGroup row aria-label='gender' defaultValue={'male'} name='gender_group'
                        onChange={(e) => {
                          setInfo({ ...info, gender: e.target.value });
                        }}
            >
              <FormControlLabel value='male' control={<Radio />} label={t('courier.male')} />
              <FormControlLabel value='female' control={<Radio />} label={t('courier.female')} />
            </RadioGroup>
          </div>
          <div className={'w100 mt2'}>
            <DateSelector
              year={info.year}
              month={info.month}
              day={info.day}
              spacing={12}
              onYearChange={(year) => {
                setInfo({ ...info, year: year });
              }}
              onMonthChange={(month) => {
                setInfo({ ...info, month: month });
              }}
              onDayChange={(day) => {
                setInfo({ ...info, day: day });
              }}
            />
          </div>
          <div className={'w100 mt2'}>
            <PartnerInput
              placeholder={t('courier.email_address')}
              onChange={(value) => {
                setInfo({ ...info, email: value });
              }}
            />
          </div>
          <div className={'w100 mt2'}>
            <AuthInput
              name={'password'}
              placeholder={t('courier.password')}
              isSecure={true}
              onChange={(value) => {
                setInfo({ ...info, password: value });
              }}
            />
          </div>
          <div className={'align-middle w100'}>
            <div>
              <CountrySelector
                country={selected_country}
                style={{ height: 48 }}
                handleChange={(c) => {
                  setSelectCountry(c);
                }}
              />
            </div>
            <div style={{ width: 20 }} />
            <div className={'flex_1'}>
              <PartnerInput
                prefix={phoneCode[selected_country]}
                // placeholder={t('courier.cell')}
                onChange={(value) => {
                  setInfo({ ...info, phone: value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={'row w100 flex_wrap'}>
        <div className={'label'}>{t('courier.vehicle_type')}</div>
        <div className={'input-wrapper'}>
          <VehicleType
            onChange={(type) => {
              setInfo({ ...info, vehicle_type: type });
            }}
          />
        </div>
      </div>
      <div className={'row terms-txt'}>
        <p>
          {t('courier.apply_terms1')}
          <Link to={ROUTES_NAMES.privacyPolicy.courier}>{t('courier.rider_privacy_policy')}</Link>
          {t('courier.apply_terms2')}
        </p>
      </div>
      <div className={'align-col-middle'}>
        <AuthBtn
          title={t('courier.apply')}
          isLoading={isLoading}
          onClick={onSubmit}
          style={{
            width: 240,
            height: 48
          }}
        />
      </div>
      <div className={'align-col-middle login-txt'}>
        <p>
          {t('or')}
          <Link to={ROUTES_NAMES.login} style={{ paddingLeft: 3 }}>
            {t('auth_login.submit')}
          </Link>
          <span> {t('courier.if_already_have_account')}</span>
        </p>
      </div>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(ApplyForm, arePropsEqual);
