import React, { useEffect, useRef, useState } from 'react';
import Seo from '../../../components/Seo';
import { Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FoodItem from '../../../components/Vendor/FoodItem';
import VendorItem from '../../../components/Vendor/VendorItem';
import CommonTabs from '../../../components/TabSelector/CommonTabs';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { getProductFavourites, getVendorFavourites } from '../../../store/actions/vendors';
import NoFavs from '../../../components/Empty/NoFavs';
import FoodScreen from '../../../components/Vendor/FoodDetails';
import PropTypes from 'prop-types';
import { setTmpFood, setAppHeaderClass } from '../../../store/actions/app';
import LoadingSpinner from '../../../components/Spinner';

const Favorites = (props) => {
  const _isMounted = useRef(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [opType, setOpType] = useState('favorites.vendors');
  const [vendors, setVendors] = useState([]);
  const [items, setItems] = useState([]);

  const [vendorLoading, setVendorLoading] = useState(null);
  const [itemsLoading, setItemsLoading] = useState(null);
  const [openFood, setOpenFood] = useState(false);

  useEffect(() => {
    props.setAppHeaderClass('app-sticky-header');
    loadVendorFavorites();
    loadProductFavorites();

    return () => {
      _isMounted.current = false;
      props.setAppHeaderClass('');
    };
  }, []);

  const loadVendorFavorites = () => {
    setVendorLoading(true);
    getVendorFavourites()
      .then((data) => {
        if (_isMounted.current === true) {
          setVendors(data);
          setVendorLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setVendorLoading(false);
        }
      });
  };

  const loadProductFavorites = () => {
    setItemsLoading(true);
    getProductFavourites()
      .then((data) => {
        if (_isMounted.current === true) {
          setItems(data);
          setItemsLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setItemsLoading(false);
        }
      });
  };

  const goVendorDetails = (vendor) => {
    navigate(`/store/${vendor.hash_id}/${vendor.slug}/${vendor.order_method}`);
  };
  const goFoodDetail = (food_data) => {
    props.setTmpFood(food_data);
    setOpenFood(true);
  };

  const _renderItems = () => {
    if (itemsLoading === true) {
      return <div className={'flex_1'}><LoadingSpinner /></div>
    }
    if (itemsLoading === false && items.length === 0) {
      return <NoFavs isVendor={false} style={{ marginTop: 40 }} />;
    }
    return items.map((food) => (
      <FoodItem
        key={food.id}
        id={food.id}
        isFav={food.isFav}
        isLike={food.isLike}
        data={food}
        onClick={() => {
          goFoodDetail(food)
        }}
        onLike={() => {}}
        onFavChange={loadProductFavorites}
      />
    ));
  };

  const _renderVendors = () => {
    if (vendorLoading === true) {
      return <div className={'flex_1'}><LoadingSpinner /></div>
    }
    if (vendorLoading === false && vendors.length === 0) {
      return <NoFavs isVendor={true} style={{ marginTop: 40 }} />;
    }
    return vendors.map((vendor) => (
      <VendorItem
        key={vendor.id}
        id={vendor.id}
        isFav={vendor.isFav}
        isOpen={vendor.is_open}
        data={vendor}
        onSelect={() => {goVendorDetails(vendor)}}
        onFavChange={loadVendorFavorites}
      />
    ));
  };

  return (
    <div data-testid="view-favorites" className={'view-favorites ph4'}>
      <Seo title={t('web_title.favorites')} />
      <Container fluid className={'container max-lg'}>
        <Row>
          <h1>{t('favorites.title')}</h1>
        </Row>
        <Row>
          <div className={'align-middle tab-wrapper'}>
            <CommonTabs
              items={['favorites.vendors', 'favorites.items']}
              item={opType}
              onChange={(type) => {
                setOpType(type);
              }}
              style={{ minWidth: 160 }}
            />
          </div>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 80 }} justify={'between'}>
          {opType === 'favorites.items' ? _renderItems() : _renderVendors()}
        </Row>
      </Container>
      {
        openFood && <FoodScreen isOpen={openFood} onClose={() => setOpenFood(false)} />
      }
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn,
});

Favorites.propTypes = {
  vendorData: PropTypes.shape({
    isFav: PropTypes.number
  }),
  user: PropTypes.shape({
    photo: PropTypes.string,
    cashback_amount: PropTypes.number
  }),
  setTmpFood: PropTypes.func,
  setAppHeaderClass : PropTypes.func
};

export default connect(mapStateToProps, {
  setTmpFood, setAppHeaderClass
})(Favorites);
