import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES_NAMES } from '../../constants';
import Logo from '../Logo';
import './index.css';
import { Menu2Outline } from '@styled-icons/evaicons-outline';
import { User } from '@styled-icons/boxicons-solid';
import { QrScan } from '@styled-icons/boxicons-regular';
import { Theme } from '../../assets';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSideBar } from '../../store/actions/app';
import { getImageFullURL } from '../../utils/common';
import Svg_cart from '../../assets/svgs/sidebar/cart.svg';
import GetAppModal from '../Modals/GetAppModal';
import LanguageModal from '../Modals/LanguageModal';
import { Earth } from '@styled-icons/remix-line';

const Header = ({ headerClass, toggleSideBar, user, language, isLoggedIn, cartItems, hasBorderBottom = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isLangModal, showLangModal] = useState(false);
  const [stickyClass, setStickyClass] = useState('');
  const [appModal, showAppModal] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setStickyClass('app-sticky-header') : setStickyClass('');
    }
  };

  const getLogoType = () => {
    if (stickyClass == '' && location && location.pathname) {
      if (location.pathname.includes('/blog') || location.pathname.includes('/categories') || location.pathname.includes('/stores')) {
        return 'black';
      }
    }
    return '';
  };

  return (
    <div
      className={
        `header ` +
        (hasBorderBottom ? 'header-bottom-border' : '') +
        ' ' + headerClass + ` ${stickyClass}`
      }>
      <div className={'align-row-start left-side ph2'}>
        <Button
          className={'drawerBtn'}
          onClick={(e) => {
            e.preventDefault();
            toggleSideBar();
          }}>
          <Menu2Outline color={Theme.colors.text} size={28} />
        </Button>
        <div className={'header-logo'}>
          <Logo type={getLogoType()} />
          {location.pathname === ROUTES_NAMES.becomePartner && (
            <div className={'vendor'}>{t('header.vendors')}</div>
          )}
        </div>
      </div>

      <div className={'right-side pr2'}>
        <div
          className={'hide-sm align-middle scan-button'}
          onClick={() => {
            showAppModal(true);
          }}
        >
          <QrScan size={24} color={Theme.colors.primary} />
          <span>{t('header.get_app')}</span>
        </div>
        {isLoggedIn == true ? (
          <div className={'align-row-start'} style={{ width: "auto" }}>
            <Link to={ROUTES_NAMES.profile} className={'align-row-start hide-sm user-info'}>
              <img src={getImageFullURL(user.photo)} />
              <h2 className={'pl-10'}>{user.full_name}</h2>
            </Link>
            <Link to={(cartItems != null && cartItems.length > 0) ? ROUTES_NAMES.checkout : '#'}
              className={'cart-btn pos_relative'}>
              <img src={Svg_cart} />
              {
                cartItems != null && cartItems.length > 0 &&
                <div className={'align-col-middle cart-badge'}>{cartItems.length}</div>
              }
            </Link>
          </div>
        ) : (
          <>
            <div
              className={'align-col-middle login-button'}
              onClick={() => {
                navigate(ROUTES_NAMES.login);
              }}
            >
              <User size={24} color={Theme.colors.white} />
            </div>
            <Link
              to={'#'}
              className={'align-middle'}
              onClick={() => {
                showLangModal(true);
              }}>
              <Earth color={'#222'} size={24} />
              <span className={'pl1'}>
                {language == 'en' ? t('english') : t('albanian')}
              </span>
            </Link>
          </>
        )}
      </div>
      {
        appModal &&
        <GetAppModal
          isOpen={appModal}
          onClose={() => showAppModal(false)}
        />
      }
      {isLangModal && <LanguageModal isOpen={isLangModal} onClose={() => showLangModal(false)} />}
    </div>
  );
};

Header.propTypes = {
  headerClass: PropTypes.string,
  user: PropTypes.shape({
    full_name: PropTypes.string,
    photo: PropTypes.string
  }),
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  toggleSideBar: PropTypes.func,
  hasBorderBottom: PropTypes.bool,
  language: PropTypes.string,
};

function mapStateToProps({ app, shop }) {
  return {
    user: app.user,
    isLoggedIn: app.isLoggedIn,
    hasVerifiedPhone: app.hasVerifiedPhone,
    isSidebarOpened: app.isSidebarOpened,
    headerClass: app.headerClass,
    language: app.language,
    cartItems: shop.items
  };
}

export default connect(mapStateToProps, {
  toggleSideBar
})(Header);
