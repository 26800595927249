import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';
import { Config } from '../../../constants';
import { isEmpty } from '../../../utils/common';
import {
  // MemorizedDiscount,
  MemorizedPriceItem,
  MemorizedFoodTitle,
  MemorizedFoodDesc,
  MemorizedFavBtn,
  MemorizedLogoImg
} from './ValueItems';
import './index.css';
import { toggleProductFavourite } from '../../../store/actions/vendors';

const FoodItem = ({ id, isFav, data, cartEnabled, cartCnt, style, onClick, onFavChange }) => {

  const onPressFav = () => {
    toggleProductFavourite(id, isFav === 1 ? 0 : 1)
      .then(() => {
        data.isFav = isFav == 1 ? 0 : 1;
        onFavChange(data);
      })
      .catch(() => {
      });
  };

  return (
    <Col key={id} xs={12} md={6} lg={4}>
      <div className={'align-middle food-item ' + ((cartEnabled == true && cartCnt > 0) ? 'food-item-cart-border' : '')}
           style={style} onClick={onClick}>
        <div className={'align-col-middle flex_1 pr1 h100'} >
          <div className={'align-row-start'}>
            {cartEnabled == true && cartCnt > 0 &&
            <div className={'cart-cnt'}>
              {cartCnt} x
            </div>
            }
            <MemorizedFoodTitle text={data.title} />
            {/*<MemorizedDiscount value={data.discount} />*/}
          </div>
          <div className={'align-row-start mt1'}>
            <MemorizedPriceItem value={data.price} discount={data.discount} />
          </div>
          <div className={'w100 mt1 flex_1'}>
            <MemorizedFoodDesc text={data.description} />
          </div>
        </div>
        <div className={'img-view'}>
          {!isEmpty(data.image_thumbnail_path) && (
            <MemorizedLogoImg
              url={`${Config.IMG_BASE_URL}${data.image_thumbnail_path}?w=200&h=200`}
            />
          )}
          <MemorizedFavBtn isFav={isFav} onChange={onPressFav} />
        </div>
      </div>
    </Col>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.id === nextProps.id &&
    prevProps.isFav === nextProps.isFav &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.onLike === nextProps.onLike &&
    prevProps.onFavChange === nextProps.onFavChange
  );
}

FoodItem.propTypes = {
  id: PropTypes.number,
  isFav: PropTypes.number,
  isLike: PropTypes.bool,
  cartEnabled: PropTypes.bool,
  cartCnt: PropTypes.number,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image_thumbnail_path: PropTypes.string,
    price: PropTypes.number,
    discount: PropTypes.number,
    isFav: PropTypes.number
  }),
  style: PropTypes.object,
  onClick: PropTypes.func,
  onLike: PropTypes.func,
  onFavChange: PropTypes.func
};
export default React.memo(FoodItem, arePropsEqual);
