// mixpanelUtil.js
import mixpanel from 'mixpanel-browser';
import {Storage} from "../services";

export const initializeMixpanel = () => {
  const token = '12dfedc3c52bab4bc3fbae7a51c3da08';
  mixpanel.init(token, { debug: true, track_pageview: true, persistence: 'localStorage' });
};

export const identifyDevice = () => {
  mixpanel.identify(Storage.getDeviceUniqueId());
};

export const trackEvent = (eventName, properties) => {
  mixpanel.track(eventName, properties);
};
