import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { CommonTabs } from '../../../../components/TabSelector';
import InvitationItem from '../../../../components/Chats/InvitationItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import { ApiFactory } from '../../../../services';
import { getFriends, setInvitationsPageData } from '../../../../store/actions/app';
import './index.css';
import Seo from '../../../../components/Seo';
import { Grid } from '@mui/material';
import ChatProfile from '../../../../components/Chats/ChatProfile';
import { isEmpty } from '../../../../utils/common';
import LoadingSpinner from '../../../../components/Spinner';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';

const Invitations = (props) => {
  const _isMounted = useRef(true);
  const { snapfooder_id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { tab, receivedItems, sentItems } = props;

  const [sentLoading, setSentLoading] = useState(null);
  const [receivedLoading, setReceivedLoading] = useState(null);

  useEffect(() => {
    seenInvitation();
    getAllInvites();
    getAllSents();

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getAllInvites = () => {
    setReceivedLoading(true);
    ApiFactory.get(`users/invitations`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load Invitations', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const res_invitations = data['invitations'];
        if (_isMounted.current == true) {
          setReceivedLoading(false);
          props.setInvitationsPageData({ receivedItems: res_invitations });
        }
      },
      (error) => {
        identifyDevice();
        trackEvent('UserInvitations', {
          action_category: 'Retrieve Invitations',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        setReceivedLoading(false);
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const getAllSents = () => {
    setSentLoading(true);
    getFriends('invited')
      .then((data) => {
        if (_isMounted.current == true) {
          setSentLoading(false);
          props.setInvitationsPageData({ sentItems: data });
        }
      })
      .catch(() => {
        setSentLoading(false);
      });
  };

  const seenInvitation = () => {
    ApiFactory.post(`users/invitations/seen`, {
      friend_id: props.user.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserInvitations', {
          action_category: 'Mark Invitations as Seen',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
      },
      () => {
        identifyDevice();
        trackEvent('UserInvitations', {
          action_category: 'Mark Invitations as Seen',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
      }
    );
  };

  const replyInvitation = (item, status) => {
    ApiFactory.post(`users/friends/update`, {
      user_id: item.id,
      friend_id: props.user.id,
      status: status
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Update Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        getAllInvites();
      },
      (error) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Update Invites',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = (item) => {
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: item.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        getAllSents();
      },
      (error) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div style={{ flex: 1 }} className={'invitation-screen'}>
      <Seo title={t('web_title.invitation')} />
      <Grid container spacing={{ xs: 2, md: 2 }} style={{ margin: 0, width: '100%' }}>
        <Grid
          item
          xs={12}
          lg={4}
          className={!isEmpty(snapfooder_id) ? 'hide-md left-side' : 'left-side'}>
          <ChatProfile />
          <div className={'align-col-middle w100 list'}>
            <div className={'align-middle operation-tab ph2 pv2'}>
              <CommonTabs
                className={'w100'}
                items={['received', 'sent']}
                item={tab}
                style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
                onChange={(item) => {
                  props.setInvitationsPageData({ tab: item });
                }}
              />
            </div>
            <div className={'flex_1 scrollview'}>
              <div className={tab == 'received' ? 'show' : 'hidden'}>
                {receivedLoading === true ? (
                  <div className={'flex_1'}>
                    <LoadingSpinner />
                  </div>
                ) : receivedLoading == false && receivedItems.length === 0 ? (
                  <NoFriendList
                    title={t('social.no_invitations')}
                    desc={t('social.no_received_invitations')}
                  />
                ) : (
                  receivedItems.map((item) => (
                    <InvitationItem
                      isSent={false}
                      key={item.id}
                      id={item.id}
                      item={item}
                      style={{ width: '100%', marginBottom: 12 }}
                      onClick={() => {
                        navigate(`/social/invitations/${item.id}`);
                      }}
                      onAccept={() => {
                        replyInvitation(item, 'accepted');
                      }}
                      onDecline={() => {
                        replyInvitation(item, 'canceled');
                      }}
                    />
                  ))
                )}
                <div style={{ height: 40 }} />
              </div>
              <div className={tab == 'sent' ? 'show' : 'hidden'}>
                <div style={{ height: 20 }} />
                {sentLoading == false && sentItems.length === 0 ? (
                  <NoFriendList
                    title={t('social.no_invitations')}
                    desc={t('social.no_sent_invitations')}
                  />
                ) : (
                  sentItems.map((item) => (
                    <InvitationItem
                      isSent={true}
                      key={item.id}
                      id={item.id}
                      item={item}
                      style={{ width: '100%', marginBottom: 12 }}
                      onClick={() => {
                        navigate(`/social/invitations/${item.id}`);
                      }}
                      onCancel={() => {
                        onCancelInvitation(item);
                      }}
                    />
                  ))
                )}
                <div style={{ height: 40 }} />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className={'align-col-start'} style={{ padding: 0 }}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

Invitations.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  tab: PropTypes.string,
  receivedItems: PropTypes.array,
  sentItems: PropTypes.array,
  setInvitationsPageData: PropTypes.func
};
const mapStateToProps = ({ app }) => ({
  user: app.user,
  tab: app.chat_invitation_page_data.tab || 'received',
  receivedItems: app.chat_invitation_page_data.receivedItems || [],
  sentItems: app.chat_invitation_page_data.sentItems || []
});

export default connect(mapStateToProps, { setInvitationsPageData })(Invitations);
