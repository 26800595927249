import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rating } from '@mui/material';
import { StarFill } from '@styled-icons/bootstrap';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';
import './index.css';

const OrderReviewItem = ({ vendor_rating, product_rating, rider_rating }) => {
  const { t } = useTranslation();

  return (
    <div className={'align-col-middle order-review-item'}>
      <h3>{t('order_review.your_reviewed')}</h3>
      <div className={'align-middle w100'}>
        <div className={'align-col-middle flex_1'}>
          <p>{t('order_review.restaurant')}</p>
          <Rating
            value={vendor_rating}
            precision={0.5}
            readOnly={true}
            icon={<StarFill color={Theme.colors.red1} size={16} style={{ marginRight: 4 }} />}
            emptyIcon={<StarFill color={Theme.colors.gray7} size={16} style={{ marginRight: 4 }} />}
          />
        </div>
        <div className={'align-col-middle flex_1'}>
          <p>{t('order_review.dish')}</p>
          <Rating
            value={product_rating}
            precision={0.5}
            readOnly={true}
            icon={<StarFill color={Theme.colors.red1} size={16} style={{ marginRight: 4 }} />}
            emptyIcon={<StarFill color={Theme.colors.gray7} size={16} style={{ marginRight: 4 }} />}
          />
        </div>
        <div className={'align-col-middle flex_1'}>
          <p>{t('order_review.rider')}</p>
          <Rating
            value={rider_rating}
            precision={0.5}
            readOnly={true}
            icon={<StarFill color={Theme.colors.red1} size={16} style={{ marginRight: 4 }} />}
            emptyIcon={<StarFill color={Theme.colors.gray7} size={16} style={{ marginRight: 4 }} />}
          />
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.vendor_rating === nextProps.vendor_rating &&
    prevProps.product_rating === nextProps.product_rating &&
    prevProps.rider_rating === nextProps.rider_rating
  );
}

OrderReviewItem.propTypes = {
  vendor_rating: PropTypes.number,
  product_rating: PropTypes.number,
  rider_rating: PropTypes.number
};
export default React.memo(OrderReviewItem, arePropsEqual);
