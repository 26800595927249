import React
// { useEffect, useState }
from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import PropTypes from 'prop-types';
import './index.css';
import FeatureItem from './FeatureItem';
import { useTranslation } from 'react-i18next';
import Svg_message from '../../../assets/svgs/home/message.svg';
import Svg_map from '../../../assets/svgs/home/map.svg';
import Svg_plus from '../../../assets/svgs/home/plus.svg';


const responsive = {
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  md: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  small: {
    breakpoint: { max: 350, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const FeaturedCards = () => {
  const { t } = useTranslation();

  const data = [
    {img : Svg_message, title : t('landing.card_message_title'), descs : [t('landing.card_message_desc1'),t('landing.card_message_desc2'),t('landing.card_message_desc3') ]},
    {img : Svg_map, title : t('landing.card_map_title'), descs : [t('landing.card_map_desc1'),t('landing.card_map_desc2'),t('landing.card_map_desc3') ]},
    {img : Svg_plus, title : t('landing.card_story_title'), descs : [t('landing.card_story_desc1'),t('landing.card_story_desc2'),t('landing.card_story_desc3') ]},
  ]

  return (
    <div className={'w100 home-featured-cards-block ph10 mt5'}>
      <Carousel responsive={responsive} autoPlay={false} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]}>
        {data.map((item, index) => (
          <div key={index} className={'ph2 pv2 h100'}>
            <FeatureItem
              data={item}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

FeaturedCards.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default FeaturedCards;
