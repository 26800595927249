import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioBtn } from '../../Buttons';
import './index.css';
import { connect } from 'react-redux';

const AddressItem = ({
                       hasRadio = true,
                       showNotes = false,
                       outOfDeliveryArea = false,
                       active = false,
                       editable = true,
                       data,
                       user,
                       style,
                       onClick,
                       onEdit
                     }) => {
  const { t } = useTranslation();
  return (
    <div className={'address-item'} style={style} onClick={onClick}>
      {
        editable != false &&
        <div className={'flex_between title-view'}>
          <div className={'address-type'}>{t(data.address_type)}</div>
          {data.favourite == 1 && <div className={'primary ml1'}>{t('primary')}</div>}
          <div className={'flex_1'} />
          <div className={'align-middle'}>
            <p
              className={'edit-link'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEdit();
              }}>
              {t('edit')}
            </p>
            {hasRadio && (
              <div className={'radio'}>
                <RadioBtn checked={active} onClick={onClick} />
              </div>
            )}
          </div>
        </div>
      }
      <div className={'info'}>
        <p>{user.phone}</p>
        <p>
          {data.street} {data.city}, {data.country}
        </p>
        {outOfDeliveryArea == true && (
          <p className={'out_delivery_area_txt'}>{t('cart.out_of_range_address')}</p>
        )}
        {showNotes == true && data.notes != null && data.notes != '' && <p>{data.notes}</p>}
      </div>
    </div>
  );
};

AddressItem.propTypes = {
  id: PropTypes.string,
  hasRadio: PropTypes.bool,
  favourite: PropTypes.number,
  showNotes: PropTypes.bool,
  active: PropTypes.bool,
  editable: PropTypes.bool,
  outOfDeliveryArea: PropTypes.bool,
  data: PropTypes.shape({
    address_type: PropTypes.string,
    favourite: PropTypes.number,
    full_name: PropTypes.string,
    phone: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    notes: PropTypes.string
  }),
  user : PropTypes.shape({
    phone: PropTypes.string
  }),
  style: PropTypes.object,
  onClick: PropTypes.func,
  onEdit: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    user: app.user,
  };
}

export default connect(mapStateToProps, {
})(AddressItem);
