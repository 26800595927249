import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../components/Logo';
import LangBtn from '../../../components/DropdownSelectors/Lang';
import './index.css';

const AuthLayout = ({ testId, className, children }) => {
  return (
    <div data-testid={testId} className={className}>
      <div className={'lang-bar'}>
        <LangBtn />
      </div>

      <div className={'center-align'}>
        <div className="auth-screen">
          <Logo className="logo" />
          {children}
        </div>
      </div>
    </div>
  );
};
AuthLayout.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element
};

export default AuthLayout;
