import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { MainBtn } from '../Buttons';
import './index.css';
import Svg_close from '../../assets/svgs/modals/close.svg';
import AddressItem from '../Cart/AddressItem';
import {
  deleteAddress,
  getAddresses,
  saveAddress,
  setLocallyAddresses,
  setTmpLocationPicked
} from '../../store/actions/app';
import { ROUTES_NAMES } from '../../constants';

const DeliveryAddressModal = (props) => {
  const { isOpen, addresses } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState(
    addresses.find((i) => i.favourite == 1)
  );

  React.useEffect(() => {
    setOpen(isOpen);
    if (isOpen) {
      setSelectedAddress(addresses.find((i) => i.favourite == 1));
    }
  }, [isOpen]);

  const onEditAddress = (addressItem) => {
    let tmpAddress = {
      ...addressItem,
      coords: {
        latitude: addressItem.lat,
        longitude: addressItem.lng
      }
    };
    props.setTmpLocationPicked(tmpAddress);
    navigate(ROUTES_NAMES.checkout + '?modal=new-address');
  };
  //
  // const onSave = async () => {
  //   if (selectedAddress == null) {
  //     return;
  //   }
  //   try {
  //     let item = { ...selectedAddress };
  //     item.favourite = 1;
  //     await saveAddress(item);
  //     await props.getAddresses();
  //     navigate(-1);
  //     // eslint-disable-next-line no-empty
  //   } catch (error) {}
  // };
  //
  // const onDeleteAddress = async () => {
  //   try {
  //     ShowConfirmModal(false);
  //     if (selectedAddress == null) {
  //       return;
  //     }
  //     await props.deleteAddress(selectedAddress.id);
  //     await props.getAddresses();
  //     // eslint-disable-next-line no-empty
  //   } catch (error) {}
  // };

  const onSelectAddress= async (address)=>{
    if (address == null) {
      return;
    }
    try {
      setSelectedAddress(address);
      let item = { ...address };
      item.favourite = 1;
      await saveAddress(item);
      await props.getAddresses();
      navigate(-1);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  const onAdd = () => {
    props.setTmpLocationPicked({});
    navigate(ROUTES_NAMES.checkout + '?modal=new-address');
  };

  const onClose = () => {
    navigate(ROUTES_NAMES.checkout);
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal delivery-address-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100  pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={onClose} />
          <h4 className={'title'}>{t('address.delivery_address')}</h4>
        </div>
        <div className={'w100 align-col-middle list'}>
          {addresses.map((item) => (
            <AddressItem
              key={item.id}
              id={item.id}
              favourite={item.favourite}
              data={item}
              active={selectedAddress != null && selectedAddress.id == item.id}
              onClick={() => {
                console.log('onSelectAddressonSelectAddressonSelectAddress ')
                onSelectAddress(item);
              }}
              onEdit={() => {
                onEditAddress(item);
              }}
            />
          ))}
          <p className={'add-btn'} onClick={onAdd}>
            {t('address.add_new_address')}
          </p>
        </div>
        {/*<div className={'flex_1 align-col-middle w100 mt20 actions'}>*/}
        {/*  <MainBtn title={t('save')} onClick={onSave} className={'save-btn'} />*/}
        {/*</div>*/}
      </div>
    </Dialog>
  );
};

DeliveryAddressModal.propTypes = {
  addresses: PropTypes.array,
  getAddresses: PropTypes.func,
  deleteAddress: PropTypes.func,
  setTmpLocationPicked: PropTypes.func,
  setLocallyAddresses: PropTypes.func,
  isOpen: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  addresses: app.addresses || []
});

export default connect(mapStateToProps, {
  getAddresses,
  deleteAddress,
  setTmpLocationPicked,
  setLocallyAddresses
})(DeliveryAddressModal);
