import React from 'react';
import { useTranslation } from 'react-i18next';
import Svg_appstore from '../../../assets/svgs/home/app_store.svg';
import Svg_googleplay from '../../../assets/svgs/home/googleplay.svg';
import './index.css';
import PropTypes from 'prop-types';

const AppIntrolast = ({android_link, ios_link}) => {
  const { t } = useTranslation();

  return (
    <div className={'align-start our-app-intro-last mt10'}>
      <div className="content">
        <h2 className={'mb10'}>
          <span className={'craving-something'}>{t('landing.ourapplast_heading')}</span><br />
          <span>{t('landing.ourapplast_headingblack')}</span>
        </h2>
        <div className="align-middle badge-wrap">
          <a
            href={ios_link}
            rel={'noreferrer'}
            target="_blank"
            className="badge badge-ios"
          >
            <img src={Svg_appstore} />
          </a>
          <div style={{ width: 40 }} />
          <a
            href={android_link}
            target="_blank"
            rel={'noreferrer'}
            className="badge badge-android"
          >
            <img src={Svg_googleplay} />
          </a>
        </div>
      </div>
    </div>
  );
};


AppIntrolast.propTypes = {
  android_link: PropTypes.string,
  ios_link: PropTypes.string,
}

export default AppIntrolast;
