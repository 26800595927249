import React, { useEffect,  useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../assets';
import NoChatList from '../../../../components/Empty/NoChatList';
import ChatItem from '../../../../components/Chats/ChatItem';
import { SearchInput } from '../../../../components/Inputs';
import { Search } from '@styled-icons/evil';
import './index.css';
import PropTypes from 'prop-types';
// import { confirmAlert } from 'react-confirm-alert';
import LoadingSpinner from '../../../../components/Spinner';

const ChatHistScreen = (props) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    new_invites: [],
    searchTerm: ''
  });
  const [display_channels, setDisplayChannels] = useState(props.chat_channels);

  useEffect(() => {
    setDisplayChannels(props.chat_channels);
  }, [props.chat_channels]);

  const onChangeSearch = async (search) => {
    setState({ ...state, searchTerm: search });
    search = search.toLowerCase();
    const user_id = props.user.id;
    let filtered_channels = [];
    props.chat_channels.map((channel) => {
      if (channel.channel_type === 'single' && channel.partner != null && channel.creator != null) {
        if (
          user_id === channel.creator.id &&
          channel.partner.full_name != null &&
          channel.partner.full_name.toLowerCase().includes(search)
        ) {
          filtered_channels.push(channel);
        } else if (
          user_id === channel.partner.id &&
          channel.creator.full_name != null &&
          channel.creator.full_name.toLowerCase().includes(search)
        ) {
          filtered_channels.push(channel);
        }
      } else {
        if (channel.full_name != null && channel.full_name.toLowerCase().includes(search)) {
          filtered_channels.push(channel);
        }
      }
    });

    setDisplayChannels(filtered_channels);
  };

  const _renderSearchbar = () => {
    return (
      <div className={'w100 align-middle search-cntainer'}>
        <SearchInput
          placeholder={t('social.search.chat')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45, marginRight: 12 }}
          value={state.searchTerm}
          onChange={onChangeSearch}
        />
      </div>
    );
  };

  return (
    <div className={'flex_1 align-col-middle chat-hist-screen'}>
      {_renderSearchbar()}
      <div className={'scrollview'}>
        {props.isLoadingChat === true ?
              <div className={'flex_1'}><LoadingSpinner /></div> : 
        props.isLoadingChat == false && display_channels.length === 0 ? (
          <NoChatList />
        ) : (
          display_channels.map((item) => (
            <ChatItem key={item.id} item={item} channel_id={item.id} user_id={props.user.id} />
          ))
        )}
      </div>
    </div>
  );
};

ChatHistScreen.propTypes = {
  user: {
    id: PropTypes.number
  },
  chat_channels: PropTypes.array,
  isLoadingChat : PropTypes.bool
};

const mapStateToProps = ({ app, chat }) => ({
  isLoggedIn: app.isLoggedIn,
  language: app.language,
  user: app.user,
  chat_channels: chat.chat_channels,
  isLoadingChat : chat.isLoadingChat
});

export default connect(mapStateToProps, {
})(ChatHistScreen);
