import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import './index.css';
import '../index.css'
import Svg_camera from '../../../assets/svgs/msg/camera.svg';
import Svg_image from '../../../assets/svgs/msg/image.svg';

const ImgPickOptionModal = ({ isOpen, onClose, onCapture, onGallery }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal new-conv-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div style={styles.modalTitle}>{t('img_pick_option.title')}</div>
        <div
          onClick={onCapture}
          className={'align-middle'}
          style={{ width: '100%', height: 50, cursor: 'pointer' }}>
          <img src={Svg_camera} />
          <div style={styles.modalBtnTxt}>{t('img_pick_option.take_picture')}</div>
        </div>
        <div style={styles.divider} />
        <div
          onClick={onGallery}
          className={'align-middle'}
          style={{ width: '100%', height: 50, cursor: 'pointer' }}>
          <img src={Svg_image} />
          <div style={styles.modalBtnTxt}>{t('img_pick_option.upload_gallery')}</div>
        </div>
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
    paddingTop: 20,
    backgroundColor: Theme.colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalTitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.text,
    marginBottom: 12
  },
  modalBtnTxt: {
    flex: 1,
    marginLeft: 8,
    fontSize: 16,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text
  },
  divider: { width: '100%', height: 1, backgroundColor: Theme.colors.gray9 }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}
ImgPickOptionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCapture: PropTypes.func,
  onGallery: PropTypes.func
};

export default React.memo(ImgPickOptionModal, arePropsEqual);
