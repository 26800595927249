import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AddProduct2Cart, updateCartItems } from '../../../store/actions/shop';
import { useTranslation } from 'react-i18next';
import { setTmpFood } from '../../../store/actions/app';
import CartItem from '../CartItem';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './index.css';
import RoundIconBtn from '../../Buttons/RoundIconBtn';
import { Config } from '../../../constants';

const CartView = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const onPlusItem = async (p_id) => {
    try {
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id == p_id);
      if (foundIndex != -1) {
        tmp[foundIndex].quantity = tmp[foundIndex].quantity + 1;
        await props.updateCartItems(tmp);
        console.log('onPlusItem', p_id);
      }
    } catch (error) {
      console.log('onPlusItem', error);
    }
  };

  const onMinusItem = async (p_id) => {
    try {
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id == p_id);
      if (foundIndex != -1) {
        tmp[foundIndex].quantity = tmp[foundIndex].quantity - 1;
        await props.updateCartItems(tmp);
        console.log('onMinusItem', p_id);
      }
    } catch (error) {
      console.log('onMinusItem', error);
    }
  };

  const onRemoveItem = async (p_id) => {
    try {
      let cart_length = props.cartItems.length;
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id == p_id);
      if (foundIndex != -1) {
        tmp.splice(foundIndex, 1);
        await props.updateCartItems(tmp);
        if (cart_length == 1) {
          navigate(-1);
        }
      }
    } catch (error) {
      console.log('onRemoveItem', error);
    }
  };

  // const onAddItem = async (p_id) => {
  //   try {
  //     if (state.promoFreeObj && state.promoFreeObj.product) {
  //       let tmp = props.cartItems.slice(0, props.cartItems.length);
  //
  //       let cartItem = state.promoFreeObj.product;
  //       cartItem.quantity = 1;
  //       cartItem.comments = '';
  //       cartItem.options = [];
  //
  //       tmp.push(cartItem);
  //       await props.updateCartItems(tmp);
  //     }
  //   } catch (error) {
  //     console.log('onPlusItem', error);
  //   }
  // };

  return (
    <div className={'align-col-middle w100 checkout-cart-view'}>
      {
        props.vendorData != null &&
        <div className={'align-row-start vendor-logo-view mb2'}>
          <RoundIconBtn
            icon={
              <img
                src={Config.IMG_BASE_URL + props.vendorData.logo_thumbnail_path}
              />
            }
            onPress={() => { }}
          />
          <div className={'logo-text'}>{props.vendorData != null && props.vendorData.title}</div>
        </div>
      }
      <div className={'w100 flex_between '}>
        <h2>{t('cart.your_items')}</h2>
        <Link to={`/store/${props.vendorData.hash_id}/${props.vendorData.slug}/${props.vendorData.order_method}`}>{t('cart.see_menu')}</Link>
      </div>
      <div className={'items'}>
        {props.cartItems.map((item) => (
          <CartItem
            key={item.id}
            data={item}
            onMinus={onMinusItem}
            onPlus={onPlusItem}
            onDelete={onRemoveItem}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  tmpFoodData: app.tmpFoodData,
  coordinates: app.coordinates,
  cartItems: shop.items,
  vendorData: shop.vendorData
});

CartView.propTypes = {
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    is_open: PropTypes.number,
    title: PropTypes.string,
    hash_id: PropTypes.string,
    slug: PropTypes.string,
    order_method : PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
  }),
  tmpFoodData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    vendor_id: PropTypes.number,
    price: PropTypes.number,
    isFav: PropTypes.number,
    image_path: PropTypes.string,
    product_options: PropTypes.array
  }),
  setTmpFood: PropTypes.func,
  AddProduct2Cart: PropTypes.func,
  updateCartItems: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default connect(mapStateToProps, {
  AddProduct2Cart,
  updateCartItems,
  setTmpFood
})(CartView);
