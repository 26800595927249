/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import Color from './Color';
import TouchableOpacity from './TouchableOpacity';

export default function Send({
  text,
  containerStyle,
  onSend,
  children,
  textStyle,
  label,
  alwaysShowSend
}) {
  if (alwaysShowSend || text.trim().length > 0) {
    return (
      <TouchableOpacity
        testID="send"
        accessible
        accessibilityLabel="send"
        style={{ ...styles.container, ...containerStyle }}
        onPress={() => {
          if(text.trim() != '') {
            onSend({ text: text.trim() }, true);
          }
        }}
        accessibilityTraits="button">
        <div>{children || <div style={{ ...styles.text, ...textStyle }}>{label}</div>}</div>
      </TouchableOpacity>
    );
  }
  return <div />;
}

const styles = {
  container: {
    height: 44,
    justifyContent: 'flex-end'
  },
  text: {
    color: Color.defaultBlue,
    fontWeight: '600',
    fontSize: 17,
    backgroundColor: Color.backgroundTransparent,
    marginBottom: 12,
    marginLeft: 10,
    marginRight: 10
  }
};

Send.defaultProps = {
  text: '',
  onSend: () => {},
  label: 'Send',
  containerStyle: {},
  textStyle: {},
  children: null,
  alwaysShowSend: false
};

Send.propTypes = {
  text: PropTypes.string,
  onSend: PropTypes.func,
  label: PropTypes.string,
  containerStyle: PropTypes.object,
  textStyle: PropTypes.object,
  children: PropTypes.element,
  alwaysShowSend: PropTypes.bool
};
