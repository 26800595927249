import { ACTION_TYPES } from '../../constants';
import { OrderType_Delivery, VSort_Title } from '../../constants/config';

const initialState = {
  isLoggedIn: null,
  hasVerifiedPhone: false,
  hasLocation: false,
  isSidebarOpened: false,
  headerClass: false,
  user: {},
  language: 'sq',

  coordinates: {},
  address: {},
  addresses: [],
  tmp_order: {},
  tmpFoodData: {},
  pass_changed: false,
  tmp_new_address: {},
  chat_channels: [],
  home_vendor_filter: {
    latitude: '',
    longitude: '',
    vendor_type: 'vendors',
    order_type: OrderType_Delivery,
    food_categs: [],
    category_id: null,
    is_meal: false,
    is_dietary: false,
    ongoing_offer: false,
    open_now: false,
    online_payment: false,
    delivery_by_snapfood: false,
    low_fee: null,
    high_fee: null,
    searchTerm: ''
  },
  home_vendor_sort: VSort_Title,
  home_page_data: {
    home_scroll_offset: 0,
    isInitialized: false,
    curFoodCatID: null,
    foodCategories: [],
    featuredBlocks: [],
    allvendors: [],
    vertPage: 1,
    vertTotalPages: 1
  },
  chat_invitation_page_data: {
    tab: 'received',
    receivedItems: [],
    sentItems: []
  },
  invitationChatInfo: null,
  invitationPickedUser: null,
  invitationRewardsSetting: [],
  referralsRewardsSetting: {},
  invitationTimerSetting: {},
  profile_blog_filter: {
    category_id: null,
    searchTerm: ''
  },
  home_orders_filter: {
    discount: false,
    cashback: false,
    promotion: false,
    split: false,
    searchTerm: '',
    orders_tab: 'current'
  },
  blog_data: {},
  blog_categories: [],
  order_msg_tags: [],
  foodCategories: [],
  activeMarker: {},
  feedback_where_tags: [],
  show_feedback_where_heard_modal: false,
  appSnackbarMsg: {visible : false, description: ''},
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.APP_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };
    case ACTION_TYPES.APP_SET_LANGE: {
      return { ...state, language: action.payload || 'sq' };
    }
    case ACTION_TYPES.APP_SET_USER_DATA:
      return { ...state, user: action.payload };
    case ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE:
      return { ...state, hasVerifiedPhone: !!action.payload };
    case ACTION_TYPES.APP_TOGGLE_SIDE_BAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };

    case ACTION_TYPES.APP_SET_SNACKBAR_MESSAGE:
      return { ...state, appSnackbarMsg: action.payload || {visible : false, description: ''} };

    case ACTION_TYPES.APP_SET_HEADER_CLASS:
      return { ...state, headerClass: action.payload || false };

    case ACTION_TYPES.APP_SET_BLOG_DATA:
      return { ...state, blog_data: action.payload || {} };

    case ACTION_TYPES.APP_SET_ADDRESSES:
      return { ...state, addresses: action.payload || [] };
    case ACTION_TYPES.APP_APPLY_LOCATION: {
      return {
        ...state,
        hasLocation: true,
        coordinates: action.payload.coordinates,
        address: action.payload.address
      };
    }
    case ACTION_TYPES.SET_FOOD:
      return { ...state, tmpFoodData: action.payload || {} };

    case ACTION_TYPES.APP_SET_VENDOR_FILTER: {
      return { ...state, home_vendor_filter: { ...state.home_vendor_filter, ...action.payload } };
    }
    case ACTION_TYPES.APP_SET_VENDOR_SORT: {
      return { ...state, home_vendor_sort: action.payload || VSort_Title };
    }
    case ACTION_TYPES.APP_SET_HOME_DATA: {
      return { ...state, home_page_data: { ...state.home_page_data, ...action.payload } };
    }
    case ACTION_TYPES.APP_TMP_ADDR_PICKED: {
      return { ...state, tmp_new_address: action.payload || {} };
    }
    case ACTION_TYPES.APP_SET_INVITATION_REWARDS: {
      return { ...state, invitationRewardsSetting: action.payload };
    }
    case ACTION_TYPES.APP_SET_ACTIVE_MARKER: {
      return { ...state, activeMarker: action.payload || {} };
    }
    case ACTION_TYPES.APP_SET_REFERRALS_REWARDS: {
      return { ...state, referralsRewardsSetting: action.payload };
    }
    case ACTION_TYPES.APP_SET_INVITE_PICKED_USER: {
      return { ...state, invitationPickedUser: action.payload };
    }
    case ACTION_TYPES.APP_SET_INVITE_CHAT_INFO: {
      return { ...state, invitationChatInfo: action.payload };
    }
    case ACTION_TYPES.APP_SET_INVITATION_TIMER_SETTING: {
      return { ...state, invitationTimerSetting: action.payload };
    }
    case ACTION_TYPES.APP_SET_CHAT_CHANNELS:
      return { ...state, chat_channels: action.payload || [] };

    case ACTION_TYPES.APP_SET_INVITATIONS_PAGE_DATA: {
      return {
        ...state,
        chat_invitation_page_data: { ...state.chat_invitation_page_data, ...action.payload }
      };
    }
    case ACTION_TYPES.APP_SET_PROFILE_BLOG_FILTER: {
      return { ...state, profile_blog_filter: { ...state.profile_blog_filter, ...action.payload } };
    }
    case ACTION_TYPES.APP_SET_BLOG_CATEGORIES: {
      return { ...state, blog_categories: action.payload || [] };
    }
    case ACTION_TYPES.APP_SET_ORDERS_FILTER: {
      return { ...state, home_orders_filter: { ...state.home_orders_filter, ...action.payload } };
    }
    case ACTION_TYPES.APP_SET_TMP_ORDER: {
      return {
        ...state,
        tmp_order: action.payload || {}
      };
    }
    case ACTION_TYPES.APP_TMP_PASS_CHANGED: {
      return { ...state, pass_changed: action.payload || false };
    }
    case ACTION_TYPES.APP_SET_ORDER_MSG_TAGS: {
      return { ...state, order_msg_tags: action.payload || [] };
    }
    case ACTION_TYPES.APP_SET_FOOD_CATEGORIES: {
      return { ...state, foodCategories: action.payload || [] };
    }
    case ACTION_TYPES.APP_SET_APP_FEEDBACK_TAGS: {
      return {
        ...state,
        feedback_where_tags: action.payload || [],
      };
    }
    case ACTION_TYPES.APP_SET_SHOW_WHERE_HEARD_FEEDBACK_MODAL: {
      return { ...state, show_feedback_where_heard_modal: action.payload || false };
    }
    default:
      return state;
  }
};

export default app;
