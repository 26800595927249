import React from 'react';
import './index.css';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import SvgQuote from '../../assets/svgs/vendor/landing/quote.svg';
import SvgStar from '../../assets/svgs/vendor/landing/star.svg';

const responsive = {
  lg: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
};

const Reviews = () => {
  const reviews = [
    { id: 3, name: 'Ester', title: "The best pizza and pasta ever\n", desc: 'The service was amazing\n' +
        'The atmosphere was great\n' +
        'I did not try everything but i liked it so muchhh' },
    { id: 2, name: 'Zhanina', title: "Super vend", desc: 'super ushqim dhe sidomos super sherbim i turnit te darkes do kthehemi serisht' },
    { id: 1, name: 'Denis', title: "The best pizzeria in Tirana", desc: 'The quality-price ratio is very fair, menu with pizzas of various types and for all tastes, with quality toppings. The owners are very nice, friendly and professional, capable of putting you at ease, also supported by a welcoming environment that is also beautiful to look at.'}
  ];

  return (
    <div className="reviews" style={{ position: 'relative' }}>
      <style>
        {`
          @media (max-width: 768px) {
            .reviews {
              padding-top: 60px;
            }
            .rating-badge {
              width: 100%;
              border-radius: 0;
              box-sizing: border-box;
            }
          }
        `}
      </style>
      <div className="rating-badge" style={{
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#F0FDF4',
        padding: '8px 12px',
        borderRadius: '0 0 20px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        zIndex: 10
      }}>
        <img src={SvgStar} alt="Star" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        <span style={{ color: '#22C55E', fontWeight: 'bold' }}>
          4.8/5 stars from over 350 SnapFood users
        </span>
      </div>
      <Carousel responsive={responsive} showDots={true} removeArrowOnDeviceType={["lg"]}>
        {reviews.map((item, index) => (
          <div key={index} className={'review-item'}>
            <div className='title-view'>
              <img src={SvgQuote} />
              <h1>{item.title}</h1>
            </div>
            <p>{item.desc}</p>
            <h4>{item.name}</h4>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Reviews;