import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getImageFullURL } from '../../../utils/common';
import './index.css';
import Svg_accept from '../../../assets/svgs/chats/accept.svg';
import Svg_decline from '../../../assets/svgs/chats/decline.svg';

const InvitationItem = ({ isSent, item, onClick, onCancel, onAccept, onDecline }) => {
  const { t } = useTranslation();
  return (
    <div className={'invitation-item'} onClick={onClick}>
      <img className={'avatar'} src={getImageFullURL(item.photo)} />
      <div className={'align-col-start'} style={{ flex: 1, alignItems: 'flex-start' }}>
        <div className={'name'}>{item.full_name}</div>
        <div className={'invite_date'}>{moment(item.invite_date).format('DD/MM/YYYY')}</div>
      </div>
      {isSent ? (
        <div
          className={'cancel-btn btn-style'}
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}>
          {t('cancel')}
        </div>
      ) : (
        <div className={'align-middle'}>
          <img
            src={Svg_decline}
            className={'btn btn-style'}
            onClick={(e) => {
              e.stopPropagation();
              onDecline();
            }}
          />
          <img
            src={Svg_accept}
            className={'btn btn-style'}
            onClick={(e) => {
              e.stopPropagation();
              onAccept();
            }}
          />
        </div>
      )}
    </div>
  );
};

InvitationItem.propTypes = {
  isSent: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    invite_date: PropTypes.string
  }),
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id;
}

export default React.memo(InvitationItem, arePropsEqual);
