import React from 'react';
import { useTranslation } from 'react-i18next'
import './Support.css';
import { Grid } from '@mui/material';

const Support = () => {
  const { t } = useTranslation();
  return (
    <div className="need-support  bg-cover">
      <Grid container spacing={{ xs: 2, md: 5 }} className={'align-middle'} style={{ margin: 0, width: '100%' }}>
        <Grid item xs={12} md={6} style={{}}>
          <div className={'title-view'}>
            <h2>{t('courier.need_support')}</h2>
            <h5>{t('courier.need_support_desc')}</h5>
          </div>
        </Grid>
        <Grid item xs={12} md={6}/>
      </Grid>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(Support, arePropsEqual);
