import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Search } from '@styled-icons/evil';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css'
import { getFriends } from '../../../store/actions/app';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import SearchInput from '../../Inputs/SearchInput';
import NoFriendList from '../../Empty/NoFriendList';
import UserItem from '../../Chats/UserItem';
import AuthBtn from '../../Buttons/AuthBtn';
import apiFactory from '../../../services/apiFactory';
import { confirmAlert } from 'react-confirm-alert';

const ChooseFriendModal = (props) => {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [state, setState] = useState({
    loading: null,
    all_friends: []
  });

  useEffect(() => {
    setOpen(props.isOpen);
    if (props.isOpen) {
      setSelected(props.selected || []);
    }
  }, [props.isOpen]);

  useEffect(() => {
    getAllFriends(searchTerm);
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getAllFriends = (searchTerm) => {
    setState({ ...state, loading: true });
    getFriends('accepted', searchTerm)
      .then((data) => {
        setState({ loading: false, all_friends: data });
      })
      .catch((err) => {
        setState({ loading: false, all_friends: [] });
        console.log('getFriends', err);
      });
  };

  const updateMapSetting = () => {
    setLoading(true);
    let friend_ids = selected.map(i => i.id);
    apiFactory
      .post(`users/update-map-setting`, {
        visible: 1,
        type: props.type,
        filter_ids: friend_ids
      })
      .then(
        () => {
          setLoading(false);
          props.onClose(true);
        },
        (error) => {
          setLoading(false);
          const message = error.message || t('generic_error');
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {
                }
              }
            ]
          });
        }
      );
  };

  const chooseFriend=()=>{
    if(selected.length > 0) {
      props.onClose(selected[0]);
    }
  }

  return (
    <Dialog open={open} className={'align-col-middle w100 modal choose-friend-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 align-middle title-header mb2'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h1 className={'title'}>{t('social.friends')}</h1>
          <div style={{ width: 40 }} />
        </div>
        <div className={'align-middle searchview'}>
          <SearchInput
            placeholder={t('social.search.friends')}
            icon={<Search size={20} color={Theme.colors.gray5} />}
            style={{ flex: 1, height: 45 }}
            value={searchTerm}
            onChange={(text) => {
              setSearchTerm(text);
              getAllFriends(text);
            }}
          />
        </div>
        <div className={'flex_1 scrollview'}>
          <div style={{ height: 20 }} />
          {state.loading == false && state.all_friends.length === 0 ? (
            <NoFriendList title={t('social.no_friends')} desc={t('social.no_friends_desc')} />
          ) : (
            state.all_friends.map((item) => (
              <UserItem
                key={item.id}
                item={item}
                type='checkbox'
                checked={selected.findIndex(s => s.id == item.id) != -1}
                onClick={() => {
                  let tmp = selected.slice(0);
                  let foundUser = selected.findIndex(i => i.id == item.id);
                  if (foundUser >= 0) {
                    if (props.type == 'order-for-friend') {
                      tmp = [];
                    }
                    else {
                      tmp.splice(foundUser, 1);
                    }
                  } else {
                    if (props.type == 'order-for-friend') {
                      tmp = [item];
                    }
                    else {
                      tmp.push(item);
                    }
                  }
                  setSelected(tmp);
                }}
              />
            ))
          )}
          <div style={{ height: 20 }} />
        </div>
        <div className={'w100 align-col-middle pt2'}>
          {
            props.type == 'order-for-friend' ?
              <AuthBtn
                title={t('save')}
                isDisabled={selected.length ==  0}
                onClick={chooseFriend}
                className={'save-btn'}
              />
              :
              <AuthBtn
                title={t('save')}
                isLoading={isLoading}
                onClick={updateMapSetting}
                className={'save-btn'}
              />
          }
        </div>
      </div>
    </Dialog>
  );
};

ChooseFriendModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  type: PropTypes.string,
  selected: PropTypes.array,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  chat_channels: app.chat_channels
});

export default connect(mapStateToProps, {})(ChooseFriendModal);
