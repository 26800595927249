import React from 'react';
import { Container, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { ROUTES_NAMES } from '../../constants';
import Seo from '../../components/Seo';
import { useTranslation } from 'react-i18next';

const Help = () => {
  const { t } = useTranslation();
  const onLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div data-testid="view-help" className={'view-help'}>
      <Seo title={t('web_title.help')} />
      <Container fluid className={'container'}>
        <Row>
          <h1>help</h1>
          <ul>
            <li>
              <Link to={ROUTES_NAMES.help.customer} onClick={onLinkClick}>
                Customer Support
              </Link>
            </li>

            <li>
              <Link to={ROUTES_NAMES.help.courier} onClick={onLinkClick}>
                Courier Support
              </Link>
            </li>

            <li>
              <Link to={ROUTES_NAMES.help.partner} onClick={onLinkClick}>
                Partner Support
              </Link>
            </li>
          </ul>
        </Row>
      </Container>
    </div>
  );
};

export default Help;
