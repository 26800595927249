import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { Heart } from '@styled-icons/foundation';
import { Dislike } from '@styled-icons/foundation';
import Svg_discount from '../../../assets/svgs/vendor/discount.svg';
import './index.css';
import { Theme } from '../../../assets';

const LikeBtns = ({ isLike, onLike }) => {
  const { t } = useTranslation();
  return (
    <div className={'align-row-start like-btns'}>
      <div
        className={isLike ? 'active' : ''}
        onClick={(e) => {
          e.preventDefault();
          onLike(true);
        }}>
        <Dislike color={Theme.colors.gray2} size={22} style={{ transform: 'rotateX(-180deg)' }} />
        <span> {t('food_item.like')}</span>
      </div>
      <span className={'divider'}>|</span>
      <div
        className={isLike ? '' : 'active'}
        onClick={(e) => {
          e.preventDefault();
          onLike(false);
        }}>
        <Dislike color={Theme.colors.gray2} size={22} /> <span> {t('food_item.dislike')}</span>
      </div>
    </div>
  );
};

const Discount = ({ value }) => {
  const { t } = useTranslation();
  return (
    <div className={'discount'}>
      <img src={Svg_discount} alt={''} />
      <span>
        {value}% {t('food_item.off')}
      </span>
    </div>
  );
};

const PriceItem = ({ value, discount }) => {
  return (
    <div className={'align-middle price-item'}>
      <span className={'default-price'}>{value} L</span>
      {discount && discount > 0 && <span className={'discount-price'}>{discount} L</span>}
    </div>
  );
};

const FavBtn = ({ isFav, onChange }) => {
  return (
    <IconButton
      className={'fav-btn'}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onChange(!isFav);
      }}>
      <Heart size={26} color={isFav ? Theme.colors.cyan2 : Theme.colors.gray7} />
    </IconButton>
  );
};

const FoodTitle = ({ text }) => {
  return <div className={'food-title'}>{text}</div>;
};

const FoodDesc = ({ text }) => {
  return <div className={'food-desc'}>{text}</div>;
};

const LogoImg = ({ url }) => {
  return (
    <div className={'food-img align-middle'}>
      <img src={url} alt={''} />
    </div>
  );
};

LikeBtns.propTypes = {
  isLike: PropTypes.bool,
  onLike: PropTypes.func
};
Discount.propTypes = {
  value: PropTypes.number
};
PriceItem.propTypes = {
  value: PropTypes.number,
  discount: PropTypes.number
};
FavBtn.propTypes = {
  isFav: PropTypes.bool,
  onChange: PropTypes.func
};
FoodTitle.propTypes = {
  text: PropTypes.string
};
FoodDesc.propTypes = {
  text: PropTypes.string
};
LogoImg.propTypes = {
  url: PropTypes.string
};

const MemorizedLikeBtns = React.memo(LikeBtns);
const MemorizedDiscount = React.memo(Discount);
const MemorizedPriceItem = React.memo(PriceItem);
const MemorizedFavBtn = React.memo(FavBtn);
const MemorizedFoodTitle = React.memo(FoodTitle);
const MemorizedFoodDesc = React.memo(FoodDesc);
const MemorizedLogoImg = React.memo(LogoImg);

export {
  MemorizedLikeBtns,
  MemorizedDiscount,
  MemorizedPriceItem,
  MemorizedFavBtn,
  MemorizedFoodTitle,
  MemorizedFoodDesc,
  MemorizedLogoImg
};
