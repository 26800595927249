import React, { useState } from 'react';
import { connect } from 'react-redux';
import {  Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import { MainBtn } from '../../Buttons';
import './index.css';
import '../index.css';
import { setProfileBlogFilter } from '../../../store/actions/app';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxBtn from '../../Buttons/CheckBoxBtn';

const BlogFilterModal = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(props.profile_blog_filter);


  React.useEffect(() => {
    setOpen(props.isOpen);
    if (props.isOpen) {
      setFilter(props.profile_blog_filter);
    }
  }, [props.isOpen]);

  const onSave = () => {
    props.setProfileBlogFilter(filter);
    props.onClose()
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal blog-filter-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative ph2'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h1 className={'title'}>{t('blog.filter_article')}</h1>
        </div>
        <div className={'scroll_view ph2'}>
          <div className={'w100 '}>
            <List className={'filter-body'}>
              <ListItem
                button
                className={'list-btn'}
                onClick={(e) => {
                  e.preventDefault();
                  setFilter({ category_id: null });
                }}>
                <ListItemText primary={t(`blog.all_topic`)} />
                <CheckBoxBtn
                  checked={filter.category_id == null}
                  onClick={() => {
                    setFilter({ category_id: null });
                  }}
                />
              </ListItem>
              {props.blog_categories.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  className={'list-btn'}
                  onClick={(e) => {
                    e.preventDefault();
                    setFilter({ category_id: item.id });
                  }}>
                  <ListItemText primary={props.language == 'en' ? item.title : item.sq_title} />
                  <CheckBoxBtn
                    checked={filter.category_id === item.id}
                    onClick={() => {
                      setFilter({ category_id: item.id });
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <div style={{ height: 15 }} />
          </div>
        </div>
        <div className={'w100 ph2 pt3'}>
          <MainBtn
            className={'save-btn'}
            title={t('save')}
            onClick={onSave}
          />
        </div>
      </div>
    </Dialog>
  );
};

BlogFilterModal.propTypes = {
  isOpen : PropTypes.bool,
  profile_blog_filter: PropTypes.shape({
    category_id: PropTypes.string,
    searchTerm: PropTypes.string
  }),
  blog_categories: PropTypes.array,
  language: PropTypes.string,
  setProfileBlogFilter: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  language: app.language,
  blog_categories: app.blog_categories || [],
  profile_blog_filter: app.profile_blog_filter
});

export default connect(mapStateToProps, {
  setProfileBlogFilter
})(BlogFilterModal);
