import React from 'react';
import Seo from '../../../components/Seo';
import { useTranslation } from 'react-i18next';

const PartnerSupport = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="view-partner-support" className={'view-partner-support'}>
      <Seo title={t('web_title.partner_support')} />
      <h1>partner-support</h1>
    </div>
  );
};

export default PartnerSupport;
