import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { PlusCircle } from '@styled-icons/boxicons-solid';
import { Config } from '../../../constants';
import { Theme } from '../../../assets';
import './suggesteditem.css';
import { useTranslation } from 'react-i18next';

const SuggestedItem = ({ data, vendor_logo_thumbnail_path, style, onAdd }) => {

  const { t } = useTranslation();

  const getImage = () => {
    if (data.image_thumbnail_path == null || data.image_thumbnail_path == '') {
      return vendor_logo_thumbnail_path;
    }
    return data.image_thumbnail_path;
  }

  return (
    <div className={'suggested-item'} style={style}>
      <div className={'align-row-start w100 ph1 pv1'}>
        <img src={`${Config.IMG_BASE_URL}${getImage()}?w=200&h=200`} />
        <div className={'info'}>
          <div className={'title fs4'}>{data.title}</div>
          <div className={'price fs3'}>{data.price} L</div>
        </div>
      </div>
      <Button
        className={'align-col-middle btn-view'}
        onClick={() => {
          onAdd(data);
        }}>
        <div className={'align-middle'}>
          <PlusCircle size={16} color={Theme.colors.text} />
          <div className={'ml1 f5'}>{t('restaurant_details.add_to_cart')}</div>
        </div>
      </Button>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.data.id === nextProps.data.id;
}

SuggestedItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number,
    image_thumbnail_path: PropTypes.string,
    discount: PropTypes.number
  }),
  vendor_logo_thumbnail_path: PropTypes.string,
  style: PropTypes.object,
  onAdd: PropTypes.func
};
export default React.memo(SuggestedItem, arePropsEqual);
