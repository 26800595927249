import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import apiFactory from '../../../services/apiFactory';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { Theme } from '../../../assets';
import './index.css';
import PropTypes from 'prop-types';
import { CheckCircleFill } from '@styled-icons/bootstrap';
import { Check } from '@styled-icons/entypo';
import { connect } from 'react-redux';
import { setOrderFor } from '../../../store/actions/shop';

const InviteCodeInput = (props) => {
  const { t } = useTranslation();
  const [promo_code, setPromoCode] = useState('');
  const [loading_invitation_code, setLoadingInviationCode] = useState(false);
  const [has_valid_invitation_code, setHasValidInviationCode] = useState(false);

  useEffect(() => {

  }, [])

  const _validateInvitationCode = async () => {
    if (loading_invitation_code) {
      return;
    }
    setLoadingInviationCode(true);
    apiFactory
      .post(`/invite-earn/validate-earninvitation-code`, {
        promo_code: promo_code
      })
      .then(
        async ({ data }) => {
          if (data.is_valid == true) {
            setLoadingInviationCode(false);
            setHasValidInviationCode(true);
            props.applyInvitationCode(promo_code);
          } else {
            onInvalidInviteCode();
          }
        },
        async (error) => {
          console.log('validate invitation coide err ', error);
          onInvalidInviteCode();
        }
      );
  };

  const onInvalidInviteCode = () => {
    setLoadingInviationCode(false);
    setHasValidInviationCode(false);

    confirmAlert({
      title: t('alerts.error'),
      message: t('cart.invalid_promotion_code'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t('Ok'),
          className: 'error-ok-btn',
          onClick: () => {}
        }
      ]
    });
  };

  const _removeInviteCode = () => {
    setHasValidInviationCode(false);
    setPromoCode('');
    props.applyInvitationCode(null);
  };

  if (!has_valid_invitation_code) {
    return (
      <div className={'invite-input'}>
        <input
          value={promo_code}
          placeholder={t('cart.promo_code.placeholder')}
          onChange={(e) => {
            setPromoCode(e.target.value);
          }}
          autoCapitalize={'none'}
          autoCorrect={'none'}
        />
        {promo_code != null && promo_code.length > 0 && (
          <IconButton
            style={{
              position: 'absolute',
              right: 10,
              padding: 0
            }}
            onClick={_validateInvitationCode}>
            {loading_invitation_code ? (
              <CircularProgress size={16} />
            ) : (
              <Check
                size={16}
                color={has_valid_invitation_code ? Theme.colors.cyan2 : Theme.colors.placeholder}
              />
            )}
          </IconButton>
        )}
      </div>
    );
  }

  if (promo_code != null && promo_code.length > 0 && has_valid_invitation_code) {
    return (
      <div className={'invite-input'}>
        <div
          className={'align-row-start'}
          style={{ flex: 1, paddingVertical: 18, paddingLeft: 10 }}>
          <div className={'code-text'}> {promo_code} </div>
          <CheckCircleFill size={16} color={'#00C22D'} />
        </div>
        <div style={{ position: 'absolute', right: 10 }}>
          {loading_invitation_code ? (
            <CircularProgress size={16} />
          ) : (
            <div className={'remove btn-style'} onClick={() => _removeInviteCode()}>
              {t('remove')}
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = ({ shop }) => ({
  order_for: shop.order_for
});

InviteCodeInput.propTypes = {
  order_for: PropTypes.object,
  applyInvitationCode: PropTypes.func,
  setOrderFor: PropTypes.func
};

export default connect(mapStateToProps, {
  setOrderFor
})(InviteCodeInput);