import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css'
import Faq from '../../../Common/Faq';
import apiFactory from '../../../../services/apiFactory';

const OrderFaqs = ({ faqs }) => {
  const [expanded, setExpanded] = useState('');
  const handleChange = (id) => (event, isExpanded) => {
    if (isExpanded == true) {
      increaseFaqCount(id);
    }
    setExpanded(isExpanded ? id : false);
  };

  const increaseFaqCount = (faq_id) => {
    apiFactory
      .post(`orders/increase-clicks-faqs`, {
        faq_id: faq_id
      })
      .then(
        () => {},
        () => {}
      );
  };

  return (
    <div className={'align-col-middle order-faqs ph2'}>
      {faqs.map((faq) => (
        <Faq
          key={faq.id}
          id={faq.id}
          className={'order-faq'}
          question={faq.question}
          answer={faq.answer}
          isExpanded={expanded === faq.id}
          handleChange={handleChange}
        />
      ))}
    </div>
  );
};

OrderFaqs.propTypes = {
  faqs: PropTypes.array
};
export default OrderFaqs;
