import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import './index.css';
import PropTypes from 'prop-types';
import Counter from '../../Common/Counter';
import { connect } from 'react-redux';
import { setCutleryCart } from '../../../store/actions/shop';

const CutleryInput = (props) => {
  const { t } = useTranslation();
  return (
    <div className={'flex_between cutlery-view mt2'}>
      <span className={'subject-title fs4'}>{t('cart.cutlery_description_off')}</span>
      <Counter
        value={props.cutlery}
        onPlus={() => props.setCutleryCart(props.cutlery + 1)}
        onMinus={() => {
          if (props.cutlery >= 1) {
            props.setCutleryCart(props.cutlery - 1);
          }
        }}
        style={{
          width: 122,
          height: 40,
          padding: 6,
          paddingHorizontal: 12,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: Theme.colors.gray6,
          backgroundColor: Theme.colors.white
        }}
        btnColor={Theme.colors.cyan2}
        btnSize={20}
      />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.cutlery === nextProps.cutlery;
}

const mapStateToProps = ({ shop }) => ({
  cutlery: shop.cutlery
});

CutleryInput.propTypes = {
  cutlery: PropTypes.number,
  setCutleryCart: PropTypes.func
};

export default connect(mapStateToProps, {
  setCutleryCart
})(React.memo(CutleryInput, arePropsEqual));
