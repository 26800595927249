import React from 'react';
import PropTypes from 'prop-types';
import { findZodiacSign, getImageFullURL } from '../../../utils/common';
import './index.css';
import { CheckBoxBtn } from '../../Buttons';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';

const UserItem = ({ item, checked, type, isFriend, invite_status, onClick, onRightBtnPress }) => {
  const { t } = useTranslation();
  return (
    <div className={'user-item ph2 pv2 w100'} onClick={onClick ? onClick : () => {
    }}>
      <img className={'avatar'} src={getImageFullURL(item.photo)} />
      <div className={'align-row-start'} style={{ flex: 1 }}>
        <div className={'name'}>{item.username || item.full_name}</div>
        {item.birthdate != null && (
          <img src={findZodiacSign(item.birthdate)} className={'zodiac'} />
        )}
      </div>
      {type === 'checkbox' && (
        <CheckBoxBtn checked={checked === true} onClick={onClick ? onClick : () => {
        }} />
      )}
      {type === 'snapfooder' && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (onRightBtnPress) {
              onRightBtnPress();
            }
          }}>
          <div
            className={'invite_status'}
            style={{
              color: invite_status === 'invited' ? Theme.colors.gray7 : Theme.colors.cyan2
            }}>
            {invite_status === 'invited' ? t('chat.cancel') : t('chat.invite')}
          </div>
        </div>
      )}
      {type == 'invite_status' && (
        isFriend == true ?
          <div
            className={'invite_status'}
            style={{
              color: Theme.colors.gray7
            }}>
            {t('chat.already_friend')}
          </div>
          :
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (onRightBtnPress) {
                onRightBtnPress();
              }
            }}>
            <div
              className={'invite_status'}
              style={{
                color: invite_status === 'invited' ? Theme.colors.gray7 : Theme.colors.cyan2
              }}>
              {invite_status === 'invited' ? t('chat.cancel') : t('chat.invite')}
            </div>
          </div>
      )}
    </div>
  );
};

UserItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    birthdate: PropTypes.string
  }),
  isFriend: PropTypes.bool,
  checked: PropTypes.bool,
  type: PropTypes.string,
  invite_status: PropTypes.string,
  onClick: PropTypes.func,
  onRightBtnPress: PropTypes.func
};

export default UserItem;
