import React from 'react';
import PropTypes from 'prop-types';
import './FeatureItem.css';

const FeatureItem = ({ data, style }) => {
  return (
    <div className={'home-featured-card-item h100'} style={style}>
      <div className={'align-start w100 ph1 pv1'}>
        <img src={data.img} />
        <div className={'title fs4 mt1'}>{data.title}</div>
        {
          data.descs.map((item, index) =>
            <div key={index} className={'desc fs4 mt2'}>{item}</div>
          )
        }
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.data.id === nextProps.data.id;
}

FeatureItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    img: PropTypes.string,
    descs: PropTypes.array
  }),
  style: PropTypes.object
};
export default React.memo(FeatureItem, arePropsEqual);
