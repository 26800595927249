import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppIntro from '../../components/Ourapp/AppIntro';
// import Live from '../../components/Ourapp/Live';
import './index.css';
import Seo from '../../components/Seo';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import querystring from 'query-string';
import { decodeHash, isEmpty } from '../../utils/common';
import VendorService from '../../services/apiVendor'; // Import VendorService
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';
import { IMG_BASE_URL, OrderType_Delivery } from '../../constants/config';
import { ApiFactory } from '../../services';
import img1 from '../../assets/images/vendor/vendors.f99bd2ba.svg.png';
import img2 from '../../assets/images/vendor/Group.png';
import img3 from '../../assets/images/vendor/order.5327bd29.svg.png';
import RestaurantCard from './menu-reviews';
import OrderingComponent from './OrderingComponent';
import NewsletterSignup from './newsletter';
import { restorantArray } from './data';
import SvgTimer from '../../assets/svgs/vendor/landing/timer.svg'
import SvgSoup from '../../assets/svgs/vendor/landing/soup.svg'
import SvgBowl from '../../assets/svgs/vendor/landing/bowl.svg'
import SvgStar from '../../assets/svgs/vendor/landing/star.svg'
import SvgWifi from '../../assets/svgs/vendor/landing/wifi.svg'
import Svg_revenue from '../../assets/svgs/partner/revenue.svg';
import PopularItems from './PopularItems';
import Reviews from './Reviews';
import Faqs from './Faqs';
import SvgQuote from '../../assets/svgs/vendor/landing/quote.svg';

const VendorsId = (props) => {
  const { t } = useTranslation();
  let { vendor_hash, order_method } = useParams();
  order_method = order_method || OrderType_Delivery;
  const vendor_id = decodeHash(vendor_hash);

  const location = useLocation();
  const parsed = querystring.parse(location.search) || {};

  const [ios_link, setIosLink] = useState(
    'https://itunes.apple.com/al/app/snapfood-food%20delivery/id1314003561?l=en&amp;mt=8'
  );
  const [android_link, setAndroidLink] = useState(
    'https://play.google.com/store/apps/details?id=com.snapfood.app&amp;hl=en'
  );

  const [vendorData, setVendorData] = useState(null);

  useEffect(() => {
    if (!isEmpty(parsed.code)) {
      setAndroidLink(
        `https://referral.snapfood.al/referral/?link=https://snapfood.al/referral?referralCode=${parsed.code}&apn=com.snapfood.app&isi=1314003561&ibi=com.snapfood.al&efr=1`
      );
      ApiFactory.get(`get-referral-link?referral_code=${parsed.code}`).then(
        ({ data }) => {
          identifyDevice();
          trackEvent('Get Referral Link', {
            action_category: 'Retrieve Link',
            action_outcome: 'success',
            interaction_element: 'Screen',
            source: 'sf_web'
          });
          if (!isEmpty(data?.referral_link)) {
            setIosLink(data?.referral_link);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [parsed.code]);

  useEffect(() => {
    loadVendorDetails(vendor_id, order_method);
  }, [vendor_id, order_method]);

  const loadVendorDetails = async (id, order_method) => {
    if (id == null) {
      return;
    }
    let { latitude, longitude } = props.coordinates;

    try {
      const { data } = await VendorService.getVendorDetail(id, latitude, longitude, order_method);
      setVendorData(data.vendor);
      identifyDevice();
      trackEvent('Landing Page - Pizza Seni', {
        action_category: 'Load Screen',
        action_outcome: 'success',
        interaction_element: 'screen',
        source: 'sf_web'
      });
    } catch (error) {
      identifyDevice();
      trackEvent('Landing Page - Pizza Seni', {
        action_category: 'Load Screen',
        action_outcome: 'failure',
        interaction_element: 'screen',
        source: 'sf_web'
      });
    }
  };

  const restorantDetail = restorantArray.find((item) => item.orderNow.includes(vendor_id));

  const title = t('landing.ordering.title') + vendorData?.title ?? '';
  const subtitle = t('landing.ordering.subtitle').replace('_VENDOR_NAME_', vendorData?.title ?? '');
  const features = [
    {
      image: img1,
      imageAlt: t('landing.ordering.features.pizza.title').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      ),
      title: t('landing.ordering.features.pizza.title').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      ),
      description: t('landing.ordering.features.pizza.description').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      )
    },
    {
      image: img2,
      imageAlt: t('landing.ordering.features.cart.title').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      ),
      title: t('landing.ordering.features.cart.title').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      ),
      description: t('landing.ordering.features.cart.description').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      )
    },
    {
      image: img3,
      imageAlt: t('landing.ordering.features.order.title').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      ),
      title: t('landing.ordering.features.order.title').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      ),
      description: t('landing.ordering.features.order.description').replace(
        '_VENDOR_NAME_',
        vendorData?.title ?? ''
      )
    }
  ];

  const loyaltyTitle = 'Our Cashback Program';
  const loyaltySubtitle =
    'Earn rewards on every order with our cashback program. The more you order, the more you save!';
  const loyaltyFeatures = [
    {
      image: Svg_revenue,
      imageAlt: loyaltyTitle,
      title: 'Cashback Levels',
      description:
        'We offer three exciting cashback levels: Silver (0.5%), Gold (1%), and Platinum (1.5%). Each level gives you a higher percentage of cashback on your orders.'
    },
    {
      image: Svg_revenue,
      imageAlt: loyaltyTitle,
      title: 'Level Progression',
      description:
        'Climb to higher levels by placing more orders. Your level is calculated based on your order frequency. Maintain your level by staying active and ordering!'
    },
    {
      image: Svg_revenue,
      imageAlt: loyaltyTitle,
      title: 'Redeem Your Rewards',
      description:
        ' Use your accumulated cashback as a discount on future orders. Simply select the cashback option at checkout and watch your bill shrink. It' +
        ' s that easy to save!'
    }
  ];

  const newsletterTitle = t('landing.newsletter.title');
  const newsletterSubtitle = t('landing.newsletter.subtitle');
  const inputPlaceholder = t('landing.newsletter.placeholder');
  const buttonText = t('landing.newsletter.signup');
  const checkboxText = t('landing.newsletter.check_title');

  return (
    <div data-testid="view-download-app" className={'view-download-app vendor-landing'}>
      <div className="vendors">
        <div className="section-title">
          <h1 className="h1-title">{t('landing.title')}</h1>
          <span className="vendor-subtitle">{t('landing.subtitle')}</span>
        </div>
        <div className="vendor-info">
          <div className="info">
            <div className="tags">
              <div className="tag" style={{ background: '#F0FDF4' }}>
                <img src={SvgTimer} />
                <span style={{ color: '#22C55E' }}>
                  {t('landing.deliver_in')}{' '}
                  {vendorData?.min_delivery_time != null && vendorData?.min_delivery_time != ''
                    ? `${vendorData?.min_delivery_time} - ${vendorData?.minimum_delivery_time}`
                    : vendorData?.minimum_delivery_time}{' '}
                  {t('vendor_profile.mins')}
                </span>
              </div>
              <div className="tag" style={{ background: '#FDF4F0' }}>
                <img src={SvgSoup} />
                <span>{t('landing.prepare_in')} 20mins</span>
              </div>
            </div>
            <h1>{vendorData?.title}</h1>
            <p>{t('landing.grid_menu.restaurant.description')}</p>
            <div className="order-now">
              <img src={SvgBowl} />
              <span>{t('landing.order_now')}</span>
            </div>
          </div>
          <div
            className="photo"
            style={{
              backgroundImage: `url('${IMG_BASE_URL}${vendorData?.profile_path}?w=600&h=600')`
            }}>
            <div className="wifi-block">
              <img src={SvgWifi} />
              <div>
                <h3>
                  {t('landing.wifi_code_for')} {vendorData?.title}
                </h3>
                <p>
                  {t('landing.enter')} &quot;<b>123213213</b>&quot; {t('landing.and_click')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-menu">
          <RestaurantCard
            imageSrc={restorantDetail.imgSrc}
            overlineText={t('landing.grid_menu.restaurant.title')}
            headingText={vendorData?.title}
            descriptionText={restorantDetail.description()}
            buttonText={t('landing.order_now')}
            link={restorantDetail?.orderNow ?? ''}
            icon={SvgBowl}
            isOrderButton={true}
          />

          <RestaurantCard
            imageSrc={restorantDetail.googleImg}
            overlineText={t('landing.grid_menu.review.title')}
            headingText={t('landing.grid_menu.review.subtitle')}
            descriptionText={t('landing.grid_menu.review.description')}
            buttonText={t('landing.grid_menu.review.btn')}
            link={restorantDetail?.leaveReview ?? ''}
            icon={SvgStar}
          />
        </div>
        <PopularItems vendorData={vendorData} />
        <div className="newsletter-main-layer" style={{ padding: '5px', background: "white" }}>
          <div className="secure-payment-block" style={{
            padding: '32px',
            background: "white",
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: '24px',
            maxWidth: '600px',
            marginTop: '20px'
          }}>
            <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                 style={{ flexShrink: 0 }}>
              <path
                d="M20.7074 10.1933H23.3416C23.6909 10.1933 24.0259 10.3412 24.2729 10.6044C24.5199 10.8676 24.6587 11.2247 24.6587 11.5969V28.4407C24.6587 28.813 24.5199 29.17 24.2729 29.4332C24.0259 29.6964 23.6909 29.8443 23.3416 29.8443H2.26826C1.91894 29.8443 1.58394 29.6964 1.33694 29.4332C1.08994 29.17 0.951172 28.813 0.951172 28.4407V11.5969C0.951172 11.2247 1.08994 10.8676 1.33694 10.6044C1.58394 10.3412 1.91894 10.1933 2.26826 10.1933H4.90242V8.78964C4.90242 6.55602 5.735 4.41389 7.21701 2.83448C8.69902 1.25507 10.709 0.367767 12.8049 0.367767C14.9008 0.367767 16.9108 1.25507 18.3928 2.83448C19.8748 4.41389 20.7074 6.55602 20.7074 8.78964V10.1933ZM11.4878 21.0463V24.2297H14.122V21.0463C14.6242 20.7373 15.0167 20.2603 15.2386 19.6894C15.4605 19.1185 15.4994 18.4854 15.3493 17.8885C15.1993 17.2916 14.8685 16.7641 14.4085 16.3879C13.9485 16.0117 13.3848 15.8078 12.8049 15.8078C12.2251 15.8078 11.6614 16.0117 11.2013 16.3879C10.7413 16.7641 10.4106 17.2916 10.2605 17.8885C10.1104 18.4854 10.1494 19.1185 10.3713 19.6894C10.5932 20.2603 10.9857 20.7373 11.4878 21.0463ZM18.0733 10.1933V8.78964C18.0733 7.30056 17.5182 5.87247 16.5302 4.81953C15.5422 3.76659 14.2022 3.17506 12.8049 3.17506C11.4077 3.17506 10.0677 3.76659 9.07965 4.81953C8.09164 5.87247 7.53659 7.30056 7.53659 8.78964V10.1933H18.0733Z"
                fill="#17CE8C"
              />
            </svg>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <span style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '24px' }}>Secure payment</span>
              <span style={{ fontWeight: 'normal', fontSize: '16px', lineHeight: '24px' }}>
          Pay easily with a choice of payment methods. All transactions are processed over an SSL-encrypted connection.
        </span>
            </div>
          </div>
        </div>
        <Reviews rating={4.8} reviewCount={1000} />
        <div className="newsletter-layer">
          <OrderingComponent
            title={loyaltyTitle}
            subtitle={loyaltySubtitle}
            features={loyaltyFeatures}
          />
        </div>

        <div className="newsletter-main-layer" style={{ padding: '64px', background: "white" }}>
          <div className="title-view">
            <img src={SvgQuote} />
            <h4>Over 467 pizzas delivered through SnapFood in the last week!</h4>
          </div>
          <div className="title-view">
            <img src={SvgQuote} alt={"9 out of 10 customers prefer ordering through SnapFood compared to\n" +
              "            phone orders"} />
            <h4>9 out of 10 customers prefer ordering through SnapFood compared to
              phone orders
            </h4>
          </div>
        </div>
        <Faqs />
      </div>

      <NewsletterSignup
        title={newsletterTitle}
        subtitle={newsletterSubtitle}
        inputPlaceholder={inputPlaceholder}
        buttonText={buttonText}
        checkboxText={checkboxText}
      />
      <div className="newsletter-main-layer" style={{ padding: '64px 200px' }}>
        <OrderingComponent title={title} subtitle={subtitle} features={features} />
      </div>


      <Seo title={t('web_title.download_app')} />
      <AppIntro ios_link={ios_link} android_link={android_link} style={{ marginTop: 0 }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.app.language,
  coordinates: state.app.coordinates,
  tmpFoodData: state.app.tmpFoodData,
  cartItems: state.shop.items,
  vendorData: state.shop.vendorData,
  isLoggedIn: state.app.isLoggedIn,
  home_vendor_filter: state.app.home_vendor_filter
});

const mapDispatchToProps = {
  // Define your actions here if needed
};

VendorsId.propTypes = {
  language: PropTypes.string,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  tmpFoodData: PropTypes.object,
  cartItems: PropTypes.array,
  vendorData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  home_vendor_filter: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorsId);
