import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './index.css';
import Svg_Chat from '../../../assets/svgs/chat/new.svg';
import { connect } from 'react-redux';
import { getImageFullURL } from '../../../utils/common';
import DropdownMenu from '../../Molcules/DropdownMenu';
import Svg_newchat from '../../../assets/svgs/chats/ic_new_chat.svg';
import { Users } from '@styled-icons/fa-solid';
import { Theme } from '../../../assets';
import NewConvModal from '../../Modals/NewConvModal';
import NewGroupChatModal from '../../Modals/NewGroupChatModal';

const ChatProfile = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  const [isNewConv, setNewConv] = useState(false);
  const [isNewGroupConv, setNewGroupConv] = useState(false);

  return (
    <div className={'chat-profile-item flex_between ph2 pv2'}>
      <div className="align-middle user-info">
        <img src={getImageFullURL(user.photo)} />
        <div>
          <h5 className={'pl-10'}>{user.full_name}</h5>
          <p>{t('you')}</p>
        </div>
      </div>
      <DropdownMenu
        className={'new-menu'}
        itemsClass={'new-chat-menu-items'}
        onClick={(index) => {
          if (index == 0) {
            setNewConv(true);
          }
          else {
            setNewGroupConv(true);
          }
          console.log(index);
        }}
        button={
          <>
            <img src={Svg_Chat} />
            {/* <p className={'fs4'}>{t('chat.new')}</p> */}
          </>
        }
        menuItems={[
          // eslint-disable-next-line react/jsx-key
          <div className={'item w100'}>
            <img src={Svg_newchat} />
            <div> {t('social.conversation')} </div>
          </div>,
          // eslint-disable-next-line react/jsx-key
          <div className={'item w100'}>
            <Users size={22} color={Theme.colors.text} />
            <div>{t('social.group_chat')}</div>
          </div>
        ]}
      />
      <NewConvModal
        isOpen={isNewConv}
        onClose={()=>{
          setNewConv(false);
        }}
      />
      <NewGroupChatModal
        isOpen={isNewGroupConv}
        onClose={()=>{
          setNewGroupConv(false);
        }}
      />
    </div>
  );
};

ChatProfile.propTypes = {
  user: PropTypes.shape({
    full_name: PropTypes.string,
    photo: PropTypes.string
  })
};

function mapStateToProps({ app }) {
  return {
    user: app.user
  };
}

export default connect(mapStateToProps, {})(ChatProfile);
