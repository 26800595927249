import React from 'react';
import { getCurrentLanguage } from '../../../../localisations';
import './index.css';
import { IMG_BASE_URL } from '../../../../constants/config';
import PropTypes from 'prop-types';
// svgs
import Svg_allcohol from '../../../../assets/svgs/food-cats/allcohol.svg';
import Svg_bio from '../../../../assets/svgs/food-cats/bio.svg';
import Svg_chinese from '../../../../assets/svgs/food-cats/chinese.svg';
import Svg_crepe from '../../../../assets/svgs/food-cats/crepe.svg';
import Svg_dessert from '../../../../assets/svgs/food-cats/dessert.svg';
import Svg_drugs from '../../../../assets/svgs/food-cats/drugs.svg';
import Svg_grocery from '../../../../assets/svgs/food-cats/grocery.svg';
import Svg_japanese from '../../../../assets/svgs/food-cats/japanese.svg';
import Svg_juice from '../../../../assets/svgs/food-cats/juice.svg';
import Svg_market from '../../../../assets/svgs/food-cats/market.svg';
import Svg_meat from '../../../../assets/svgs/food-cats/meat.svg';
import Svg_mediterranean from '../../../../assets/svgs/food-cats/mediterranean.svg';
import Svg_mexican from '../../../../assets/svgs/food-cats/mexican.svg';
import Svg_smoothie from '../../../../assets/svgs/food-cats/smoothie.svg';
import Svg_soup from '../../../../assets/svgs/food-cats/soup.svg';
import Svg_pizza from '../../../../assets/svgs/food-cats/pizza.svg';
import Svg_breakfast from '../../../../assets/svgs/food-cats/breakfast.svg';
import Svg_sushi from '../../../../assets/svgs/food-cats/sushi.svg';
import Svg_burger from '../../../../assets/svgs/food-cats/burger.svg';
import Svg_vegan from '../../../../assets/svgs/food-cats/vegan.svg';
import Svg_winery from '../../../../assets/svgs/food-cats/winery.svg';

export const getFoodCategoryIcon = (category) => {
  if (category.image) {
    return IMG_BASE_URL + category.image;
  }
  if (category.icon === 'glass-3') {
    return Svg_allcohol;
  } else if (category.icon === 'pizza') {
    return Svg_pizza;
  } else if (category.icon === 'broccoli') {
    return Svg_bio;
  } else if (category.icon === 'noodles') {
    return Svg_chinese;
  } else if (category.icon === 'kebab') {
    return Svg_crepe;
  } else if (category.icon === 'cupcake') {
    return Svg_dessert;
  } else if (category.icon === 'tea') {
    return Svg_drugs;
  } else if (category.icon === 'apple') {
    return Svg_grocery;
  } else if (category.icon === 'sushi-2') {
    return Svg_japanese;
  } else if (category.icon === 'frappe') {
    return Svg_juice;
  } else if (category.icon === 'groceries') {
    return Svg_market;
  } else if (category.icon === 'steak') {
    return Svg_meat;
  } else if (category.icon === 'spaguetti') {
    return Svg_mediterranean;
  } else if (category.icon === 'taco') {
    return Svg_mexican;
  } else if (category.icon === 'coffee-4') {
    return Svg_smoothie;
  } else if (category.icon === 'coffee') {
    return Svg_soup;
  } else if (category.icon === 'sushi-1') {
    return Svg_sushi;
  } else if (category.icon === 'hamburguer-1') {
    return Svg_burger;
  } else if (category.icon === 'carrot') {
    return Svg_vegan;
  } else if (category.icon === 'glass-4') {
    return Svg_winery;
  }
  return Svg_breakfast;
};

const CategItem = ({ category, onSelect }) => {
  return (
    <div className={'food-category-item mb3'} onClick={onSelect}>
      <div className={'flex_1 h100'}>
        <h4>{getCurrentLanguage() === 'en' ? category.title_en : category.title_sq}</h4>
      </div>
      <div className={'img-wrapper'}>
        <img src={getFoodCategoryIcon(category)} />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.category != null &&
    prevProps.category.icon === nextProps.category.icon &&
    prevProps.category.image === nextProps.category.image &&
    prevProps.category.title_en === nextProps.category.title_en &&
    prevProps.category.title_sq === nextProps.category.title_sq
  );
}

CategItem.propTypes = {
  category: PropTypes.shape({
    image: PropTypes.string,
    icon: PropTypes.string,
    title_en: PropTypes.string,
    title_sq: PropTypes.string
  }),
  onSelect: PropTypes.func
};

export default React.memo(CategItem, arePropsEqual);
