import moment from 'moment';
import ROUTES_NAMES from '../constants/routeNames';

export const getClosedVendorModalTitle = (vendorData, language) => {
  if (vendorData.custom_closed_info != null && vendorData.custom_closed_info != '') {
    return vendorData.custom_closed_info;
  }
  if (vendorData.vendor_opening_days != null) {
    let day_index = vendorData.vendor_opening_days.findIndex(
      (i) => i.week_day == new Date().getDay().toString()
    );
    if (day_index != -1) {
      if (vendorData.vendor_opening_days[day_index].time_open != null) {
        let open_time = moment(
          vendorData.vendor_opening_days[day_index].time_open,
          'HH:mm:ss'
        ).format('h:mm A');
        if (language == 'en') {
          return `This vendor is currently closed and will open at ${open_time}. You can still see their menu.`;
        } else {
          return `Ky shitës aktualisht është i mbyllur dhe do të hapet në ${open_time}. Ende mund të shihni menunë e tyre.`;
        }
      }
    }
  }
  if (language == 'en') {
    return 'This vendor is currently closed. You can still see their menu.';
  } else {
    return 'Ky shitës aktualisht është i mbyllur. Ende mund të shihni menunë e tyre.';
  }
};

export const getPageTitle = (path) => {
  if (path.includes(ROUTES_NAMES.contact)) {
    return '/contact';
  } else if (path.includes(ROUTES_NAMES.login)) {
    return '/login';
  } else if (path.includes(ROUTES_NAMES.resetPass)) {
    return '/reset-password';
  } else if (path.includes(ROUTES_NAMES.resetPassDone)) {
    return '/reset-password-success';
  } else if (path.includes(ROUTES_NAMES.verification)) {
    return '/verification';
  } else if (path.includes(ROUTES_NAMES.downloadApp)) {
    return '/download-app';
  } else if (path.includes(ROUTES_NAMES.becomeCourier)) {
    return '/courier';
  } else if (path.includes(ROUTES_NAMES.becomePartner)) {
    return '/partner';
  } else if (path.includes(ROUTES_NAMES.privacyPolicy.courier)) {
    return '/courier/privacy-policy';
  } else if (path.includes(ROUTES_NAMES.privacyPolicy.partner)) {
    return '/partner/privacy-policy';
  } else if (path.includes(ROUTES_NAMES.privacyPolicy.privacy_main)) {
    return '/privacy-policy';
  } else if (path.includes(ROUTES_NAMES.terms_conditions)) {
    return '/terms-conditions';
  } else if (path.includes(ROUTES_NAMES.promotions)) {
    return '/promotions';
  } else if (path.includes(ROUTES_NAMES.social)) {
    return '/social';
  } else if (path.includes(ROUTES_NAMES.messages)) {
    return '/chat/conversation';
  } else if (path.includes(ROUTES_NAMES.earnInviteSnapfooder)) {
    return '/earn-screen/invite-snapfooder';
  } else if (path.includes(ROUTES_NAMES.earnInviteFriend)) {
    return '/earn-screen/invite-friend';
  } else if (path.includes(ROUTES_NAMES.paymentmethod)) {
    return '/pay-method';
  } else if (path.includes(ROUTES_NAMES.snapfoodMap)) {
    return '/snapfood-map';
  } else if (path.includes(ROUTES_NAMES.settings)) {
    return '/settings';
  } else if (path.includes(ROUTES_NAMES.orders)) {
    return '/orders';
  } else if (path.includes(ROUTES_NAMES.orderSummary)) {
    return '/orders/summary';
  } else if (path.includes(ROUTES_NAMES.favorites)) {
    return '/favourites';
  } else if (path.includes(ROUTES_NAMES.address)) {
    return '/addresses/add-address';
  } else if (path.includes(ROUTES_NAMES.addresses)) {
    return '/addresses';
  } else if (path.includes(ROUTES_NAMES.wallet)) {
    return '/wallet';
  } else if (path.includes(ROUTES_NAMES.paymentmethods)) {
    return '/pay-methods';
  } else if (path.includes(ROUTES_NAMES.earnInvitation)) {
    return '/earn-screen';
  } else if (path.includes(ROUTES_NAMES.earnInvitationHist)) {
    return '/invitation/history';
  } else if (path.includes(ROUTES_NAMES.earnInvitationDetails)) {
    return '/invitation/details';
  } else if (path.includes(ROUTES_NAMES.profile)) {
    return '/profile';
  } else if (path.includes(ROUTES_NAMES.chat)) {
    return '/chat';
  } else if (path.includes(ROUTES_NAMES.invitations)) {
    return '/invitations';
  } else if (path.includes(ROUTES_NAMES.friends)) {
    return '/friends';
  } else if (path.includes(ROUTES_NAMES.snapfooders)) {
    return '/snapfoodies';
  } else if (path.includes(ROUTES_NAMES.categories)) {
    return '/categories';
  } else if (path.includes(ROUTES_NAMES.vendorsMap)) {
    return '/vendors-map';
  } else if (path.includes(ROUTES_NAMES.vendors)) {
    return '/stores';
  } else if (path.includes(ROUTES_NAMES.vendorDetails)) {
    return '/store/details';
  } else if (path.includes(ROUTES_NAMES.vendorPastOrder)) {
    return '/store/past-orders';
  } else if (path.includes(ROUTES_NAMES.snapfooder)) {
    return '/snapfoodie/details';
  } else if (path.includes(ROUTES_NAMES.blog)) {
    return '/blog';
  } else if (path.includes(ROUTES_NAMES.blogDetails)) {
    return '/blog/details';
  } else if (path.includes(ROUTES_NAMES.checkout)) {
    return '/checkout';
  } else if (path.includes(ROUTES_NAMES.delete_account_help)) {
    return '/delete-account-help';
  }
  return 'Home';
};