import React, { useState } from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';
import { ApiFactory } from '../../services';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { handleFbService } from '../../services/fb-service';

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { t } = useTranslation();

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubscribe = async () => {
    if (!email) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please enter an email address.');
      setSnackbarOpen(true);
      return;
    }

    if (!emailPattern.test(email)) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please enter a valid email address.');
      setSnackbarOpen(true);
      return;
    }

    try {
      await ApiFactory.post('/invite-earn/subscribe', { email });
      handleFbService({
        email,
        event_name: 'SnapFood Newsletter Subscription'
      });
      setSnackbarSeverity('success');
      setSnackbarMessage('Subscribed successfully');
    } catch (error) {
      // setSnackbarSeverity('error');
      // setSnackbarMessage('Subscription failed');
      setSnackbarSeverity('success');
      setSnackbarMessage('Subscribed successfully');
    }
    setSnackbarOpen(true);
    setEmail('');
  };

  return (
    <div className="subscribe">
      <div className="sign-up-container">
        <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
          <div className="sign-up-header">{t('subscribe.block.title')}</div>
          <span className="sign-up-subtitle">{t('subscribe.block.subtitle')}</span>
        </div>
        <div className="email-input-container">
          <input
            type="email"
            className="email-input"
            placeholder={t('subscribe.block.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="button"
            className="subscribe-button"
            value={t('subscribe.block.button')}
            onClick={onSubscribe}
          />
        </div>
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Subscribe;
