import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const SupportMsgItem = ({ msg, onClick, style }) => {
  return (
    <div className={'support-msg-item'} onClick={() => onClick(msg)} style={style}>
      {msg.title}
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.msg != null && prevProps.msg.title === nextProps.msg.title;
}

SupportMsgItem.propTypes = {
  msg: PropTypes.shape({
    title: PropTypes.string
  }),
  style: PropTypes.object,
  onClick: PropTypes.func
};
export default React.memo(SupportMsgItem, arePropsEqual);
