import React from 'react';
import PropTypes from 'prop-types';
import { Wallet } from '@styled-icons/entypo';
import { Theme } from '../../assets';
import './index.css';

const Cashback = ({ value }) => {
  return (
    <div className={'align-middle cashback-info'}>
      <Wallet size={16} color={Theme.colors.white} style={{ marginRight: 8 }} />
      <span>{value} L</span>
    </div>
  );
};
Cashback.propTypes = {
  value: PropTypes.string
};
export default React.memo(Cashback);
