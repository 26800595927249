import React from 'react';
import { useTranslation } from 'react-i18next';
import './MobileIntro.css';
import { Grid } from '@mui/material';

const MobileIntro = () => {
  const { t } = useTranslation();
  return (
    <div className="mobile-intro bg-cover">
      <Grid container spacing={{ xs: 2, md: 5 }} className={'align-middle'} style={{ margin: 0, width: '100%' }}>
        <Grid item xs={12} md={6} style={{}}>
          <div className={'title-view'}>
            <h2>{t('partner.how_it_work')}</h2>
            <h5>{t('partner.how_it_work_desc')}</h5>
          </div>
        </Grid>
        <Grid item xs={12} md={6}/>
      </Grid>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(MobileIntro, arePropsEqual);
