import React, { useEffect, useState , useRef} from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ChevronLeft } from '@styled-icons/boxicons-regular';
import './index.css';
import { reOrder, setDeliveryInfoCart, resetCart } from '../../../store/actions/shop';
import { setTmpOrder, setAppHeaderClass } from '../../../store/actions/app';
import { MainBtn } from '../../../components/Buttons';
import OrderSummInfo from '../../../components/Order/OrderSummInfo';
import { getOrderDetail } from '../../../store/actions/orders';
import {
  // useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import OrderStepper from './orderstepper';
import { ROUTES_NAMES } from '../../../constants';
import OrderReviewModal from '../../../components/Modals/OrderReviewModal';
import { Theme } from '../../../assets';
import OrderSupportModal from '../../../components/Modals/OrderSupportModal';
import { OrderType_Delivery } from '../../../constants/config';
import { checkVendorOpen } from '../../../store/actions/vendors';
import { extractErrorMessage } from '../../../utils/common';
import { confirmAlert } from 'react-confirm-alert';
import OrderReviewItem from '../../../components/Order/OrderReview';
import LoadingSpinner from '../../../components/Spinner';

const past_status = ['delivered', 'picked_up', 'completed'];
const OrderSummScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const location = useLocation();
  const { order_id } = useParams();


  // const parsed = querystring.parse(location.search);
  // const isNew = parsed.is_new === 'true';

  // const past_status = ['delivered', 'picked_up', 'reserved'];
  const [isLoaded, setLoaded] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);

  const _Timer = useRef(null);
  useEffect(() => {
    loadOrderData(order_id);
    _Timer.current = setInterval(() => {
      loadOrderData(order_id, false);
    }, 20000)
    return () => {
      if (_Timer.current) {
        clearInterval(_Timer.current);
        _Timer.current = null;
      }
    }
  }, [order_id]);

  useEffect(() => {
    props.setAppHeaderClass('app-sticky-header');
    return () => {
      props.setAppHeaderClass('');
    };
  }, []);

  const loadOrderData=(order_id, showLoading = true)=>{
    if (showLoading) {
      setLoaded(false);
    }
    getOrderDetail(order_id)
      .then((order_data) => {
        props.setTmpOrder(order_data);
        if (showLoading) {
          setLoaded(true);
        }
      })
      .catch(() => {
        if (showLoading) {
          setLoaded(true);
        }
      });
  }

  if (isLoaded != true || props.order.id == null) {
    return <div style={{height: '50vh'}}>
      <div className={'flex_1'}>
          <LoadingSpinner />
        </div>
    </div>
  }

  const reorderRequest = async (order, restaurant) => {
    props.reOrder(order, restaurant).then(
      async (items) => {
        if (items.length > 0) {
          props.setDeliveryInfoCart({
            handover_method: order.order_type
          });
          navigate(ROUTES_NAMES.checkout, { replace: true });
        } else {
          navigate(`/store/${order.vendor.hash_id}/${order.vendor.slug}`);
        }
      },
      async (error) => {
        return confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {
              }
            }
          ]
        });
      }
    );
  };

  const reorder = () => {
    const restaurant = props.order.vendor;
    if (restaurant == null) {
      return confirmAlert({
        title: t('restaurant_details.we_are_sorry'),
        message: t('order_summary.reorder_unavailable_vendor'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }

    let items = props.cartItems.filter((i) => i.vendor_id != restaurant.id);
    if (items.length > 0) {
      checkVendorOpen(items[0].vendor_id)
        .then((is_open) => {
          if (is_open == true) {
            confirmAlert({
              title: t('restaurant_details.new_order_question'),
              message: t('restaurant_details.new_order_text'),
              closeOnEscape: false,
              closeOnClickOutside: false,
              buttons: [
                {
                  label: t('confirm'),
                  onClick: () => {
                    reorderRequest(props.order, restaurant);
                  }
                },
                {
                  label: t('cancel'),
                  onClick: () => {
                  }
                }
              ]
            });
          } else {
            reorderRequest(props.order, restaurant);
          }
        })
        .catch(() => {
          reorderRequest(props.order, restaurant);
        });
    } else {
      reorderRequest(props.order, restaurant);
    }
  };

  const isPastOrder = (order) => {
    if (past_status.find((i) => i == order.status) == null) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={'order-summ-screen mb10'}>
        <Row className={'align-col-middle mv3 ph2'} style={{ marginLeft: 0, marginRight: 0, justifyContent: 'center' }}>
          <Col xs={12} lg={10} className={'header-view'}>
            <OrderStepper order={props.order} />
            <hr className='lineStyle' style={{ marginTop: 12 }} />
            <div
              className={'align-middle back-link btn-style'}
              onClick={() => {
                navigate(ROUTES_NAMES.orders, { replace: true });
              }}>
              <ChevronLeft size={24} color={Theme.colors.gray7} />
              <div style={{ marginLeft: 8 }}>{t('order_summary.my_orders')}</div>
            </div>
          </Col>
        </Row>
        <Row className={'align-col-middle mt5'} style={{  marginLeft: 0, marginRight: 0,  justifyContent: 'center' }}>
          <Col xs={12} lg={7} className={'ph4'}>
            <OrderSummInfo order={props.order} isOrdered={true}/>
            {(isPastOrder(props.order) || props.order.status === 'declined') && props.order?.vendor?.active == 1 && (
              <div className={'align-col-middle w100 '}>
                <div className={'reorder-btn btn-style mv3'} onClick={reorder}>
                  {t('order_summary.order_again')}
                </div>
              </div>
            )}
            {isPastOrder(props.order) && props.order.order_review && (
              <OrderReviewItem
                vendor_rating={
                  props.order.order_review.vendor_review != null
                    ? props.order.order_review.vendor_review.rating
                    : 0
                }
                product_rating={
                  props.order.order_review.product_review != null
                    ? props.order.order_review.product_review.rating
                    : 0
                }
                rider_rating={
                  props.order.order_review.rider_review != null
                    ? props.order.order_review.rider_review.rating
                    : 0
                }
              />
            )}
            {isPastOrder(props.order) && !props.order.order_review && (
              <div className={'w100 ph2'}>
                <MainBtn
                  title={t('order_summary.review_order')}
                  className={'proceed-btn'}
                  style={{ marginTop: 35 }}
                  onClick={() => {
                    setShowReviewModal(true);
                  }}
                />
              </div>
            )}
            {props.order.order_type === OrderType_Delivery && props.order.status !== 'new' && (
              <div className={'w100 align-col-middle'}>
                <MainBtn
                  title={t('order_summary.order_help')}
                  className={'proceed-btn'}
                  style={{ marginTop: 35, width: 240 }}
                  onClick={() => {
                    setShowSupportModal(true);
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
      <OrderReviewModal
        order_id={props.order.id}
        showModal={showReviewModal}
        onClose={() => {
          setShowReviewModal(false);
        }}
      />
      {showSupportModal && (
        <OrderSupportModal
          onClose={() => {
            setShowSupportModal(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  order: app.tmp_order,
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates,
  vendorData: shop.vendorData || {},
  cartItems: shop.items
});

OrderSummScreen.propTypes = {
  order: {
    id: PropTypes.number
  },
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  cartItems: PropTypes.array,
  user: PropTypes.object,
  setDeliveryInfoCart: PropTypes.func,
  clearCart: PropTypes.func,
  setTmpOrder: PropTypes.func,
  reOrder: PropTypes.func,
  setAppHeaderClass: PropTypes.func
};

export default connect(mapStateToProps, {
  resetCart,
  setTmpOrder,
  reOrder,
  setDeliveryInfoCart,
  setAppHeaderClass
})(OrderSummScreen);
