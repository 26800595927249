import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import apiFactory from '../../../../services/apiFactory';
import Theme from '../../../../assets/theme';
import InvitationModal from '../../../../components/Modals/EarnInvitationModal';
import InviteOptionModal from '../../../../components/Modals/InvitationOptionModal';
import {
  loadInvitationTimerSetting,
  setInvitationPickedUser,
  setEarnInviteChatInfo
} from '../../../../store/actions/app';
import { createSingleChannel, findSingleChannel } from '../../../../services/chat';
import { isEmpty, getHourMin, convertTimeString2Hours, ucFirst } from '../../../../utils/common';
import ListBtn from '../../../../components/Buttons/ListBtn';
import AuthBtn from '../../../../components/Buttons/AuthBtn';
import './index.css';
import CommonTooltip from '../../../../components/Common/CommonTooltip';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import LoadingModal from '../../../../components/Modals/LoadingModal';
import Img_invite from '../../../../assets/images/earn/earn_referal.png';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';

const EarnScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isInviteOptionModal, showInviteOptionModal] = useState(false);
  const [isInviteModal, showInviteModal] = useState(false);

  const [isSendingInvitation, setSendingInvitation] = useState(false);
  const referralsSetting = {
    cycle_hours_earn_invitation: props.referralsRewardsSetting.cycle_hours_earn_invitation || 1,
    user_rewards: props.referralsRewardsSetting.user_rewards || 100,
    register_rewards: props.referralsRewardsSetting.register_rewards || 100,
    max_num_referral: props.referralsRewardsSetting.max_num_referral || 0
  };

  useEffect(() => {
    props.loadInvitationTimerSetting();
  }, []);

  useEffect(() => {
    console.log('invitationPickedUser ', props.invitationPickedUser);
    if (props.invitationPickedUser) {
      showInviteModal(true);
    }
  }, [props.invitationPickedUser]);

  const getEarnHowToWorksDescription = () => {
    let defaultMsg = t('invitation_earn.earn_desc');
    if (isEmpty(props.referralsRewardsSetting.earninvitation_howto_works) == false) {
      defaultMsg = props.referralsRewardsSetting.earninvitation_howto_works;
    }
    defaultMsg = defaultMsg.replace('XXX', referralsSetting.cycle_hours_earn_invitation);

    return defaultMsg;
  };

  const onEnterChannel = async (partner, invitation_code) => {
    let defaultMsg = t('invitation_earn.friend_use_this_code');

    if (isEmpty(props.referralsRewardsSetting.earninvite_friend_message) == false) {
      defaultMsg = props.referralsRewardsSetting.earninvite_friend_message;
    }

    defaultMsg = defaultMsg
      .replace('###', ucFirst(partner.username || partner.full_name))
      .replace('XXX', invitation_code);

    console.log(defaultMsg);
    let found_channel = await findSingleChannel(props.user.id, partner.id);
    if (found_channel != null) {
      props.setEarnInviteChatInfo({
        defaultMsg: defaultMsg,
        invitation_code: invitation_code
      });
      navigate(`/social/chat/${found_channel.id}`);
    } else {
      let channelID = await createSingleChannel(props.user, partner);
      if (channelID != null) {
        props.setEarnInviteChatInfo({
          defaultMsg: defaultMsg,
          invitation_code: invitation_code
        });
        navigate(`/social/chat/${channelID}`);
      } else {
        confirmAlert({
          title: t('alerts.error'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    }
  };

  const onSendInvitation = () => {
    let partnerData = { ...props.invitationPickedUser };
    let isFriend = props.invitationPickedUser?.isFriend == true;
    let receiver_id = props.invitationPickedUser?.id;
    if (receiver_id == null) {
      return;
    }
    onCloseInvitationModal();
    setSendingInvitation(true);
    apiFactory
      .post(`invite-earn/send-earninvitation`, {
        receiver_id: receiver_id
      })
      .then((res) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Send Earn Invitation',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        props.loadInvitationTimerSetting();
        if (res.data?.invitation_code && isFriend) {
          onEnterChannel(partnerData, res.data?.invitation_code);
        }
      })
      .catch((err) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Send Earn Invitation',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        console.log('onSendInvitation err ', err);
        const message = err.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      })
      .finally(() => {
        setSendingInvitation(false);
      });
  };

  const onCloseInvitationModal = () => {
    showInviteModal(false);
    props.setInvitationPickedUser(null);
  };

  const renderLearnMore = () => {
    const invitationRewardsSetting = props.invitationRewardsSetting;
    const getGroupedInfo = () => {
      let slots = [];
      for (let i = 0; i < invitationRewardsSetting.length; i++) {
        let setting = invitationRewardsSetting[i];
        if (setting.from && setting.to && setting.rewards_rate != null) {
          let time = `${getHourMin(setting.from)}-${getHourMin(setting.to)}`;
          let index = slots.findIndex((s) => s.rewards_rate == setting.rewards_rate);
          if (index != -1) {
            let tmp = slots[index].times || [];
            tmp = tmp.slice(0);
            tmp.push(time);
            tmp.sort((a, b) => convertTimeString2Hours(a) - convertTimeString2Hours(b));
            slots[index].times = tmp;
          } else {
            slots.push({
              times: [time],
              rewards_rate: setting.rewards_rate
            });
          }
        }
      }
      return slots;
    };
    return (
      <CommonTooltip
        title={t('invitation_earn.learn_invite_title')}
        content={
          <div className={'align-col-middle mt1'}>
            {getGroupedInfo().map((setting, index) => (
              <div key={index} className={'tooltip-row'}>
                <div className={'dot'} />
                <span className={'tooltip-txt'}>
                  {t('invitation_earn.learn_invite_desc1_01')}
                  {setting.times != null &&
                    setting.times.map((tm, idx) => (
                      <>
                        <span key={idx} style={{ fontFamily: Theme.fonts.bold }}>
                          {tm}
                        </span>
                        {idx < setting.times.length - 1 && t('and')}
                      </>
                    ))}

                  {t('invitation_earn.learn_invite_desc1_02')}
                  <span style={{ fontFamily: Theme.fonts.bold }}>{setting.rewards_rate}%</span>
                  {t('invitation_earn.learn_invite_desc1_03')}
                </span>
              </div>
            ))}
            <div className={'tooltip-row'}>
              <div className={'dot'} />
              <p className={'tooltip-txt'}>
                {t('invitation_earn.learn_invite_desc2_01')}
                <span style={{ fontFamily: Theme.fonts.bold }}>1%</span>
                {t('invitation_earn.learn_invite_desc2_02')}
              </p>
            </div>
          </div>
        }
        anchor={<p className={'learnmore'}>{t('invitation_earn.learn_more')}</p>}
      />
    );
  };

  return (
    <div className={'earn-screen'}>
      <div className={'align-col-middle'}>
        <img src={Img_invite} style={{ width: 250, height: 250, objectFit: 'contain' }} />
      </div>
      <div style={{ height: 20 }} />
      <div className={'align-col-middle w100'}>
        <p className={'earn-desc'}>{getEarnHowToWorksDescription()}</p>
        <div style={{ height: 20 }} />
        {renderLearnMore()}
        <div style={{ height: 20 }} />
        <AuthBtn
          title={
            props.invitationTimerSetting.can_invite == false &&
            props.invitationTimerSetting.remaining_time_to_use
              ? t('invitation_earn.invite_again_later').replace(
                  '###',
                  props.invitationTimerSetting.remaining_time_to_use.toString()
                )
              : t('Invite_Earn')
          }
          isDisabled={
            props.invitationTimerSetting.can_invite == false &&
            props.invitationTimerSetting.remaining_time_to_use != null
          }
          style={{ width: '100%' }}
          onClick={() => {
            showInviteOptionModal(true);
          }}
        />
        <div className={'divider'} />
        <ListBtn
          className={'hist-btn'}
          title={t('invitation_earn.active_earninvitations')}
          onClick={() => {
            navigate('/profile/earn-invitations?active=1');
          }}
        />
        <div className={'divider'} />
        <ListBtn
          className={'hist-btn'}
          title={t('invitation_earn.invitation_hist')}
          onClick={() => {
            navigate('/profile/earn-invitations');
          }}
        />
      </div>
      <div style={{ height: 40 }} />
      <InviteOptionModal
        isOpen={isInviteOptionModal}
        onClose={() => showInviteOptionModal(false)}
        onFriend={() => {
          showInviteOptionModal(false);
          navigate('/profile/earninvite-friend');
        }}
        onSnapfood={() => {
          showInviteOptionModal(false);
          navigate('/profile/earninvite-snapfooder');
        }}
      />
      <InvitationModal
        isOpen={isInviteModal}
        name={
          props.invitationPickedUser
            ? props.invitationPickedUser.username || props.invitationPickedUser.full_name
            : ''
        }
        onClose={onCloseInvitationModal}
        onSend={onSendInvitation}
      />
      <LoadingModal showModal={isSendingInvitation} />
    </div>
  );
};

EarnScreen.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  invitationPickedUser: PropTypes.shape({
    id: PropTypes.number,
    isFriend: PropTypes.bool,
    username: PropTypes.string,
    full_name: PropTypes.string
  }),
  invitationRewardsSetting: PropTypes.array,
  invitationTimerSetting: {},
  referralsRewardsSetting: {
    earninvitation_howto_works: PropTypes.string,
    earninvite_friend_message: PropTypes.string
  },
  loadInvitationTimerSetting: PropTypes.func,
  setInvitationPickedUser: PropTypes.func,
  setEarnInviteChatInfo: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  invitationPickedUser: app.invitationPickedUser,
  invitationRewardsSetting: app.invitationRewardsSetting,
  referralsRewardsSetting: app.referralsRewardsSetting,
  invitationTimerSetting: app.invitationTimerSetting || {}
});

export default connect(mapStateToProps, {
  loadInvitationTimerSetting,
  setInvitationPickedUser,
  setEarnInviteChatInfo
})(EarnScreen);
