import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { PriceBoard } from '../../../components/Checkout';
import { MainBtn } from '../../../components/Buttons';
import { CommentInput } from '../../../components/Inputs';
import apiFactory from '../../../services/apiFactory';
import { OrderType_Delivery } from '../../../constants/config';
import PaymentMethodItem from '../../../components/Checkout/PaymentMethodItem';
import { Pay_COD } from '../../../constants/config';
import { setDeliveryInfoCart, setPaymentInfoCart, setPriceCart } from '../../../store/actions/shop';
import CardPayMethodItem from '../../../components/Checkout/CardPayMethodItem';
import AuthInput from '../../../components/Inputs/AuthInput';
import { Theme } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import querystring from 'query-string';
import { ROUTES_NAMES } from '../../../constants';
import { AddCardModal } from '../../../components/Modals';
import { confirmAlert } from 'react-confirm-alert';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const PaymentMethodsStep = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const parsed = querystring.parse(location.search) || {};

  const [cards, setCards] = useState([]);
  const [isNewCardModal, OpenNewCardModal] = useState(false);

  useEffect(() => {
    loadPaymentMethods();
  }, [props.user.default_card_id]);

  useEffect(() => {
    if (parsed.modal == 'new-card' && isNewCardModal == false) {
      OpenNewCardModal(true);
    } else {
      OpenNewCardModal(false);
    }
  }, [parsed.modal]);

  const setDefaultCard = (card_list) => {
    let found_index = card_list.findIndex((card) => card.id === props.user.default_card_id);
    if (found_index === -1) {
      props.setPaymentInfoCart({
        ...props.payment_info,
        selected_card: card_list.length > 0 ? card_list[0] : null
      });
    } else {
      props.setPaymentInfoCart({
        ...props.payment_info,
        selected_card: card_list[found_index]
      });
    }
  };

  const loadPaymentMethods = () => {
    apiFactory.get(`stripe/payment-methods`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load Payment Methods', {
          action_category: 'Load screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        let loadedCards = data || [];
        setCards(loadedCards);
        setDefaultCard(loadedCards);
      },
      () => {
        identifyDevice();
        trackEvent('Load Payment Methods', {
          action_category: 'Load screen',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
      }
    );
  };

  const calculateOrderTotal = () => {
    const { subtotal, discount, cashback, small_order_fee, delivery_fee } = props.cartPrice;

    let total = subtotal - cashback - discount;
    if (props.delivery_info.handover_method == OrderType_Delivery) {
      total = total + small_order_fee;
      total = total + delivery_fee;

      if (
        props.payment_info.method != 'cash' &&
        props.vendorData.delivery_type == 'Snapfood' &&
        props.delivery_info.tip_rider > 0
      ) {
        total = total + parseInt(props.delivery_info.tip_rider);
      }
    }
    return total;
  };

  const renderPaymentMethods = () => {
    const { t } = useTranslation();

    return (
      <div className={'align-col-middle w100 checkout-payment-methods'}>
        <div className={'w100 flex_between '}>
          <h5>{t('cart.payment_method')}</h5>
        </div>
        <Row className={'w100 mt3'}>
          <Col lg={6} sm={12} className={'mt1 ph1'}>
            <PaymentMethodItem
              title={t(Pay_COD)}
              checked={props.payment_info.method === 'cash'}
              onClick={() => {
                props.setPaymentInfoCart({
                  ...props.payment_info,
                  method: 'cash',
                  splits: []
                });
              }}
            />
          </Col>

          {props.vendorData != null && props.vendorData.online_payment === 1 && (
            <React.Fragment>
              <Col lg={6} sm={12} className={'ph1 mt1'}>
                <CardPayMethodItem
                  checked={props.payment_info.method === 'stripe'}
                  cards={cards}
                  curCard={props.payment_info.selected_card}
                  onClick={() => {
                    props.setPaymentInfoCart({
                      ...props.payment_info,
                      method: 'stripe'
                    });
                  }}
                  onPressCard={(card) => {
                    props.setPaymentInfoCart({
                      ...props.payment_info,
                      selected_card: card
                    });
                  }}
                  onAddCard={() => {
                    navigate(ROUTES_NAMES.checkout + '?modal=new-card');
                  }}
                />
              </Col>
            </React.Fragment>
          )}
        </Row>
        <Row className={'w100 mt3'}>
          <div className={'subject-title fs4 mb2'}>{t('cart.delivery_note')}</div>
          <CommentInput
            placeholder={t('cart.add_your_note')}
            text={props.delivery_info.comments}
            onChange={(text) => {
              props.setDeliveryInfoCart({
                comments: text
              });
            }}
          />
        </Row>
      </div>
    );
  };

  const _renderTipRider = () => {
    return (
      <div className={'flex_between rider-tip-view mt2'}>
        <span className={'subject-title fs4 flex_1'}>{t('cart.leave_rider_tip')}</span>
        <AuthInput
          style={{
            width: 120,
            height: 46,
            marginBottom: 0,
            borderWidth: 1,
            borderColor: Theme.colors.gray6,
            backgroundColor: Theme.colors.white,
            textAlign: 'center'
          }}
          placeholder={t('cart.enter_amount')}
          textAlign="center"
          text={props.delivery_info.tip_rider}
          onChange={(t) => {
            let int_val = t !== '' ? parseInt(t) : 0;
            props.setDeliveryInfoCart({
              tip_rider: int_val
            });
          }}
        />
      </div>
    );
  };

  const onProceed = () => {
    const { method, selected_card } = props.payment_info;
    if (method == 'stripe' && selected_card == null) {
      return confirmAlert({
        title: null,
        message: t('cart.select_card'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    props.setPriceCart({
      ...props.cartPrice,
      order_total: calculateOrderTotal()
    });
    props.setStep(2);
  };

  return (
    <Row
      className={'align-col-middle ph2'}
      style={{ marginLeft: 0, marginRight: 0, justifyContent: 'center' }}>
      <Col xs={12} md={7} className={'left-view  mt5'}>
        {renderPaymentMethods()}
      </Col>
      <Col xs={12} md={5} className={'right-view  mt5'}>
        <MainBtn
          className={'proceed-btn'}
          title={t('cart.continue_to_payment')}
          onClick={onProceed}
        />
        {props.payment_info.method != 'cash' &&
          props.delivery_info.handover_method == OrderType_Delivery &&
          props.vendorData.delivery_type == 'Snapfood' &&
          _renderTipRider()}
        <PriceBoard
          handover_method={props.delivery_info.handover_method}
          delivery_type={props.vendorData.delivery_type}
          payment_method={props.payment_info.method}
          minOrderPrice={props.cartPrice.min_order_price}
          small_order_fee={props.cartPrice.small_order_fee}
          delivery_fee={props.cartPrice.delivery_fee}
          cashback={props.cartPrice.cashback}
          discount={props.cartPrice.discount}
          sub_total={props.cartPrice.subtotal}
          total_price={calculateOrderTotal()}
        />
      </Col>
      <AddCardModal isOpen={isNewCardModal} />
    </Row>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates,
  order_data: shop,
  cartItems: shop.items || [],
  cutlery: shop.cutlery,
  coupon: shop.coupon,
  comments: shop.comments,
  cartPrice: shop.cartPrice,
  vendorData: shop.vendorData || {},

  delivery_info: shop.delivery_info,
  payment_info: shop.payment_info
});

PaymentMethodsStep.propTypes = {
  outOfDeliveryArea: PropTypes.bool,
  cartItems: PropTypes.array,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    tip_rider: PropTypes.number
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    delivery_type: PropTypes.string,
    online_payment: PropTypes.number
  }),
  cartPrice: PropTypes.shape({
    cashback: PropTypes.number,
    discount: PropTypes.number,
    delivery_fee: PropTypes.number,
    min_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    subtotal: PropTypes.number
  }),
  payment_info: PropTypes.object,
  user: PropTypes.object,
  setDeliveryInfoCart: PropTypes.func,
  setPaymentInfoCart: PropTypes.func,
  setPriceCart: PropTypes.func,
  setStep: PropTypes.func
};

export default connect(mapStateToProps, {
  setPaymentInfoCart,
  setDeliveryInfoCart,
  setPriceCart
})(PaymentMethodsStep);
