import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FeaturedCards from '../../components/Home/FeaturedCards';
import ApplyCards from '../../components/Home/ApplyCards';
import AppIntro from '../../components/Home/AppIntro';
import AppFeatures from '../../components/Home/AppFeatures';
import './index.css';
import Seo from '../../components/Seo';
import Banner from '../../components/Home/Banner';
import WhereHeardModal from '../../components/Modals/WhereHeardModal';
import { setShowWhereHeardFeedbackModal, setAppHeaderClass } from '../../store/actions/app';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { handleFbService } from '../../services/fb-service';
// import { createUUID } from '../../utils/common';
// import { KEYS } from '../../services/storage';

const Home = (props) => {
  // const external_id = createUUID();
  // const country = localStorage.getItem(KEYS.COUNTRY_CODE);

  const { t } = useTranslation();

  useEffect(() => {
    // handleFbService({
    //   event_name: 'load home',
    //   external_id,
    //   country
    // });
    props.setAppHeaderClass('app-home-header');
    return () => {
      props.setAppHeaderClass('');
    };
  }, []);

  useEffect(() => {
    if (props.isLoggedIn) {
      props.setShowWhereHeardFeedbackModal(true);
    }
  }, [props.isLoggedIn]);

  return (
    <div data-testid="view-home" className={'view-home'}>
      <Seo title={t('web_title.default')} />
      <Banner />
      <AppIntro />
      <FeaturedCards />
      <AppFeatures />
      <ApplyCards />
      {props.show_feedback_where_heard_modal && <WhereHeardModal />}
    </div>
  );
};

Home.propTypes = {
  isLoggedIn: PropTypes.bool,
  show_feedback_where_heard_modal: PropTypes.bool,
  setShowWhereHeardFeedbackModal: PropTypes.func,
  setAppHeaderClass: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn,
  show_feedback_where_heard_modal: app.show_feedback_where_heard_modal
});

export default connect(mapStateToProps, {
  setShowWhereHeardFeedbackModal,
  setAppHeaderClass
})(Home);
