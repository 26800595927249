import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css';
import { Config } from '../../../constants';
import RoundIconBtn from '../../Buttons/RoundIconBtn';
import GoogleMapReact from 'google-map-react';
import { ChevronLeft } from '@mui/icons-material';
import { Theme } from '../../../assets';
import LocationMarker from '../../Markers/LocationMarker';

const LocationMsgModal = (props) => {
  const { isOpen, onClose } = props;
  const [open, setOpen] = React.useState(false);
  const [curLoc, setCurLoc] = useState({
    latitude: Config.TIRANA_CITY_LOCATION.latitude,
    longitude: Config.TIRANA_CITY_LOCATION.longitude
  });

  useEffect(() => {
    if (isOpen) {
      setCurLoc({
        latitude: props.latitude || Config.TIRANA_CITY_LOCATION.latitude,
        longitude: props.longitude || Config.TIRANA_CITY_LOCATION.longitude
      });
    }
    setOpen(isOpen);
  }, [isOpen]);


  return (
    <Dialog open={open} className={'align-col-middle w100 modal location-msg-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'map-view'}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY}}
            // defaultCenter={[curLoc.latitude, curLoc.longitude]}
            center={[curLoc.latitude, curLoc.longitude]}
            defaultZoom={12}
            options={{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              styles: Config.SocialMapScreenStyles
            }}>
            <LocationMarker lat={curLoc.latitude} lng={curLoc.longitude} />
          </GoogleMapReact>
        </div>
        <RoundIconBtn
          style={{
            ...styles.headerBtn
          }}
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </Dialog>
  );
};

const styles = {
  headerBtn: {
    width: 33,
    height: 33,
    borderRadius: 8,
    backgroundColor: Theme.colors.white
  }
};

LocationMsgModal.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.latitude == nextProps.latitude &&
    prevProps.longitude == nextProps.longitude &&
    prevProps.isOpen == nextProps.isOpen;
}

export default React.memo(LocationMsgModal, arePropsEqual);
