import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';
import { Config } from '../../../constants';
import { isEmpty } from '../../../utils/common';
import {
  MemorizedPriceItem,
  MemorizedFoodTitle,
  MemorizedFoodDesc,
  MemorizedFavBtn,
  MemorizedLogoImg
} from './ValueItems';
import './index.css';
import { toggleProductFavourite } from '../../../store/actions/vendors';

const GroceryFoodItem = ({ id, isFav,  data, style, onClick, onFavChange }) => {
  const onPressFav = () => {
    console.log('press fav ')
    toggleProductFavourite(id, isFav === 1 ? 0 : 1)
      .then(() => {
        data.isFav = isFav == 1 ? 0 : 1
        onFavChange(data);
      })
      .catch(() => {});
  };

  return (
    <Col key={id} xs={12} md={6} lg={4}>
      <div className={'food-item'} style={style} onClick={onClick}>
        <div className={'flex_between title-view'}>
          <div className={'align-row-start'}>
            <MemorizedFoodTitle text={data.title} />
            {/*<MemorizedDiscount value={data.discount} />*/}
          </div>
          <MemorizedFavBtn isFav={isFav} onChange={onPressFav} />
        </div>
        <div className={'flex_between info'}>
          <div className={'flex_1'}>
            <div className={'w100'}>
              <MemorizedFoodDesc text={data.description} />
            </div>
            <div className={'align-row-start mt1'}>
              <MemorizedPriceItem value={data.price} discount={data.discount} />
            </div>
            {/*<div className={'align-row-start mt1'}>*/}
            {/*  <MemorizedLikeBtns isLike={isLike} onLike={onLike} />*/}
            {/*</div>*/}
          </div>
          {!isEmpty(data.image_thumbnail_path) ? (
            <MemorizedLogoImg
              url={`${Config.IMG_BASE_URL}${data.image_thumbnail_path}?w=200&h=200`}
            />
          ) : (
            <div style={{ width: 80, height: 80 }} />
          )}
        </div>
      </div>
    </Col>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.id === nextProps.id &&
    prevProps.isFav === nextProps.isFav &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.onLike === nextProps.onLike &&
    prevProps.onFavChange === nextProps.onFavChange
  );
}

GroceryFoodItem.propTypes = {
  id: PropTypes.string,
  isFav: PropTypes.bool,
  isLike: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image_thumbnail_path: PropTypes.string,
    price: PropTypes.number,
    discount: PropTypes.number,
    isFav : PropTypes.number
  }),
  style: PropTypes.object,
  onClick: PropTypes.func,
  onLike: PropTypes.func,
  onFavChange: PropTypes.func
};
export default React.memo(GroceryFoodItem, arePropsEqual);
