import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { ROUTES_NAMES } from '../../constants';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


export function AuthMiddleware({ isLoggedIn, hasVerifiedPhone, children }) {
  let location = useLocation();

  if (!isLoggedIn) {
    if (location.pathname.includes(ROUTES_NAMES.checkout)) {
      return <Navigate to={ROUTES_NAMES.login} state={{ from: location }} replace />;
    }
    return <Navigate to={ROUTES_NAMES.home} state={{ from: location }} replace />;
  } else if (!hasVerifiedPhone) {
    return <Navigate to={ROUTES_NAMES.verification} state={{ from: location }} replace />;
  } else {
    return children;
  }
}

AuthMiddleware.propTypes = {
  children: PropTypes.element,
  user: PropTypes.shape({
    full_name: PropTypes.string,
    photo: PropTypes.string
  }),
  isLoggedIn: PropTypes.bool,
  hasVerifiedPhone: PropTypes.bool
};

function mapStateToProps({ app }) {
  return {
    user: app.user,
    isLoggedIn: app.isLoggedIn,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {})(AuthMiddleware);

