const ACTION_TYPES = {
  SHOW_ALERT: 'SHOW_ALERT',
  DISMISS_ALERT: 'DISMISS_ALERT',
  APP_SET_USER_DATA: 'APP.SET_USER_DATA',
  APP_SET_HAS_VERIFIED_PHONE: 'APP.SET_HAS_VERIFIED_PHONE',
  APP_LOGGED_IN: 'APP.LOGGED_IN',
  APP_TOGGLE_SIDE_BAR: 'APP.TOGGLE_SIDE_BAR',
  APP_SET_HEADER_CLASS: 'APP_SET_HEADER_CLASS',
  APP_SET_ADDRESSES: 'APP.SET_ADDRESSES',
  APP_TMP_ADDR_PICKED: 'APP.TMP_ADDR_PICKED',
  APP_TMP_PASS_CHANGED : 'APP_TMP_PASS_CHANGED',
  APP_APPLY_LOCATION: 'APP_APPLY_LOCATION',
  APP_SET_ACTIVE_SCREEN_FROM_PUSH: 'APP.SET_ACTIVE_SCREEN_FROM_PUSH',
  APP_SET_PROFILE_BLOG_FILTER: 'APP.SET_PROFILE_BLOG_FILTER',
  APP_SET_BLOG_CATEGORIES: 'APP_SET_BLOG_CATEGORIES',
  APP_SET_ORDERS_FILTER: 'APP_SET_ORDERS_FILTER',
  APP_SET_LANGE: 'APP.SET_LANG',
  APP_SET_REWARDS_PICKED_USER: 'APP_SET_REWARDS_PICKED_USER',
  APP_SET_CHAT_CHANNELS: 'APP_SET_CHAT_CHANNELS',
  APP_SET_HOME_DATA: 'APP_SET_HOME_DATA',
  APP_SET_INVITATIONS_PAGE_DATA: 'APP_SET_INVITATIONS_PAGE_DATA',
  APP_SET_INVITE_PICKED_USER : 'APP_SET_INVITE_PICKED_USER',
  APP_SET_INVITE_CHAT_INFO : 'APP_SET_INVITE_CHAT_INFO',
  APP_TMP_SET_SELECTED_FOR_GROUP_CHAT: 'APP_TMP_SET_SELECTED_FOR_GROUP_CHAT',
  APP_TMP_SNAPFOOD_MAP_DATA: 'APP_TMP_SNAPFOOD_MAP_DATA',
  APP_SET_VENDOR_FILTER: 'APP.SET_VENDOR_FILTER',
  APP_SET_VENDOR_SORT: 'APP.SET_VENDOR_SORT',
  APP_SET_ACTIVE_MARKER: 'APP_SET_ACTIVE_MARKER',
  APP_SET_MESSAGES_SCROLLER: 'APP_SET_MESSAGES_SCROLLER',
  APP_SEEN_ONBOARD: 'APP_SEEN_ONBOARD',
  APP_SET_INVITATION_REWARDS: 'APP_SET_INVITATION_REWARDS',
  APP_SET_REFERRALS_REWARDS: 'APP_SET_REFERRALS_REWARDS',
  APP_SET_INVITATION_TIMER_SETTING: 'APP_SET_INVITATION_TIMER_SETTING',
  APP_SET_TMP_ORDER: 'APP.APP_SET_TMP_ORDER',
  APP_SET_ORDER_MSG_TAGS: 'APP.APP_SET_ORDER_MSG_TAGS',
  APP_SET_FOOD_CATEGORIES: 'APP.APP_SET_FOOD_CATEGORIES',
  APP_SET_ORDER_FOR : 'APP_SET_ORDER_FOR',
  APP_SET_CONFIRM_LEGAL_AGE : 'APP_SET_CONFIRM_LEGAL_AGE',
  APP_SET_APP_FEEDBACK_TAGS : 'APP_SET_APP_FEEDBACK_TAGS',
  APP_SET_SHOW_WHERE_HEARD_FEEDBACK_MODAL : 'APP_SET_SHOW_WHERE_HEARD_FEEDBACK_MODAL',
  APP_SET_BLOG_DATA : 'APP_SET_BLOG_DATA',
  APP_SET_SNACKBAR_MESSAGE: 'APP_SET_SNACKBAR_MESSAGE',
  // Profile
  APP_DELETED_ADDRESS: 'APP_DELETED_ADDRESS',

  // CART
  SET_VENDOR_CART: 'CART.SET_VENDOR_CART',
  RESET_CART: 'CART.RESET_CART',
  CLEAR_CART: 'CART.CLEAR_CART',
  UPDATE_CART_ITEMS: 'CART.UPDATE_CART_ITEMS',
  ADD_ITEM_CART: 'CART.ADD_ITEM_CART',
  REMOVE_ITEM_CART: 'CART.REMOVE_ITEM_CART',
  SET_CART_SPLIT: 'CART.SET_CART_SPLIT',
  SET_CUTLERY_CART: 'CART.SET_CUTLERY_CART',
  SET_COMMENT_CART: 'CART.SET_COMMENT_CART',
  SET_COUPON_CART: 'CART.SET_COUPON_CART',
  SET_PRICE_CART: 'CART.SET_PRICE_CART',
  SET_DELIVERY_INFO_CART: 'CART.SET_DELIVERY_INFO_CART',
  SET_PAYMENT_INFO_CART: 'CART.SET_PAYMENT_INFO_CART',
  SET_DEFAULT_DELIVERY_ADDRESS: 'CART.SET_DEFAULT_DELIVERY_ADDRESS',

  // TMP
  SET_FOOD: 'TMP.SET_FOOD',

  // chat
  APP_SET_CHANNELS : 'APP_SET_CHANNELS',
  APP_SET_MESSAGES_BY_CHANNEL : 'APP_SET_MESSAGES_BY_CHANNEL',
  APP_SET_NEW_INVITES : 'APP_SET_NEW_INVITES',
  APP_SET_CHANNELS_LOADING : 'APP_SET_CHANNELS_LOADING',
  APP_INIT_CHANNEL_SUCCESS : 'APP_INIT_CHANNEL_SUCCESS',
  APP_SET_CALL_CHANNELS : 'APP_SET_CALL_CHANNELS',
  APP_INIT_CHAT_ROOM_ERROR : 'APP_INIT_CHAT_ROOM_ERROR',
  APP_INIT_CHAT_ROOM_STARTED : 'APP_INIT_CHAT_ROOM_STARTED',
  APP_SET_NEW_MESSAGE_NUM : 'APP_SET_NEW_MESSAGE_NUM',
  APP_USER_LOGGED_OUT: 'APP_USER_LOGGED_OUT',
  APP_ENTER_CHAT : 'APP_ENTER_CHAT'

};

export default ACTION_TYPES;
