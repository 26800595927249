import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import Seo from '../../components/Seo';
import { Config } from '../../constants';
import ShopInfoView from '../../components/VendorDetails/ShopInfoView';
import { setVendorCart, resetCart } from '../../store/actions/shop';
import { setTmpFood } from '../../store/actions/app';
import VendorService from '../../services/apiVendor';
import VendorFoodList from '../../components/VendorDetails/VedorFoodList';
import VendorInfo from '../../components/VendorDetails/VendorInfo';
import CartModal from '../../components/Cart/CartModal';
import { CommonTabs } from '../../components/TabSelector';
import HScrollMenu from '../../components/VendorDetails/HScrollMenu';
import { setAppHeaderClass } from '../../store/actions/app';
import ActionMenu from '../../components/VendorDetails/ActionMenu';
import { Grid } from '@mui/material';
import LoadingSpinner from '../../components/Spinner';
import { VendorClosedModal } from '../../components/Modals';
import { getClosedVendorModalTitle } from '../../utils/helper';
import { decodeHash } from '../../utils/common';
import DeliveryType from '../../components/VendorDetails/DeliveryType';
import CartViewBtn from '../../components/VendorDetails/CartViewBtn';
import { OrderType_Delivery } from '../../constants/config';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

const HEADER_HEIGHT = 80;
const VendorDetails = (props) => {
  let { vendor_hash, order_method } = useParams();
  order_method = order_method || OrderType_Delivery;
  const vendor_id = decodeHash(vendor_hash);

  const navigate = useNavigate();

  const _mounted = useRef(true);
  const categoriesRef = useRef(null);

  const [horizontalScrollMenuIndex, setHorizontalScrollMenuIndex] = useState(0);
  const isPrevStickyApplied = useRef(false);
  const [isStickyApplied, setStickyApplied] = useState(false);

  const [isLoading, showLoading] = useState(false);
  const [isClosedVendorModal, showClosedVendorModal] = useState(false);
  const [isCartModal, showCartModal] = useState(false);
  const [past_orders, setPastOrders] = useState([]);
  const [order_methods, setOrderMethods] = useState([]);
  const [handover_method, setHandoverMethod] = useState(
    props.home_vendor_filter.order_type || OrderType_Delivery
  );

  const has_own_cartitems = useMemo(() => {
    let vendor_cart_items = props.cartItems.filter(
      (i) => i.vendor_id == vendor_id && i.quantity > 0
    );
    return vendor_cart_items.length > 0;
  }, [props.cartItems, vendor_id]);

  const closedVendorTitle = useMemo(
    () => getClosedVendorModalTitle(props.vendorData, props.language),
    [props.vendorData, props.language]
  );

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      let offsetTop = 700;

      if (currentScrollY >= offsetTop - HEADER_HEIGHT && isPrevStickyApplied.current === false) {
        setStickyApplied(true);
        isPrevStickyApplied.current = true;
      }
      if (currentScrollY < offsetTop - HEADER_HEIGHT && isPrevStickyApplied.current === true) {
        setStickyApplied(false);
        isPrevStickyApplied.current = false;
      }

      if (categoriesRef.current != null && categoriesRef.current.length > 0) {
        let found_index = 0;
        categoriesRef.current.map((cat, index) => {
          if (document.getElementById(`vendor-food-category-${index}`) != null) {
            let category_offset = document.getElementById(
              `vendor-food-category-${index}`
            ).offsetTop;

            if (currentScrollY > category_offset - 150) {
              found_index = index;
            }
          }
        });
        setHorizontalScrollMenuIndex(found_index);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    props.setAppHeaderClass('app-sticky-header');
    // setHorizontalScrollMenuIndex(0);

    loadPastOrders(vendor_id);
    if (
      props.vendorData == null ||
      props.vendorData.id == null ||
      props.vendorData.id != vendor_id ||
      props.vendorData.categories == null
    ) {
      loadData();
    } else {
      if (order_method != null) {
        let supported_order_methods = order_method.split('-');
        if (supported_order_methods.length > 0) {
          setOrderMethods(supported_order_methods);
          setHandoverMethod(props.home_vendor_filter.order_type || supported_order_methods[0]);
        }
      }
    }
    return () => {
      _mounted.current = false;
      props.setAppHeaderClass('');
    };
  }, [vendor_id]);

  const loadData = () => {
    _mounted.current = true;

    if (order_method != null) {
      let supported_order_methods = order_method.split('-');
      if (supported_order_methods.length > 0) {
        setOrderMethods(supported_order_methods);
        setHandoverMethod(props.home_vendor_filter.order_type || supported_order_methods[0]);
        loadVendorDetails(vendor_id, supported_order_methods[0]);
      }
    }
  };

  const loadPastOrders = (vendor_id) => {
    // if (props.isLoggedIn !== true) {
    //   return;
    // }
    VendorService.getPastOrders(vendor_id)
      .then(({ data }) => {
        setPastOrders(data.orders.data || []);
      })
      .catch(() => {});
  };

  const loadVendorDetails = async (id, order_method) => {
    if (id == null) {
      return;
    }
    let { latitude, longitude } = props.coordinates;

    showLoading(true);

    VendorService.getVendorDetail(id, latitude, longitude, order_method)
      .then(({ data }) => {
        data = data.vendor;
        categoriesRef.current = data.categories;
        props.setVendorCart(data);
        showLoading(false);

        setTimeout(() => {
          showClosedVendorModal(data.is_open !== 1);
        }, 200);
        identifyDevice();
        trackEvent('Vendor Details (Web)', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'screen',
          source: 'sf_web'
        });
      })
      .catch(() => {
        showLoading(false);
        identifyDevice();
        trackEvent('Vendor Details (Web)', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'screen',
          source: 'sf_web'
        });
      });
  };

  const renderCategoriesMenu = () => {
    return (
      <div
        id={'vendor-categ-list-view'}
        className={[
          'align-col-middle categ-list bg-white ph4',
          isStickyApplied ? 'sticky-header' : ''
        ].join(' ')}>
        <HScrollMenu
          selectedItem={horizontalScrollMenuIndex}
          onItemSelected={(index) => {
            let category_offset = document.getElementById(
              `vendor-food-category-${index}`
            ).offsetTop;
            window.scrollTo(0, category_offset > 60 ? category_offset - 60 : 0);
          }}
          items={props.vendorData.categories || []}
        />
      </div>
    );
  };

  return (
    <div data-testid="view-vendor" className={'view-vendor'}>
      <Seo title={`${props.vendorData.title} Store | SnapFood- Savor and Socialize`} />
      <div
        className={'vendor-bg overlay-dark ph4'}
        style={{
          backgroundImage: `url('${Config.IMG_BASE_URL}${props.vendorData.profile_path}?w=600&h=600')`
        }}>
        <div className={'flex_between w100 flex_wrap'}>
          <ShopInfoView
            data={props.vendorData}
            vendor_id={props.vendorData.id}
            is_open={props.vendorData.is_open}
            handover_method={handover_method}
          />
          <ActionMenu
            showPastOrderLink={past_orders.length > 0}
            onPressCart={() => showCartModal(true)}
          />
        </div>
      </div>
      <div className={'mt3'} />
      <Grid container spacing={{ xs: 2, md: 5 }} className={'align-middle'}>
        <Grid item xs={12} md={12} lg={8} style={{}}>
          <VendorInfo data={props.vendorData} />
          <div className={'w100 ph4'} style={{ display: 'flex' }}>
            {handover_method == OrderType_Delivery && (
              <DeliveryType
                type={props.vendorData.delivery_type}
                vendor_type={props.vendorData.vendor_type}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={4} style={{}}>
          {order_methods.length > 1 && (
            <CommonTabs
              items={order_methods}
              item={handover_method}
              className={'order-methods-tabs mh4'}
              onChange={(type) => {
                setHandoverMethod(type);
              }}
            />
          )}
        </Grid>
      </Grid>
      {isLoading === true ? (
        <div className={'flex_1'}>
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <div className={'w100 mt3'}>
            {renderCategoriesMenu()}
            <VendorFoodList />
          </div>
          <CartModal
            handover_method={handover_method}
            isOpen={isCartModal}
            onClose={() => showCartModal(false)}
          />
        </div>
      )}
      <div style={{ height: 40 }} />
      {has_own_cartitems && (
        <div className={'align-col-middle w100 cartbtn-view'}>
          <CartViewBtn
            onClick={() => {
              showCartModal(true);
            }}
            cartItems={props.cartItems}
          />
        </div>
      )}
      <VendorClosedModal
        isOpen={isClosedVendorModal}
        description={closedVendorTitle}
        explore={() => {
          showClosedVendorModal(false);
          navigate(-1);
        }}
        seeMenu={() => {
          showClosedVendorModal(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  language: app.language,
  coordinates: app.coordinates,
  tmpFoodData: app.tmpFoodData,
  cartItems: shop.items,
  vendorData: shop.vendorData,
  isLoggedIn: app.isLoggedIn,
  home_vendor_filter: app.home_vendor_filter
});

VendorDetails.propTypes = {
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  language: PropTypes.string,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    isFav: PropTypes.number,
    profile_path: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    is_open: PropTypes.number,
    order_method: PropTypes.string,
    categories: PropTypes.array,
    hash_id: PropTypes.string,
    slug: PropTypes.string,
    delivery_type: PropTypes.string,
    vendor_type: PropTypes.string
  }),
  home_vendor_filter: PropTypes.shape({
    order_type: PropTypes.string
  }),
  tmpFoodData: PropTypes.shape({
    isFav: PropTypes.number
  }),
  setAppHeaderClass: PropTypes.func,
  setTmpFood: PropTypes.func,
  setVendorCart: PropTypes.func,
  resetCart: PropTypes.func
};

export default connect(mapStateToProps, {
  setAppHeaderClass,
  setVendorCart,
  setTmpFood,
  resetCart
})(VendorDetails);
