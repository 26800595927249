import React from 'react';
import { useTranslation } from 'react-i18next';
import Svg_boss from '../../assets/svgs/courier/boss.svg';
import Svg_quickmoney from '../../assets/svgs/courier/quickmoney.svg';
import Svg_track from '../../assets/svgs/courier/track.svg';
import './Features.css';
import { Grid } from '@mui/material';

const Features = () => {
  const { t } = useTranslation();
  return (
    <div className="features pv10" >
      <h3>{t('courier.features_title')}</h3>
      <Grid container spacing={{ xs: 6, md: 4 }} className={'align-middle'} >
        <Grid item xs={12} md={4} style={{}}>
          <div className={'features-item'}>
            <img src={Svg_boss} />
            <h5>{t('courier.be_own_boss_title')}</h5>
            <p className={'ph3'}>{t('courier.be_own_boss_desc')}</p>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{}}>
          <div className={'features-item'}>
            <img src={Svg_quickmoney} />
            <h5>{t('courier.quick_money_title')}</h5>
            <p className={'ph3'}>{t('courier.quick_money_desc')}</p>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{}}>
          <div className={'features-item'}>
            <img src={Svg_track} />
            <h5>{t('courier.track_earning_title')}</h5>
            <p className={'ph3'}>{t('courier.track_earning_desc')}</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(Features, arePropsEqual);
