import React from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import './index.css';
import '../index.css'
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';

const EmojiPickerModal = ({ isOpen, onClose, onPickEmoji }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onEmojiClick = (event, emojiObject) => {
    onPickEmoji(emojiObject);
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal emoji-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <Picker
          onEmojiClick={onEmojiClick}
          disableAutoFocus={true}
          skinTone={SKIN_TONE_MEDIUM_DARK}
          groupNames={{ smileys_people: 'PEOPLE' }}
          native
        />
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    backgroundColor: Theme.colors.white
  }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}
EmojiPickerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onPickEmoji: PropTypes.func
};
export default React.memo(EmojiPickerModal, arePropsEqual);
