import React, { useState } from 'react';
import Seo from '../../../components/Seo';
import './index.css';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changePassword, updateProfileDetails } from '../../../store/actions/auth';
import { setTmpPassChanged, setAppLang } from '../../../store/actions/app';
import RadioBtn from '../../../components/Buttons/RadioBtn';
import ChangePasswordModal from '../../../components/Modals/ChangePasswordModal';
import ToggleBtn from '../../../components/Buttons/ToggleBtn';

const NotiSetting = ({ item, value, onChange }) => {
  return (
    <div className={'flex_between w100'} style={{ paddingTop: 20 }}>
      <div className={'subject-title'}>{item}</div>
      <ToggleBtn onClick={onChange} isOn={value} />
    </div>
  );
};
NotiSetting.propTypes = {
  value: PropTypes.bool,
  item: PropTypes.string,
  onChange: PropTypes.func
};

const Settings = (props) => {
  const { t } = useTranslation();

  const [isPassModal, showPassModal] = useState(false);

  const changeAppLang = async (lang) => {
    await props.setAppLang(lang);
  };

  const onUpdateNoti = (data) => {
    props
      .updateProfileDetails(data)
      .then((res) => {
        console.log('onUpdateNoti ', res);
      })
      .catch((e) => {
        console.log('onUpdateNoti ', e);
      });
  };

  const _renderLanguageSetting = () => {
    return (
      <div className={'align-col-start lang-setting'}>
        <div className={'flex_between'}>
          <div className={'subject-title'}>{t('account.lang_label')}</div>
        </div>
        <div
          onClick={() => changeAppLang('sq')}
          className={'flex_between w100 mt2'}
          style={{ marginBottom: 16 }}>
          <div className={'lang-text'}>{t('account.albanian')}</div>
          <RadioBtn onClick={() => changeAppLang('sq')} checked={props.language == 'sq'} />
        </div>
        <div className={'divider'} />
        <div onClick={() => changeAppLang('en')} className={'flex_between w100 mt2'}>
          <div className={'lang-text'}>{t('account.english')}</div>
          <RadioBtn onClick={() => changeAppLang('en')} checked={props.language == 'en'} />
        </div>
      </div>
    );
  };

  return (
    <div data-testid="view-settings" className={'settings-screen'}>
      <Seo title={t('web_title.settings')} />
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        className={'align-middle'}
        style={{ width: '100%' }}>
        <Grid item xs={12} md={6} lg={4} style={{}}>
          <h3>{t('account.settings')}</h3>
          <div className={'align-col-middle section-view ph2'}>
            <NotiSetting
              item={t('account.push_noti')}
              value={props.user.notifications === 1}
              onChange={() => onUpdateNoti({ push_notis: props.user.notifications === 1 ? 0 : 1 })}
            />
            <NotiSetting
              item={t('account.promo_noti')}
              value={props.user.promotions === 1}
              onChange={() => onUpdateNoti({ promo_notis: props.user.promotions === 1 ? 0 : 1 })}
            />
            <NotiSetting
              item={t('account.blog')}
              value={props.user.blog_notifications === 1}
              onChange={() =>
                onUpdateNoti({ blog_notifications: props.user.blog_notifications === 1 ? 0 : 1 })
              }
            />
          </div>
          <div className={'align-col-middle section-view'}>{_renderLanguageSetting()}</div>
          <div onClick={() => showPassModal(true)} className={'flex_between w100 item-btn'}>
            <span>{t('account_change_pass.header_title')}</span>
            {props.pass_changed && (
              <span className={'noti-text'}>{t('account_change_pass.success')}</span>
            )}
          </div>
          <div style={{ height: 100 }} />
        </Grid>
      </Grid>
      <ChangePasswordModal isOpen={isPassModal} onClose={() => showPassModal(false)} />
    </div>
  );
};

Settings.propTypes = {
  pass_changed: PropTypes.bool,
  user: PropTypes.shape({
    notifications: PropTypes.number,
    promotions: PropTypes.number,
    email_notis: PropTypes.number,
    blog_notifications: PropTypes.number
  }),
  language: PropTypes.string,
  setAppLang: PropTypes.func,
  updateProfileDetails: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  pass_changed: app.pass_changed
});

export default connect(mapStateToProps, {
  changePassword,
  setTmpPassChanged,
  setAppLang,
  updateProfileDetails
})(Settings);
