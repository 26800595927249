import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';
import PropTypes from 'prop-types';

const PromotionView = ({ promoFreeObj }) => {
  const { t } = useTranslation();
  if (promoFreeObj == null) {
    return null;
  }
  if (promoFreeObj.type == 'item' && parseInt(promoFreeObj.value) > 0) {
    return (
      <div className={'promotion-view ph2 pv2 mt2'}>
        <div className={'title fs6'}>{t('cart.checkout_use_promotion')}</div>
        <div className={'desc fs4 mt1'}>
          {t('cart.promo_free_item').replace('###', parseInt(promoFreeObj.value))}
        </div>
      </div>
    );
  } else if (promoFreeObj.type == 'free_delivery') {
    return (
      <div className={' promotion-view  ph2 pv2 mt2'}>
        <div className={'title fs6'}>{t('cart.checkout_use_promotion')}</div>
        <div className={'desc fs4 mt1'}>{t('cart.promo_free_delivery')}</div>
      </div>
    );
  }
  return null;
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.promoFreeObj != null &&
    nextProps.promoFreeObj != null &&
    prevProps.promoFreeObj.type === nextProps.promoFreeObj.type &&
    prevProps.promoFreeObj.value === nextProps.promoFreeObj.value
  );
}

PromotionView.propTypes = {
  promoFreeObj: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.number
  })
};

export default React.memo(PromotionView, arePropsEqual);
