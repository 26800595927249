import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { updateProfileDetails } from '../../../store/actions/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChatTextFill } from '@styled-icons/bootstrap';
import PropTypes from 'prop-types';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import SnapfooderAvatar from '../../Chats/SnapfooderAvatar';
import SuggestedUserItem from '../../Chats/SuggestedUser';
import { MainBtn } from '../../Buttons';
import { ApiFactory } from '../../../services';
import { confirmAlert } from 'react-confirm-alert';
import './index.css';
import '../index.css';
import { createSingleChannel, findSingleChannel } from '../../../services/chat';
import { Theme } from '../../../assets';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const SnapfooderModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    loading: null,
    user: {}
  });
  const [isFriend, setIsFriend] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  React.useEffect(() => {
    setOpen(props.isOpen);
    setIsFriend(null);
    if (props.isOpen) {
      if (props.snapfooder_id != null) {
        getSnapfoodDetail(props.snapfooder_id, true);
        checkFriend(props.snapfooder_id);
      }
    }
  }, [props.isOpen]);

  const getSnapfoodDetail = (user_id, initLoading = false) => {
    if (initLoading == true) {
      setState({ ...state, loading: true });
    }
    ApiFactory.get(`users/snapfooders/${user_id}`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load snapfooder', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const res_snapfooder = data['snapfooder'];
        setState({
          ...state,
          user: res_snapfooder,
          loading: false
        });
      },
      () => {
        setState({ ...state, loading: false });
      }
    );
  };

  const checkFriend = (user_id) => {
    ApiFactory.post(`users/friends/check`, {
      user_id: props.user.id,
      friend_id: user_id
    }).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('FriendCheck', {
          action_category: 'Check Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setIsFriend(data.success == true);
      },
      () => {
        identifyDevice();
        trackEvent('FriendCheck', {
          action_category: 'Check Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setIsFriend(false);
      }
    );
  };

  const onSendInvitation = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/update`, {
      user_id: props.user.id,
      friend_id: state.user.id,
      status: 'invited'
    }).then(
      () => {
        identifyDevice();
        trackEvent('FriendUpdate', {
          action_category: 'Update Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        getSnapfoodDetail(state.user.id);
        setBtnLoading(false);
      },
      (error) => {
        identifyDevice();
        trackEvent('FriendUpdate', {
          action_category: 'Update Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        setBtnLoading(false);
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: state.user.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        getSnapfoodDetail(state.user.id);
        setBtnLoading(false);
      },
      (error) => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        setBtnLoading(false);
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onRemoveFriend = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: state.user.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        checkFriend(state.user.id);
        setBtnLoading(false);
      },
      (error) => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        setBtnLoading(false);
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onEnterChannel = async () => {
    if (state.user == null) {
      return;
    }
    let found_channel = await findSingleChannel(props.user.id, state.user.id);
    if (found_channel != null) {
      navigate(`/social/chat/${found_channel.id}`);
    } else {
      setState({ ...state, isCreatingChannel: true });
      let channelID = await createSingleChannel(props.user, state.user);
      setState({ ...state, isCreatingChannel: false });
      if (channelID != null) {
        navigate(`/social/chat/${channelID}`);
      } else {
        console.error(t('alerts.error'), t('checkout.something_is_wrong'));
      }
    }
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal snapfooder-profile-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          {/*<h1 className={'title'}>{t('account_details.edit_profile')}</h1>*/}
        </div>
        <div className={'scroll_view'}>
          <SnapfooderAvatar
            full_name={state.user.username || state.user.full_name}
            photo={state.user.photo}
            birthdate={state.user.birthdate}
            country={state.user.country}
            photoViewStyle={{ width: 100, height: 100, borderRadius: 15 }}
            avatarImgStyle={{ width: 100, height: 100 }}
          />
          {isFriend != null && (
            <div
              style={{
                width: '100%',
                paddingHorizontal: 20,
                marginTop: 20,
                paddingTop: 20,
                marginBottom: 40
              }}>
              {isFriend ? (
                <>
                  <MainBtn
                    loading={btnLoading}
                    className={'cyan2_bg mt2'}
                    title={
                      <div className={'align-middle'}>
                        <ChatTextFill
                          size={22}
                          color={Theme.colors.white}
                          style={{ marginRight: 8 }}
                        />
                        <span
                          style={{ fontSize: 18, color: '#fff', fontFamily: Theme.fonts.semiBold }}>
                          {t('bottom-chat')}
                        </span>
                      </div>
                    }
                    onClick={onEnterChannel}
                  />
                  <div className={'remove-friend mt2'} onClick={onRemoveFriend}>
                    {t('friend_related.remove_friend')}
                  </div>
                </>
              ) : (
                <MainBtn
                  loading={btnLoading}
                  className={state.user.invite_status == 'invited' ? 'gray7_bg' : 'cyan2_bg'}
                  title={
                    state.user.invite_status == 'invited'
                      ? t('friend_related.cancel_invitation')
                      : t('friend_related.add_friend')
                  }
                  onClick={
                    state.user.invite_status == 'invited' ? onCancelInvitation : onSendInvitation
                  }
                />
              )}
            </div>
          )}
          {state.user.suggested_users != null && state.user.suggested_users.length > 0 && (
            <div className={'align-col-start suggested-hlist'}>
              <div className={'divider'} />
              <div className={'subject-title'}>{t('chat.suggested_users')}</div>
              <div className={'suggested-list'}>
                {state.user.suggested_users.slice(0, 10).map((item) => (
                  <SuggestedUserItem
                    key={item.id}
                    id={item.id}
                    full_name={item.username || item.full_name}
                    photo={item.photo}
                    onViewProfile={() => {
                      getSnapfoodDetail(item.id);
                      checkFriend(item.id);
                    }}
                  />
                ))}
              </div>
              {/*<div className={'scrollview_hider'} />*/}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

SnapfooderModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
    photo: PropTypes.string,
    birthdate: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    username: PropTypes.string,
    sex: PropTypes.string
  }),
  snapfooder_id: PropTypes.number,
  updateProfileDetails: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {
  updateProfileDetails
})(SnapfooderModal);
