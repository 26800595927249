import { useState, useEffect, useRef } from 'react';

export default function useLongPress(callback = () => {}, ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false);
  const _event = useRef(null)
  const updateStartLongPress=(flag, e)=>{
    _event.current = e;
    setStartLongPress(flag)
  }

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(()=>callback(_event.current), ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: (e) => updateStartLongPress(true, e),
    onMouseUp: (e) => updateStartLongPress(false, e),
    onMouseLeave: (e) => updateStartLongPress(false, e),
    onTouchStart: (e) => updateStartLongPress(true, e),
    onTouchEnd: (e) => updateStartLongPress(false, e)
  };
}
