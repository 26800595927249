/* eslint no-use-before-define: ["error", { "variables": false }] */

import PropTypes from 'prop-types';
import React from 'react';
import { Close } from '@styled-icons/evaicons-solid';
import Composer from './Composer';
import Send from './Send';
import Color from './Color';
import { IconButton } from '@mui/material';
import { Theme } from '../../../assets';
import Svg_removeimg from '../../../assets/svgs/msg/remove_image.svg';
import Hscroller from '../../Common/Hscroller';
import SupportMsgItem from '../SupportMsgItem';

export default class InputToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: 'absolute'
    };
  }

  renderActions() {
    if (this.props.renderActions) {
      return this.props.renderActions(this.props);
    }
    return null;
  }

  renderSend() {
    if (this.props.renderSend) {
      return this.props.renderSend(this.props);
    }
    return <Send {...this.props} />;
  }

  renderComposer() {
    if (this.props.renderComposer) {
      return this.props.renderComposer(this.props);
    }

    return <Composer {...this.props} />;
  }

  renderAccessory() {
    return null;
  }

  render() {
    return (
      <div
        style={{
          ...styles.container,
          ...this.props.containerStyle,
          ...{ minHeight: this.props.composerHeight }
        }}>
        {this.props.quote_msg != null && (
          <div className={'align-middle'} style={styles.quote_view}>
            <div className={'align-col-middle'} style={{ flex: 1, alignItems: 'flex-start' }}>
              <div style={styles.quote_name}>
                {this.props.quote_msg.user.username || this.props.quote_msg.user.full_name}
              </div>
              <div className={'numline-1'} style={styles.quote_text}>
                {this.props.quote_msg.text}
              </div>
            </div>
            <IconButton
              style={{ padding: 0, marginLeft: 8 }}
              onClick={this.props.onCancelQuote ? this.props.onCancelQuote : () => {}}>
              <Close size={20} color={Theme.colors.text} />
            </IconButton>
          </div>
        )}
        {this.props.images != null && (
          <div className={'align-middle'} style={{ alignItems: 'flex-start', marginBottom: 6 }}>
            {
              <Hscroller>
                {this.props.images.map((img, index) => (
                  <div
                    key={index}
                    style={{ width: 73, height: 73, marginRight: 12, position: 'relative' }}>
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 8,
                        objectFit: 'cover'
                      }}
                      src={img}
                    />
                    <IconButton
                      style={{ padding: 0, position: 'absolute', top: 8, right: 8 }}
                      onClick={
                        this.props.onRemoveImage ? () => this.props.onRemoveImage(index) : () => {}
                      }>
                      <img src={Svg_removeimg} />
                    </IconButton>
                  </div>
                ))}
              </Hscroller>
            }
          </div>
        )}
        {this.props.quote_msg == null &&
          this.props.images == null &&
          this.props.default_msgs != null &&
          this.props.default_msgs.length > 0 && (
            <div className={'align-middle order-chat-default-messages'} style={styles.chatFooter}>
              <Hscroller>
                {this.props.default_msgs.map((msg, index) => (
                  <SupportMsgItem
                    key={index}
                    msg={msg}
                    onClick={() => {
                      this.props.onPressDefaultMsg(msg);
                    }}
                  />
                ))}
              </Hscroller>
            </div>
          )}
        <div style={{ ...styles.primary, ...this.props.primaryStyle }}>
          {this.renderActions()}
          {this.renderComposer()}
          {this.renderSend()}
        </div>
        {this.renderAccessory()}
      </div>
    );
  }
}

const styles = {
  container: {
    borderTopWidth: 1,
    borderTopColor: Color.defaultColor,
    backgroundColor: Color.white,
    bottom: 0,
    left: 0,
    right: 0
  },
  primary: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  accessory: {
    height: 44
  },
  quote_name: { fontSize: 14, fontFamily: Theme.fonts.bold, color: Theme.colors.cyan2 },
  quote_text: {
    marginTop: 11,
    fontSize: 16,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text
  },
  quote_view: {
    alignItems: 'flex-start',
    marginBottom: 6,
    paddingLeft: 10,
    borderLeftWidth: 2,
    borderLeftColor: Theme.colors.cyan2
  },
  chatFooter: { width: '100%', height: 33, marginBottom: 12 }
};

InputToolbar.defaultProps = {
  renderAccessory: null,
  renderActions: null,
  renderSend: null,
  renderComposer: null,
  containerStyle: {},
  primaryStyle: {},
  accessoryStyle: {},
  onPressActionButton: () => {}
};

InputToolbar.propTypes = {
  composerHeight: PropTypes.number,
  renderAccessory: PropTypes.func,
  renderActions: PropTypes.func,
  renderSend: PropTypes.func,
  renderComposer: PropTypes.func,
  onPressActionButton: PropTypes.func,
  onCancelQuote: PropTypes.func,
  containerStyle: PropTypes.object,
  primaryStyle: PropTypes.object,
  accessoryStyle: PropTypes.object,
  quote_msg: PropTypes.shape({
    text: PropTypes.string,
    user: PropTypes.shape({
      username: PropTypes.string,
      full_name: PropTypes.string
    })
  }),
  images: PropTypes.array,
  default_msgs: PropTypes.array,
  onRemoveImage: PropTypes.func,
  onPressDefaultMsg: PropTypes.func
};
