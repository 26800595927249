import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@styled-icons/material';
import { Theme } from '../../../assets';
import './index.css';
import ReactHtmlParser from 'react-html-parser';

const Faq = ({ className, question, answer }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = () => {
    setExpanded(!expanded);

    // setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      className={'faq-item ' + className}
      expanded={expanded}
      onChange={handleChange}>
      <AccordionSummary
        className={'question'}
        expandIcon={<ExpandMore size={24} color={Theme.colors.text} />}>
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails className={'answer'}>
        <Typography>{ReactHtmlParser(answer)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return !(
    // prevProps.id !== nextProps.id ||
    prevProps.question !== nextProps.question ||
    // prevProps.isExpanded !== nextProps.isExpanded ||
    prevProps.answer !== nextProps.answer
  );
}

Faq.propTypes = {
  // id: PropTypes.number,
  className: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
  // isExpanded: PropTypes.bool,
 // handleChange: PropTypes.func
};
export default React.memo(Faq, arePropsEqual);
