import React, { useState } from 'react';
import './index.css';
import email from '../../assets/svgs/ourapp/mail.svg';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const NewsletterSignup = ({ title, subtitle, inputPlaceholder, buttonText, checkboxText }) => {
  const [emailValue, setEmailValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    emailValue.length > 0 && isChecked && navigate('/login?view=register');
  };

  return (
    <div className="newsletter-main-layer">
      <div className="newsletter-container">
        <div className="mail-icon">
          <img src={email} alt="mail" />
        </div>
        <h1>{title}</h1>
        <p className="newsletter-subtitle">{subtitle}</p>
        <div className="form-section">
          <div className="newsletter-content">
            <input
              type="email"
              placeholder={inputPlaceholder}
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
            />
            <button onClick={handleClick}>{buttonText}</button>
          </div>
          <div className="newsletter-checkbox">
            <input
              type="checkbox"
              className='custom-checkbox'
              id="subscribe"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <label htmlFor="subscribe">{checkboxText}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSignup;
