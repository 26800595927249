import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MainBtn } from '../Buttons';
import './index.css';

const VendorClosedModal = ({ isOpen, description, explore, seeMenu }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open} className={'align-col-middle w100 modal vendor-closed-modal'}>
      <div className={'align-col-middle content'}>
        <h3>{t('modals.closed')}</h3>
        <p>{description}</p>
        <div className={'flex_1 align-middle w100 actions flex_wrap'}>
          <MainBtn
            title={t('modals.explore_other_vendor')}
            onClick={explore}
            className={'explore'}
          />
          <div style={{ width: 20 }} />
          <MainBtn title={t('modals.see_menu')} onClick={seeMenu} className={'see_menu'} />
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen && prevProps.description === nextProps.description;
}

VendorClosedModal.propTypes = {
  isOpen: PropTypes.bool,
  description: PropTypes.string,
  explore: PropTypes.func,
  seeMenu: PropTypes.func
};
export default React.memo(VendorClosedModal, arePropsEqual);
