import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { StarFill } from '@styled-icons/bootstrap';
import { AccessTime } from '@styled-icons/material-outlined';
import { Heart } from '@styled-icons/foundation';
import Svg_euro from '../../../assets/svgs/vendor/ic_euro.svg';
import './index.css';
import { Theme } from '../../../assets';
import { MapMarker } from '@styled-icons/open-iconic';

const LikedItem = ({ value }) => {
  const { t } = useTranslation();
  return (
    <h3 className={'liked-txt'}>
      {value}% {t('vendor_item.liked')}
    </h3>
  );
};

const RateItem = ({ value }) => {
  return (
    <div className={'align-middle rate-item'}>
      <StarFill size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
      <span>{value}</span>
    </div>
  );
};

const PriceItem = ({ value }) => {
  return (
    <div className={'align-middle price-item'}>
      <img src={Svg_euro} alt={''} />
      <span>{value} L</span>
    </div>
  );
};

const MinMaxTimeItem = ({ value }) => {
  const { t } = useTranslation();
  return (
    <div className={'align-middle price-item'}>
      <AccessTime size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
      <span>
        {value} {t('vendor_item.minutes')}
      </span>
    </div>
  );
};

const DistanceItem = ({ value }) => {
  return (
    <div className={'align-middle price-item'}>
      <MapMarker size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
      <span>{(parseFloat(value) / 1000).toFixed(2)} Km</span>
    </div>
  );
};

const FavBtn = ({ isFav, onChange }) => {
  return (
    <IconButton
      className={'fav-btn'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange(!isFav);
      }}>
      <Heart size={26} color={isFav === 1 ? Theme.colors.cyan2 : Theme.colors.gray7} />
    </IconButton>
  );
};

const VendorOpenIndicator = ({ isOpen }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`indicator`}
      style={{ color: isOpen ? Theme.colors.green1 : Theme.colors.red1 }}>
      <div
        style={{
          backgroundColor: isOpen ? Theme.colors.green1 : Theme.colors.red1
        }}
      />
      <span style={{ paddingLeft: 4 }}>
        {isOpen ? t('vendor_item.open_now') : t('vendor_item.closed')}
      </span>
    </div>
  );
};

const VendorTag = ({ text }) => {
  return <div className={'tag-text'}>{text}</div>;
};

const LogoImg = ({ url }) => {
  return (
    <div className={'vendor-logo align-middle'}>
      <img src={url} alt={''} />
    </div>
  );
};

LikedItem.propTypes = {
  value: PropTypes.number
};
RateItem.propTypes = {
  value: PropTypes.string
};
PriceItem.propTypes = {
  value: PropTypes.number
};
MinMaxTimeItem.propTypes = {
  value: PropTypes.number
};
DistanceItem.propTypes = {
  value: PropTypes.number
};
FavBtn.propTypes = {
  isFav: PropTypes.bool,
  onChange: PropTypes.func
};
VendorOpenIndicator.propTypes = {
  isOpen: PropTypes.bool
};
VendorTag.propTypes = {
  text: PropTypes.string
};
LogoImg.propTypes = {
  url: PropTypes.string
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.value === nextProps.value;
}

const MemorizedLikedItem = React.memo(LikedItem, arePropsEqual);
const MemorizedRateItem = React.memo(RateItem, arePropsEqual);
const MemorizedPriceItem = React.memo(PriceItem, arePropsEqual);
const MemorizedMinMaxTimeItem = React.memo(MinMaxTimeItem, arePropsEqual);
const MemorizedDistanceItem = React.memo(DistanceItem, arePropsEqual);
const MemorizedFavBtn = React.memo(FavBtn);
const MemorizedVendorOpenIndicator = React.memo(VendorOpenIndicator);
const MemorizedVendorTag = React.memo(VendorTag);
const MemorizedLogoImg = React.memo(LogoImg);

export {
  MemorizedLikedItem,
  MemorizedRateItem,
  MemorizedPriceItem,
  MemorizedMinMaxTimeItem,
  MemorizedDistanceItem,
  MemorizedVendorOpenIndicator,
  MemorizedVendorTag,
  MemorizedFavBtn,
  MemorizedLogoImg
};
