import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MainBtn } from '../../Buttons';
import { ROUTES_NAMES } from '../../../constants';
// import Svg_vendor from '../../../assets/svgs/home/vendor_card.svg';
// import Svg_rider from '../../../assets/svgs/home/rider_card.svg';
import './ApplyItem.css';

const ApplyItem = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={[
        'align-start',
        'home-business-card ph4 pv4',
        type === 'vendor' ? 'home-business-card-vendor' : (type === 'rider' ? 'home-business-card-rider' : 'home-business-card-snapfood')
      ].join(' ')}
    >
      <div className="card-body">
        <h3>
          {type === 'vendor' ? t('landing.wanna_grow_business') : (type === 'rider' ? t('landing.wanna_become_rider') : t('landing.join_snapfood'))}
        </h3>
        <p>{type === 'vendor' ? t('landing.apply_business') : (type === 'rider' ? t('landing.apply_rider'): t('landing.join_snapfood_desc'))}</p>
        <div style={{ width: 230 }}>
          <MainBtn
            title={t('landing.apply_now')}
            onClick={(e) => {
              e.preventDefault();
              if (type === 'vendor') {
                navigate(ROUTES_NAMES.becomePartner);
                window.scrollTo(0, 0);
              }
              else if (type === 'rider') {
                navigate(ROUTES_NAMES.becomeCourier);
                window.scrollTo(0, 0);
              }
              else {
                  window.location = "mailto:info@snapfood.al";
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

ApplyItem.propTypes = {
  type: PropTypes.string
};

export default React.memo(ApplyItem, arePropsEqual);
