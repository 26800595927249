import { API_AI, API_QUIZ, WEB_AI } from '../components/Messages/react-web-gifted-chat/Constant';
import apiFactory from './apiFactory';

export const quizService = () => {
  const blogCount = async (payload) => apiFactory.post(`${API_AI.path}/suggest-quiz`, payload);

  const faqCount = async (payload) => apiFactory.post(`${WEB_AI.path}/update-statistics`, payload);

  const createQuiz = async (payload) => apiFactory.post(API_QUIZ.path, payload);

  return { blogCount, faqCount, createQuiz };
};
