import axios from 'axios';

const fbService = ({ id, accessToken, payload }) => {
  axios.post(`https://graph.facebook.com/v19.0/${id}/events?access_token=${accessToken}`, payload);
};

async function hashValue(email) {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashedEmailHex = Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashedEmailHex;
}

export const handleFbService = async ({ email, event_name, name, external_id, country }) => {
  try {
    const hashedEmail = await hashValue(email);
    const hashedName = await hashValue(name);
    const hashedExternal = await hashValue(external_id);
    const hashedCountry = await hashValue(country);
    const event_time = Math.floor((Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000);

    const body = {
      id: '865519432256430',
      accessToken:
        'EAAVcLRz9LHYBO3AvmzZA2oJ3zaZBsf5uwLlrmXs9ZB3ZCUAaxHycPd3vs3rARsxuZAZB6ZC9jTF541j1hM77YI5pKZArGDijMebuJcckzfaPR1N6evGjewEDQ53mDrWEgRocU8ztI5GiT3G0HOmdmpH7zwmYdAMXAYxDaYpzsbaZB95GGa5bpn3rPYG0x4l30eM3PXAZDZD',
      payload: {
        data: [
          {
            event_name,
            event_time,
            action_source: 'website',
            user_data: {
              em: email ? hashedEmail : undefined,
              fn: name ? hashedName : undefined,
              external_id: external_id ? hashedExternal : undefined,
              country: country ? hashedCountry : undefined
            }
          }
        ]
      }
    };

    fbService(body);
  } catch (error) {
    console.error('Error:', error);
  }
};
