import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import './index.css';
import '../index.css';
import { setAppLang } from '../../../store/actions/app';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const LanguageModal = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const onSelect = (lang) => {
    props.setAppLang(lang);
    props.onClose();
  };

  return (
    <Dialog open={open} className={'align-col-middle  modal choose-lang-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative ph2'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h3 className={'title'}>{t('footer.choose_lang')}</h3>
        </div>
        <div className={'scroll_view ph2'}>
          <div className={'w100 '}>
            <List className={'filter-body'}>
              {['en', 'sq'].map((item, index) => (
                <ListItem
                  button
                  key={index}
                  className={'list-btn'}
                  onClick={(e) => {
                    e.preventDefault();
                    if (item != props.language){
                      onSelect(item);
                    }
                  }}>
                  <ListItemText primary={item == 'en' ? t('english') : t('albanian')} />
                  {
                    item != props.language &&
                    <div
                      onClick={() => {
                        onSelect(item);
                      }}
                      className={'choose-link'}
                    >
                      {t('choose')}
                    </div>
                  }
                </ListItem>
              ))}
            </List>
            <div style={{ height: 15 }} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};


LanguageModal.propTypes = {
  isOpen: PropTypes.bool,
  language: PropTypes.string,
  setAppLang: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  language: app.language
});

export default connect(mapStateToProps, { setAppLang })(LanguageModal);