import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { IconButton } from '@mui/material';
import { MinusCircle, PlusCircle, Trash } from '@styled-icons/boxicons-solid';
import { Theme } from '../../../assets';

const CartItem = ({ data, onPlus, onMinus, onDelete }) => {
  return (
    <div className={'flex_between checkout-cart-item mv1'}>
      <div>
        <h4>{data.title}</h4>
        <h3>{parseInt(data.price)} L</h3>
      </div>
      <div className={'align-middle right'}>
        <IconButton style={{ padding: 0 }} onClick={() => onDelete(data.id)}>
          <Trash size={20} color={Theme.colors.gray7} />
        </IconButton>
        <div className={'align-middle counter'}>
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              if (data.quantity > 1) {
                onMinus(data.id);
              } else {
                onDelete(data.id);
              }
            }}>
            <MinusCircle size={20} color={Theme.colors.text} />
          </IconButton>
          <div className={'qty'}>{data.quantity}</div>
          <IconButton style={{ padding: 0 }} onClick={() => onPlus(data.id)}>
            <PlusCircle size={20} color={Theme.colors.text} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

CartItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.number,
    quantity: PropTypes.number,
    title: PropTypes.string,
    comments: PropTypes.string
  }),
  style: PropTypes.object,
  onPlus: PropTypes.func,
  onMinus: PropTypes.func,
  onDelete: PropTypes.func
};
export default CartItem;
