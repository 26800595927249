import { ACTION_TYPES } from '../../constants';
import { OrderType_Delivery } from '../../constants/config';

const INITIAL_STATE = {
  items: [],
  vendorData: {},
  cutlery: 1,
  coupon: {},
  comments: '',
  order_for: null,
  confirm_legal_age: false,
  cartPrice: {
    order_total: 0,
    subtotal: 0,
    discount: 0,
    cashback: 0,
    small_order_fee: 0,
    delivery_fee: 0,
    min_order_price: 0,
    promo_code: null
  },
  delivery_info: {
    handover_method: OrderType_Delivery,
    address: {},
    contactless_delivery: false,
    comments: '',
    pickup_date: '',
    pickup_time: '',
    num_guests: 0,
    tip_rider: 0,
    reserve_for: {},
    is_schedule: 0,
    schedule_time: null
  },
  payment_info: {
    method: 'cash',
    cards: [],
    selected_card: null,
    comments: '',
    splits: []
  }
};

const shop = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_CART_ITEMS: {
      return {
        ...state,
        items: action.payload,
        payment_info: {
          ...state.payment_info,
          splits: []
        }
      };
    }
    case ACTION_TYPES.CLEAR_CART: {
      return {
        ...INITIAL_STATE,
        items: action.payload || [],
        payment_info: {
          ...state.payment_info,
          splits: []
        }
      };
    }
    case ACTION_TYPES.RESET_CART: {
      return {
        ...INITIAL_STATE,
        items: state.items,
        vendorData: state.vendorData || {},
        cutlery: state.cutlery,
        payment_info: {
          ...state.payment_info,
          splits: []
        }
      };
    }
    case ACTION_TYPES.SET_CUTLERY_CART: {
      return {
        ...state,
        cutlery: action.payload
      };
    }
    case ACTION_TYPES.SET_COMMENT_CART: {
      return {
        ...state,
        comments: action.payload
      };
    }
    case ACTION_TYPES.SET_COUPON_CART: {
      return {
        ...state,
        coupon: action.payload || {}
      };
    }
    case ACTION_TYPES.SET_PRICE_CART: {
      return {
        ...state,
        cartPrice: action.payload
      };
    }

    case ACTION_TYPES.SET_DELIVERY_INFO_CART: {
      return {
        ...state,
        delivery_info: {
          ...state.delivery_info,
          ...action.payload
        }
      };
    }
    case ACTION_TYPES.SET_PAYMENT_INFO_CART: {
      return {
        ...state,
        payment_info: action.payload
      };
    }

    case ACTION_TYPES.SET_VENDOR_CART: {
      return {
        ...state,
        vendorData: action.payload || {},
        payment_info: {
          ...state.payment_info,
          splits: []
        }
      };
    }

    case ACTION_TYPES.APP_SET_ORDER_FOR: {
      return {
        ...state,
        order_for: action.payload,
      };
    }

    case ACTION_TYPES.APP_SET_CONFIRM_LEGAL_AGE: {
      return {
        ...state,
        confirm_legal_age: action.payload || false,
      };
    }
    default:
      return { ...state };
  }
};

export default shop;
