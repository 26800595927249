import React, { useEffect, useState } from 'react';
import Seo from '../../../components/Seo';
import './index.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Svg_chat from '../../../assets/svgs/chat/chat.svg';
import Svg_friends from '../../../assets/svgs/chat/friends.svg';
import Svg_snpfood from '../../../assets/svgs/chat/snapfooders.svg';
import Svg_inv from '../../../assets/svgs/chat/invitations.svg';
import Svg_chat_active from '../../../assets/svgs/chat/chat_a.svg';
import Svg_friends_active from '../../../assets/svgs/chat/friends_a.svg';
import Svg_snpfood_active from '../../../assets/svgs/chat/snapfooders_a.svg';
import Svg_inv_active from '../../../assets/svgs/chat/invitations_a.svg';

// import { ApiFactory } from '../../../services';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Social = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const pages = ['social/chat', 'social/friends', 'social/snapfoodies', 'social/invitations'];
  const [value, setValue] = useState(0);

  useEffect(() => {
    let index = pages.findIndex((p) => pathname.includes(p));
    if (index != -1) {
      setValue(index);
    }
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue < pages.length) {
      navigate(`/${pages[newValue]}`);
    }
  };

  //
  // useEffect(() => {
  //   getNewInvites();
  // }, []);
  // const getNewInvites = () => {
  //   ApiFactory.get(`users/invitations?seen=0`).then(
  //     ({ data }) => {
  //       const res_invitations = data['invitations'];
  //       if (_isMounted.current === true) {
  //         setState({ ...state, new_invites: res_invitations });
  //       }
  //     },
  //     () => {}
  //   );
  // };

  return (
    <div data-testid='view-social' className={'view-social'}>
      <Seo title={t('web_title.social')} />
      <div className='w100 align-middle main'>
        <Tabs value={value} onChange={handleChange} className='tabs'>
          <Tab icon={<img src={value == 0 ? Svg_chat_active : Svg_chat} />}
               label={t('sidebar.chat')} {...a11yProps(0)} />
          <Tab icon={<img src={value == 1 ? Svg_friends_active : Svg_friends} />}
               label={t('social.friends')} {...a11yProps(1)} />
          <Tab icon={<img src={value == 2 ? Svg_snpfood_active : Svg_snpfood} />}
               label={t('social.snapfooders')} {...a11yProps(2)} />
          <Tab icon={<img src={value == 3 ? Svg_inv_active : Svg_inv} />}
               label={t('social.invitation')} {...a11yProps(3)} />
        </Tabs>
        <div className={'content'}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Social;
