import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MainBtn } from '../Buttons';
import Svg_failed from '../../assets/svgs/vendor/error.svg';
import './index.css';

const OrderFailedModal = ({ showModal, message, onTry, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState(message);

  React.useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    setMsg(message);
  }, [message]);

  return (
    <Dialog open={open} className={'align-col-middle w100 modal order-failed-modal'}>
      <div className={'align-col-middle content'}>
        <img src={Svg_failed} />
        <h2>{t('modals.order_failed')}</h2>
        <p>{msg}</p>
        <div className={'flex_1 align-col-middle w100 actions'}>
          <MainBtn title={t('modals.try_again')} onClick={onTry} className={'try-btn'} />
          <MainBtn title={t('modals.save_order_later')} onClick={onClose} className={'save-btn'} />
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal == nextProps.showModal || prevProps.message == nextProps.message;
}

OrderFailedModal.propTypes = {
  showModal: PropTypes.bool,
  message: PropTypes.string,
  onTry: PropTypes.func,
  onClose: PropTypes.func
};
export default React.memo(OrderFailedModal, arePropsEqual);
