import React from 'react';
import Theme from '../../assets/theme';
import PropTypes from 'prop-types';
import { Heart as HeartOutline } from '@styled-icons/ionicons-outline';
import { Heart as Heart } from '@styled-icons/ionicons-solid';

const LikeBtn = ({ onChange, checked, cnt, size, style, roundStyle }) => {
  return (
    <div className={'align-col-middle'} style={{paddingTop: 16, ...style}}>
      <div
        style={{ ...styles.btn, ...roundStyle}}
        onClick={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}>
        {
          checked == true ?
            <Heart size={size || 20} color={checked == true ? Theme.colors.cyan2 : Theme.colors.gray7}/>
            :
            <HeartOutline size={size || 20} color={checked == true ? Theme.colors.cyan2 : Theme.colors.gray7}/>
        }
      </div>
      <div style={styles.cntTxt}>{cnt}</div>
    </div>
  );
};

LikeBtn.propTypes = {
  checked: PropTypes.bool,
  cnt: PropTypes.number,
  size: PropTypes.number,
  roundStyle: PropTypes.object,
  style: PropTypes.object,
  onChange: PropTypes.func
};

const styles =  {
  btn: {
    backgroundColor: Theme.colors.white, padding: 2, borderRadius: 20,
    elevation: 2,
    shadowColor: Theme.colors.blackPrimary,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  cntTxt: { marginTop: 4, fontSize: 12, fontFamily: Theme.fonts.semiBold, color: Theme.colors.cyan2 },
}
export default LikeBtn;
