import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Container, Row, Col } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import { ArrowRightShort } from '@styled-icons/bootstrap';
import './index.css';
import CategItem from './CategItem';
import { Theme } from '../../../assets';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Config } from '../../../constants';

const PopularMenus = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goCategory = (category) => {
    let params = [];
    params.push(`category=${category.id}`);
    params.push(`latitude=${Config.TIRANA_CITY_LOCATION.latitude}`);
    params.push(`longitude=${Config.TIRANA_CITY_LOCATION.longitude}`);
    params.push(`formatted_address=${Config.TIRANA_CITY_LOCATION.street} ${Config.TIRANA_CITY_LOCATION.city}, ${Config.TIRANA_CITY_LOCATION.country}`);
    navigate(`/stores?${params.join('&')}`);
  };

  if (props.foodCategories.length === 0) {
    return null;
  }
  return (
    <div className={'align-col-middle home-foods-menu mt10'}>
      <h2 className={'mb5'}>{t('landing.menu_title')}</h2>
      <div className="align-middle w100 list">
        {props.foodCategories.slice(0, 9).map((menu, index) => (
          <CategItem key={index} category={menu} onSelect={() => goCategory(menu)} />
        ))}
        <div className={'menu-item-view mb3'} onClick={()=> {
          navigate(`/categories`);
        }}>
          <div className={'align-middle menu-item see_more_btn'}>
            <div>{t('landing.see_more')}</div>
            <ArrowRightShort
              size={28}
              color={Theme.colors.text}
              style={{ marginLeft: 4, paddingTop: 4 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PopularMenus.propTypes = {
  foodCategories: PropTypes.array
};

const mapStateToProps = ({ app }) => ({
  foodCategories: app.foodCategories
});

export default connect(mapStateToProps, {})(PopularMenus);
