import React from 'react';
import { useTranslation } from 'react-i18next';
import Svg_scooter from '../../assets/svgs/courier/scooter-lg.svg';
import Svg_phone from '../../assets/svgs/courier/smartphone.svg';
import Svg_id from '../../assets/svgs/courier/id.svg';
import './NeedStart.css';
import { Grid } from '@mui/material';

const NeedStart = () => {
  const { t } = useTranslation();
  return (
    <div className='features pv10'>
      <h3>{t('courier.features_title')}</h3>
      <Grid container spacing={{ xs: 6, md: 4 }} className={'align-middle'}>
        <Grid item xs={12} md={4} style={{}}>
          <div className={'features-item'}>
            <img src={Svg_scooter} />
            <h5>{t('courier.need_start_1')}</h5>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{}}>
          <div className={'features-item'}>
            <img src={Svg_phone} />
            <h5>{t('courier.need_start_2')}</h5>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{}}>
          <div className={'features-item'}>
            <img src={Svg_id} />
            <h5>{t('courier.need_start_3')}</h5>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(NeedStart, arePropsEqual);
