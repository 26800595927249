import React
// { useEffect, useState }
from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import PropTypes from 'prop-types';
import './FeaturesCarousel.css';
import FeatureItem from './FeatureItem';
import { useTranslation } from 'react-i18next';
import Svg_cashback from '../../../assets/svgs/home/cashback.svg';
import Svg_earninvite from '../../../assets/svgs/home/earninvite.svg';
import Svg_coupon from '../../../assets/svgs/home/coupon.svg';
import Svg_referral from '../../../assets/svgs/home/referral.svg';


const responsive = {
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  md: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  small: {
    breakpoint: { max: 350, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const FeaturesCarousel = () => {
  const { t } = useTranslation();

  const data = [
    {img : Svg_cashback, title : t('landing.cashback_title'), desc : t('landing.cashback_desc')},
    {img : Svg_earninvite, title : t('landing.earninvite_title'), desc : t('landing.earninvite_desc')},
    {img : Svg_coupon, title : t('landing.coupon_title'), desc : t('landing.coupon_desc')},
    {img : Svg_referral, title : t('landing.referral_title'),  desc : t('landing.referral_desc')},
  ]

  return (
    <div className={'w100 home-app-features-carousel'}>
      <Carousel responsive={responsive} autoPlay={false} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]}>
        {data.map((item, index) => (
          <div key={index} className={'ph2 pv2 h100'}>
            <FeatureItem
              data={item}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

FeaturesCarousel.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default FeaturesCarousel;
