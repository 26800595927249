import AuthService from '../../services/apiAuth';
import { ApiFactory, Storage } from '../../services';
import { ACTION_TYPES } from '../../constants';
import { getWebPushToken } from '../../services/pushWeb';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

const getLoggedInUserData = () => {
  return new Promise((resolve, reject) => {
    try {
      AuthService.getLoggedInUserData()
        .then((res) => {
          identifyDevice();
          trackEvent('Auth Service', {
            action_category: 'Load Home Page',
            action_outcome: 'success',
            interaction_element: 'screen',
            source: 'sf_web'
          });
          resolve(res.data.user);
        })
        .catch((err) => {
          identifyDevice();
          trackEvent('Auth Service', {
            action_category: 'Load Home Page',
            action_outcome: 'fail',
            interaction_element: 'screen',
            source: 'sf_web'
          });
          reject(err);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getLoggedInUser = () => (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    try {
      const user = await getLoggedInUserData();
      dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: user
      });
      dispatch({
        type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
        payload: !!user['verified_by_mobile']
      });
      resolve(user);
    } catch (e) {
      resolve();
    }
  });
};

export const login =
  ({ email, password }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        const device = {
          token: '123'
        };
        AuthService.emailLogin({ email, password, device }).then(
          async (response) => {
            identifyDevice();
            trackEvent('Auth Service', {
              action_category: 'Email login',
              action_outcome: 'success',
              interaction_element: 'screen',
              source: 'sf_web'
            });
            const { token, verified_by_mobile } = response.data;
            Storage.setAppToken(token);
            const user = await getLoggedInUserData();

            dispatch({
              type: ACTION_TYPES.APP_SET_USER_DATA,
              payload: user
            });
            dispatch({
              type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
              payload: !!verified_by_mobile
            });

            resolve(user);
          },
          async (e) => {
            reject(e);
          }
        );
      } catch (e) {
        identifyDevice();
        trackEvent('Auth Service', {
          action_category: 'Email login',
          action_outcome: 'fail',
          interaction_element: 'screen',
          source: 'sf_web'
        });
        reject(e);
      }
    });
  };

export const setAsLoggedIn = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({
        type: ACTION_TYPES.APP_LOGGED_IN,
        payload: true
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const updateProfileDetails = (user) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    AuthService.updateProfile({
      full_name: user.full_name,
      username: user.username,
      email: user.email,
      phone: user.phone,
      photo: user.photo,
      gender: user.gender,
      birthday: user.birthday,
      push_notis: user.push_notis,
      email_notis: user.email_notis,
      promo_notis: user.promo_notis,
      blog_notifications: user.blog_notifications,
      latitude: user.latitude,
      longitude: user.longitude,
      default_card_id: user.default_card_id
    }).then(async ({ data }) => {
      identifyDevice();
      trackEvent('Auth Service', {
        action_category: 'Update profile',
        action_outcome: 'success',
        interaction_element: 'screen',
        source: 'sf_web'
      });
      await dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: data.user
      });
      resolve(data.user);
    }, reject);
  });
};

export const setAsSeenOnboard = () => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      Storage.setSeenOnboard(true);
      dispatch({
        type: ACTION_TYPES.APP_SEEN_ONBOARD,
        payload: true
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const legacyLogin = (token) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      const device = {
        token: getWebPushToken()
      };

      ApiFactory.post(
        'login/legacy',
        { device },
        {
          headers: {
            Authorization: token
          }
        }
      ).then(
        async (response) => {
          const { token, verified_by_mobile } = response.data;
          Storage.setAppToken(token);

          const user = await getLoggedInUserData();
          dispatch({
            type: ACTION_TYPES.APP_SET_USER_DATA,
            payload: user
          });
          dispatch({
            type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
            payload: !!verified_by_mobile
          });

          resolve(user);
        },
        async (e) => {
          reject(e);
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const register = (user) => () => {
  return new Promise((resolve, reject) => {
    try {
      const device = { token: getWebPushToken() };
      AuthService.register({ ...user, device }).then(resolve, reject);
      identifyDevice();
      trackEvent('Auth Service', {
        action_category: 'Register',
        action_outcome: 'success',
        interaction_element: 'screen',
        source: 'sf_web'
      });
    } catch (e) {
      identifyDevice();
      trackEvent('Auth Service', {
        action_category: 'Register',
        action_outcome: 'fail',
        interaction_element: 'screen',
        source: 'sf_web'
      });
      reject(e);
    }
  });
};

export const facebookLogin = (token) => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const device = { token: getWebPushToken() };
      const response = await ApiFactory.post('login/facebook', { access_token: token, device });
      Storage.setAppToken(response.data.token);
      const user = await getLoggedInUserData();
      dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: user
      });
      dispatch({
        type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
        payload: !!user['verified_by_mobile']
      });
      resolve(user);
    } catch (e) {
      reject(e);
    }
  });
};

export const googleLogin = (id_token) => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const device = { token: getWebPushToken() };
      const response = await ApiFactory.post('login/google', { id_token: id_token, device });

      Storage.setAppToken(response.data.token);
      const user = await getLoggedInUserData();
      dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: user
      });
      dispatch({
        type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
        payload: !!user['verified_by_mobile']
      });
      resolve(user);
    } catch (e) {
      reject(e);
    }
  });
};

export const appleLogin =
  ({ user, identityToken, email, fullName }) =>
  async (dispatch) => {
    try {
      const device = { token: getWebPushToken() };
      if (!email) {
        email = '';
      }
      if (!fullName.nickName) {
        fullName = '';
      } else {
        fullName = fullName.nickName;
      }
      const data = {
        apple_id: user,
        apple_identity_token: identityToken,
        email: email,
        name: fullName,
        device
      };
      const response = await ApiFactory.post('login/apple', data);
      const { token, verified_by_mobile } = response.data;
      Storage.setAppToken(token);
      const loggedUser = await getLoggedInUserData();
      dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: loggedUser
      });
      dispatch({
        type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
        payload: !!verified_by_mobile
      });
      return { data: loggedUser, status: 'OK' };
    } catch (e) {
      return { data: e, status: 'ERROR' };
    }
  };

export const logout = () => async (dispatch) => {
  try {
    await ApiFactory.get('logout');
    Storage.clearAppToken();
    dispatch({
      type: ACTION_TYPES.APP_LOGGED_IN,
      payload: false
    });
    dispatch({
      type: ACTION_TYPES.APP_SET_ADDRESSES,
      payload: []
    });
    dispatch({
      type: ACTION_TYPES.APP_SET_USER_DATA,
      payload: {}
    });
    return { data: '', status: 'OK' };
  } catch (e) {
    return { data: e, status: 'ERROR' };
  }
};

export const changePassword = (old_password, password) => () => {
  return new Promise((resolve, reject) => {
    try {
      AuthService.changePass({
        old_password: old_password,
        password: password
      }).then(resolve, reject);
      identifyDevice();
      trackEvent('Auth Service', {
        action_category: 'Change password',
        action_outcome: 'success',
        interaction_element: 'screen',
        source: 'sf_web'
      });
    } catch (e) {
      reject(e);
      identifyDevice();
      trackEvent('Auth Service', {
        action_category: 'Change password',
        action_outcome: 'fail',
        interaction_element: 'screen',
        source: 'sf_web'
      });
    }
  });
};

export const setHasVerifiedPhone = (value) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
    payload: value
  });
};
