import React from 'react';
import { Composer, InputToolbar, Send } from '../react-web-gifted-chat';
import { Theme } from '../../../assets';
import { Image } from '@styled-icons/ionicons-solid';
import { CameraFill } from '@styled-icons/bootstrap';
import { Direction } from '@styled-icons/entypo';
import { Mic } from '@styled-icons/icomoon';
import { EmojiEmotions } from '@styled-icons/material-outlined';
import { Send as SendIcon } from '@styled-icons/material';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import PropTypes from 'prop-types';

export const renderInputToolbar = (
  props,
  quote_msg,
  images,
  default_msgs,
  onCancelQuote,
  onRemoveImage,
  onPressDefaultMsg
) => (
  <InputToolbar
    {...props}
    containerStyle={{
      // position: 'absolute',
      // bottom: 0,
      // left: 0,
      backgroundColor: Theme.colors.gray8,
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 18,
      paddingBottom: 18,
      borderTopWidth: 0
    }}
    primaryStyle={{ alignItems: 'center', display: 'flex' }}
    quote_msg={quote_msg}
    default_msgs={default_msgs}
    onCancelQuote={onCancelQuote}
    images={images}
    onRemoveImage={onRemoveImage}
    onPressDefaultMsg={onPressDefaultMsg}
  />
);

const CustomComposer = ({
  props,
  onPressEmoji,
  onPressLocation,
  onImageUpload,
  onImageCapture,
  onRecord
}) => {
  // onCapture
  const { t } = useTranslation();

  return (
    <div
      className={'align-col-middle w100'}
      style={{
        backgroundColor: Theme.colors.white,
        borderRadius: 16,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 8,
        paddingBottom: 8
      }}>
      <Composer
        {...props}
        placeholder={t('social.chat.type_something')}
        placeholderTextColor={Theme.colors.gray5}
        textInputStyle={{
          flex: 1,
          textAlign: 'left',
          color: Theme.colors.text,
          fontSize: 16,
          lineHeight: '20px',
          fontFamily: Theme.fonts.medium,
          backgroundColor: Theme.colors.white,
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 12,
          marginLeft: 0,
          borderLeftWidth: 1,
          borderLeftColor: Theme.colors.gray6,
          borderLeftStyle: 'solid'
        }}
      />
      <div className={'flex_between w100'}>
        <div className={'align-row-start w100'}>
          <IconButton style={{ padding: 0 }} onClick={onPressEmoji}>
            <EmojiEmotions size={21} color={Theme.colors.text} />
          </IconButton>
          <div style={styles.divider} />
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              onPressLocation();
            }}>
            <Direction size={20} color={Theme.colors.text} />
          </IconButton>
          <div style={styles.divider} />
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              onImageCapture();
            }}>
            <CameraFill size={18} color={Theme.colors.text} />
          </IconButton>
          <div style={styles.divider} />
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              onImageUpload();
            }}>
            <Image size={18} color={Theme.colors.text} />
          </IconButton>
          <div style={styles.divider} />
          <IconButton style={{ padding: 0 }} onClick={() => onRecord()}>
            <Mic size={16} color={Theme.colors.text} />
          </IconButton>
        </div>
        <Send
          {...props}
          containerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 15
          }}>
          <div className={'align-middle'}>
            <SendIcon size={18} color={Theme.colors.gray7} />
            <div style={styles.sendTxt}>{t('social.chat.send')}</div>
          </div>
        </Send>
      </div>
    </div>
  );
};

const styles = {
  divider: {
    height: 20,
    width: 1,
    marginLeft: 12,
    marginRight: 12,
    backgroundColor: Theme.colors.gray6
  },
  sendTxt: {
    marginLeft: 8,
    fontSize: 14,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.gray7
  }
};

CustomComposer.propTypes = {
  props: PropTypes.object,
  onPressEmoji: PropTypes.func,
  onPressLocation: PropTypes.func,
  onImageUpload: PropTypes.func,
  onImageCapture: PropTypes.func,
  onRecord: PropTypes.func
};
export const renderComposer = (
  props,
  onPressEmoji,
  onPressLocation,
  onImageUpload,
  onImageCapture,
  onRecord
) => (
  <CustomComposer
    props={props}
    onPressEmoji={onPressEmoji}
    onPressLocation={onPressLocation}
    onImageUpload={onImageUpload}
    onImageCapture={onImageCapture}
    onRecord={onRecord}
  />
);

export const renderSend = () => null;
