import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ApplyForm from './ApplyForm';
import Features from './Features';
import MobileIntro from './MobileIntro';
import Partners from './Partners';
import Faqs from './Faqs';
import './index.css';
import Seo from '../../components/Seo';

const Partner = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="view-partner" className="view-partner partner-screen">
      <Seo title={t('web_title.register_partner')} />
      <section className="main ph4 pv4 bg-cover bg-theme-color-light overlay-dark">
        <Grid container spacing={{ xs: 2, md: 5 }} className={'align-middle'}  >
          <Grid item xs={12} md={6} style={{}}>
            <div className={'title-view'}>
              <h1>{t('partner.title')}</h1>
              <h4>{t('partner.sub_title')}</h4>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <ApplyForm />
          </Grid>
        </Grid>
      </section>
      <section className="">
        <Features />
      </section>
      <section className="">
        <MobileIntro />
      </section>
      <section className="">
        <Partners />
      </section>
      <section className="">
        <Faqs />
      </section>
    </div>
  );
};

export default Partner;
