import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../layout';
import Seo from '../../../components/Seo';
import { AuthInput } from '../../../components/Inputs';
import Spacing from '../../../components/Spacing';
import { AuthBtn } from '../../../components/Buttons';
import './index.css';
import { confirmAlert } from "react-confirm-alert";
import { validatePhoneNumber, isEmpty } from '../../../utils/common';
import { updateProfileDetails } from '../../../store/actions/auth';
import { connect } from 'react-redux';
import { ROUTES_NAMES } from '../../../constants';
import PropTypes from 'prop-types';

const EditPhone = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(props.user?.phone ?? '');
 
  const update = async () => {
    if (props.user == null) { return; }
    const user = { ...props.user };
    user.phone = phone;
    const isValidPhone = validatePhoneNumber(user.phone);
    if (!isValidPhone) {
      return confirmAlert({
        title: t('attention'),
        message: t('alerts.wrong_phone_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => { }
          }
        ]
      });
    }

    try {
      setLoading(true);
      user.photo = null;
      await props.updateProfileDetails(user);
      setLoading(false);
      navigate(ROUTES_NAMES.verification)
    } catch (error) {
      console.log('update error', error)
      setLoading(false);
      confirmAlert({
        title: t('attention'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => { }
          }
        ]
      });
    }
  };


  return (
    <AuthLayout testId="view-verification" className={'view-editphone'}>
      <div className="auth-form">
        <Seo title={t('web_title.edit_phone')} />
        <h1>{!isEmpty(props.user?.phone) ? t('edit_phone.header1') : t('edit_phone.header1_new_user')}</h1>
        <h3>{!isEmpty(props.user?.phone) ? t('edit_phone.text') : t('edit_phone.text_new_user')}</h3>
        <div className={'align-middle vcode-inputs'}>
          <AuthInput
            text={phone}
            placeholder={t('auth_register.cell')}
            onChange={(text) => {
              if (text == null) {
                return;
              }
              setPhone(text);
            }}
          />
        </div>
        <Spacing height={32} />
        <div className={'center-align ph-20'}>
          <AuthBtn isLoading={loading} title={!isEmpty(props.user?.phone) ? t('edit_phone.button') : t('edit_phone.button_new_user')} onClick={() => { update() }} />
        </div>
        <Spacing height={40} />
      </div>
    </AuthLayout>
  );
};


EditPhone.propTypes = {
  user: PropTypes.shape({
    phone: PropTypes.string
  }),
  updateProfileDetails: PropTypes.func
};
function mapStateToProps({ app }) {
  return {
    user: app.user,
  };
}

export default connect(mapStateToProps, {
  updateProfileDetails
})(EditPhone);
