import React from 'react';
import PropTypes from 'prop-types';
import { Config } from '../../../constants';
import './index.css';

const BlogItem = ({ id, data, language, style, onClick }) => {
  return (
    <div key={id} className={'blog-item'} style={style} onClick={onClick}>
      <div
        className="img bg-cover bg-theme-color-light overlay-dark"
        style={{
          backgroundImage: `url('${Config.IMG_BASE_URL}${data && data.image_cover}??h=250')`
        }}
      />
      <div className="info">
        <div className={'flex_1'}>
          <div className={'align-row-start tags'}>
            {data && data.categories && (
              <div className={'tag-text'}>{data.categories.map((x) => (language == 'en' ? x.title : x.sq_title)).join(', ')}</div>
            )}
          </div>
          <div className={'flex_between mt1'}>
            <h3 className={'blog-title'}>{data.title}</h3>
          </div>
          <div className={'flex_between mt1'}>
            <h3 className={'author-txt'}>{data.author}</h3>
            <h3 className={'date-txt'}>{data.created_at}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id && prevProps.language === nextProps.language;
}

BlogItem.propTypes = {
  id: PropTypes.string,
  language : PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    image_cover: PropTypes.string,
    categories: PropTypes.array,
    author: PropTypes.string,
    created_at: PropTypes.string,
    slug: PropTypes.string,
    hash: PropTypes.string
  }),
  style: PropTypes.object,
  onClick: PropTypes.func
};
export default React.memo(BlogItem, arePropsEqual);
