import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { changePassword } from '../../../store/actions/auth';
import {setTmpPassChanged} from '../../../store/actions/app';
import { useTranslation } from 'react-i18next';
import {
  extractErrorMessage
} from '../../../utils/common';
import { AuthInput } from '../../../components/Inputs';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css'
import { confirmAlert } from 'react-confirm-alert';
import AuthBtn from '../../Buttons/AuthBtn';
import Svg_close from '../../../assets/svgs/modals/close.svg';

const ChangePasswordModal = (props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [curPass, setCurPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  React.useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const validatePassword = (curPass, password, passwordConfirmation) => {
    return new Promise((resolve, reject) => {
      if (!curPass || !password || !passwordConfirmation) {
        reject(t('alerts.fill_all_fields'));
      } else if (!password || password !== passwordConfirmation) {
        reject(t('alerts.password_mismatch'));
      } else if (curPass === password) {
        reject(t('cannot_use_old_password'));
      } else {
        resolve();
      }
    });
  };

  const onChangePass = () => {
    validatePassword(curPass, newPass, confirmPass)
      .then(async () => {
        setLoading(true);
        props
          .changePassword(curPass, newPass)
          .then(() => {
            setLoading(false);
            props.setTmpPassChanged(true);
            props.onClose();
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            confirmAlert({
              title: t('restaurant_details.we_are_sorry'),
              message: extractErrorMessage(error),
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: t('Ok'),
                  className: 'error-ok-btn',
                  onClick: () => {
                  }
                }
              ]
            });
          });
      })
      .catch((msg) => {
        confirmAlert({
          title: t('attention'),
          message: msg,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {
              }
            }
          ]
        });
      });
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal edit-profile-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h4 className={'title'}>{t('account_change_pass.header_title')}</h4>
        </div>
        <div className={'scrollview'}>
          <AuthInput
            placeholder={t('account_change_pass.cur_password')}
            placeholderTextColor={'#DFDFDF'}
            onChange={(val) => setCurPass(val)}
            text={curPass}
            isSecure={true}
            style={{ marginBottom: 20 }}
          />
          <AuthInput
            placeholder={t('account_change_pass.new_password')}
            placeholderTextColor={'#DFDFDF'}
            onChange={(val) => setNewPass(val)}
            text={newPass}
            isSecure={true}
            style={{ marginBottom: 20 }}
          />
          <AuthInput
            placeholder={t('account_change_pass.re_type_password')}
            placeholderTextColor={'#DFDFDF'}
            onChange={(val) => setConfirmPass(val)}
            text={confirmPass}
            isSecure={true}
            style={{ marginBottom: 20 }}
          />
        </div>
        <div className={'flex_1 align-col-middle w100 mt2 actions'}>
          <AuthBtn
            isLoading={isLoading}
            isDisabled={isLoading}
            onClick={onChangePass}
            className={'save-btn'}
            title={t('account_change_pass.header_title')}
          />
        </div>
      </div>
    </Dialog>
  );
};

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.shape({
    photo: PropTypes.string,
    birthdate: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    username: PropTypes.string,
    sex: PropTypes.string
  }),
  changePassword: PropTypes.func,
  setTmpPassChanged : PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {
  changePassword, setTmpPassChanged
})(ChangePasswordModal);
