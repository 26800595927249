import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Seo from '../../../../components/Seo';
import './index.css';
import ChatHistScreen from '../chathist';
import ChatProfile from '../../../../components/Chats/ChatProfile';
import { useTranslation } from 'react-i18next';
import { isEmpty } from '../../../../utils/common';

const ChatScreen = () => {
  const { t } = useTranslation();
  let { channel_id } = useParams();
  console.log(channel_id)
  return (
    <div style={{ flex: 1 }} className={'view-chat'}>
      <Seo title={t('web_title.chat')} />
      <Grid container spacing={{ xs: 2, md: 2 }} style={{ margin: 0, width: '100%' }}>
        <Grid item xs={12} lg={4} className={!isEmpty(channel_id) ? 'hide-md left-side' : 'left-side'}  style={{ margin: 0, padding: 0 }}>
          <ChatProfile />
          <ChatHistScreen />
        </Grid>
        <Grid item xs={12} lg={8} style={{ margin: 0, padding: 0 }}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatScreen;
