import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Theme from '../../../assets/theme';
import { ucFirst } from '../../../utils/common';
import './index.css';
import PropTypes from 'prop-types';

const InvitationHistItem = ({ data, is_received, onSelect, style }) => {
  const { t } = useTranslation();

  const colors = {
    used: Theme.colors.red1,
    using: Theme.colors.red2,
    expired: Theme.colors.gray7,
    available: Theme.colors.cyan2
  };
  const getName = () => {
    if (is_received) {
      return ucFirst(data.sender?.username || data.sender?.full_name);
    }
    return ucFirst(data.receiver?.username || data.receiver?.full_name);
  };

  const getDate = () => {
    return moment(data.invite_time, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD | hh:mm A');
  };

  const getStatus = () => {
    if (data.is_used == 1) {
      return 'used';
    } else if (data.is_used == 2) {
      return 'using';
    } else if (data.is_expired == 1) {
      return 'expired';
    }
    return 'available';
  };

  return <div onClick={() => onSelect()} className={'invitation-hist-item'} style={style}>
    <div className={'flex_between'}>
      <p className={'name'}>{getName()}</p>
      {is_received && data.status == 'available' && (
        <p className={'date_limit'}>{data.remaining_time_to_use}</p>
      )}
    </div>
    <div className={'flex_between'} style={{ marginTop: 6 }}>
      {!is_received && data.used_rewards_amount != null && data.used_rewards_amount > 0 ? (
        <p className={'invite-code'}>
          {t('invitation_earn.your_earned')}
          <p style={{ fontFamily: Theme.fonts.semiBold }}>{data.used_rewards_amount} L</p>
        </p>
      ) : (
        <p className={'invite-code'}>
          {t('invitation_earn.code')} : <span style={{ fontFamily: Theme.fonts.semiBold }}>{data.invite_code}</span>
        </p>
      )}
    </div>
    <div className={'flex_between'} style={{ marginTop: 6 }}>
      <p className={'name'}>{getDate()}</p>
      <p className={'status'} style={{ color: colors[getStatus()] }}>
        {is_received && getStatus() == 'available' && t('invitation_earn.use_code')}
        {!is_received && getStatus() == 'available' && t('invitation_earn.sent')}
        {getStatus() != 'available' && t(`invitation_earn.${getStatus()}`)}
      </p>
    </div>
  </div>;
};


InvitationHistItem.propTypes = {
  data: PropTypes.shape({
    sender: PropTypes.shape({
      username: PropTypes.string,
      full_name: PropTypes.string
    }),
    receiver: PropTypes.shape({
      username: PropTypes.string,
      full_name: PropTypes.string
    }),
    invite_time: PropTypes.string,
    used_rewards_amount: PropTypes.number,
    is_used: PropTypes.number,
    is_expired: PropTypes.number,
    status: PropTypes.string,
    invite_code: PropTypes.string,
    remaining_time_to_use: PropTypes.number
  }),
  is_received: PropTypes.bool,
  style: PropTypes.object,
  onSelect: PropTypes.func
};
export default InvitationHistItem;
