import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Svg_close from '../../assets/svgs/modals/close.svg';
import './index.css';
import { AuthBtn } from '../Buttons';
import { confirmAlert } from 'react-confirm-alert';
import apiFactory from '../../services/apiFactory';
import { connect } from 'react-redux';
import { updateProfileDetails } from '../../store/actions/auth';
import AuthInput from '../Inputs/AuthInput';

const AddCardModal = (props) => {
  const { isOpen } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [card_num, setNum] = useState('');
  const [name, setName] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiry_month, setExpiryMonth] = useState('');
  const [expiry_year, setExpiryYear] = useState('');
  const [loading, setLoading] = useState(false);

  const inputExpiry = (value) => {
    value = value.replace('/', '');
    if (value.length > 4) {
      return;
    }
    setExpiryMonth(value.slice(0, 2));
    setExpiryYear(value.slice(2, 4));
  };

  const formatCardNum = (num) => {
    let formatted = '';
    for (var i = 0; i < num.length; i++) {
      if (i !== 0 && i % 4 === 0) {
        formatted = formatted + ' ';
      }
      formatted = formatted + num[i];
    }
    return formatted;
  };

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onBack = () => {
    navigate(-1);
  };

  const validateCard = () => {
    if (name == '') {
      confirmAlert({
        title: t('attention'),
        message: t('card.wrong_name'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    if (card_num.length != 16) {
      confirmAlert({
        title: t('attention'),
        message: t('card.wrong_number'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    if (cvv.length != 3) {
      confirmAlert({
        title: t('attention'),
        message: t('card.wrong_cvv'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }

    let year = parseInt(expiry_year);
    let month = parseInt(expiry_month);

    let this_year = new Date().getFullYear() - 2000;
    let this_month = new Date().getMonth() + 1;

    if (year < this_year || month <= 0 || month > 12) {
      console.log(this_year, year);
      confirmAlert({
        title: t('attention'),
        message: t('card.wrong_expiry_date'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    if (year == this_year && month < this_month) {
      console.log(month, this_month);
      confirmAlert({
        title: t('attention'),
        message: t('card.wrong_expiry_date'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    return true;
  };

  const changePrimary = async (card) => {
    try {
      await props.updateProfileDetails({
        default_card_id: card.id
      });
      setLoading(false);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.log('change card Primary', error);
      // alerts.error(translate('alerts.error'), translate('checkout.something_is_wrong'));
      navigate(-1);
    }
  };

  const onSaveCard = () => {
    if (validateCard() == false) {
      return;
    }
    setLoading(true);
    apiFactory
      .post(`stripe/payment-methods/create`, {
        name: name,
        number: card_num,
        cvc: cvv,
        exp_month: expiry_month,
        exp_year: expiry_year
      })
      .then(
        ({ data }) => {
          changePrimary(data.payment_method);
        },
        (error) => {
          setLoading(false);
          console.log('onSaveCard error', error);
          const message = error.message || t('generic_error');
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
        }
      );
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal add-card-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={onBack} />
          <h4 className={'title'}>{t('payment_method.add_new_card')}</h4>
        </div>
        <div className={'align-col-middle w100'}>
          <div className={'card-bg'}>
            <h3>{name === '' ? t('card.cardholder_name') : name}</h3>
            <h1>{formatCardNum(card_num) === '' ? t('card.number') : formatCardNum(card_num)}</h1>
            <div className={'flex_between cvv_expiry'}>
              <span>
                CVV: <span> {cvv === '' ? '***' : cvv}</span>
              </span>
              <span>
                Expiry:{' '}
                <span>
                  {expiry_month != '' || expiry_year != ''
                    ? expiry_month + '/' + expiry_year
                    : '**/**'}
                </span>
              </span>
            </div>
          </div>
          <div className={'row'}>
            <AuthInput
              text={card_num}
              onChange={(val) => {
                if (val.length <= 16) {
                  setNum(val);
                }
              }}
              placeholder={t('card.number')}
            />
          </div>
          <div className={'row'}>
            <AuthInput
              text={name}
              onChange={(val) => {
                if (val.length <= 32) {
                  setName(val);
                }
              }}
              placeholder={t('card.cardholder_name')}
            />
          </div>
          <div className={'row'}>
            <div className={'flex_1'}>
              <AuthInput
                text={
                  expiry_month !== '' || expiry_year !== '' ? expiry_month + '/' + expiry_year : ''
                }
                onChange={inputExpiry}
                placeholder={t('card.expiry_date')}
              />
            </div>
            <div style={{ width: 20 }} />
            <div className={'flex_1'}>
              <AuthInput
                text={cvv}
                onChange={(val) => {
                  if (val.length <= 3) {
                    setCvv(val);
                  }
                }}
                placeholder="CVV"
              />
            </div>
          </div>
          <div className={'flex_1 align-col-middle w100 mt20 actions'}>
            <AuthBtn
              isLoading={loading}
              isDisabled={
                loading ||
                name === '' ||
                card_num === '' ||
                cvv === '' ||
                expiry_month === '' ||
                expiry_year === ''
              }
              title={t('card.save_card')}
              onClick={onSaveCard}
              className={'save-btn'}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

AddCardModal.propTypes = {
  isOpen: PropTypes.bool,
  updateProfileDetails: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {
  updateProfileDetails
})(AddCardModal);
