const ROUTES_NAMES = {
  home: '/',
  login: '/login',
  resetPass: '/reset-password',
  resetPassDone: '/reset-password-success',
  verification: '/verification',
  edit_phone: '/edit-phone',
  contact: '/contact',
  downloadApp: '/download-app',
  landingVendor: '/landing/:vendor_hash/:order_method',
  becomePartner: '/partner',
  becomeCourier: '/courier',
  privacyPolicy: {
    courier: '/courier/privacy-policy',
    partner: '/partner/privacy-policy',
    privacy_main: '/privacy-policy'
  },
  help: {
    index: '/help',
    courier: '/help/courier',
    customer: '/help/customer',
    partner: '/help/partner'
  },
  careers: '/careers',
  blog: '/blog',
  blogDetails: '/blog/:blog_hash/:blog_name',
  vendorDetails: '/store/:vendor_hash/:vendor_name/:order_method',
  vendorPastOrder: '/past-order/:vendor_hash',
  categories: '/categories',
  profile: '/profile',
  addresses: 'addresses',
  address: 'address',
  paymentmethods: 'pay-methods',
  paymentmethod: 'pay-method',
  wallet: 'wallet',
  earnInvitation: 'earn-invitation',
  earnInvitationHist: 'earn-invitations',
  earnInvitationDetails: 'earn-invitations/:earninvitation_id',
  earnInviteFriend: 'earninvite-friend',
  earnInviteSnapfooder: 'earninvite-snapfooder',
  orders: '/orders',
  orderSummary: '/order/:order_id',
  favorites: '/favorites',
  promotions: '/promotions',
  social: '/social',
  chat: 'chat',
  messages: ':channel_id',
  friends: 'friends',
  snapfooders: 'snapfoodies',
  invitations: 'invitations',
  snapfooder: ':snapfooder_id',
  settings: '/settings',
  snapfoodMap: '/snapfood-map',
  vendorsMap: '/vendors-map',
  cart: '/cart',
  checkout: '/checkout',
  vendors: '/stores',
  search: '/search',
  delete_account_help: '/delete-account-help',
  terms_conditions: 'terms-conditions'
};

export default ROUTES_NAMES;
