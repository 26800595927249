import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-grid-system';
import './Partners.css';

const Partners = () => {
  const { t } = useTranslation();
  const partner_icons = [
    'https://prod.snapfood.al/images/vendors/tn_1654853063_0d321fe2f8f26a8b3920d7b5f382b4c7.jpeg',
    'https://prod.snapfood.al/images/vendors/tn_1587716677_dd99827731bbc2a15d48adbd4e1b2307.jpg',
    'https://prod.snapfood.al/images/vendors/tn_1612442878_5cb103ace45902eb51cd9febbd7be1cc.jpeg',
    'https://prod.snapfood.al/images/vendors/tn_1654849384_30b487530cdcf8e62b39a72e1c3c8f69.jpeg',
    'https://prod.snapfood.al/images/vendors/tn_1587114170_45d93b118c3f64839ebc5e1081bbf267.jpg',
    'https://prod.snapfood.al/images/vendors/tn_1635514122_b0c2727093d9c27e0f624b166a366c38.jpeg',
    'https://prod.snapfood.al/images/vendors/tn_1646418236_e86b8939e48268b5435ff15381bc0e3b.jpeg',
    'https://prod.snapfood.al/images/vendors/tn_1634533838_cdf8cd35ed39e59cf9af4f7cdd824599.jpeg'
  ];
  return (
    <div className="partners ph2 pv10">
      <h3>{t('partner.our_partners')}</h3>
      <h5>{t('partner.our_partners_desc')}</h5>
      <div>
        <Container className="container" fluid>
          <Row justify={'center'}>
            {partner_icons.map((icon, index) => (
              <div key={index} className={'partner-item'}>
                <img src={icon} />
              </div>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(Partners, arePropsEqual);
