import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MainBtn } from '../Buttons';
import './index.css';

const ChangeAddressConfirmModal = ({ isOpen, fee, onCancel, onOk }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open} className={'align-col-middle w100 modal remove-cart-modal'}>
      <div className={'align-col-middle content'}>
        <h2 style={{ marginBottom: 8 }}>{t('modals.modify_address_confirm')}</h2>
        <h2 className={'mt8'}>{t('modals.modify_address_fee').replace('###', fee)}</h2>
        <div className={'flex_1 align-col-middle mt20 w100 actions'}>
          <MainBtn title={t('modals.yes_sure')} onClick={onOk} className={'sure-btn'} />
          <MainBtn title={t('cancel')} onClick={onCancel} className={'cancel-btn'} />
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen && prevProps.product_name === nextProps.product_name;
}

ChangeAddressConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  fee: PropTypes.number,
  onOk: PropTypes.func,
  onCancel: PropTypes.func
};
export default React.memo(ChangeAddressConfirmModal, arePropsEqual);
