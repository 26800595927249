import React from "react";
import ReactHtmlParser from 'react-html-parser';
import Seo from "../../components/Seo";
import "./index.css";
import { useTranslation } from "react-i18next";

const DeleteAccountHelp = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="view-terms" className={"align-col-middle view-terms"}>
      <Seo title={t("web_title.delete_account_help")} />
        <div style={{ width: "100%", maxWidth: 800 }}>
          <h1 style={{ width: "100%", textAlign: "left" }}>
            {t("delete_account_help")}
          </h1>
          <div>
          {ReactHtmlParser(t('privacy.delete_account_help'))}
        </div>
        </div>
    </div>
  );
};

export default DeleteAccountHelp;
