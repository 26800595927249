import React from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';
import Faq from '../../components/Common/Faq';

const Faqs = () => {
  const { t } = useTranslation();

  const pizzaSeniFaqs = [
    { question: 'courier.faq_question_1', answer: 'courier.faq_answer_1' },
    { question: 'courier.faq_question_2', answer: 'courier.faq_answer_2' },
    { question: 'courier.faq_question_3', answer: 'courier.faq_answer_3' },
    { question: 'courier.faq_question_4', answer: 'courier.faq_answer_4' },
    { question: 'courier.faq_question_5', answer: 'courier.faq_answer_5' }
  ];

  const orderingSnapFoodFaqs = [
    { question: 'courier.faq_question_6', answer: 'courier.faq_answer_6' },
    { question: 'courier.faq_question_7', answer: 'courier.faq_answer_7' },
    { question: 'courier.faq_question_8', answer: 'courier.faq_answer_8' },
    { question: 'courier.faq_question_9', answer: 'courier.faq_answer_9' },
    { question: 'courier.faq_question_10', answer: 'courier.faq_answer_10' }
  ];

  const partnershipFaqs = [
    { question: 'courier.faq_question_11', answer: 'courier.faq_answer_11' },
    { question: 'courier.faq_question_12', answer: 'courier.faq_answer_12' },
    { question: 'courier.faq_question_13', answer: 'courier.faq_answer_13' },
    { question: 'courier.faq_question_14', answer: 'courier.faq_answer_14' }
  ];

  return (
    <div className="faqs">
      <h1>{t('landing.faq.title')}</h1>
      <div>
        <div className="items">
          <h2>About Pizza Seni</h2>
          {pizzaSeniFaqs.map((faq, index) => (
            <Faq
              key={`pizzaSeni_faq_${index + 1}`}
              question={t(faq.question)}
              answer={t(faq.answer)}
            />
          ))}
        </div>

        <div className="items">
          <h2>Ordering through SnapFood</h2>
          {orderingSnapFoodFaqs.map((faq, index) => (
            <Faq
              key={`orderingSnapFood_faq_${index + 1}`}
              question={t(faq.question)}
              answer={t(faq.answer)}
            />
          ))}
        </div>

        <div className="items">
          <h2>SnapFood and Pizza Seni Partnership</h2>
          {partnershipFaqs.map((faq, index) => (
            <Faq
              key={`partnership_faq_${index + 1}`}
              question={t(faq.question)}
              answer={t(faq.answer)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
