import React from 'react';
import './index.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/sq';
import { useTranslation } from 'react-i18next';
import SmallOrderFeeTooltip from '../../Common/SmallOrderFeeTooltip';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import { Theme } from '../../../assets';
import RoundIconBtn from '../../Buttons/RoundIconBtn';
import { Config } from '../../../constants';
import AddressItem from '../../Cart/AddressItem';
import { isEmpty } from '../../../utils/common'

const OrderSummInfo = (props) => {
  const { t } = useTranslation();
  const { order, isOrdered } = props;
  const getDiscountAmount = (order) => {
    if (order.coupon_amount != null && parseInt(order.coupon_amount) > 0) {
      return parseInt(order.coupon_amount);
    } else if (order.discount_amount != null && parseInt(order.discount_amount) > 0) {
      return parseInt(order.discount_amount);
    }
    return 0;
  };

  const _OrderPItem = (data) => {
    return (
      <div key={data.id} className={'align-middle w100'}>
        <div style={styles.item_qty}>x {data.quantity}</div>
        <div style={styles.item_divider} />
        <div className={'align-middle'} style={styles.item_infoview}>
          <div style={styles.item_title}>{data.title}</div>
          <div style={styles.item_price}>{parseInt(data.price)} L</div>
        </div>
      </div>
    );
  };

  console.log('order summ ', order, props.language);

  return (
    <div className={'align-col-start order-summ-view'}>
      <h5>{t('order_summary.title')}: <span>{order.order_note}</span></h5>
      {
        isOrdered &&
        <p className={'order-date mt2 mb1'}>
          {moment(order.ordered_date, 'DD-MM-YYYY HH:mm').locale(props.language || 'sq').format('DD MMMM YYYY, HH:mm')}
        </p>
      }
      {isOrdered && order.order_type == OrderType_Delivery &&
      <AddressItem data={order.address || {}} editable={false} textSize={16} showNotes={true} />
      }
      <div className={'align-middle mt3'} style={{ width: '100%', marginBottom: 16 }}>
        {order.vendor != null && (
          <div className={'align-row-start flex_1'}>
            <RoundIconBtn
              style={{ ...styles.LogoView }}
              icon={
                <img
                  style={styles.Logo}
                  src={Config.IMG_BASE_URL + order.vendor.logo_thumbnail_path}
                />
              }
              onClick={() => {
              }}
            />
            <div style={styles.LogoText}>{order.vendor.title}</div>
          </div>
        )}
      </div>
      {
        isOrdered && order.order_type != OrderType_Delivery &&
        <div className={'vendor-address mb2'}>
          <div className={'vendor-phone'} >{order.vendor?.phone_number}</div>
          <div className={'vendor-phone mt1'}  >{order.vendor?.address}</div>
        </div>
      }
      {
        isOrdered && order.order_for_friend != null &&
        <div className={'info-value-txt mb2'}  style={{ width: '100%', textAlign: 'center', }}>
          {t('order_summary.promotion_for')} : {
          order.order_for_friend.username || order.order_for_friend.full_name
        }
        </div>
      }
      {
        isOrdered && order.order_type == OrderType_Delivery && order.is_schedule == 1 && !isEmpty(order.schedule_time) &&
        <div className={'info-value-txt mb2'} >
          {t('order_summary.scheduled_time')} : {
          moment(order.schedule_time, "YYYY-MM-DD HH:mm:ss").locale(props.language || 'sq').format('DD MMMM YYYY, HH:mm')
        }
        </div>
      }
      {
        isOrdered && order.order_type == OrderType_Pickup &&
        <div className={'info-value-txt mb2'}>
          {t('order_summary.pickup_time')} : {
          order.pickup_datetime ? moment(order.pickup_datetime, "YYYY-MM-DD HH:mm:ss").locale(props.language || 'sq').format('DD MMMM YYYY, HH:mm')
            : 0
        }
        </div>
      }
      {
        isOrdered && order.order_type == OrderType_Reserve &&
        <div className={'info-value-txt mb2'}>
          {t('order_summary.reserve_time')} : {
          order.pickup_datetime ? moment(order.pickup_datetime, "YYYY-MM-DD HH:mm:ss").locale(props.language || 'sq').format('DD MMMM YYYY, HH:mm')
            : 0
        }
        </div>
      }
      {
        isOrdered && order.order_type == OrderType_Reserve &&
        <div className={'info-value-txt mb2'}>
          {t('cart.num_guests')} : {order.num_guests ?? 0}
        </div>
      }
      <div className={'align-col-middle order-info-view'}>
        {order.products != null && order.products.map((item) => _OrderPItem(item))}
        {order.total_price != null && (
          <div className={'w100 flex_between top-border mt2'}>
            <div className={'subject-title'}>{t('cart.order_total')}</div>
            <div className={'order-total'}>{parseInt(order.total_price)} L</div>
          </div>
        )}
        {order.sub_total != null && (
          <div className={'w100 flex_between mt1'}>
            <div className={'order-info-text'}>{t('cart.subtotal')}</div>
            <div className={'order-info-text'}>{parseInt(order.sub_total)} L</div>
          </div>
        )}
        {order.cashback != null && parseInt(order.cashback) >= 0 && (
          <div className={'w100 flex_between mt1'}>
            <div className={'order-info-text'}>{t('orders.filter.cashback')}</div>
            <div className={'order-info-text'}>
              {parseInt(order.cashback) > 0 ? `-${parseInt(order.cashback)}` : `0`} L
            </div>
          </div>
        )}
        {order.total_price != null && getDiscountAmount(order) >= 0 && (
          <div className={'w100 flex_between mt1'}>
            <div className={'order-info-text'}>{t('orders.filter.discount')}</div>
            <div className={'order-info-text'}>
              {parseInt(getDiscountAmount(order)) > 0
                ? `-${parseInt(getDiscountAmount(order))}`
                : `0`}{' '}
              L
            </div>
          </div>
        )}
        {order.order_type === OrderType_Delivery &&
        order.small_order_fee != null &&
        parseInt(order.small_order_fee) > 0 && (
          <div className={'w100 flex_between mt1'}>
            <div className={'align-middle'}>
              <div className={'order-info-text pr-10'}>{t('cart.small_order_fee')}</div>
              <SmallOrderFeeTooltip
                delivery_minimum_order_price={order.delivery_minimum_order_price}
                small_order_fee={parseInt(order.small_order_fee)}
              />
            </div>

            <div className={'order-info-text'}>{parseInt(order.small_order_fee) + ' L'}</div>
          </div>
        )}
        {order.delivery_fee != null && parseInt(order.delivery_fee) >= 0 && (
          <div className={'w100 flex_between mt1'}>
            <div className={'order-info-text'}>{t('checkout.delivery_fee')}</div>
            <div className={'order-info-text'}>{parseInt(order.delivery_fee)} L</div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  LogoText: {
    color: Theme.colors.text,
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    marginLeft: 10
  },
  LogoView: {
    width: 34,
    height: 34,
    borderRadius: 8,
    backgroundColor: Theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  Logo: { width: 28, height: 28 },
  item_divider: { width: 1, height: 35, backgroundColor: Theme.colors.gray6 },
  item_qty: { width: 35, fontSize: 16, color: Theme.colors.red1, fontFamily: Theme.fonts.semiBold },
  item_title: {
    flex: 1,
    marginTop: 8,
    fontSize: 16,
    color: Theme.colors.text,
    fontFamily: Theme.fonts.medium
  },
  item_price: {
    marginTop: 8,
    marginBottom: 12,
    fontSize: 16,
    color: Theme.colors.text,
    fontFamily: Theme.fonts.medium
  },
  item_infoview: { flex: 1, alignItems: 'stretch', marginLeft: 12 }
};

OrderSummInfo.propTypes = {
  order: PropTypes.shape({
    order_type: PropTypes.string,
    order_note: PropTypes.string,
    delivery_fee: PropTypes.number,
    small_order_fee: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    coupon_amount: PropTypes.number,
    discount_amount: PropTypes.number,
    total_price: PropTypes.number,
    cashback: PropTypes.number,
    sub_total: PropTypes.number,
    products: PropTypes.array,
    vendor: PropTypes.shape({
      logo_thumbnail_path: PropTypes.string,
      title: PropTypes.string,
      phone_number: PropTypes.string,
      address : PropTypes.string
    }),
    ordered_date: PropTypes.string,
    address: PropTypes.object,
    schedule_time: PropTypes.string,
    pickup_datetime: PropTypes.string,
    num_guests: PropTypes.number,
    order_for_friend : {
      username: PropTypes.string,
      full_name: PropTypes.string
    },
    is_schedule : PropTypes.number
  }),
  isOrdered: PropTypes.bool,
  language: PropTypes.string
};

const mapStateToProps = (app) => ({
  language: app.language
});

export default connect(mapStateToProps, {})(OrderSummInfo);
