import { ACTION_TYPES } from '../../constants';

const INITIAL_STATE = {
	channelId: null,
	chat_channels: [],
	call_channels: [],
	user: {},
	messages: [],
	new_invites: [],
	newMessagesNum: 0,
	userCanEnterChat: false,
	isChannelLoading: false,
	isLoadingChat: null,
	messagesByChannel: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case ACTION_TYPES.APP_SET_MESSAGES_BY_CHANNEL: {
			return {
				...state,
				messagesByChannel: { ...state.messagesByChannel,[action.payload.key]:action.payload.messages },
			};
		}
		case ACTION_TYPES.APP_INIT_CHANNEL_SUCCESS: {
			return {
				...state,
				channelId: action.payload,
				isChannelLoading: false,
			};
		}
		case ACTION_TYPES.APP_SET_CHANNELS: {
			return {
				...state,
				chat_channels: action.payload || [],
			};
		}
		case ACTION_TYPES.APP_SET_CHANNELS_LOADING: {
			return {
				...state,
				isLoadingChat: action.payload || false,
			};
		}
		case ACTION_TYPES.APP_SET_CALL_CHANNELS: {
			return {
				...state,
				call_channels: action.payload || [],
			};
		}
		case ACTION_TYPES.APP_SET_NEW_INVITES:
			return { ...state, new_invites: action.payload };
		case ACTION_TYPES.APP_INIT_CHAT_ROOM_ERROR: {
			return {
				...state,
				isChannelLoading: false,
			};
		}
		case ACTION_TYPES.APP_INIT_CHAT_ROOM_STARTED: {
			return {
				...state,
				isChannelLoading: true,
			};
		}
		case ACTION_TYPES.APP_SET_NEW_MESSAGE_NUM: {
			return {
				...state,
				newMessagesNum: action.payload || 0,
			};
		}
		case ACTION_TYPES.APP_USER_LOGGED_OUT: {
			return INITIAL_STATE;
		}
		case ACTION_TYPES.APP_ENTER_CHAT: {
			return {
				...state,
				userCanEnterChat: true,
			};
		}
		default:
			return { ...state };
	}
};

