import { ACTION_TYPES } from '../../constants';
import apiFactory from '../../services/apiFactory';
import { setLanguage } from '../../localisations';
import { Storage } from '../../services';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

export const toggleSideBar = (payload) => {
  return { type: ACTION_TYPES.APP_TOGGLE_SIDE_BAR, payload: payload };
};

export const setAppHeaderClass = (payload) => {
  return { type: ACTION_TYPES.APP_SET_HEADER_CLASS, payload: payload };
};

export const setTmpFood = (payload) => {
  return { type: ACTION_TYPES.SET_FOOD, payload: payload };
};

export const setTmpOrder = (payload) => {
  return { type: ACTION_TYPES.APP_SET_TMP_ORDER, payload: payload };
};

export const setUserData = (payload) => {
  return { type: ACTION_TYPES.APP_SET_USER_DATA, payload: payload };
};

export const setTmpLocationPicked = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_ADDR_PICKED, payload: payload };
};

export const setTmpPassChanged = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_PASS_CHANGED, payload: payload };
};

export const setAllChatChannels = (payload) => {
  return { type: ACTION_TYPES.APP_SET_CHAT_CHANNELS, payload: payload };
};

export const setOrderMessageTags = () => (dispatch, getState) => {
  return new Promise((resolve) => {
    apiFactory.get(`orders/get-support-tags`).then(({ data }) => {
      let tags = data.tags || [];
      if (getState().app.language === 'en') {
        tags = tags.map((t) => ({ id: t.id, title: t.title_en }));
      } else {
        tags = tags.map((t) => ({ id: t.id, title: t.title_sq }));
      }

      dispatch({
        type: ACTION_TYPES.APP_SET_ORDER_MSG_TAGS,
        payload: tags
      });
      resolve();
    }, resolve);
  });
};

export const setTmpSelectedForGroupChat = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_SET_SELECTED_FOR_GROUP_CHAT, payload: payload };
};

export const goActiveScreenFromPush = (value) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.APP_SET_ACTIVE_SCREEN_FROM_PUSH,
    payload: value
  });
};

export const setActiveSnapfoodMarker = (payload) => {
  return { type: ACTION_TYPES.APP_SET_ACTIVE_MARKER, payload: payload };
};

export const setShowAppSnackbar = (payload) => {
  return { type: ACTION_TYPES.APP_SET_SNACKBAR_MESSAGE, payload: payload };
};

export const setProfileBlogFilter = (payload) => {
  return { type: ACTION_TYPES.APP_SET_PROFILE_BLOG_FILTER, payload: payload };
};

export const setProfileBlogs = (payload) => {
  return { type: ACTION_TYPES.APP_SET_BLOG_CATEGORIES, payload: payload };
};

export const setTmpSnapfoodMapData = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_SNAPFOOD_MAP_DATA, payload: payload };
};

export const setHomeVendorFilter = (payload) => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      await dispatch({
        type: ACTION_TYPES.APP_SET_VENDOR_FILTER,
        payload: payload
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const setHomeVendorSort = (payload) => {
  return { type: ACTION_TYPES.APP_SET_VENDOR_SORT, payload: payload };
};

export const setHomeOrdersFilter = (payload) => {
  return { type: ACTION_TYPES.APP_SET_ORDERS_FILTER, payload: payload };
};

export const setHomeData = (payload) => {
  return { type: ACTION_TYPES.APP_SET_HOME_DATA, payload: payload };
};

export const setBlogData = (payload) => {
  return { type: ACTION_TYPES.APP_SET_BLOG_DATA, payload: payload };
};

export const setPickedUserIdForRewards = (payload) => {
  return { type: ACTION_TYPES.APP_SET_REWARDS_PICKED_USER, payload: payload };
};

export const setAddress =
  ({ coordinates, address }) =>
  async (dispatch) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        Storage.setLastCoordinates(coordinates);
        await dispatch({
          type: ACTION_TYPES.APP_APPLY_LOCATION,
          payload: {
            coordinates,
            address
          }
        });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

export const getAddresses = () => (dispatch) => {
  return new Promise((resolve) => {
    apiFactory.get('addresses').then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Address', {
          action_category: 'Retrieve address details',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        dispatch({
          type: ACTION_TYPES.APP_SET_ADDRESSES,
          payload: data.addresses || []
        });
        resolve(data.addresses);
      },
      () => resolve([])
    );
  });
};

export const setLocallyAddresses = (addresses) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: ACTION_TYPES.APP_SET_ADDRESSES,
      payload: addresses || []
    });
    resolve(addresses);
  });
};

export const saveAddress = (address) => {
  return new Promise((resolve, reject) => {
    if (address.id) {
      apiFactory.put(`addresses/${address.id}`, address).then(() => {
        identifyDevice();
        trackEvent('Address', {
          action_category: 'Retrieve address details',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        resolve();
      }, reject);
    } else {
      apiFactory.post('addresses', address).then(async () => {
        identifyDevice();
        trackEvent('Address', {
          action_category: 'Create Address',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        resolve();
      }, reject);
    }
  });
};

export const addDefaultAddress = (address) => () => {
  return new Promise((resolve, reject) => {
    apiFactory.post('addresses/default', address).then(async () => {
      identifyDevice();
      trackEvent('Address', {
        action_category: 'Create Address',
        action_outcome: 'success',
        interaction_element: 'button clicking',
        source: 'sf_web'
      });
      resolve();
    }, reject);
  });
};

export const deleteAddress = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiFactory.delete(`addresses/${id}`).then(() => {
      identifyDevice();
      trackEvent('Address', {
        action_category: 'Delete Address',
        action_outcome: 'success',
        interaction_element: 'button clicking',
        source: 'sf_web'
      });
      dispatch({
        type: ACTION_TYPES.APP_DELETED_ADDRESS,
        payload: id
      });
      resolve();
    }, reject);
  });
};

export const setAppLang = (language) => (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    setLanguage(language);
    await dispatch({ type: ACTION_TYPES.APP_SET_LANGE, payload: language });
    resolve();
  });
};

export const getFriends = (status, searchTerm, filter_ids, online_payment) => {
  return new Promise((resolve) => {
    apiFactory
      .post('users/friends', {
        status: status,
        name: searchTerm == '' ? null : searchTerm,
        filter_ids: filter_ids,
        online_payment: online_payment
      })
      .then(
        ({ data }) => {
          resolve(data.friends);
        },
        () => resolve([])
      );
  });
};

export const setInvitationsPageData = (payload) => {
  return { type: ACTION_TYPES.APP_SET_INVITATIONS_PAGE_DATA, payload: payload };
};

export const setEarnInviteChatInfo = (value) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.APP_SET_INVITE_CHAT_INFO,
    payload: value
  });
};

export const setInvitationPickedUser = (value) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.APP_SET_INVITE_PICKED_USER,
    payload: value
  });
};

export const setReferralsRewards = (value) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.APP_SET_REFERRALS_REWARDS,
    payload: value
  });
};

export const getRewardsSetting = () => async (dispatch) => {
  return new Promise((resolve) => {
    apiFactory
      .get(`invite-earn/get-rewards-setting`)
      .then(async ({ data }) => {
        await dispatch({
          type: ACTION_TYPES.APP_SET_INVITATION_REWARDS,
          payload: data.rewards || []
        });
        resolve();
      })
      .catch((err) => {
        console.log('getRewardsSetting err ', err);
        dispatch({
          type: ACTION_TYPES.APP_SET_INVITATION_REWARDS,
          payload: []
        });
        resolve();
      });
  });
};

export const getReferralsRewardsSetting = () => async (dispatch) => {
  return new Promise((resolve) => {
    apiFactory.get(`invite-earn/get-referrals-setting`).then(
      async ({ data }) => {
        console.log('getReferralsRewardsSetting ', data);
        await dispatch({
          type: ACTION_TYPES.APP_SET_REFERRALS_REWARDS,
          payload: {
            cycle_hours_earn_invitation: data.cycle_hours_earn_invitation,
            exp_days_earn_invitation: data.exp_days_earn_invitation,
            user_rewards: data.inviter_earning_amount,
            register_rewards: data.register_earning_amount,
            max_num_referral: data.max_num_referral,
            show_referral_module: data.show_referral_module,
            show_earn_invitation_module: data.show_earn_invitation_module,
            limit_user_referrals: data.limit_user_referrals,
            earninvite_friend_message: data.earninvite_friend_message,
            earninvite_timer_popup_message: data.earninvite_timer_popup_message,
            referral_code_share_message: data.referral_code_share_message,
            referral_description_message: data.referral_description_message,

            earninvitation_howto_works: data.earninvitation_howto_works,
            referral_howto_works_title: data.referral_howto_works_title,
            referral_howto_works_subtitle_1: data.referral_howto_works_subtitle_1,
            referral_howto_works_desc_1: data.referral_howto_works_desc_1,
            referral_howto_works_subtitle_2: data.referral_howto_works_subtitle_2,
            referral_howto_works_desc_2: data.referral_howto_works_desc_2
          }
        });
        resolve();
      },
      async (err) => {
        console.log('get Referrals Rewards Setting ', err);
        await dispatch({
          type: ACTION_TYPES.APP_SET_REFERRALS_REWARDS,
          payload: {}
        });
        resolve();
      }
    );
  });
};

export const loadInvitationTimerSetting = () => async (dispatch) => {
  return new Promise((resolve) => {
    apiFactory.post(`/invite-earn/check-earninvitation`).then(
      async ({ data }) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Check Earn Invitation',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        let showTimer = false;
        if (
          data.can_invite == false &&
          data.remaining_time_to_use != null &&
          data.remaining_time_to_use > 0
        ) {
          showTimer = true;
        }

        await dispatch({
          type: ACTION_TYPES.APP_SET_INVITATION_TIMER_SETTING,
          payload: {
            can_invite: data.can_invite,
            showTimer: showTimer,
            total_invite_time: data.total_invite_time,
            remaining_time_to_use: data.remaining_time_to_use,
            last_invite_time: data.last_invite_time,
            last_invite_time_diff: data.last_invite_time_diff
          }
        });
        resolve();
      },
      async (err) => {
        console.log('load Invitation Timer Setting ', err);
        await dispatch({
          type: ACTION_TYPES.APP_SET_INVITATION_TIMER_SETTING,
          payload: {}
        });
        resolve();
      }
    );
  });
};

export const setInvitationTimerSetting = (payload) => async (dispatch, getState) => {
  let invitationTimerSetting = getState().app.invitationTimerSetting || {};
  await dispatch({
    type: ACTION_TYPES.APP_SET_INVITATION_TIMER_SETTING,
    payload: {
      ...invitationTimerSetting,
      ...payload
    }
  });
};

export const getFoodCategories =
  (params = []) =>
  (dispatch) => {
    return new Promise((resolve) => {
      apiFactory.get(`vendors/food-categories?${params.join('&')}`).then(
        ({ data }) => {
          identifyDevice();
          trackEvent('FoodCategories', {
            action_category: 'Retrieve Food Categories',
            action_outcome: 'success',
            interaction_element: 'Screen',
            source: 'sf_web'
          });
          let formattedFoodCategories = [];
          if (data.food_categories && data.food_categories.length >= 0) {
            formattedFoodCategories = data.food_categories.map(
              ({ icon, id, search_count, title_en, title_sq, image }) => ({
                id,
                icon,
                title_en,
                title_sq,
                image,
                search_count,
                selected: false
              })
            );
          }
          dispatch({
            type: ACTION_TYPES.APP_SET_FOOD_CATEGORIES,
            payload: formattedFoodCategories
          });
          resolve(true);
        },
        (error) => {
          console.log('load food categories ', error);
          resolve(false);
        }
      );
    });
  };

export const setShowWhereHeardFeedbackModal = (payload) => (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (payload == true) {
      let hide_feedback_modal = false;
      try {
        hide_feedback_modal =
          (await Storage.getStorageKey(Storage.KEYS.HIDE_WHERE_HEARD_MODAL_SHOW)) || false;
      } catch (e) {
        console.log(e);
      }
      if (hide_feedback_modal == false) {
        let feedback_tags = [];
        try {
          let res = await apiFactory.get(`feedback/tags`);
          if (res.data && res.data.feedback_tags) {
            feedback_tags = res.data.feedback_tags || [];
          }
        } catch (e) {
          console.log(e);
        }

        if (feedback_tags.length == 0) {
          resolve();
          return;
        }

        await dispatch({ type: ACTION_TYPES.APP_SET_APP_FEEDBACK_TAGS, payload: feedback_tags });

        let cnt = 0;
        try {
          cnt = (await Storage.getStorageKey(Storage.KEYS.WHERE_HEARD_MODAL_SHOW_CNT)) || 0;
        } catch (e) {
          console.log(e);
        }

        cnt = cnt + 1;

        console.log('cnt ', cnt);
        if (cnt >= 8) {
          await dispatch({
            type: ACTION_TYPES.APP_SET_SHOW_WHERE_HEARD_FEEDBACK_MODAL,
            payload: true
          });
          cnt = 0;
        }

        try {
          await Storage.setStorageKey(Storage.KEYS.WHERE_HEARD_MODAL_SHOW_CNT, cnt);
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      await Storage.setStorageKey(Storage.KEYS.HIDE_WHERE_HEARD_MODAL_SHOW, true);
      await dispatch({
        type: ACTION_TYPES.APP_SET_SHOW_WHERE_HEARD_FEEDBACK_MODAL,
        payload: false
      });
    }
    resolve();
  });
};
