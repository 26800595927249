import React, { useState, useRef, useCallback, useEffect } from 'react';
import { GiftedChat } from '../../Messages/react-web-gifted-chat';
import { connect } from 'react-redux';
import { convertTimestamp2Date, getImageFullURL } from '../../../utils/common';
import {
  channel_collection,
  getChannelData,
  sendMessage,
  setLike,
  uploadImage,
  uploadAudio,
  seenUnreadCntChannel,
  deleteGroupChannel,
  exitGroupChannel,
  updateLastMessageOnChannel,
  deleteMessage,
  ORDER_SUPPORT_CHANNEL,
  MSG_CHANNEL
} from '../../../services/chat';
import {
  goActiveScreenFromPush,
  setEarnInviteChatInfo,
  setShowAppSnackbar
} from '../../../store/actions/app';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// import { Theme } from '../../../../assets';
import EmojiPickerModal from '../../Modals/EmojiPicker';
import ImgGalleryModal from '../../Modals/ImgGalleryModal';
import LocationMsgOptionsModal from '../../Modals/LocationMsgOptions';
// import AudioInputView from '../components/AudioInputView';
import ConfirmModal from '../../Modals/ConfirmModal';
import MessagesHeader from '../../Messages/MessagesHeader';
import { renderInputToolbar, renderComposer, renderSend } from '../../Messages/InputToolbar';
import { renderBubble, renderMessage, renderSystemMessage } from '../../Messages/MessageContainer';
import PropTypes from 'prop-types';
// import {
//   checkLocationPermission,
//   requestLocationPermission
// } from '../../../common/services/location';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import { checkLocationPermission, getCurrentLocation } from '../../../services/location';
import LoadingModal from '../../Modals/LoadingModal';
import AudioInputView from '../../Messages/AudioInput';
import apiFactory from '../../../services/apiFactory';
import { ROLE_CUSTOMER } from '../../../constants/config';
import LocationPickupModal from '../../Modals/LocationPickupModal';
import CameraModal from '../../Modals/CameraModal';
import LocationMsgModal from '../../Modals/LocationMsgModal';
import { KEYS } from '../../../services/storage';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const PerPage = 12;
const MessagesScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { channel_id } = useParams();
  channel_id = props.channel_id ? props.channel_id : channel_id;

  const channel_name = props.is_order_support === true ? ORDER_SUPPORT_CHANNEL : MSG_CHANNEL;

  const file_input = useRef(null);

  const [isCameraOn, setIsCameraOn] = useState(false);

  const [imageUploading, setImageUploading] = useState(false);

  const [prevLoading, setPrevLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [isMuted, SetMuteGroup] = useState(false);
  const [isDeleteGroupModal, ShowDeleteGroupModal] = useState(false);
  const [isExitGroupModal, ShowExitGroupModal] = useState(false);
  const [isShareLocModal, ShowShareModal] = useState(false);
  const [isPickLocModal, ShowPickLocModal] = useState(false);
  const [isLocMsgModal, ShowLocMsgModal] = useState(false);
  const [showEmoji, setShowEMoji] = useState(false);
  const [isGalleryModal, ShowGalleryModal] = useState(false);
  const [modal_imgs, setModalImages] = useState([]);

  const [channelData, setChannelData] = useState(null);

  const [text, setText] = useState(props.invitationChatInfo?.defaultMsg || '');
  const [messages, setMessages] = useState([]);
  const [quote_msg, setQuoteMsg] = useState(null);
  const [images, setImages] = useState(null);
  const [isRecording, setRecording] = useState(false);

  const locMsgCoord = useRef({ latitude: null, longitude: null });

  const msgs_unlistener = useRef(null);
  const systemMsg = {
    _id: 1,
    text: '',
    createdAt: new Date(),
    system: true
  };

  React.useEffect(() => {
    if (msgs_unlistener.current != null) {
      msgs_unlistener.current();
    }
    loadChannelData().then();
    const messages_coll = channel_collection(channel_name)
      .doc(channel_id)
      .collection('messages')
      .limit(PerPage)
      .orderBy('created_time', 'desc');
    msgs_unlistener.current = messages_coll.onSnapshot((querySnapshot) => {
      let msgs = [];
      querySnapshot.docs.forEach((doc) => {
        if (doc.exists) {
          msgs.push({
            id: doc.data()._id,
            ...doc.data(),
            createdAt: convertTimestamp2Date(doc.data().createdAt),
            fbDate: doc.data().createdAt
          });
        }
      });
      setMessages(msgs);
      if (msgs.length >= PerPage) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    });

    return () => {
      if (msgs_unlistener.current != null) {
        msgs_unlistener.current();
      }
      props.goActiveScreenFromPush({
        isChatVisible: false
      });
    };
  }, [channel_id]);

  useEffect(() => {
    setText(props.invitationChatInfo?.defaultMsg || '');
    return () => {
      props.setEarnInviteChatInfo(null);
    };
  }, []);

  const loadChannelData = async () => {
    let channel = await getChannelData(channel_name, channel_id);
    setChannelData(channel);

    await seenUnreadCntChannel(channel_name, channel, props.user.id);
  };

  const loadPrevMessage = () => {
    if (prevLoading || hasMore === false || messages.length === 0) {
      return;
    }
    let start = messages[messages.length - 1].created_time;
    if (start == null) {
      return;
    }
    const messages_coll = channel_collection(channel_name)
      .doc(channel_id)
      .collection('messages')
      .orderBy('created_time', 'desc')
      .limit(PerPage)
      .startAfter(start);

    setPrevLoading(true);
    messages_coll
      .get()
      .then((snaps) => {
        identifyDevice();
        trackEvent('Load chats', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'screen',
          source: 'sf_web'
        });
        let msgs = [];
        snaps.docs.forEach((doc) => {
          if (doc.exists) {
            msgs.push({
              ...doc.data(),
              createdAt: convertTimestamp2Date(doc.data().createdAt),
              fbDate: doc.data().createdAt
            });
          }
        });
        if (msgs.length > 0) {
          let tmpMsgs = messages.slice(0, messages.length);
          msgs.map((msg) => {
            tmpMsgs.push(msg);
          });
          setMessages(tmpMsgs);
        }
        setPrevLoading(false);
        if (msgs.length > 0) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch(() => {
        setPrevLoading(false);
        setHasMore(false);
      });
  };

  const onSend = async (newMessages = []) => {
    if (quote_msg != null) {
      newMessages.map((msg, index) => {
        newMessages[index].reply = quote_msg;
      });
      setQuoteMsg(null);
    }
    if (images != null && images.length > 0) {
      setImageUploading(true);
      let imageUrls = [];
      for (var i = 0; i < images.length; i++) {
        if (images[i] != null && images[i] != null) {
          try {
            let res = await uploadImage(images[i].split(',')[1]);
            if (res != null && res.data != null && res.data.success === true) {
              imageUrls.push(res.data.url);
            }
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
      }
      setImages(null);

      if (imageUrls.length === 0) {
        return;
      } else {
        newMessages.map((msg, index) => {
          newMessages[index].images = imageUrls;
        });
      }
    }

    //////////////
    if (props.invitationChatInfo != null && props.invitationChatInfo.invitation_code != null) {
      newMessages.map((msg, index) => {
        newMessages[index].invitation_code = props.invitationChatInfo.invitation_code;
      });
      props.setEarnInviteChatInfo(null);
    }
    /////////////

    for (let i = 0; i < newMessages.length; i++) {
      await sendMessage(channel_name, channelData.id, props.user.id, newMessages[i]);
    }
    setImageUploading(false);
  };

  const sendLocation = (location, type = 0) => {
    let newMsg = {
      user: {
        _id: props.user.id,
        username: props.user.username,
        full_name: props.user.full_name,
        photo: props.user.photo,
        phone: props.user.phone,
        email: props.user.email
      },
      map: {
        coords: {
          latitude: location.latitude,
          longitude: location.longitude
        },
        type: type // 0 : my location, 1 : a location
      }
    };
    onSend([newMsg]);
  };
  const addCurrentLocation = async () => {
    ShowShareModal(false);
    try {
      let hasPermission = await checkLocationPermission();
      if (hasPermission) {
        const location = await getCurrentLocation();
        if (location) {
          sendLocation(location);
        }
      } else {
        confirmAlert({
          title: t('attention'),
          message: t('locationUnavailable'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    } catch (error) {
      confirmAlert({
        title: t('attention'),
        message: t('locationUnavailable'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onSendAudio = async (currentTime, fileSize, base64, type) => {
    try {
      let res = await uploadAudio(base64, type);
      if (res != null && res.data != null && res.data.success == true) {
        let newMsg = {
          user: {
            _id: props.user.id,
            username: props.user.username,
            full_name: props.user.full_name,
            photo: props.user.photo,
            phone: props.user.phone,
            email: props.user.email
          },
          audio: {
            url: res.data.url,
            duration: currentTime,
            fileSize: fileSize,
            playing: false,
            position: 0
          }
        };
        onSend([newMsg]);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setRecording(false);
  };

  const goFindLocation = () => {
    ShowShareModal(false);
    ShowPickLocModal(true);
  };

  const onPressLocation = () => {
    ShowShareModal(true);
  };

  const onSelectEmoji = (emojiObj) => {
    setShowEMoji(false);
    setText((text) => text.concat(emojiObj.emoji));
  };

  const onPressEmoji = () => {
    // Keyboard.dismiss();
    setTimeout(() => {
      setShowEMoji(true);
    }, 100);
  };

  const onImageUpload = () => {
    if (file_input.current != null) {
      file_input.current.click();
    }
  };

  const onImageCapture = () => {
    setIsCameraOn(true);
  };

  const onRecord = () => {
    setRecording(true);
  };

  const onCapture = (imageData) => {
    if (imageData == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageData.blob);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImages([reader.result]);
      }
    };

    setIsCameraOn(false);
  };

  const onError = useCallback(() => {
    confirmAlert({
      title: '',
      message: t('account_details.no_camera'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t('Ok'),
          className: 'error-ok-btn',
          onClick: () => {
            setIsCameraOn(false);
            // navigate(-1);
          }
        }
      ]
    });
  }, []);

  const onHandleGalleryImgs = ({ target }) => {
    let tmp_images = [];
    const reader = new FileReader();

    function readFile(index) {
      if (index >= target.files.length) {
        setImages(tmp_images);
        return;
      }
      const file = target.files[index];
      reader.onload = function (e) {
        // get file content
        tmp_images.push(e.target.result);
        // do sth with bin
        readFile(index + 1);
      };
      reader.readAsDataURL(file);
    }

    readFile(0);
  };

  const onDeleteGroup = async () => {
    let ret = await deleteGroupChannel(channelData.id);
    ShowDeleteGroupModal(true);
    if (ret === true) {
      navigate(-1);
    } else {
      confirmAlert({
        title: t('alerts.error'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onExitGroup = async () => {
    if (channelData.users.length > 1) {
      let ret = await exitGroupChannel(channelData, props.user.id);
      ShowExitGroupModal(false);
      if (ret === true) {
        let channel = await getChannelData(channel_name, channelData.id);
        setChannelData(channel);
      } else {
        confirmAlert({
          title: t('alerts.error'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    } else {
      ShowExitGroupModal(false);
      await onDeleteGroup();
    }
  };

  const increaseTagCount = (tag_id) => {
    apiFactory
      .post(`orders/increase-clicks-tags`, {
        tag_id: tag_id
      })
      .then(
        () => {
          identifyDevice();
          trackEvent('order Service', {
            action_category: 'Increase clicks tags',
            action_outcome: 'success',
            interaction_element: 'screen',
            source: 'sf_web'
          });
        },
        () => {}
      );
  };

  const onCancelQuote = () => {
    setQuoteMsg(null);
  };

  const onRemoveImage = (index) => {
    let tmp_imgs = images.slice(0, images.length);
    tmp_imgs.splice(index, 1);
    setImages(tmp_imgs.length === 0 ? null : tmp_imgs);
  };

  const onLongPressMessage = () => {
    // if (currentMessage && currentMessage.text) {
    //   setQuoteMsg(currentMessage);
    // }
  };

  const onPressMsg = async (currentMessage) => {
    // Keyboard.dismiss();
    if (
      currentMessage &&
      currentMessage.text &&
      currentMessage.user?._id != props.user.id &&
      currentMessage.invitation_code != null
    ) {
      try {
        let res = await apiFactory.post('/invite-earn/earninvitation', {
          invitation_code: currentMessage.invitation_code
        });

        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Invite Friends',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });

        if (res.data && res.data.invition) {
          let invition_data = res.data.invition;
          if (invition_data.is_used == 0 && invition_data.is_expired != 1) {
            try {
              navigator.clipboard.writeText(currentMessage.invitation_code);
            } catch (e) {
              console.log(e);
            }

            localStorage.setItem(KEYS.INVITE_CODE, currentMessage.invitation_code);
            props.setShowAppSnackbar({ visible: true, description: t('code_complete') });
            navigate('/');
          } else if (invition_data.is_used == 0 && invition_data.is_expired == 1) {
            props.setShowAppSnackbar({
              visible: true,
              description: t('invitation_earn.invitation_expired')
            });
          } else if (invition_data.is_used == 1) {
            props.setShowAppSnackbar({
              visible: true,
              description: t('invitation_earn.invitation_used')
            });
          } else if (invition_data.is_used == 2) {
            props.setShowAppSnackbar({
              visible: true,
              description: t('invitation_earn.invitation_using')
            });
          }
        }
        return;
      } catch (error) {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Invite Friends',
          action_outcome: 'fail',
          interaction_element: 'button',
          source: 'sf_web'
        });
      }
    }

    if (
      currentMessage &&
      currentMessage.map &&
      currentMessage.map.coords &&
      currentMessage.map.coords.latitude &&
      currentMessage.map.coords.longitude
    ) {
      if (locMsgCoord.current) {
        locMsgCoord.current.latitude = currentMessage.map.coords.latitude;
        locMsgCoord.current.longitude = currentMessage.map.coords.longitude;
        ShowLocMsgModal(true);
      }
    }
  };

  const onShowGalleryMsgs = (images) => {
    if (images.length > 0) {
      setModalImages(images);
      ShowGalleryModal(true);
    }
  };

  const onDoublePress = (currentMessage) => {
    let likes = currentMessage.likes || [];
    const foundIndex = likes.findIndex((i) => i == props.user.id);
    if (foundIndex == -1) {
      setLike(channelData, props.user.id, currentMessage, onLikeSuccess);
    }
    console.log('onDouble Press');
  };

  const onLikeChange = (message) => {
    setLike(channelData, props.user.id, message, onLikeSuccess);
  };

  const onLikeSuccess = (msgId, likes) => {
    let msgs = messages.slice(0);
    const index = msgs.findIndex((i) => i._id == msgId);
    if (index != -1) {
      msgs[index] = {
        ...msgs[index],
        createdAt: convertTimestamp2Date(msgs[index].fbDate),
        likes: likes
      };
      setMessages(msgs);
    }
  };

  const onPopupPress = (message, type) => {
    if (message && message.text && type == 'reply') {
      // const options = ['Copy Text', 'Quote Message', 'Cancel'];;
      setQuoteMsg(message);
    } else if (type == 'forward') {
      // props.navigation.navigate(RouteNames.ForwardScreen, { channelData: channelData, message: message });
    } else if (type == 'unsend') {
      try {
        if (messages.length >= 1) {
          if (messages[0]._id == message._id) {
            let newLastMsg = null;
            if (messages.length >= 2) {
              newLastMsg = messages[1];
            }
            updateLastMessageOnChannel(channelData.id, newLastMsg);
          }
        }

        deleteMessage(channelData.id, message._id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onCopyPress = (onCopyChanged) => {
    console.log('on Copy changed', onCopyChanged);
    // setShowSnackBar(onCopyChanged);
  };

  const renderEmptyInputToolbar = () => (
    <div className={'no-members-txt'}>{t('social.chat.no_longer_member')}</div>
  );

  const renderBottomInputbar = (giftchat_props) => {
    if (channelData != null && channelData.users.findIndex((i) => i === props.user.id) === -1) {
      return renderEmptyInputToolbar();
    } else if (isRecording) {
      return (
        <AudioInputView
          onRemove={() => {
            setRecording(false);
          }}
          onSend={onSendAudio}
        />
      );
    }
    return renderInputToolbar(
      giftchat_props,
      quote_msg,
      images,
      props.is_order_support === true ? props.order_msg_tags : [],
      onCancelQuote,
      onRemoveImage,
      (msg) => {
        let newMsg = {
          user: {
            _id: props.user.id,
            username: props.user.username,
            full_name: props.user.full_name,
            photo: props.user.photo,
            avatar: getImageFullURL(props.user.photo),
            phone: props.user.phone,
            email: props.user.email,
            role: ROLE_CUSTOMER
          },
          text: msg.title
        };
        onSend([newMsg]);
        increaseTagCount(msg.id);
      }
    );
  };

  const isCloseToTop = (event) => {
    return event.target.scrollTop === 0;
  };

  const isCloseToBottom = (event) => {
    const node = event.target;
    return node.scrollHeight - node.scrollTop > node.clientHeight - 20;
  };

  const onGoBack = async () => {
    if (channelData != null) {
      await seenUnreadCntChannel(channel_name, channelData, props.user.id);
    }
    navigate(-1);
  };
  console.log('props.user.id ', props.user.id);
  return (
    <div className={'messages-screen'}>
      <GiftedChat
        messages={messages.length === 0 ? [systemMsg] : messages}
        text={text}
        onInputTextChanged={(_text) => {
          setText(_text);
        }}
        onSend={onSend}
        user={{
          _id: props.user.id,
          username: props.user.username,
          full_name: props.user.full_name,
          photo: props.user.photo,
          phone: props.user.phone,
          email: props.user.email
        }}
        minInputToolbarHeight={100}
        alwaysShowSend={true}
        showUserAvatar={false}
        // disableForward={props.is_order_support == true}
        disableForward={true}
        renderUsernameOnMessage={true}
        textInputAutoFocus={false}
        renderLoading={() => (
          <div
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: 12
            }}>
            <CircularProgress size={50} />
          </div>
        )}
        listViewProps={{
          ListFooterComponent: () => {
            return (
              <div
                className={'align-col-middle'}
                style={{ minHeight: props.is_order_support === true ? '30px' : '90px' }}>
                <div
                  style={{
                    height: props.is_order_support === true ? 30 : 90,
                    minHeight: props.is_order_support === true ? '30px' : '90px',
                    backgroundColor: '#fff'
                  }}
                />
                {prevLoading && (
                  <div
                    style={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingVertical: 12
                    }}>
                    <CircularProgress size={20} />
                  </div>
                )}
              </div>
            );
          },
          ListHeaderComponent: () => {
            return <div style={{ height: 30, minHeight: '30px', backgroundColor: '#fff' }} />;
          },
          onScroll: (event) => {
            // eslint-disable-next-line no-empty
            if (isCloseToTop(event)) {
            }
            if (isCloseToBottom(event)) {
              loadPrevMessage();
            }
          },
          keyboardShouldPersistTaps: 'handled'
        }}
        renderInputToolbar={renderBottomInputbar}
        renderSend={(props) => renderSend(props)}
        renderComposer={(props) =>
          renderComposer(
            props,
            onPressEmoji,
            onPressLocation,
            onImageUpload,
            onImageCapture,
            onRecord
          )
        }
        renderMessage={renderMessage}
        renderBubble={(props) =>
          renderBubble(
            props,
            channelData != null && channelData.channel_type != 'single',
            onLongPressMessage,
            onPressMsg,
            onDoublePress,
            onShowGalleryMsgs,
            onLikeChange,
            onPopupPress,
            onCopyPress
          )
        }
        // renderAvatar={null}
        alignTop={props.is_order_support === true}
        // scrollToBottom={260}
        bottomOffset={0}
        renderSystemMessage={props.is_order_support === true ? renderSystemMessage : () => null}
        // renderAvatarOnTop
        // renderActions={renderActions}
        // renderMessageImage
        // renderCustomView={renderCustomView}
        // isCustomViewBottom
        renderFooter={() => (
          <div>
            <div />
          </div>
        )}
        loadEarlier={true}
        renderLoadEarlier={() => (
          <div
            className={'align-col-middle'}
            style={{ minHeight: props.is_order_support === true ? '30px' : '90px' }}>
            <div
              style={{
                height: props.is_order_support === true ? 30 : 90,
                minHeight: props.is_order_support === true ? '30px' : '90px',
                backgroundColor: '#fff'
              }}
            />
            {prevLoading && (
              <div
                style={{ justifyContent: 'flex-end', alignItems: 'center', paddingVertical: 12 }}>
                <CircularProgress size={20} />
              </div>
            )}
          </div>
        )}
        parsePatterns={(linkStyle) => [
          {
            pattern: /#(\w+)/,
            style: linkStyle,
            onPress: () => {}
          },
          {
            pattern: /kod: \w+/g,
            style: linkStyle
          },
          {
            pattern: /kod \w+/g,
            style: linkStyle
          }
        ]}
      />
      {!props.is_order_support && (
        <MessagesHeader
          isMuted={isMuted}
          channel_id={channelData == null ? null : channelData.id}
          data={channelData}
          user_id={props.user.id}
          onBack={() => {
            onGoBack();
          }}
          onCall={() => {}}
          onDelete={() => {
            ShowDeleteGroupModal(true);
          }}
          onExit={() => {
            ShowExitGroupModal(true);
          }}
          onMute={() => {
            SetMuteGroup(!isMuted);
          }}
          onGallery={() => {
            navigate(-1);
          }}
          onPressName={() => {
            if (channelData == null) {
              return;
            }
            let user_id = props.user.id;
            if (channelData.channel_type === 'single') {
              if (user_id === channelData.creator.id) {
                navigate(`/social/snapfoodies/${channelData.partner.id}`);
              } else if (user_id === channelData.partner.id) {
                navigate(`/social/snapfoodies/${channelData.creator.id}`);
              }
            } else {
              // group
            }
          }}
        />
      )}

      <LocationMsgOptionsModal
        isOpen={isShareLocModal}
        onCurrentLoc={addCurrentLocation}
        onPickMap={goFindLocation}
        onClose={() => ShowShareModal(false)}
      />
      <LocationPickupModal
        isOpen={isPickLocModal}
        onSendLocation={(location) => sendLocation(location, 1)}
        onClose={() => ShowPickLocModal(false)}
      />
      {isLocMsgModal && (
        <LocationMsgModal
          isOpen={isLocMsgModal}
          latitude={locMsgCoord.current.latitude}
          longitude={locMsgCoord.current.longitude}
          onClose={() => ShowLocMsgModal(false)}
        />
      )}
      <ConfirmModal
        showModal={isDeleteGroupModal}
        title={t('group_related.confirm_del_group')}
        yes={t('group_related.confirm_del_group_yes')}
        no={t('group_related.confirm_del_group_no')}
        onYes={onDeleteGroup}
        onClose={() => ShowDeleteGroupModal(false)}
      />
      <ConfirmModal
        showModal={isExitGroupModal}
        title={t('group_related.confirm_exit_group')}
        yes={t('group_related.confirm_exit_group_yes')}
        no={t('group_related.confirm_exit_group_no')}
        onYes={onExitGroup}
        onClose={() => ShowExitGroupModal(false)}
      />
      <EmojiPickerModal
        isOpen={showEmoji}
        onClose={() => {
          setShowEMoji(false);
        }}
        onPickEmoji={onSelectEmoji}
      />
      <ImgGalleryModal
        images={modal_imgs}
        showModal={isGalleryModal}
        onClose={() => ShowGalleryModal(false)}
      />
      <CameraModal
        isOpen={isCameraOn}
        onClose={() => {
          setIsCameraOn(false);
        }}
        onCapture={onCapture}
        onError={onError}
      />
      <input
        accept="image/jpeg/png"
        ref={file_input}
        hidden={true}
        type="file"
        multiple={true}
        onChange={onHandleGalleryImgs}
      />
      <LoadingModal showModal={imageUploading} />
    </div>
  );
};

MessagesScreen.propTypes = {
  channel_id: PropTypes.string,
  is_order_support: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  invitationChatInfo: {
    defaultMsg: PropTypes.string,
    invitation_code: PropTypes.string
  },
  order_msg_tags: PropTypes.array,
  style: PropTypes.object,
  goActiveScreenFromPush: PropTypes.func,
  setEarnInviteChatInfo: PropTypes.func,
  setShowAppSnackbar: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  coordinates: app.coordinates,
  order_msg_tags: app.order_msg_tags || [],
  user: app.user,
  invitationChatInfo: app.invitationChatInfo
});

export default connect(mapStateToProps, {
  goActiveScreenFromPush,
  setEarnInviteChatInfo,
  setShowAppSnackbar
})(MessagesScreen);
