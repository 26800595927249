import React from 'react';
import Seo from '../../../components/Seo';
import { useTranslation } from 'react-i18next';

const CourierSupport = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="view-courier-support" className={'view-courier-support'}>
      <Seo title={t('web_title.courier_support')} />
      <h1>courier-support</h1>
    </div>
  );
};

export default CourierSupport;
