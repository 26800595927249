import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import Svg_active from '../../../assets/svgs/checkout/ic_active.svg';
import Svg_inactive from '../../../assets/svgs/checkout/ic_inactive.svg';

const Step = ({ item, step, onClick }) => {
  return (
    <div
      className={
        'align-middle step mt2 ' +
        (item.id <= step ? 'active' : 'inactive') +
        (item.id > 0 ? ' flex_1' : '')
      }
      onClick={() => {
        if (item.id <= step) {
          onClick();
        }
      }}>
      {item.id > 0 && <div className={'divider flex_1'} />}
      <img src={item.id <= step ? Svg_active : Svg_inactive} />
      <div className={'label'}>{item.label}</div>
    </div>
  );
};

const Stepper = ({ step, setStep }) => {
  const { t } = useTranslation();
  const MENUS = [
    { id: 0, label: t('checkout.order_delivery_info') },
    { id: 1, label: t('checkout.payment_method') },
    { id: 2, label: t('checkout.order_confirm') }
  ];

  return (
    <div className={'align-row-start checkout-stepper ph2 flex_wrap'}>
      {MENUS.map((item) => (
        <Step
          key={item.id}
          item={item}
          step={step}
          onClick={() => {
            setStep(item.id);
          }}
        />
      ))}
    </div>
  );
};

Step.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string
  }),
  step: PropTypes.number,
  onClick: PropTypes.func
};

Stepper.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.step === nextProps.step;
}

export default React.memo(Stepper, arePropsEqual);
