import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Svg_delivery from '../../assets/svgs/partner/delivery.svg';
import Svg_revenue from '../../assets/svgs/partner/revenue.svg';
import Svg_riskfree from '../../assets/svgs/partner/riskfree.svg';
import './Features.css';

const Features = () => {
  const { t } = useTranslation();
  return (
    <div className='features pv10'>
      <h3>{t('partner.features_title')}</h3>
      <Grid container spacing={{ xs: 6, md: 4 }} className={'align-middle'} >
        <Grid item xs={12} md={4} style={{}}>
          <div className={'features-item'}>
            <img src={Svg_revenue} />
            <h5>{t('partner.more_revenue_title')}</h5>
            <p className={'ph3'}>{t('partner.more_revenue_desc')}</p>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{}}>
            <div className={'features-item'}>
              <img src={Svg_riskfree} />
              <h5>{t('partner.risk_free_title')}</h5>
              <p className={'ph3'}>{t('partner.risk_free_desc')}</p>
            </div>
        </Grid>
        <Grid item xs={12} md={4} style={{}}>
            <div className={'features-item'}>
              <img src={Svg_delivery} />
              <h5>{t('partner.wanna_deliver')}</h5>
              <p className={'ph3'}>{t('partner.wanna_deliver_desc')}</p>
            </div>
        </Grid>
      </Grid>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(Features, arePropsEqual);
