import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { QRCode } from 'react-qrcode-logo';
import './index.css';
import '../index.css'
import { connect } from 'react-redux';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import Img_logo from '../../../assets/images/logo_circle.png';

const GetAppModal = (props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const referralLink = '"https://reward.snapfood.al/referral/?link=https://snapfood.al/reward?apn=com.snapfood.app&isi=1314003561&ibi=com.snapfood.al"'

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open} className={'align-col-middle w100 modal get-app-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'align-col-middle w100 main'}>
          <div className='align-middle w100'>
            <h2 className='flex_1'>{t('get_app.title')}</h2>
            <img src={Svg_close} className={'close-btn'} onClick={() => onClose()} />
          </div>
          <h4>{t('get_app.scan_qr')}</h4>
          <QRCode value={referralLink} size={180} quietZone={8} removeQrCodeBehindLogo={true} qrStyle={'dots'} logoImage={Img_logo} />
          <p>{t('get_app.checkout_appstore')}</p>
        </div>
        <div className={'w100 align-col-middle ph2'}>
          <div className="align-middle badge-wrap">
            <a
              href="https://apps.apple.com/al/app/snapfood-food-friends/id1314003561"
              rel={'noreferrer'}
              target="_blank"
              className="badge badge-ios"
            />
            <a
              href="https://play.google.com/store/apps/details?id=com.snapfood.app&amp;hl=en"
              target="_blank"
              rel={'noreferrer'}
              className="badge badge-android"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

GetAppModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  coordinates: app.coordinates,
  tmp_new_address: app.tmp_new_address
});

export default connect(mapStateToProps, {})(GetAppModal);
