import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import { VendorType_Restaurant } from '../../../constants/config';
import {
  setVendorCart,
  AddProduct2Cart,
  AddProductVendorCheck,
  removeProductFromCart,
  updateCartItems
} from '../../../store/actions/shop';
import { setTmpFood } from '../../../store/actions/app';
// import { toggleFavourite } from '../../../store/actions/vendors';
import { FoodItem, GroceryFoodItem, FoodScreen } from '../../../components/Vendor';
import PropTypes from 'prop-types';
import { Row } from 'react-grid-system';

const VendorFoodList = (props) => {
  const { t } = useTranslation();

  const { vendorData, cartItems } = props;
  const [openFood, setOpenFood] = useState(false);

  useEffect(() => {
    onProductFavChange(props.tmpFoodData);
  }, [props.tmpFoodData.isFav]);

  const goFoodDetail = (food_data) => {
    props.setTmpFood(food_data);
    setOpenFood(true);
  };

  const onProductFavChange = (data) => {
    const { categories } = vendorData;
    if (categories && categories.length && categories.length > 0) {
      let tmp = categories.slice(0, categories.length);
      let cat_index = tmp.findIndex((i) => i.id == data.category_id);
      if (cat_index !== -1) {
        if (
          tmp[cat_index].products &&
          tmp[cat_index].products.length &&
          tmp[cat_index].products.length > 0
        ) {
          let product_index = tmp[cat_index].products.findIndex((i) => i.id === data.id);
          if (product_index !== -1) {
            tmp[cat_index].products[product_index].isFav = data.isFav;

            props.setVendorCart({ ...vendorData, categories: tmp });
          }
        }
      }
    }
  };

  const onPressAddCart = (product) => {
    props
      .AddProductVendorCheck(product)
      .then((available) => {
        if (available) {
          onAddCart(product);
        } else {
          confirmAlert({
            title: t('restaurant_details.new_order_question'),
            message: t('restaurant_details.new_order_text'),
            closeOnEscape: false,
            closeOnClickOutside: false,
            buttons: [
              {
                label: t('confirm'),
                onClick: () => onConfirmReset(product)
              },
              {
                label: t('cancel'),
                onClick: () => {
                }
              }
            ]
          });
        }
      })
      .catch(() => {
      });
  };

  const onConfirmReset = async (product) => {
    let cartItem = { ...product };
    cartItem.quantity = 1;
    cartItem.comments = '';
    cartItem.options = [];

    await props.updateCartItems([cartItem]);
  };

  const onAddCart = (product) => {
    let foundIndex = props.cartItems.findIndex((i) => i.id === product.id);
    if (foundIndex === -1) {
      let cartItem = { ...product };
      cartItem.quantity = 1;
      cartItem.comments = '';
      cartItem.options = [];

      props.AddProduct2Cart(cartItem);
    } else {
      let cartItem = props.cartItems[foundIndex];
      cartItem.quantity = cartItem.quantity + 1;

      props.AddProduct2Cart(cartItem);
    }
  };

  const onRmvCart = async (product) => {
    try {
      await props.removeProductFromCart(product);
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
  };

  const _renderVertFoods = (curCat) => {
    return vendorData.vendor_type === VendorType_Restaurant ? (
      <Row className={' food-list'}>
        {curCat.products != null &&
        curCat.products.length != null &&
        curCat.products.map((food) => (
          <FoodItem
            key={food.id}
            id={food.id}
            isFav={food.isFav}
            isLike={food.isLike}
            data={food}
            cartEnabled={true}
            cartCnt={getCartCnt(food)}
            onLike={() => {
            }}
            onFavChange={onProductFavChange}
            onClick={() => goFoodDetail(food)}
          />
        ))}
      </Row>
    ) : (
      <Row className={' food-list'}>
        {curCat.products != null &&
        curCat.products.length != null &&
        curCat.products.map((item, index) => (
          <GroceryFoodItem
            key={index}
            style={{ width: '100%' }}
            disabled={vendorData.is_open !== 1}
            data={item}
            id={item.id}
            food_id={item.id}
            isFav={item.isFav}
            cartCnt={getCartCnt(item)}
            onAddCart={onPressAddCart}
            onRmvCart={onRmvCart}
            onFavChange={onProductFavChange}
            onClick={() => goFoodDetail(item)}
          />
        ))}
      </Row>
    );
  };

  const _renderMenuCategory = (item, index) => {
    return (
      <div className={'category'} id={`vendor-food-category-${index}`}>
        {item.title}
      </div>
    );
  };

  const getCartCnt = (food_data) => {
    let foundIndex = cartItems.findIndex((i) => i.id === food_data.id);
    if (foundIndex !== -1) {
      return cartItems[foundIndex].quantity;
    }
    return 0;
  };

  if (vendorData == null || vendorData.categories == null) {
    return null;
  }

  return (
    <div style={{ marginTop: 16, marginBottom: 80 }} className={'w100 vendor-food-list ph2'}>
      {vendorData.categories.map((category, index) => {
        return (
          <React.Fragment key={category.id}>
            {_renderMenuCategory(category, index)}
            {_renderVertFoods(category)}
          </React.Fragment>
        );
      })}
      <FoodScreen isOpen={openFood} onClose={() => setOpenFood(false)} />
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  tmpFoodData: app.tmpFoodData,
  vendorData: shop.vendorData,
  isLoggedIn: app.isLoggedIn,
  cartItems: shop.items
});

VendorFoodList.propTypes = {
  cartItems: PropTypes.array,
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    categories: PropTypes.array,
    vendor_type: PropTypes.string,
    is_open: PropTypes.number,
    price: PropTypes.number,
    discount: PropTypes.number
  }),
  tmpFoodData: PropTypes.shape({
    isFav: PropTypes.number
  }),
  AddProductVendorCheck: PropTypes.func,
  setVendorCart: PropTypes.func,
  AddProduct2Cart: PropTypes.func,
  removeProductFromCart: PropTypes.func,
  updateCartItems: PropTypes.func,
  setTmpFood: PropTypes.func,
  onFavChange: PropTypes.func
};

export default connect(mapStateToProps, {
  setVendorCart,
  // toggleFavourite,
  setTmpFood,
  AddProduct2Cart,
  AddProductVendorCheck,
  updateCartItems,
  removeProductFromCart
})(VendorFoodList);
