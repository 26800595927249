import React from 'react';
import { Theme } from '../../../assets';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CommonTooltip from '../../Common/CommonTooltip';
import './index.css';
import Svg_bike from '../../../assets/svgs/vendor/bike.svg';
import Svg_snapfood from '../../../assets/svgs/vendor/snapfood.svg';

const DeliveryType = ({ type, vendor_type }) => {
  const { t } = useTranslation();

  return (
    type === 'Snapfood' ? (
      <div className={'row_center mt2'} style={{ flexWrap: 'nowrap' }}>
        <img src={Svg_snapfood} />
        <CommonTooltip
          anchor={t('vendor_profile.snapfood_delivery')}
          anchorStyle={{
            marginTop: 8,
            marginBottom: 8,
            paddingLeft: 4,
            fontSize: 15,
            fontFamily: Theme.fonts.semiBold,
            color: Theme.colors.text,
            textDecorationLine: 'underline',
            textDecorationColor: Theme.colors.text
          }}
          title={t('tooltip.what_means')}
          description={t('tooltip.snapfood_delivery_desc')}
        />
      </div>
    ) : (
      type === 'Restaurant' ?
      <div className={'row_center mt2'} style={{ flexWrap: 'nowrap' }}>
        <img src={Svg_bike} />
        <CommonTooltip
          anchor={t('vendor_profile.store_delivery_' + vendor_type)}
          anchorStyle={{
            marginTop: 8,
            marginBottom: 8,
            paddingLeft: 4,
            fontSize: 15,
            fontFamily: Theme.fonts.semiBold,
            color: Theme.colors.text,
            textDecorationLine: 'underline',
            textDecorationColor: Theme.colors.text
          }}
          title={t('tooltip.what_means_' + vendor_type)}
          description={t('tooltip.own_delivery_desc_' + vendor_type)}
        />
      </div>
        : null
    )
  );
};

DeliveryType.propTypes = {
  type: PropTypes.string,
  vendor_type: PropTypes.string
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.type === nextProps.type && prevProps.vendor_type === nextProps.vendor_type;
}

export default React.memo(DeliveryType, arePropsEqual);
