import React, { useMemo } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import './index.css';
import '../index.css'
import ImageCapture from 'react-image-data-capture';
import Svg_close from '../../../assets/svgs/modals/close.svg';

const CameraModal = ({ isOpen, onClose, onCapture, onError }) => {
  const [open, setOpen] = React.useState(false);
  const config = useMemo(() => ({ video: true }), []);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal camera-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div className={'w100 pos_relative pl2 pt2'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => onClose()} />
        </div>
        <ImageCapture
          onCapture={onCapture}
          onError={onError}
          width={520}
          userMediaConfig={config}
        />
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    backgroundColor: Theme.colors.black
  }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}
CameraModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCapture: PropTypes.func,
  onError: PropTypes.func
};
export default React.memo(CameraModal, arePropsEqual);
