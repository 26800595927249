import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from '../../../../components/Inputs';
import { Search } from '@styled-icons/evil';
import UserItem from '../../../../components/Chats/UserItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import './index.css';
import { Theme } from '../../../../assets';
import { getFriends, setInvitationPickedUser } from '../../../../store/actions/app';
import PropTypes from 'prop-types';
import RoundIconBtn from '../../../../components/Buttons/RoundIconBtn';
import { ChevronLeft } from '@mui/icons-material';

const EarnInviteFriends = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: null,
    friends: []
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAllFriends();
  }, []);

  const getAllFriends = (searchTerm) => {
    setState({ ...state, loading: true });

    getFriends('accepted', searchTerm)
      .then((data) => {
        setState({ ...state, loading: false, friends: data });
      })
      .catch(() => {
        setState({ ...state, loading: false });
      });
  };

  return (
    <div style={{ flex: 1 }} className={'earninvite-friends-screen'}>
      <div className={'align-middle header-view'}>
        <RoundIconBtn
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={'title flex_1'}>{t('invitation_earn.invite_friend')}</div>
        <div style={{ width: 32 }} />
      </div>
      <div className={'align-col-middle w100 list'}>
        <div className={'align-middle searchview'}>
          <SearchInput
            placeholder={t('social.search.friends')}
            icon={<Search size={20} color={Theme.colors.gray5} />}
            style={{ flex: 1, height: 45 }}
            value={searchTerm}
            onChange={(text) => {
              setSearchTerm(text);
              getAllFriends(text);
            }}
          />
        </div>
        <div className={'scrollview'}>
          {state.loading === false && state.friends.length === 0 ? (
            <NoFriendList title={t('social.no_friends')} desc={t('social.no_friends_desc')} />
          ) : (
            state.friends.map((item) => (
              <UserItem
                key={item.id}
                item={item}
                id={item.id}
                onClick={() => {
                  props.setInvitationPickedUser({ ...item, isFriend: true });
                  navigate(-1);
                }}
              />
            ))
          )}
          <div style={{ height: 20 }} />
        </div>
      </div>
    </div>
  );
};


EarnInviteFriends.propTypes = {
  setInvitationPickedUser: PropTypes.func
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setInvitationPickedUser })(EarnInviteFriends);
