import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { setTmpLocationPicked } from '../../../store/actions/app';
import { useTranslation } from 'react-i18next';
import { AuthInput } from '../../../components/Inputs';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css'
import AuthBtn from '../../Buttons/AuthBtn';
import Svg_close from '../../../assets/svgs/modals/close.svg';

const AddressLabelModal = (props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [address_label, setLabel] = useState('');

  React.useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const onSave = () => {
    if (address_label.length > 0) {
      props.setTmpLocationPicked({
        ...props.tmp_new_address,
        address_type: address_label,
      });
      props.onClose()
    }
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal address-label-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h4 className={'title'}>{t('address.custom_label')}</h4>
        </div>
        <div className={'scrollview'}>
          <AuthInput
            placeholder={t('address.custom_label')}
            placeholderTextColor={'#DFDFDF'}
            onChange={(val) => setLabel(val)}
            text={address_label}
            style={{ marginBottom: 20 }}
          />
        </div>
        <div className={'flex_1 align-col-middle w100 mt2 actions'}>
          <AuthBtn
            onClick={onSave}
            className={'save-btn'}
            title={t('save')}
          />
          <div className='align-col-middle w100 mt2 cancel-btn' onClick={() => props.onClose()}>
            {t('cancel')}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

AddressLabelModal.propTypes = {
  isOpen: PropTypes.bool,
  tmp_new_address: PropTypes.object,
  setTmpLocationPicked: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  tmp_new_address: app.tmp_new_address,
});

export default connect(mapStateToProps, {
  setTmpLocationPicked
})(AddressLabelModal);
