import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImgGalleryModal = ({ showModal, images, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const [photoIndex, setIndex] = React.useState(0);

  React.useEffect(() => {
    setOpen(showModal);
    setIndex(0);
  }, [showModal]);

  if (open) {
    return (
      <Lightbox
        mainSrc={images[photoIndex]}
        nextSrc={images[(photoIndex + 1) % images.length]}
        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
        onCloseRequest={onClose}
        onMovePrevRequest={() => setIndex((photoIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setIndex((photoIndex + 1) % images.length)}
      />
    );
  }

  return null;
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal;
}

ImgGalleryModal.propTypes = {
  showModal: PropTypes.bool,
  images: PropTypes.array,
  onClose: PropTypes.func
};
export default React.memo(ImgGalleryModal, arePropsEqual);
