import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AuthBtn } from '../Buttons';
import './index.css';
import Svg_close from '../../assets/svgs/modals/back.svg';
import { TextSelector } from '../DropdownSelectors';
import { CommentInput } from '../Inputs';
import LocationInput from '../Inputs/LocationInput';
import { connect } from 'react-redux';
import {
  deleteAddress,
  getAddresses,
  saveAddress,
  setLocallyAddresses,
  setTmpLocationPicked
} from '../../store/actions/app';
import { ROUTES_NAMES } from '../../constants';
import { confirmAlert } from 'react-confirm-alert';
import AddressLabelModal from './AddressLabelModal'

const NewAddressModal = (props) => {
  const { isOpen } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [isCustomLabelModal, showCustomLabelModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [address_text, setAddressText] = useState('');
  const _tmp_new_address = useRef(props.tmp_new_address)

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    let text = props.tmp_new_address.street || '';
    if (props.tmp_new_address.city != null && props.tmp_new_address.city !== '') {
      text = `${text} ${props.tmp_new_address.city}`;
    }
    if (props.tmp_new_address.country != null && props.tmp_new_address.country !== '') {
      text = `${text}, ${props.tmp_new_address.country}`;
    }
    setAddressText(text);
  }, [props.tmp_new_address.street, props.tmp_new_address.city, props.tmp_new_address.country]);

  useEffect(() => {
    _tmp_new_address.current = props.tmp_new_address;
  }, [props.tmp_new_address]);

  const onFindonMap = () => {
    navigate(ROUTES_NAMES.checkout + '?modal=find-on-map');
  };

  const onBack = () => {
    navigate(-1);
  };

  const onSaveAddress = async () => {
    if (props.tmp_new_address == null || props.tmp_new_address.coords == null) {
      return confirmAlert({
        title: t('attention'),
        message: t('add_new_address.please_enter_address'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    let lat = props.tmp_new_address.coords.latitude || props.coordinates.latitude;
    let lng = props.tmp_new_address.coords.longitude || props.coordinates.longitude;

    let country = props.tmp_new_address.country || props.address.country;
    let city = props.tmp_new_address.city || props.address.city;

    let new_address = {
      id: props.tmp_new_address.id,
      address_type: props.tmp_new_address.address_type || 'Home',
      notes: props.tmp_new_address.notes,
      lat: lat,
      lng: lng,
      country: country,
      city: city,
      street: props.tmp_new_address.street || city,
      building: props.tmp_new_address.building,
      floor: props.tmp_new_address.floor,
      apartment: props.tmp_new_address.apartment,
      favourite: 1
    };

    try {
      setLoading(true);
      await saveAddress(new_address);
      await props.getAddresses();
      setLoading(false);
      navigate(-1);
      navigate(-1);
    } catch (error) {
      setLoading(false);
    }
  };

  console.log('address_text ', address_text);

  return (
    <Dialog open={open} className={'align-col-middle w100 modal add-address-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={onBack} />
          <h4 className={'title'}>{t('address.new_address')}</h4>
        </div>
        <div className={'w100 align-col-middle mt2 form'}>
          <div className={'row'}>
            <div className={'flex_1 label'}>{t('address.address_name')}</div>
            <div className={'flex_1 input-wrapper'}>
              <TextSelector
                values={['home', 'work', 'custom']}
                value={props.tmp_new_address.address_type || 'home'}
                handleChange={(text) => {
                  if (text == 'custom') {
                    showCustomLabelModal(true)
                  }
                  else {
                    props.setTmpLocationPicked({
                      ..._tmp_new_address.current,
                      address_type: text
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={'w100 align-col-start mt20'}>
            <div style={{ width: '100%', paddingBottom: 20 }}>
              <div className={'label'} style={{ marginBottom: 12 }}>
                {t('address_new.street')}
              </div>
              <LocationInput
                defaultInput={address_text}
                placeholder={t('address_new.search_location')}
                onChange={(loc) => {
                  let tmpAddress = {
                    coords: {
                      latitude: loc.latitude,
                      longitude: loc.longitude
                    },
                    street: loc.street,
                    building: loc.building,
                    country: loc.country,
                    city: loc.city
                  };
                  props.setTmpLocationPicked({
                    ..._tmp_new_address.current,
                    ...tmpAddress
                  });
                }}
              />
              <div style={{ height: 12 }} />
              <div className={'label'} style={{ marginBottom: 12 }}>
                {t('address_new.note')}
              </div>
              <CommentInput
                placeholder={t('address_new.note_placeholder')}
                text={props.tmp_new_address.notes}
                onChange={(text) => {
                  props.setTmpLocationPicked({
                    ..._tmp_new_address.current,
                    notes: text
                  });
                }}
              />
            </div>
            <p className={'find-btn'} onClick={onFindonMap}>
              {(props.tmp_new_address.street || '') !== '' ||
              (props.tmp_new_address.building || '') !== ''
                ? t('address_new.relocate_on_map')
                : t('address_new.find_on_map')}
            </p>
          </div>
        </div>
        <div className={'flex_1 align-col-middle w100 mt20 actions'}>
          <AuthBtn
            isDisabled={loading}
            isLoading={loading}
            title={t('address.save_address')}
            onClick={onSaveAddress}
            className={'save-btn'}
          />
        </div>
      </div>
      <AddressLabelModal 
        isOpen={isCustomLabelModal}
        onClose={()=>showCustomLabelModal(false)}
      />
    </Dialog>
  );
};

NewAddressModal.propTypes = {
  address: PropTypes.shape({
    country: PropTypes.string,
    city: PropTypes.string
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  tmp_new_address: PropTypes.shape({
    address_type: PropTypes.string,
    notes: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number,
    floor: PropTypes.string,
    apartment: PropTypes.string,
    coords: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    })
  }),

  getAddresses: PropTypes.func,
  setTmpLocationPicked: PropTypes.func,
  updateProfileDetails: PropTypes.func,

  isOpen: PropTypes.bool,
  onBack: PropTypes.func,
  onFindonMap: PropTypes.func,
  onSave: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  tmp_new_address: app.tmp_new_address,
  coordinates: app.coordinates,
  address: app.address
});

export default connect(mapStateToProps, {
  getAddresses,
  deleteAddress,
  setTmpLocationPicked,
  setLocallyAddresses
})(NewAddressModal);
