import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import './index.css';
import '../index.css'
import AuthBtn from '../../Buttons/AuthBtn';

const EarnInvitationModal = ({ isOpen, name, onClose, onSend }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal earn-invitation-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div className={'flex_between'}>
          <div style={styles.modalTitle}>{t('invitation_earn.send_invitation_to')}</div>
          <div style={styles.modalBtnTxt} onClick={onClose}>{t('cancel')}</div>
        </div>
        <div className={'name'}>
          {name}
        </div>
        <AuthBtn
          title={t('invitation_earn.send_invitation')}
          onClick={onSend}
          style={{ width: '100%' }}
        />
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
    paddingTop: 20,
    backgroundColor: Theme.colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalTitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.text,
    marginBottom: 12
  },
  modalBtnTxt: {
    flex: 1,
    marginLeft: 8,
    fontSize: 16,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text,
    cursor: 'pointer'
  },
  divider: { width: '100%', height: 1, backgroundColor: Theme.colors.gray9 }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}

EarnInvitationModal.propTypes = {
  isOpen: PropTypes.bool,
  name : PropTypes.string,
  onClose: PropTypes.func,
  onSend: PropTypes.func
};

export default React.memo(EarnInvitationModal, arePropsEqual);
