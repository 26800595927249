import React from 'react';
import { Breadcrumbs, IconButton, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import { ROUTES_NAMES } from '../../../constants';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Theme } from '../../../assets';
import { getFoodCategoryIcon } from '../../Home/PopularMenus/CategItem';
import { isEmpty } from '../../../utils/common';
import { Close } from '@styled-icons/evaicons-solid';

const Breadcrumb = (props) => {
  const { language, curCategory, formatted_address, clearAddress } = props;
  const { t } = useTranslation();
  return (
    <div className={'align-row-start breadcrumb'}>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        <Link
          underline="hover"
          href={ROUTES_NAMES.categories}
          style={{ color: Theme.colors.gray7 }}>
          {t('vendors_list.categories')}
        </Link>
        <Link underline="hover" color="inherit" href={ROUTES_NAMES.categories}>
          {curCategory.id && (
            <div className={'align-middle'}>
              <img src={getFoodCategoryIcon(curCategory)} />
              <span>{language == 'en' ? curCategory.title_en : curCategory.title_sq}</span>
            </div>
          )}
        </Link>
        {!isEmpty(formatted_address) && (
          <Link underline="hover" color="inherit" href={'#'}>
            <div className={'align-middle address-tag'}>
              <div>{formatted_address}</div>
              <IconButton
                style={{ padding: 4 }}
                onClick={() => {
                  clearAddress();
                }}>
                <Close size={22} color={Theme.colors.text} />
              </IconButton>
            </div>
          </Link>
        )}
      </Breadcrumbs>
    </div>
  );
};

Breadcrumb.propTypes = {
  language: PropTypes.func,
  curCategory: PropTypes.shape({
    id: PropTypes.number,
    title_en: PropTypes.string,
    title_sq: PropTypes.string
  }),
  formatted_address: PropTypes.string,
  clearAddress: PropTypes.func
};

export default Breadcrumb;
