import React, { useState, useRef } from 'react';
import { Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import './index.css';
import { sendOrder } from '../../../store/actions/shop';
import { AuthBtn } from '../../../components/Buttons';
import { CommentInput } from '../../../components/Inputs';
import { clearCart, setCommentCart } from '../../../store/actions/shop';
import { OrderType_Delivery, OrderType_Reserve } from '../../../constants/config';
import OrderSummInfo from '../../../components/Order/OrderSummInfo';
import { Config } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { OrderFailedModal } from '../../../components/Modals';

const PlaceOrderStep = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paypal_dropin_instance = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isOrderFailedModal, showOrderFailedModal] = useState(false);
  const [OrderFailedMessage, setOrderFailedMessage] = useState(t('cart.order_failed'));

  const doPay = async () => {
    const { method } = props.payment_info;
    if (method === 'paypal') {
      if (paypal_dropin_instance.current != null) {
        try {
          const { nonce } = await paypal_dropin_instance.current.requestPaymentMethod();
          finalizeCheckout(nonce);
        } catch (error) {
          if (error.message != null) {
            confirmAlert({
              title: t('alerts.error'),
              message: error.message,
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: t('Ok'),
                  className: 'error-ok-btn',
                  onClick: () => {}
                }
              ]
            });
          } else {
            confirmAlert({
              title: t('alerts.error'),
              message: t('checkout.something_is_wrong'),
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: t('Ok'),
                  className: 'error-ok-btn',
                  onClick: () => {}
                }
              ]
            });
          }
        }
      }
    } else if (method === 'apple') {
      // doApplepay();
      // show apple pay modal
      // if (applePaymentRequest) {
      //   applePaymentRequest.show();
      // }
    } else {
      finalizeCheckout();
    }
  };

  const orderBilled = () => {
    let splits = props.payment_info.splits || [];
    if (splits.length === 0) {
      return false;
    }

    let isBilled = true;
    splits.map((item) => {
      if (item.price == null) {
        isBilled = false;
      }
    });
    return isBilled;
  };

  const getOrderData = (paypal_nonce) => {
    // show loading
    const { items, vendorData, cutlery, coupon, comments } = props.order_data;
    let cartProducts = items.filter((i) => i.vendor_id == vendorData.id);
    const products = cartProducts.map((item) => {
      return {
        id: item.id,
        qty: item.quantity,
        options: item.options && item.options.length > 0 ? item.options.map((x) => x.id) : [],
        item_instructions: item.comments
      };
    });

    const {
      address,
      handover_method,
      contactless_delivery,
      tip_rider,
      pickup_date,
      pickup_time,
      num_guests,
      reserve_for,
      is_schedule,
      schedule_time
    } = props.delivery_info;
    const { cashback, promo_code } = props.cartPrice;
    const { method, selected_card, splits } = props.payment_info;

    let orderData = {
      vendor_id: vendorData.id,
      products,
      order_note: comments,
      has_cutlery: cutlery > 0 ? 1 : 0,
      cutlery: cutlery,
      source: Config.PLATFORM,
      coupon_code: coupon.code,
      repeated: 0,
      handover_method: handover_method === 'Pickup at store' ? 'Pickup' : handover_method,
      delivery_instruction: props.delivery_info.comments,
      cashback: cashback,
      payment_method: method,
      contactless_delivery: contactless_delivery ? 1 : 0,
      is_schedule: is_schedule === 1 ? 1 : 0,
      schedule_time: is_schedule === 1 ? schedule_time : null
    };

    if (promo_code != null && promo_code.trim() != '') {
      orderData.promo_code = promo_code;
    }

    if (handover_method == OrderType_Reserve) {
      orderData.reserve_for = reserve_for.id;
      orderData.num_guests = parseInt(num_guests) == 0 ? 1 : parseInt(num_guests);
    }

    if (handover_method != OrderType_Delivery) {
      orderData.order_note = '';
      orderData.pickup_date = pickup_date;
      orderData.pickup_time = pickup_time;
    } else {
      orderData.address_id = address.id;

      if (
        props.payment_info.method != 'cash' &&
        vendorData != null &&
        vendorData.delivery_type == 'Snapfood' &&
        tip_rider > 0
      ) {
        orderData.tip_rider = parseInt(tip_rider);
      } else {
        orderData.tip_rider = 0;
      }
    }

    if (method === 'stripe') {
      if (selected_card == null) {
        confirmAlert({
          title: t('warning'),
          message: t('cart.select_card'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
        return null;
      } else {
        orderData.payment_method_id = selected_card.id;
      }
    }

    if (method === 'paypal') {
      orderData.paypal_nonce = paypal_nonce;
    }

    if (orderBilled() === true) {
      orderData.splits = splits.map((item) => {
        return {
          person_id: item.id,
          amount: item.price,
          person_name: item.username || item.full_name
        };
      });
    }

    return orderData;
  };

  const getOrderDescription = () => {
    const { items, vendorData, coupon, comments } = props.order_data;
    const { handover_method, tip_rider } = props.delivery_info;
    const { subtotal, discount, cashback, small_order_fee, delivery_fee, order_total } =
      props.cartPrice;
    const { method } = props.payment_info;

    let orderData = {
      total_price: order_total,
      sub_total: subtotal,
      discount: discount,
      cashback: cashback,
      small_order_fee: small_order_fee,
      delivery_fee: delivery_fee,
      tip_rider: tip_rider,
      vendor: vendorData,
      products: items,
      order_note: comments,
      coupon_code: coupon.code,

      handover_method: handover_method === 'Pickup at store' ? 'Pickup' : handover_method,
      delivery_instruction: props.delivery_info.comments,
      payment_method: method
    };

    return orderData;
  };

  const finalizeCheckout = async (paypal_nonce) => {
    console.log('finalizeCheckout');
    let orderData = getOrderData(paypal_nonce);
    if (orderData == null) {
      return;
    }

    setLoading(true);
    sendOrder(orderData).then(
      (order) => {
        props.clearCart([]);
        navigate(`/order/${order.id}?is_new=true`, { replace: true });
        setLoading(false);
      },
      (error) => {
        console.log(error.message);
        setLoading(false);
        setOrderFailedMessage(error.message ? error.message : t('cart.order_failed'));
        showOrderFailedModal(true);
      }
    );
  };

  // const handlePaymentMethodReceived = async (event) => {
  //   // const paymentDetails = {
  //   //   payment_method: event.paymentMethod.id,
  //   //   shipping: {
  //   //     name: event.shippingAddress.recipient,
  //   //     phone: event.shippingAddress.phone,
  //   //     address: {
  //   //       line1: event.shippingAddress.addressLine[0],
  //   //       city: event.shippingAddress.city,
  //   //       postal_code: event.shippingAddress.postalCode,
  //   //       state: event.shippingAddress.region,
  //   //       country: event.shippingAddress.country
  //   //     }
  //   //   }
  //   // };
  //
  //   let orderData = getOrderData();
  //
  //   setLoading(true);
  //   apiFactory
  //     .post('checkout/get-applepay-client-secret', orderData)
  //     .then(async ({ data }) => {
  //       const { error, paymentIntent } = await stripe.confirmCardPayment(data.client_secret, {
  //         payment_method: event.paymentMethod.id
  //       });
  //
  //       setLoading(false);
  //       if (error) {
  //         return;
  //       }
  //       if (paymentIntent.status === 'succeeded') {
  //         finalizeCheckout();
  //       } else {
  //         console.warn(`Unexpected status: ${paymentIntent.status} for ${paymentIntent}`);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       setOrderFailedMessage(error.message ? error.message : t('cart.order_failed'));
  //       showOrderFailedModal(true);
  //     });
  // };

  return (
    <div className={'align-col-middle mt5'} >
      <Col xs={12} lg={7} className={'ph4'}>
        <OrderSummInfo order={getOrderDescription()} />
        <div className={'mt3 mb3'}>
          <div className={'subject-title fs4 mb2'}>{t('cart.comment')}</div>
          <CommentInput
            placeholder={t('cart.add_comment')}
            text={props.order_data.comments}
            onChange={(text) => {
              props.setCommentCart(text);
            }}
          />
        </div>
        <AuthBtn
          isLoading={loading}
          isDisabled={loading}
          className={'proceed-btn'}
          title={t('cart.confirm_order')}
          onClick={doPay}
        />
      </Col>
      <OrderFailedModal
        showModal={isOrderFailedModal}
        title={OrderFailedMessage}
        onClose={() => {
          showOrderFailedModal(false);
        }}
        onTry={() => {
          showOrderFailedModal(false);
          doPay();
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates,
  order_data: shop,
  cartItems: shop.items || [],
  cutlery: shop.cutlery,
  coupon: shop.coupon,
  comments: shop.comments,
  cartPrice: shop.cartPrice,
  vendorData: shop.vendorData || {},
  delivery_info: shop.delivery_info,
  payment_info: shop.payment_info
});

PlaceOrderStep.propTypes = {
  outOfDeliveryArea: PropTypes.bool,
  cartItems: PropTypes.array,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    }),
    tip_rider: PropTypes.number,
    num_guests: PropTypes.number,
    contactless_delivery: PropTypes.bool,
    pickup_date: PropTypes.string,
    pickup_time: PropTypes.string,
    reserve_for: PropTypes.shape({
      id: PropTypes.number
    }),
    is_schedule: PropTypes.number,
    schedule_time: PropTypes.string
  }),
  order_data: PropTypes.shape({
    items: PropTypes.array,
    cutlery: PropTypes.number,
    coupon: PropTypes.object,
    comments: PropTypes.string,
    vendorData: PropTypes.shape({
      id: PropTypes.number,
      delivery_type: PropTypes.string,
      online_payment: PropTypes.number
    })
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    order_method: PropTypes.string,
    maximum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    categories: PropTypes.array,
    can_schedule: PropTypes.number
  }),
  cartPrice: PropTypes.shape({
    subtotal: PropTypes.number,
    small_order_fee: PropTypes.number,
    cashback: PropTypes.number,
    discount: PropTypes.number,
    delivery_fee: PropTypes.number,
    order_total: PropTypes.number,
    promo_code: PropTypes.string
  }),
  payment_info: PropTypes.object,
  cutlery: PropTypes.number,
  comments: PropTypes.string,
  user: PropTypes.object,
  setCommentCart: PropTypes.func,
  clearCart: PropTypes.func
};

export default connect(mapStateToProps, {
  clearCart,
  setCommentCart
})(PlaceOrderStep);
