import { t } from 'i18next';
import PizzaSeniSmall from '../../assets/images/vendor/pizzaSeni.png';
import PizzaSeniImg from '../../assets/images/vendor/menu_vendor.png';
import googleImg from '../../assets/images/vendor/google-review.png';

export const restorantArray = [
  {
    orderNow: `https://reward.snapfood.al/referral/?link=https://snapfood.al/reward?restaurantId=334&apn=com.snapfood.app&isi=1314003561&ibi=com.snapfood.al`,
    leaveReview: 'https://g.co/kgs/2kN3Scr',
    description: () => t('landing.grid_menu.restaurant.description'),
    imgSrc: PizzaSeniImg,
    imgSmall: PizzaSeniSmall,
    googleImg
  }
];
