import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AuthBtn } from '../../../../components/Buttons';
import { AuthInput } from '../../../../components/Inputs';
import CardView from '../../../../components/Profile/CardView';
import apiFactory from '../../../../services/apiFactory';
import { updateProfileDetails } from '../../../../store/actions/auth';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';

const NewCardScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [card_num, setCardNum] = useState('');
  const [cvv, setCvv] = useState('');
  const [expiry_month, setExpiryMonth] = useState('');
  const [expiry_year, setExpiryYear] = useState('');

  const inputExpiry = (value) => {
    value = value.replace('/', '');
    if (value.length > 4) {
      return;
    }
    setExpiryMonth(value.slice(0, 2));
    setExpiryYear(value.slice(2, 4));
  };

  const formatCardNum = (num) => {
    let formatted = '';
    for (let i = 0; i < num.length; i++) {
      if (i !== 0 && i % 4 === 0) {
        formatted = formatted + ' ';
      }
      formatted = formatted + num[i];
    }
    return formatted;
  };

  const _renderForm = () => {
    return (
      <div className={'w100 align-col-start mt3'}>
        <AuthInput
          text={card_num}
          onChange={(val) => {
            if (val.length <= 16) {
              setCardNum(val);
            }
          }}
          placeholder={t('card.number')}
          style={styles.marginB20}
        />
        <AuthInput
          text={name}
          onChange={(val) => setName(val)}
          placeholder={t('card.cardholder_name')}
          style={styles.marginB20}
        />
        <div className={'align-middle mb20 w100'}>
          <div style={{ flex: 1 }}>
            <AuthInput
              text={
                expiry_month !== '' || expiry_year !== '' ? expiry_month + '/' + expiry_year : ''
              }
              onChange={inputExpiry}
              placeholder={t('card.expiry_date')}
            />
          </div>
          <div style={{ width: 20 }} />
          <div style={{ flex: 1 }}>
            <AuthInput
              text={cvv}
              onChange={(val) => {
                if (val.length <= 3) {
                  setCvv(val);
                }
              }}
              placeholder="CVV"
            />
          </div>
        </div>
      </div>
    );
  };

  const validateCard = () => {
    if (name === '') {
      confirmAlert({
        title: t('alerts.error'),
        message: t('card.wrong_name'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    if (card_num.length !== 16) {
      confirmAlert({
        title: t('alerts.error'),
        message: t('card.wrong_number'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    if (cvv.length !== 3) {
      confirmAlert({
        title: t('alerts.error'),
        message: t('card.wrong_cvv'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }

    let year = parseInt(expiry_year);
    let month = parseInt(expiry_month);

    let this_year = new Date().getFullYear() - 2000;
    let this_month = new Date().getMonth() + 1;

    if (year < this_year || month <= 0 || month > 12) {
      confirmAlert({
        title: t('alerts.error'),
        message: t('card.wrong_expiry_date'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    if (year === this_year && month < this_month) {
      confirmAlert({
        title: t('alerts.error'),
        message: t('card.wrong_expiry_date'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
      return false;
    }
    return true;
  };

  const changePrimary = async (card) => {
    try {
      await props.updateProfileDetails({
        default_card_id: card.id
      });
      setLoading(false);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      navigate(-1);
    }
  };

  const onSaveCard = () => {
    if (validateCard() === false) {
      return;
    }
    setLoading(true);
    apiFactory
      .post(`stripe/payment-methods/create`, {
        name: name,
        number: card_num,
        cvc: cvv,
        exp_month: expiry_month,
        exp_year: expiry_year
      })
      .then(
        ({ data }) => {
          changePrimary(data.payment_method).then();
        },
        (error) => {
          setLoading(false);
          const message = error.message || t('generic_error');
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
        }
      );
  };

  return (
    <div className={'add-card-screen'}>
      <div className={'scrollview align-col-middle'}>
        <CardView
          cvv={cvv}
          expiry_month={expiry_month}
          expiry_year={expiry_year}
          card_num={formatCardNum(card_num)}
          name={name}
        />
        {_renderForm()}
      </div>
      <div className={'w100 ph-20 mt4'} style={{ marginBottom: 40 }}>
        <AuthBtn
          isLoading={loading}
          isDisabled={
            loading ||
            name === '' ||
            card_num === '' ||
            cvv === '' ||
            expiry_month === '' ||
            expiry_year === ''
          }
          title={t('card.save_card')}
          onClick={onSaveCard}
          className={'save-btn'}
        />
      </div>
    </div>
  );
};

const styles = {
  header: {
    height: 65,
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: 20,
    paddingRight: 20
  },
  marginB20: { marginBottom: 20 }
};

NewCardScreen.propTypes = {
  cvv: PropTypes.string,
  expiry_month: PropTypes.number,
  expiry_year: PropTypes.number,
  card_num: PropTypes.string,
  name: PropTypes.string,
  updateProfileDetails: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {
  updateProfileDetails
})(NewCardScreen);
