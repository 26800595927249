import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import { IconButton } from '@mui/material';
import { Search } from '@styled-icons/evil';
import Svg_curloc from '../../assets/svgs/markers/current_location.svg';
import './index.css';
import { Theme } from '../../assets';
import { Config } from '../../constants';
import { useTranslation } from 'react-i18next';
import { extractCityInfo } from '../../utils/common';
import {
  checkLocationPermission,
  getAddressByCoordinates,
  getCurrentLocation
} from '../../services/location';
import { confirmAlert } from 'react-confirm-alert';

const LocationInput = ({ defaultInput, placeholder, style, onChange, onChangeText }) => {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState('');

  useEffect(() => {
    setText(defaultInput);
  }, [defaultInput]);

  const _setCurrentLocation = async () => {
    try {
      let hasPermission = await checkLocationPermission();
      if (hasPermission) {
        const location = await getCurrentLocation();
        if (location) {
          const address = await getAddressByCoordinates(location);
          if (address) {
            onChange(address);
            setText(address.formatted_address || '');
          }
        }
      }
    } catch (error) {
      confirmAlert({
        title: t('attention'),
        message: t('locationUnavailable'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }
  };

  return (
    <div style={style} className='input location-input'>
      <div className={text != null && text.length > 0 ? 'active-icon' : 'icon'}>
        <Search size={20} color={Theme.colors.gray5} />
      </div>
      <Autocomplete
        apiKey={Config.GOOGLE_MAP_API_KEY}
        language={i18n.language}
        placeholder={placeholder}
        value={text}
        options={{
          types: ['geocode'],
          componentRestrictions: { country: ['al', 'xk'] }
        }}
        onChange={(e) => {
          setText(e.target.value);
          if (onChangeText) {
            onChangeText(e.target.value);
          }
        }}
        onPlaceSelected={(place) => {
          let data = extractCityInfo(place.address_components);

          onChange({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            formatted_address: place.formatted_address || '',
            street: data.street,
            building: data.building,
            country: data.country,
            city: data.city
          });
          setText(place.formatted_address || '');
        }}
      />
      <IconButton
        style={{ padding: 4 }}
        onClick={() => {
          _setCurrentLocation();
        }}>
        <img src={Svg_curloc} />
      </IconButton>
    </div>
  );
};

LocationInput.propTypes = {
  defaultInput: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func
};
export default LocationInput;
