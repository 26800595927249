import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const SnapfooderGroupMarker = ({count, onMarkerPress}) => {
  return (
    <div className={'align-col-middle map-marker snapfooder-group-marker'} onClick={onMarkerPress}>
      <div className={'marker-text'}>
        {count}
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.lat == nextProps.lat && prevProps.lng == nextProps.lng && prevProps.count == nextProps.count;
}

SnapfooderGroupMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  count : PropTypes.number,
  onMarkerPress : PropTypes.func
};
export default React.memo(SnapfooderGroupMarker, arePropsEqual);
