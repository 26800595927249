import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Svg_close from '../../assets/svgs/vendor/close.svg';
import { ChevronRight } from '@styled-icons/bootstrap';
import './index.css';
import { Theme } from '../../assets';

const SplitBillModal = ({ isOpen, onClose, onFriend, onSnapfooder }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open} className={'align-col-middle w100 modal split-bill-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={onClose} />
          <h1>{t('modals.split_bill_with')}</h1>
        </div>
        <div className={'align-col-middle w100 actions mt12'}>
          <div className={'w100 list-btn align-row-start'} onClick={onFriend}>
            <p>{t('modals.friends')}</p>
            <ChevronRight size={20} color={Theme.colors.text} />
          </div>
          <div className={'w100 list-btn align-row-start'} onClick={onSnapfooder}>
            <p>{t('modals.snapfood_user')}</p>
            <ChevronRight size={20} color={Theme.colors.text} />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}

SplitBillModal.propTypes = {
  isOpen: PropTypes.bool,
  description: PropTypes.string,
  onClose: PropTypes.func,
  onFriend: PropTypes.func,
  onSnapfooder: PropTypes.func
};
export default React.memo(SplitBillModal, arePropsEqual);
