import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardVoice, ChevronLeft } from '@styled-icons/material';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import { IconButton } from '@mui/material';
import Svg_send from '../../../assets/svgs/chats/send.svg';
import { seconds2Time } from '../../../utils/common';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import { confirmAlert } from 'react-confirm-alert';
// import AudioReactRecorder, { RecordState } from 'audio-react-recorder';

const AudioInputView = ({ onRemove, onSend }) => {
  const { t } = useTranslation();

  const statusRef = useRef('none');
  const TimerRef = useRef(null);

  const [currentTime, setTime] = useState(0);
  const curTimeRef = useRef(currentTime);
  const setCurrentTime = (time) => {
    curTimeRef.current = time;
    setTime(time);
  };

  const recorder = useRef(null);

  const stop = () => {
    try {
      if (recorder.current != null) {
        recorder.current.stop();
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  useEffect(() => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
      navigator.getUserMedia(
        {
          audio: true
        },
        streamHandler,
        errorHandler,
        []
      );
    } else {
      navigator.mediaDevices
        .getUserMedia({
          audio: true
        })
        .then(streamHandler)
        .catch(errorHandler);
    }
  }, []);

  const streamHandler = (stream) => {
    recorder.current = new MediaRecorder(stream);

    recorder.current.ondataavailable = (e) => {
      // Converting audio blob to base64
      let reader = new FileReader();
      reader.onloadend = () => {
        if (statusRef.current == 'send') {
          const base64Audio = reader.result;
          if (base64Audio != null && base64Audio.split(',').length > 1) {
            console.log('base64Audio.split(',')[0] ', base64Audio.split(',')[0])
            let type = ""
            if (base64Audio.split(',')[0].includes("audio/mp4")) { // safari
              type = ".mp4";
            }
            else {
              type = ".ogg";
            }
            onSend(curTimeRef.current, e.data.size, base64Audio.split(',')[1], type);
          } else {
            onRemove();
          }
        }
      };

      if (statusRef.current == 'send') {
        reader.readAsDataURL(e.data);
      }
    };

    recorder.current.start(); // Starting the record
    setTimer();
  };

  const errorHandler = () => {
    confirmAlert({
      title: t('attention'),
      message: t('audio_not_supported'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t('Ok'),
          className: 'error-ok-btn',
          onClick: () => {}
        }
      ]
    });
  };

  const setTimer = () => {
    TimerRef.current = setInterval(() => {
      setCurrentTime(curTimeRef.current + 1);
    }, 1000);
  };

  const clearTimer = () => {
    if (TimerRef.current != null) {
      clearInterval(TimerRef.current);
    }
  };

  const onPressSend = async () => {
    statusRef.current = 'send';
    clearTimer();
    stop();
  };

  const onPressCancel = async () => {
    statusRef.current = 'remove';
    stop();
    clearTimer();
    onRemove();
  };

  return (
    <div className={'w100 align-middle ph-20'} style={styles.container}>
      <Swiper
        onSlideChange={(swiperCore) => {
          const { activeIndex } = swiperCore;
          if (activeIndex == 1) {
            onPressCancel();
          }
        }}>
        <SwiperSlide>
          <div className={'align-middle flex_1'}>
            <KeyboardVoice size={30} color={Theme.colors.red1} />
            <div style={styles.elapsedtime}>{seconds2Time(currentTime)}</div>
            <div style={styles.cancelTxt}>Slide to cancel</div>
            <ChevronLeft size={22} color={Theme.colors.cyan2} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={'align-middle flex_1'} />
        </SwiperSlide>
      </Swiper>
      <IconButton style={{ padding: 0, marginLeft: 20 }} onClick={onPressSend}>
        <img src={Svg_send} />
      </IconButton>
    </div>
  );
};

const styles = {
  container: {
    height: 94,
    padding: 20,
    backgroundColor: Theme.colors.gray8
  },
  elapsedtime: {
    marginLeft: 16,
    flex: 1,
    fontSize: 18,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.text
  },
  cancelTxt: {
    marginRight: 5,
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.cyan2
  }
};

AudioInputView.propTypes = {
  onRemove: PropTypes.func,
  onSend: PropTypes.func
};

export default AudioInputView;
