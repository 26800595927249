import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import './index.css';
import { ChevronRight } from '@styled-icons/bootstrap';
import { Theme } from '../../assets';

const ListBtn = ({ className, title, iconSize = 20, style, onClick }) => {
  return (
    <Button
      variant="contained"
      className={'app-btn list-btn ' + className}
      style={style}
      onClick={onClick}>
      <div className={'flex_between flex_1'}>
        <div className={'btn-title'}>{title}</div>
        <ChevronRight size={iconSize} color={Theme.colors.text} />
      </div>
    </Button>
  );
};

ListBtn.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  iconSize: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func
};
export default React.memo(ListBtn);
