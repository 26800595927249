import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import apiFactory from '../../../../services/apiFactory';
import Theme from '../../../../assets/theme';
import {
  loadInvitationTimerSetting,
  setInvitationPickedUser,
  setShowAppSnackbar
} from '../../../../store/actions/app';
import {
  getHourMin,
  convertTimeString2Hours,
  ucFirst,
  minutes2Days
} from '../../../../utils/common';
import AuthBtn from '../../../../components/Buttons/AuthBtn';
import './index.css';
import CommonTooltip from '../../../../components/Common/CommonTooltip';
import PropTypes from 'prop-types';
import { KEYS } from '../../../../services/storage';
import Svg_img from '../../../../assets/svgs/invite/timer.svg';
import RoundIconBtn from '../../../../components/Buttons/RoundIconBtn';
import { ChevronLeft } from '@mui/icons-material';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';

const EarnIniviteDetailsScreen = (props) => {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { earninvitation_id } = useParams();

  const [invitationData, setInvitationData] = useState({});

  useEffect(() => {
    _isMounted.current = true;
    loadData(earninvitation_id);
    return () => {
      _isMounted.current = false;
    };
  }, [earninvitation_id]);

  const loadData = (invitation_id) => {
    if (invitation_id == null) {
      return;
    }
    apiFactory
      .post('/invite-earn/earninvitation', {
        invitation_id: invitation_id
      })
      .then(({ data }) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Send Earn Invitation',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        if (_isMounted.current == true) {
          setInvitationData(data.invition || {});
        }
      })
      .catch((err) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Send Earn Invitation',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        if (_isMounted.current == true) {
          console.log('err loadData', err);
        }
      });
  };

  const getRemainingDays = () => {
    let time = minutes2Days(invitationData.remaining_time_to_use);
    if (time.length > 2) {
      let flag = false;
      let disp = '';
      if (time[0] > 0) {
        if (time[0] == 1) {
          disp = time[0] + t('invitation_earn.day') + ' ';
          flag = true;
        } else {
          disp = time[0] + t('invitation_earn.days') + ' ';
          flag = false;
        }
      }

      if (time[1] > 0) {
        if (time[1] == 1) {
          disp = disp + time[1] + t('invitation_earn.hour') + ' ';
          flag = true;
        } else {
          disp = disp + time[1] + t('invitation_earn.hours') + ' ';
          flag = false;
        }
      }

      if (time[2] > 0) {
        if (time[2] == 1) {
          disp = disp + time[2] + t('invitation_earn.min') + ' ';
          flag = true;
        } else {
          disp = disp + time[2] + t('invitation_earn.mins') + ' ';
          flag = false;
        }
      }
      return (
        disp + (flag == true ? t('invitation_earn.remaining') : t('invitation_earn.remaining1'))
      );
    }
    return '';
  };

  const onUseCode = async () => {
    try {
      navigator.clipboard.writeText(invitationData.invite_code);
    } catch (e) {
      console.log(e);
    }

    localStorage.setItem(KEYS.INVITE_CODE, invitationData.invite_code);
    props.setShowAppSnackbar({ visible: true, description: t('code_complete') });
    navigate('/');
  };

  const renderLearnMore = async () => {
    const invitationRewardsSetting = props.invitationRewardsSetting;

    const getGroupedInfo = () => {
      let slots = [];
      for (let i = 0; i < invitationRewardsSetting.length; i++) {
        let setting = invitationRewardsSetting[i];
        if (setting.from && setting.to && setting.rewards_rate != null) {
          let time = `${getHourMin(setting.from)}-${getHourMin(setting.to)}`;
          let index = slots.findIndex((s) => s.rewards_rate == setting.rewards_rate);
          if (index != -1) {
            let tmp = slots[index].times || [];
            tmp = tmp.slice(0);
            tmp.push(time);
            tmp.sort((a, b) => convertTimeString2Hours(a) - convertTimeString2Hours(b));
            slots[index].times = tmp;
          } else {
            slots.push({
              times: [time],
              rewards_rate: setting.rewards_rate
            });
          }
        }
      }
      return slots;
    };
    return (
      <CommonTooltip
        id="invitation-detail"
        title={t('invitation_earn.learn_invite_title')}
        content={
          <div className={'align-col-middle mt1'} style={{ marginRight: 8 }}>
            {getGroupedInfo().map((setting, index) => (
              <div key={index} className={'tooltip-row'}>
                <div className={'dot'} />
                {invitationData.is_received == 1 ? (
                  <p className={'tooltip-txt'}>
                    {t('invitation_earn.learn_invite_desc3_01')}
                    {setting.times != null &&
                      setting.times.map((tm, idx) => (
                        <>
                          <span key={idx} style={{ fontFamily: Theme.fonts.bold }}>
                            {tm}
                          </span>
                          {idx < setting.times.length - 1 && t('and')}
                        </>
                      ))}
                    {', '}
                    {ucFirst(invitationData.sender?.username || invitationData.sender?.full_name)}
                    {t('invitation_earn.learn_invite_desc3_02')}
                    <span style={{ fontFamily: Theme.fonts.bold }}>{setting.rewards_rate}%</span>
                    {t('invitation_earn.learn_invite_desc3_03')}
                  </p>
                ) : (
                  <p className={'tooltip-txt'}>
                    {t('invitation_earn.learn_invite_desc1_01')}
                    {setting.times != null &&
                      setting.times.map((tm, idx) => (
                        <>
                          <span key={idx} style={{ fontFamily: Theme.fonts.bold }}>
                            {tm}
                          </span>
                          {idx < setting.times.length - 1 && t('and')}
                        </>
                      ))}
                    {t('invitation_earn.learn_invite_desc1_02')}
                    <span style={{ fontFamily: Theme.fonts.bold }}>{setting.rewards_rate}%</span>
                    {t('invitation_earn.learn_invite_desc1_03')}
                  </p>
                )}
              </div>
            ))}
            <div className={'tooltip-row'}>
              <div className={'dot'} />
              {invitationData.is_received == 1 ? (
                <p className={'tooltip-txt'}>
                  {t('invitation_earn.learn_invite_desc4_01')}
                  {ucFirst(invitationData.sender?.username || invitationData.sender?.full_name)}
                  {t('invitation_earn.learn_invite_desc4_02')}
                  <span style={{ fontFamily: Theme.fonts.bold }}>1%</span>
                  {t('invitation_earn.learn_invite_desc4_03')}
                </p>
              ) : (
                <p className={'tooltip-txt'}>
                  {t('invitation_earn.learn_invite_desc2_01')}
                  <span style={{ fontFamily: Theme.fonts.bold }}>1%</span>
                  {t('invitation_earn.learn_invite_desc2_02')}
                </p>
              )}
            </div>
          </div>
        }
        anchor={
          <p className={'learnmore'}>
            {invitationData.is_received == 1
              ? t('invitation_earn.how_does_it_work_receiver')
              : t('invitation_earn.how_does_it_work_sender')}
          </p>
        }
      />
    );
  };

  return (
    <div className={'earn-invitation-details'}>
      <div className={'align-middle header-view'}>
        <RoundIconBtn
          style={{
            ...styles.headerBtn
          }}
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={'title flex_1'}>{t('invitation_earn.invitation_details')}</div>
        <div style={{ width: 32 }} />
      </div>
      {invitationData.id != null && (
        <div className={'scroll-view w100'} style={{ paddingTop: 32 }}>
          {invitationData.is_expired != 1 && (
            <div className={'align-col-middle'} style={{ marginBottom: 25 }}>
              <img src={Svg_img} style={{ width: 205 }} />
            </div>
          )}
          <div className={'align-col-middle mb3'}>
            {invitationData.is_expired == 1 ? (
              <p style={styles.expiredTxt}>{t('invitation_earn.invitation_expired')}</p>
            ) : (
              invitationData.is_used == 0 && <p style={styles.remainingTxt}>{getRemainingDays()}</p>
            )}
            {invitationData.is_expired == 1 ? (
              <p style={styles.expiredCodeTxt}>{invitationData.invite_code}</p>
            ) : (
              <p style={styles.codeTxt}>{invitationData.invite_code}</p>
            )}
          </div>
          <div style={styles.divider} />
          <div className={'w100 mt2'}>
            <div className={'flex_between'} style={styles.infoItem}>
              <p style={styles.infoLabel}>
                {invitationData.is_received == 1
                  ? t('invitation_earn.sender')
                  : t('invitation_earn.receiver')}
              </p>
              <p style={styles.infoValue}>
                {invitationData.is_received == 1
                  ? ucFirst(invitationData.sender?.username || invitationData.sender?.full_name)
                  : ucFirst(
                      invitationData.receiver?.username || invitationData.receiver?.full_name
                    )}
              </p>
            </div>
            <div className={'flex_between'} style={styles.infoItem}>
              <p style={styles.infoLabel}>{t('invitation_earn.date')}</p>
              <p style={styles.infoValue}>
                {moment(invitationData.invite_time, 'YYYY-MM-DD hh:mm:ss').format(
                  'DD/MM/YYYY | hh:mm A'
                )}
              </p>
            </div>
            {invitationData.is_used == 0 &&
              invitationData.is_expired != 1 &&
              invitationData.is_received != 1 && (
                <p style={styles.description}>
                  {t('invitation_earn.code_not_used_yet').replace(
                    '###',
                    ucFirst(invitationData.receiver?.username || invitationData.receiver?.full_name)
                  )}
                </p>
              )}
            {invitationData.is_expired != 1 && renderLearnMore()}
          </div>
          <div style={{ height: 30 }} />
        </div>
      )}
      {invitationData.is_expired != 1 &&
        invitationData.is_used == 0 &&
        invitationData.is_received == 1 && (
          <div style={styles.bottom}>
            <AuthBtn
              title={t('invitation_earn.use_code')}
              style={{ width: '100%' }}
              onClick={onUseCode}
            />
          </div>
        )}
    </div>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  codeTxt: { fontSize: 25, marginTop: 12, fontFamily: Theme.fonts.bold, color: Theme.colors.text },
  learnmore: {
    fontSize: 17,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.gray7,
    textDecorationLine: 'underline'
  },
  divider: { width: '100%', height: 1, marginVertical: 16, backgroundColor: '#F6F6F9' },
  scrollview: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 20,
    backgroundColor: Theme.colors.white
  },
  infoItem: {
    paddingHorizontal: 20,
    paddingVertical: 12,
    borderRadius: 12,
    marginBottom: 12,
    backgroundColor: Theme.colors.gray8
  },
  infoLabel: { fontSize: 17, fontFamily: Theme.fonts.semiBold, color: Theme.colors.text },
  infoValue: { fontSize: 17, fontFamily: Theme.fonts.semiBold, color: Theme.colors.gray7 },
  description: {
    textAlign: 'center',
    width: '100%',
    paddingHorizontal: 50,
    marginVertical: 12,
    fontSize: 17,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text
  },
  remainingTxt: { fontSize: 14, fontFamily: Theme.fonts.medium, color: Theme.colors.red1 },
  expiredTxt: { fontSize: 19, fontFamily: Theme.fonts.semiBold, color: Theme.colors.red1 },
  expiredCodeTxt: {
    marginTop: 16,
    textDecorationLine: 'line-through',
    textDecorationColor: Theme.colors.gray7,
    fontSize: 25,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.gray7
  },
  bottom: { width: '100%', paddingHorizontal: 20, marginBottom: 30 }
};

EarnIniviteDetailsScreen.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  invitationPickedUser: PropTypes.shape({
    id: PropTypes.number,
    isFriend: PropTypes.bool,
    username: PropTypes.string,
    full_name: PropTypes.string
  }),
  invitationRewardsSetting: PropTypes.array,
  invitationTimerSetting: {},
  referralsRewardsSetting: {
    earninvitation_howto_works: PropTypes.string
  },
  loadInvitationTimerSetting: PropTypes.func,
  setInvitationPickedUser: PropTypes.func,
  setShowAppSnackbar: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  invitationPickedUser: app.invitationPickedUser,
  invitationRewardsSetting: app.invitationRewardsSetting,
  referralsRewardsSetting: app.referralsRewardsSetting,
  invitationTimerSetting: app.invitationTimerSetting || {}
});

export default connect(mapStateToProps, {
  loadInvitationTimerSetting,
  setInvitationPickedUser,
  setShowAppSnackbar
})(EarnIniviteDetailsScreen);
