import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { Search } from '@styled-icons/evil';
import { Theme } from '../../../assets';
import LoadingModal from '../../../components/Modals/LoadingModal';
import PropTypes from 'prop-types';
import './index.css';
import '../index.css';
import { confirmAlert } from 'react-confirm-alert';
import { createSingleChannel, findSingleChannel } from '../../../services/chat';
import { getFriends } from '../../../store/actions/app';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import SearchInput from '../../Inputs/SearchInput';
import CommonTabs from '../../TabSelector/CommonTabs';
import ApiFactory from '../../../services/apiFactory';
import NoFriendList from '../../Empty/NoFriendList';
import UserItem from '../../Chats/UserItem';
import LoadingSpinner from '../../Spinner';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const NewConvModal = (props) => {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, ShowLoading] = useState(false);

  const [opType, setOpType] = useState('friends');
  const [searchTerm, setSearchTerm] = useState('');

  const [stateSnapfooder, setStateSnapfooder] = useState({
    loading: null,
    snapfooders: [],
    page: 1,
    totalPages: 1
  });

  const [stateFriends, setStateFriends] = useState({
    loading: null,
    all_friends: []
  });

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    getFriendsNotInChannel(searchTerm);
    getSnapfooders(searchTerm, stateSnapfooder.page);
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getFriendsNotInChannel = (searchTerm) => {
    if (isLoading) {
      return;
    }
    let filter_ids = [];
    props.chat_channels.map((channel) => {
      if (channel.channel_type == 'single') {
        let partners = channel.users.filter((i) => i != props.user.id);
        if (partners.length > 0) {
          filter_ids.push(partners[0]);
        }
      }
    });

    setStateFriends({ ...stateFriends, loading: true });
    getFriends('accepted', searchTerm, filter_ids)
      .then((data) => {
        if (_isMounted.current == true) {
          setStateFriends({ ...stateFriends, all_friends: data, loading: false });
        }
      })
      .catch(() => {
        setStateFriends({ ...stateFriends, loading: false });
      });
  };

  const getSnapfooders = (search, page) => {
    const params = [`name=${search}`, `page=${page}`];
    if (stateSnapfooder.loading == true) {
      return;
    }
    if (_isMounted.current == true) {
      setStateSnapfooder({
        ...stateSnapfooder,
        loading: true
      });
    }
    ApiFactory.get(`users/snapfooders?${params.join('&')}`)
      .then(
        ({ data }) => {
          identifyDevice();
          trackEvent('Load snapfooders', {
            action_category: 'Load Screen',
            action_outcome: 'success',
            interaction_element: 'screen',
            source: 'sf_web'
          });
          const res_snapfooders = data['snapfooders'];
          if (_isMounted.current === true) {
            if (page > 1) {
              const currentSnapfooderIds = stateSnapfooder.snapfooders.map((x) => x.id);
              const newItems = res_snapfooders['data'].filter(
                (x) => currentSnapfooderIds.indexOf(x.id) === -1
              );
              setStateSnapfooder({
                ...stateSnapfooder,
                loading: false,
                snapfooders: stateSnapfooder.snapfooders.concat(newItems),
                page: res_snapfooders['current_page'],
                totalPages: res_snapfooders['last_page']
              });
            } else {
              setStateSnapfooder({
                ...stateSnapfooder,
                loading: false,
                snapfooders: res_snapfooders['data'] || [],
                page: res_snapfooders['current_page'],
                totalPages: res_snapfooders['last_page']
              });
            }
          }
        },
        (error) => {
          const message = error.message || t('generic_error');
          setStateSnapfooder({
            ...stateSnapfooder,
            loading: false
          });
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
        }
      )
      .catch(() => {
        identifyDevice();
        trackEvent('Load snapfooders', {
          action_category: 'Load Screen',
          action_outcome: 'fail',
          interaction_element: 'screen',
          source: 'sf_web'
        });
      });
  };

  const updateSnapfoodDetail = (user_id) => {
    ApiFactory.get(`users/snapfooders/${user_id}`)
      .then(
        ({ data }) => {
          identifyDevice();
          trackEvent('UserSnapfooders', {
            action_category: 'Retrieve Snapfooders',
            action_outcome: 'success',
            interaction_element: 'screen',
            source: 'sf_web'
          });
          const res_snapfooder = data['snapfooder'];
          if (_isMounted.current == true) {
            let tmp = stateSnapfooder.snapfooders.slice(0, stateSnapfooder.snapfooders.length);
            let found_index = tmp.findIndex((i) => i.id == user_id);
            if (found_index >= 0) {
              tmp[found_index].invite_status = res_snapfooder.invite_status;
              setStateSnapfooder({
                ...stateSnapfooder,
                snapfooders: tmp
              });
            }
          }
        },
        () => {}
      )
      .catch(() => {
        identifyDevice();
        trackEvent('Load snapfooder', {
          action_category: 'Load Screen',
          action_outcome: 'fail',
          interaction_element: 'screen',
          source: 'sf_web'
        });
      });
  };

  const onSendInvitation = async (item) => {
    ApiFactory.post(`users/friends/update`, {
      user_id: props.user.id,
      friend_id: item.id,
      status: 'invited'
    }).then(
      () => {
        identifyDevice();
        trackEvent('FriendUpdate', {
          action_category: 'Update Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        updateSnapfoodDetail(item.id);
      },
      (error) => {
        identifyDevice();
        trackEvent('FriendUpdate', {
          action_category: 'Update Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = async (item) => {
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: item.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        updateSnapfoodDetail(item.id);
      },
      (error) => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onEnterChannel = async (partner) => {
    ShowLoading(true);
    let found_channel = await findSingleChannel(props.user.id, partner.id);
    if (found_channel != null) {
      ShowLoading(false);
      navigate(`/social/chat/${found_channel.id}`, { replace: true });
      props.onClose();
    } else {
      let channelID = await createSingleChannel(props.user, partner);
      ShowLoading(false);
      if (channelID != null) {
        navigate(`/social/chat/${channelID}`, { replace: true });
        props.onClose();
      } else {
        confirmAlert({
          title: t('alerts.error'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    }
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal new-chat-modal'}>
      <div className={'align-col-middle content pt3 pb2 ph2'}>
        <div className={'w100 align-middle title-header mb2'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h4 className={'title'}>{t('social.new_chat')}</h4>
          <div style={{ width: 40 }} />
        </div>
        <div className={'align-middle searchview'}>
          <SearchInput
            placeholder={t('social.search.friends')}
            icon={<Search size={20} color={Theme.colors.gray5} />}
            style={{ flex: 1, height: 45 }}
            value={searchTerm}
            onChange={(text) => {
              setSearchTerm(text);
              getFriendsNotInChannel(text);
              getSnapfooders(text, 1);
            }}
          />
        </div>
        <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
          <div className={'align-middle operation-tab'}>
            <CommonTabs
              items={['friends', 'snapfooders']}
              item={opType}
              style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
              onChange={(item) => setOpType(item)}
            />
          </div>
        </div>
        <div className={'flex_1 scrollview'}>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              if (opType === 'friends' && stateSnapfooder.page < stateSnapfooder.totalPages) {
                getSnapfooders(searchTerm, stateSnapfooder.page + 1);
              }
            }}
            hasMore={opType === 'friends' && stateSnapfooder.page < stateSnapfooder.totalPages}
            loader={
              <div className="align-col-middle mt12 mb20" key={0}>
                <CircularProgress size={20} color="primary" />
              </div>
            }
            useWindow={false}>
            <div className={opType === 'friends' ? 'show' : 'hidden'}>
              <div style={{ height: 20 }} />
              {stateFriends.loading === true ? (
                <div className={'flex_1'}>
                  <LoadingSpinner />
                </div>
              ) : stateFriends.loading == false && stateFriends.all_friends.length === 0 ? (
                <NoFriendList title={t('social.no_friends_from_new_chat')} />
              ) : (
                stateFriends.all_friends.map((item) => (
                  <UserItem
                    key={item.id}
                    item={item}
                    id={item.id}
                    type="none"
                    onClick={() => {
                      onEnterChannel(item);
                    }}
                  />
                ))
              )}
              <div style={{ height: 40 }} />
            </div>
            <div className={opType === 'snapfooders' ? 'show' : 'hidden'}>
              <div style={{ height: 20 }} />
              {stateSnapfooder.loading === true ? (
                <div className={'flex_1'}>
                  <LoadingSpinner />
                </div>
              ) : stateSnapfooder.loading == false && stateSnapfooder.snapfooders.length === 0 ? (
                <NoFriendList title={t('social.no_snapfooders_from_new_chat')} />
              ) : (
                stateSnapfooder.snapfooders.map((item) => (
                  <UserItem
                    key={item.id}
                    item={item}
                    id={item.id}
                    invite_status={item.invite_status}
                    type="snapfooder"
                    onClick={() => {
                      navigate(`/social/snapfoodies/${item.id}`);
                    }}
                    onRightBtnPress={
                      item.invite_status === 'invited'
                        ? () => onCancelInvitation(item)
                        : () => onSendInvitation(item)
                    }
                  />
                ))
              )}
              <div style={{ height: 40 }} />
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <LoadingModal showModal={isLoading} />
    </Dialog>
  );
};

NewConvModal.propTypes = {
  isOpen: PropTypes.bool,
  chat_channels: PropTypes.array,
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  chat_channels: app.chat_channels
});

export default connect(mapStateToProps, {})(NewConvModal);
