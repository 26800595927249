import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Config } from '../../../constants';
import { StarFill, Clock } from '@styled-icons/bootstrap';
import PropTypes from 'prop-types';
import './index.css';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import { MapMarkerAlt } from '@styled-icons/fa-solid';
import Svg_euro from '../../../assets/svgs/vendor/ic_euro_white.svg';

const ShopInfoView = ({ data, handover_method, is_open, language }) => {
  const { t } = useTranslation();
  const getTags = () => {
    let tags_str = language == 'en' ? data.tags_en : data.tags;
    if (tags_str == null) {
      return [];
    }
    return tags_str.split(',');
  };

  return (
    <div className={'row-start shopinfo-view'}>
      <img src={Config.IMG_BASE_URL + data.logo_thumbnail_path} className={'vendor-logo'} />
      <div className={'right'}>
        <div className={'align-row-start'}>
          <h1>{data.title}</h1>
          <div className={'align-middle open-close-badge'}>
            <div style={{ backgroundColor: is_open === 1 ? '#0F0' : '#F00' }} />
            <span>
              {is_open === 1 ? t('vendor_profile.open_now') : t('vendor_profile.close_now')}
            </span>
          </div>
        </div>
        <h3>{data.custom_text}</h3>
        <div className={'row-start'}>
          {handover_method == OrderType_Delivery &&
            data.delivery_minimum_order_price != null &&
            data.minimum_delivery_time != null && (
              <div className={'row-start flex_wrap'} style={{ marginTop: 4 }}>
                {data.rating_interval != null && (
                  <div className={'align-middle'}>
                    <StarFill size={14} color={'#FFF'} style={{ marginRight: 6 }} />
                    <h3>
                      {(parseFloat(data.rating_interval) / 2).toFixed(1)}{' '}
                      <span style={{ paddingLeft: 8, paddingRight: 8 }}>|</span>
                    </h3>
                  </div>
                )}
                <div className={'align-middle'}>
                  <img src={Svg_euro} alt={''} style={{ marginRight: 4 }} />
                  <h3>
                    {parseInt(data.delivery_minimum_order_price)} L{' '}
                    <span style={{ paddingLeft: 8, paddingRight: 8 }}>|</span>
                  </h3>
                </div>
                <div className={'align-middle'}>
                  <Clock size={16} color={'#FFF'} style={{ marginRight: 6 }} />
                  <h3>
                    {(data.min_delivery_time != null && data.min_delivery_time != '') ? `${data.min_delivery_time} - ${data.minimum_delivery_time}` : data.minimum_delivery_time} {t('vendor_profile.mins')}
                  </h3>
                </div>
              </div>
            )}
          {handover_method == OrderType_Pickup && (
            <div className={'row-start'} style={{ marginTop: 4 }}>
              {data.distance != null && parseFloat(data.distance) > 0 && (
                <div className={'align-middle'}>
                  <MapMarkerAlt size={16} color={'#FFF'} />
                  <h3>
                    {(parseFloat(data.distance) / 1000).toFixed(2)} Km{' '}
                    <span style={{ paddingLeft: 8, paddingRight: 8 }}>|</span>
                  </h3>
                </div>
              )}
              <div className={'align-middle'}>
                <Clock size={16} color={'#FFF'} style={{ marginRight: 6 }} />
                <h3>
                  {data.max_pickup_time} {t('vendor_profile.mins')}
                </h3>
              </div>
            </div>
          )}
          {handover_method == OrderType_Reserve && (
            <div className={'align-middle'} style={{ marginTop: 4 }}>
              {data.distance != null && parseFloat(data.distance) > 0 && (
                <div className={'align-middle'}>
                  <MapMarkerAlt size={16} color={'#FFF'} />
                  <h3>
                    {(parseFloat(data.distance) / 1000).toFixed(2)} Km{' '}
                    <span style={{ paddingLeft: 8, paddingRight: 8 }}>|</span>
                  </h3>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={'row-start flex_wrap'} >
          {getTags().map((tag, index) => (
            <div key={index} className={'tag-item mt1'}>
              {tag}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ShopInfoView.propTypes = {
  vendor_id: PropTypes.number,
  is_open: PropTypes.number,
  handover_method: PropTypes.string,
  language: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    custom_text: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    rating_interval: PropTypes.string,
    order_method: PropTypes.string,
    delivery_fee: PropTypes.number,
    min_delivery_time: PropTypes.number,
    minimum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    distance: PropTypes.number,
    max_pickup_time: PropTypes.number,
    tags_en: PropTypes.string,
    tags: PropTypes.string
  })
};
const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language
});
export default connect(mapStateToProps, {})(ShopInfoView);
