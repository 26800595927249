import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplyForm from './ApplyForm';
import Features from './Features';
import Support from './Support';
import NeedStart from './NeedStart';
import Faqs from './Faqs';
import './index.css';
import Seo from '../../components/Seo';
import { Grid } from '@mui/material';

const Courier = () => {
  const { t } = useTranslation();
  return (
    <div data-testid="view-courier" className="view-courier courier-screen">
      <Seo title={t('web_title.register_courier')} />
      <section className="main ph4 pv4  bg-theme-color-light overlay-dark">
        <Grid container spacing={{ xs: 2, md: 5 }} className={'align-middle'}  >
          <Grid item xs={12} md={6} style={{}}>
            <div className={'title-view'}>
              <h1>{t('courier.title')}</h1>
              <h4>{t('courier.sub_title')}</h4>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <ApplyForm />
          </Grid>
        </Grid>
      </section>
      <section className="">
        <Features />
      </section>
      <section className="">
        <Support />
      </section>
      <section className="">
        <NeedStart />
      </section>
      <section className="">
        <Faqs />
      </section>
    </div>
  );
};

export default Courier;
