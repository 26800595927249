import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { setDeliveryInfoCart } from '../../../store/actions/shop';
import MainBtn from '../../Buttons/MainBtn';
import { isEmpty, convertTimeString2Hours } from '../../../utils/common';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import { TextSelector } from '../../DropdownSelectors';
import { useTranslation } from 'react-i18next';
import '../index.css'
import './index.css'


const CartScheduleModal = (props) => {
  const { showModal, onClose } = props;
  const { t } = useTranslation();
  const [visible, SetVisible] = useState(showModal);

  const dates = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedTimeIndex, setSelectedTimeIndex] = useState(-1);

  const [availPickupDays, setAvailPickupDays] = useState([]);

  useEffect(() => {
    SetVisible(showModal);
    if (showModal) {
      if (props.vendorData.vendor_opening_days != null) {
        let days = [];
        for (let j = 0; j < props.vendorData.vendor_opening_days.length; j++) {
          const openDay = props.vendorData.vendor_opening_days[j];
          const _index = days.findIndex(d => d.week_day == openDay.week_day);
          let _times = [];
          if (_index != -1) {
            _times = days[_index].times ? [...days[_index].times] : [];
          }

          const time_open = convertTimeString2Hours(openDay.time_open);
          const time_close = convertTimeString2Hours(openDay.time_close);

          for (let k = time_open; k <= time_close; k = k + 0.5) {
            let _time = `${Math.floor(k)}:00`;
            if ((k - Math.floor(k)) >= 0.5) {
              _time = `${Math.floor(k)}:30`;
            }

            if (parseInt(_time.split(':')[0]) < 10) {
              _time = '0' + _time;
            }

            if (_times.findIndex(t => t == _time) == -1) {
              _times.push(_time);
            }
          }

          if (_times.length > 0) {
            if (_index != -1) {
              days[_index].times = _times;
            } else {
              days.push({
                week_day: openDay.week_day,
                times: _times
              });
            }
          }
        }

        let availDays = [];
        for (let ii = 0; ii < 7; ii++) {
          let _date = moment(new Date()).add(ii, 'days').toDate();
          let foundIndex = days.findIndex(d => d.week_day == ((_date.getDay() - 1) < 0 ? (_date.getDay() + 6) : (_date.getDay() - 1)).toString());
          if (foundIndex != -1) {
            if (ii == 0) { // today
              let tmpData = days[foundIndex];

              let today_weekday = tmpData.week_day;
              let today_available_times = [];
              if (tmpData.times != null && tmpData.times.length > 0) {
                for (let tt = 0; tt < tmpData.times.length; tt++) {
                  let now_hours = new Date().getHours() + new Date().getMinutes() / 60;
                  const time_hours = convertTimeString2Hours(tmpData.times[tt]);

                  if (time_hours >= (now_hours + 0.5)) {
                    today_available_times.push(tmpData.times[tt]);
                  }
                }
              }

              if (today_available_times.length > 0) {
                availDays.push({
                  week_day: today_weekday,
                  times: today_available_times,
                  date: moment(_date).format('YYYY-MM-DD'),
                  day: 'Today'
                });
              }
            } else {
              availDays.push({
                ...days[foundIndex],
                date: moment(_date).format('YYYY-MM-DD'),
                day: (ii == 1 ? 'Tomorrow' : dates[_date.getDay()])
              });
            }
          }
        }

        if (availDays.length > 0) {
          setAvailPickupDays(availDays);
        }
      }
    }
  }, [showModal]);

  useEffect(() => {
    if (availPickupDays.length > 0 && availPickupDays[0].times && availPickupDays[0].times.length > 0) {
      let date_index = 0;
      let time_index = 0;
      if (props.delivery_info.schedule_time != null) {
        let tmp = props.delivery_info.schedule_time.split(' ');
        if (tmp.length > 1) {
          let tmpDateIndex = availPickupDays.findIndex(d => d.date == tmp[0]);
          if (tmpDateIndex != -1) {
            date_index = tmpDateIndex;
            console.log('date_index ', date_index, tmp[1]);
            if (availPickupDays[tmpDateIndex].times != null) {
              let tmpTimeIndex = availPickupDays[tmpDateIndex].times.findIndex(t => t + ':00' == tmp[1]);
              if (tmpTimeIndex != -1) {
                console.log('time_index ', tmpTimeIndex);
                time_index = tmpTimeIndex;
              }
            }
          }
        }
      }
      setSelectedDate(availPickupDays[date_index]);
      setSelectedTimeIndex(time_index);
    }
  }, [availPickupDays, props.delivery_info.schedule_time]);


  const onChangeDay = (_date) => {
    setSelectedDate(_date);
  };

  const onConfirm = () => {
    if (selectedTimeIndex == -1 || selectedTimeIndex >= selectedDate.times.length) {
      return;
    }
    let _date = selectedDate.date;
    let _time = selectedDate.times[selectedTimeIndex];
    if (isEmpty(_date) || isEmpty(_time)) {
      return;
    }

    console.log('confirm ', _date, _time);
    props.setDeliveryInfoCart({
      is_schedule: 1,
      schedule_time: `${_date} ${_time}:00`
    });
    onClose();
  };

  return (
    <Dialog open={visible} className={'align-col-middle w100 modal delivery-schedule-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100  pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={onClose} />
          <h4 className={'title'}>{t('cart.schedule_order')}</h4>
        </div>
        {
          (availPickupDays.length > 0 && selectedDate != null && selectedDate.times != null && selectedTimeIndex != -1) &&
          <>
            <div className={'w100 mt3 date-input'}>
              <TextSelector
                values={availPickupDays.map(d => d.day)}
                value={(availPickupDays.findIndex((i) => i.date == selectedDate.date) != -1) ?
                  availPickupDays[availPickupDays.findIndex((i) => i.date == selectedDate.date)].day : null}
                handleChange={(text, index) => {
                  onChangeDay(availPickupDays[index]);
                }}
              />
            </div>
            <div className={'w100 mt2 time-input'}>
              <TextSelector
                translate={false}
                values={selectedDate.times}
                value={(selectedDate.times.length > selectedTimeIndex) ? selectedDate.times[selectedTimeIndex] : null}
                handleChange={(text, index) => {
                  setSelectedTimeIndex(index);
                }}
              />
            </div>
          </>
        }
        <div
          className={'flex_1 align-col-middle w100 actions'}
          style={{ marginTop: 72 }}>
          <MainBtn title={t('save')} onClick={onConfirm} className={'save-btn'} />
        </div>
      </div>
    </Dialog>
  );
};

CartScheduleModal.propTypes = {
  showModal: PropTypes.bool,
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    vendor_opening_days: PropTypes.array
  }),
  delivery_info: PropTypes.shape({
    schedule_time: PropTypes.string
  }),
  setDeliveryInfoCart: PropTypes.func,
  onClose: PropTypes.func
};


const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  delivery_info: shop.delivery_info,
  vendorData: shop.vendorData || {}
});

export default connect(mapStateToProps, {
  setDeliveryInfoCart
})(CartScheduleModal);
