import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import Svg_active from '../../../../assets/svgs/checkout/ic_active.svg';
import Svg_inactive from '../../../../assets/svgs/checkout/ic_inactive.svg';
import Svg_declined from '../../../../assets/svgs/checkout/declined.svg';
import Svg_delivered from '../../../../assets/svgs/checkout/delivered.svg';
import {
  Order_Accepted,
  Order_Completed,
  Order_Delivered,
  Order_Outfordelivery,
  Order_Pending,
  Order_Pickedup,
  Order_Preparing,
  Order_Ready_pickup,
  Order_Reserved,
  OrderType_Delivery,
  OrderType_Reserve
} from '../../../../constants/config';
import { Theme } from '../../../../assets';

const Step = ({ item, step }) => {
  return (
    <div
      className={
        'align-middle mt2 step ' +
        (item.status <= step ? 'active' : 'inactive') +
        (item.status > 0 ? ' flex_1' : '')
      }
      onClick={() => {}}>
      {item.status > 0 && <div className={'divider flex_1'} />}
      <img src={item.status <= step ? Svg_active : Svg_inactive} />
      <div>
        <div className={'name'}>{item.name}</div>
        <div className={'desc'}>{item.desc}</div>
      </div>
    </div>
  );
};

const OrderStepper = ({ order }) => {
  const { t } = useTranslation();

  const [items, setItems] = useState([]);
  const [status, setStatus] = useState(0);

  const delivery_steps = [
    {
      status: Order_Pending,
      id: 'new',
      name: t('order_delivery_status.pending'),
      desc: t('order_delivery_status.pending_desc')
    },
    {
      status: Order_Preparing,
      id: 'processing',
      name: t('order_delivery_status.prepare_order'),
      desc: t('order_delivery_status.prepare_order_desc')
    },
    {
      status: Order_Outfordelivery,
      id: 'picked_by_rider',
      name: t('order_delivery_status.out_for_delivery'),
      desc: t('order_delivery_status.out_for_delivery_desc')
    },
    {
      status: Order_Delivered,
      id: 'delivered',
      name: t('order_delivery_status.delivered'),
      desc: t('order_delivery_status.delivered_desc')
    }
  ];

  const pickup_steps = [
    {
      status: Order_Pending,
      id: 'new',
      name: t('order_pickup_status.pending'),
      desc: t('order_pickup_status.pending_desc')
    },
    {
      status: Order_Accepted,
      id: 'processing',
      name: t('order_pickup_status.accepted_order'),
      desc: t('order_pickup_status.accepted_order_desc')
    },
    {
      status: Order_Ready_pickup,
      id: 'ready_to_pickup',
      name: t('order_pickup_status.ready_for_pickup'),
      desc: t('order_pickup_status.ready_for_pickup_desc')
    },
    {
      status: Order_Pickedup,
      id: 'picked_up',
      name: t('order_pickup_status.picked_up'),
      desc: t('order_pickup_status.picked_up_desc')
    }
  ];

  const reserve_steps = [
    {
      status: Order_Pending,
      id: 'new',
      name: t('order_reserve_status.pending'),
      desc: t('order_reserve_status.pending_desc')
    },
    {
      status: Order_Reserved,
      id: 'reserved',
      name: t('order_reserve_status.reserved'),
      desc: t('order_reserve_status.reserved_desc')
    },
    {
      status: Order_Completed,
      id: 'completed',
      name: t('order_reserve_status.completed'),
      desc: t('order_reserve_status.completed_desc')
    }
  ];

  useEffect(() => {
    if (order.order_type == OrderType_Delivery) {
      setItems(delivery_steps);
      let status = order.status;
      if (status == 'notified') {
        status = 'processing';
      }

      if (status == 'accepted') {
        if (order.vendor.delivery_type == 'Snapfood') {
          status = 'picked_by_rider';
        }
        else {
          status = 'processing';
        }
      }
      setStatus(delivery_steps.findIndex((i) => i.id == status));
    } else if (order.order_type == OrderType_Reserve) {
      setItems(reserve_steps);
      setStatus(reserve_steps.findIndex((i) => i.id == order.status));
    } else {
      setItems(pickup_steps);
      setStatus(pickup_steps.findIndex((i) => i.id == order.status));
    }
  }, [order]);

  if (order.status === 'declined') {
    return (
      <div className={'align-row-start order-stepper  ph2 flex_wrap'}>
        <div className={'align-middle step declined-status'}>
          <img src={Svg_declined} />
          <div>
            <div className={'name'}>{t('orders.status_rejected')}</div>
            <div className={'desc'} style={{color : Theme.colors.text}}>{t('orders.order_declined_desc')}</div>
          </div>
        </div>
      </div>
    );
  }

  if (order.status == 'delivered') {
    return (
      <div className={'align-row-start order-stepper  ph2 flex_wrap'}>
        <div className={'align-middle step delivered-status'}>
          <img src={Svg_delivered} />
          <div>
            <div className={'name'}>{t('order_delivery_status.delivered')}</div>
            <div className={'desc'}>{t('order_delivery_status.delivered_desc')}</div>
          </div>
        </div>
      </div>
    );
  }

  if (order.status == 'picked_up') {
    return (
      <div className={'align-row-start order-stepper  ph2 flex_wrap'}>
        <div className={'align-middle step delivered-status'}>
          <img src={Svg_delivered} />
          <div>
            <div className={'name'}>{t('order_pickup_status.picked_up')}</div>
            <div className={'desc'}>{t('order_pickup_status.picked_up_desc')}</div>
          </div>
        </div>
      </div>
    );
  }

  if (order.status == 'completed') {
    return (
      <div className={'align-row-start order-stepper  ph2 flex_wrap'}>
        <div className={'align-middle step delivered-status'}>
          <img src={Svg_delivered} />
          <div>
            <div className={'name'}>{t('order_reserve_status.completed')}</div>
            <div className={'desc'}>{t('order_reserve_status.completed_desc')}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'align-row-start order-stepper  ph2'}>
      {items.map((item) => (
        <Step key={item.id} item={item} step={status} />
      ))}
    </div>
  );
};

Step.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.number,
    name: PropTypes.string,
    desc: PropTypes.string
  }),
  step: PropTypes.number,
  onClick: PropTypes.func
};

OrderStepper.propTypes = {
  order: PropTypes.shape({
    order_type: PropTypes.string,
    status: PropTypes.string,
    vendor: PropTypes.shape({
      delivery_type: PropTypes.string
    })
  })
};

export default OrderStepper;
