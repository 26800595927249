import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-grid-system';
import { Config } from '../../../constants';
import {
  MemorizedRateItem,
  MemorizedPriceItem,
  MemorizedMinMaxTimeItem,
  MemorizedVendorTag,
  MemorizedFavBtn,
  MemorizedLogoImg,
  MemorizedDistanceItem
} from './ValueItems';
import './index.css';
import { OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import { toggleFavourite } from '../../../store/actions/vendors';
import Img_open from '../../../assets/images/vendor/is_open.png';
import Img_closed from '../../../assets/images/vendor/is_closed.png';
import Img_open_al from '../../../assets/images/vendor/al_open.png';
import Img_closed_al from '../../../assets/images/vendor/al_closed.png';

const getTags = (language, data) => {
  if (data == null) return [];
  let tags_str = language === 'en' ? data.tags_en : data.tags;
  if (tags_str == null) {
    return [];
  }
  return tags_str.split(',');
};

const VendorItem = ({ isFav, isOpen, isLoggedIn, language, data, style, onFavChange, onSelect }) => {
  const { i18n } = useTranslation();

  const tags = useMemo(() => getTags(i18n.language, data), [i18n.language, data]);

  const onClick = (e) => {
    e.preventDefault();
    onSelect();
  };

  const onPressFav = () => {
    toggleFavourite(data.id, isFav === 1 ? 0 : 1)
      .then(() => {
        data.isFav = isFav === 1 ? 0 : 1;
        onFavChange(data);
      })
      .catch(() => {
      });
  };

  const renderInfo = () => {
    if (data.selected_order_method === OrderType_Pickup) {
      return (
        <React.Fragment>
          <div className={'align-row-start w100 mt1 flex_wrap'}>
            <MemorizedRateItem value={(parseFloat(data.rating_interval) / 2).toFixed(1)} />
            <div className={'text '} style={{ paddingLeft: 10, paddingRight: 10 }}>
              |
            </div>
            {data.distance != null && parseFloat(data.distance) > 0 && (
              <>
                <MemorizedDistanceItem value={data.distance} />
                <div className={'text '} style={{ paddingLeft: 10, paddingRight: 10 }}>
                  |
                </div>
              </>
            )}
            <MemorizedMinMaxTimeItem value={data.max_pickup_time} />
          </div>
        </React.Fragment>
      );
    }
    if (data.selected_order_method === OrderType_Reserve) {
      return (
        <div className={'align-row-start w100 mt1 flex_wrap'}>
          <MemorizedRateItem value={(parseFloat(data.rating_interval) / 2).toFixed(1)} />

          {data.distance != null && parseFloat(data.distance) > 0 && (
            <>
              <div className={'text '} style={{ paddingLeft: 10, paddingRight: 10 }}>
                |
              </div>
              <MemorizedDistanceItem value={data.distance} />
            </>
          )}
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className={'align-row-start mt1 flex_wrap'}>
          <MemorizedRateItem value={(parseFloat(data.rating_interval) / 2).toFixed(1)} />
          <div style={{ marginLeft: 6, marginRight: 6 }}>|</div>
          <MemorizedPriceItem value={data.delivery_minimum_order_price} />
          <div style={{ marginLeft: 6, marginRight: 6 }}>|</div>
          <MemorizedMinMaxTimeItem
            value={(data.min_delivery_time != null && data.min_delivery_time != '') ? `${data.min_delivery_time} - ${data.minimum_delivery_time}` : data.minimum_delivery_time} />
        </div>
      </React.Fragment>
    );
  };

  return (
    <Col xs={12} md={6} lg={4} className={'vendor-item-wrap'}>
      <div className={'vendor-item'} style={style} onClick={onClick}>
        <div
          className='img bg-cover bg-theme-color-light overlay-dark'
          style={{
            backgroundImage: `url('${Config.IMG_BASE_URL}${data.profile_path}?w=600&h=600')`
          }}>
          <div className={'align-row-start tags'}>
            {tags.map((tag, index) => (
              <MemorizedVendorTag text={tag} key={index} />
            ))}
          </div>
          <img src={isOpen ?  (language == 'en' ? Img_open : Img_open_al) : (language == 'en' ? Img_closed : Img_closed_al)} className={'open-close-img'}/>
          {
            isLoggedIn && <MemorizedFavBtn isFav={isFav} onChange={onPressFav} />
          }
        </div>
        <div className='info'>
          <div>
            <MemorizedLogoImg
              url={`${Config.IMG_BASE_URL}${data.logo_thumbnail_path}?w=200&h=200`}
            />
          </div>
          <div className={'flex_1'}>
            <div className={'flex_between'} style={{ alignItems: 'flex-start' }}>
              <h3 className={'vendor-title'}>{data.title}</h3>
            </div>
            {renderInfo()}
          </div>
        </div>
      </div>
    </Col>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.vendor_id === nextProps.vendor_id &&
    prevProps.isFav === nextProps.isFav &&
    prevProps.isLoggedIn === nextProps.isLoggedIn &&
    prevProps.language === nextProps.language &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.data != null &&
    nextProps.data != null &&
    prevProps.data.selected_order_method === nextProps.data.selected_order_method
  );
}

VendorItem.propTypes = {
  vendor_id: PropTypes.number,
  isFav: PropTypes.number,
  isOpen: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  language : PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    isFav: PropTypes.number,
    is_open: PropTypes.number,
    can_schedule: PropTypes.number,
    selected_order_method: PropTypes.string,
    custom_text: PropTypes.string,
    profile_path: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    tags_en: PropTypes.string,
    tags: PropTypes.string,
    rating_interval: PropTypes.string,
    distance: PropTypes.number,
    max_pickup_time: PropTypes.number,
    minimum_delivery_time: PropTypes.number,
    min_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number
  }),
  style: PropTypes.object,
  onSelect: PropTypes.func,
  onFavChange: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  language : app.language,
  isLoggedIn: app.isLoggedIn
});
export default connect(mapStateToProps, {})(React.memo(VendorItem, arePropsEqual));
