import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthLayout from '../layout';
import Seo from '../../../components/Seo';
import { ROUTES_NAMES } from '../../../constants';
import { AuthInput } from '../../../components/Inputs';
import Spacing from '../../../components/Spacing';
import { AuthBtn } from '../../../components/Buttons';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import apiFactory from '../../../services/apiFactory';
import { Storage } from '../../../services';
import './index.css';
import querystring from 'query-string';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const parsed = querystring.parse(location.search) || {};

  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password == '') {
      return confirmAlert({
        title: null,
        message: t('auth_login.validate_cell_pass'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    if (password != confirm_password) {
      return confirmAlert({
        title: null,
        message: t('auth_register.validate_cell_pass_match'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    let token = parsed.token;
    setLoading(true);
    apiFactory.post(`reset-password`, { token, password }).then(
      async () => {
        identifyDevice();
        trackEvent('PasswordReset', {
          action_category: 'Initiate Password Reset',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setLoading(false);
        Storage.removeStorageKey(Storage.KEYS.TMP_FORGOT_EMAIL);
        navigate(ROUTES_NAMES.resetPassDone);
      },
      async (err) => {
        identifyDevice();
        trackEvent('PasswordReset', {
          action_category: 'Initiate Password Reset',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        setLoading(false);
        console.log('forgot-password err ', err);
        const message = err.message || t('checkout.something_is_wrong');
        return confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <AuthLayout testId="view-login" className={'view-login'}>
      <Seo title={'Reset Password Done | SnapFood- Savor and Socialize'} />
      <div className="auth-form">
        <h1>{t('reset_password.header')}</h1>
        <AuthInput
          name={'password'}
          placeholder={t('reset_password.new_password')}
          isSecure={true}
          onChange={(v) => {
            setPassword(v);
          }}
        />
        <AuthInput
          name={'password'}
          placeholder={t('reset_password.confirm_password')}
          isSecure={true}
          onChange={(v) => {
            setConfirmPassword(v);
          }}
        />
        <Spacing height={32} />
        <AuthBtn
          isLoading={isLoading}
          title={t('reset_password.reset_password')}
          onClick={onSubmit}
        />
        <Spacing height={40} />
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
