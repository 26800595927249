import React
// { useEffect, useState }
  from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import PropTypes from 'prop-types';
import './index.css';
import ApplyItem from './ApplyItem';


const responsive = {
  lg: {
    breakpoint: { max: 3000, min: 2000 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  md: {
    breakpoint: { max: 2000, min: 1600 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1600, min: 766 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  small: {
    breakpoint: { max: 350, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const ApplyCards = () => {
  return (
    <div className={'w100 business-card-view ph10 mv5'}>
      <Carousel responsive={responsive} autoPlay={false} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]}>
        {['vendor', 'rider', 'snapfood'].map((item, index) => (
          <div key={index} className={'ph2 pv2 h100'}>
            <ApplyItem
              type={item}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

ApplyCards.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default ApplyCards;
