import React from 'react';
import './index.css';

// eslint-disable-next-line react/prop-types
const OrderingComponent = ({ title, subtitle, features = [] }) => {
  return (
    <div className="snapfood-main-layer">
      <div className="snapfood-title-block">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
      <div className="snapfood-ordering mt5">
        {features.map((item, index) => (
          <div className="sf-section mb8" key={index}>
            <div className="tab-img">
              <img src={item.image} />
            </div>
            <div>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderingComponent;
