import ACTION_TYPES from '../../constants/actionTypes';

export const setAllChannels = (payload) => {
    return { type: ACTION_TYPES.APP_SET_CHANNELS, payload: payload }
}

export const setMessagesByChannel = (payload) => ({ type: ACTION_TYPES.APP_SET_MESSAGES_BY_CHANNEL,payload });

export const setNewInvites = (payload) => ({ type: ACTION_TYPES.APP_SET_NEW_INVITES, payload })

export const setChannelsLoading = (payload) => {
	return { type: ACTION_TYPES.APP_SET_CHANNELS_LOADING, payload: payload };
};

export const setNewMessagesNumber = (payload) => {
  return { type: ACTION_TYPES.APP_SET_NEW_MESSAGE_NUM, payload: payload };
};


