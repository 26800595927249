import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import { RadioBtn } from '../../Buttons';
import { Pay_Card } from '../../../constants/config';
import './index.css';
import PropTypes from 'prop-types';

const CardPayMethodItem = ({ checked, cards, curCard, onClick, onPressCard, onAddCard, style }) => {
  const { t } = useTranslation();
  return (
    <div
      className={'align-col-middle card-payment-method-item'}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}
      style={style}>
      <div className={'flex_between w100'}>
        <div className={'title'} style={checked ? { fontFamily: Theme.fonts.semiBold } : {}}>
          {t(Pay_Card)}
        </div>
        <RadioBtn checked={checked} onClick={onClick ? onClick : () => {}} />
      </div>
      {checked && (
        <div className={'w100 align-col-middle'}>
          <div style={styles.divider} />
          {(cards || []).map((item, index) => (
            <div
              className={'flex_between w100'}
              style={{ paddingTop: 12, paddingBottom: 12 }}
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                if (onPressCard) {
                  onPressCard(item);
                }
              }}>
              <div
                className={'card_number'}
                style={
                  curCard != null && curCard.id === item.id ? { color: Theme.colors.text } : {}
                }>
                **** **** **** {item.card.last4}
              </div>
              <RadioBtn
                onClick={onPressCard ? () => onPressCard(item) : () => {}}
                checked={curCard != null && curCard.id === item.id}
              />
            </div>
          ))}
          <div style={styles.divider} />
          <div onClick={onAddCard ? onAddCard : () => {}}>
            <div className={'add_card_btn'}>+ {t('payment_method.add_new_card')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  divider: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
    height: 1,
    backgroundColor: Theme.colors.gray6
  }
};

CardPayMethodItem.propTypes = {
  checked: PropTypes.bool,
  cards: PropTypes.array,
  curCard: PropTypes.shape({
    id: PropTypes.string
  }),
  onClick: PropTypes.func,
  onPressCard: PropTypes.func,
  onAddCard: PropTypes.func,
  style: PropTypes.object
};

export default CardPayMethodItem;
