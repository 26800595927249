import React, { useEffect, useMemo, useState } from 'react';
import Seo from '../../../components/Seo';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  useParams } from 'react-router-dom';
import './index.css';
// import FilterView from '../../../components/Order/FilterView';
import { Container, Col, Row } from 'react-grid-system';
import { setHomeOrdersFilter } from '../../../store/actions/app';
import { getOrders } from '../../../store/actions/orders';
import NoOrders from '../../../components/Empty/NoOrders';
import OrderItem from '../../../components/Order/OrderItem';
import { Config } from '../../../constants';
import ShopInfoView from '../../../components/VendorDetails/ShopInfoView';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../components/Spinner';
import { decodeHash } from '../../../utils/common';

const PerPage = 15;

const VendorPastOrders = (props) => {
  const { vendor_hash } = useParams();
  const vendor_id = decodeHash(vendor_hash);

  const [orders, setOrders] = useState([]);
  const [isLoading, setLoading] = useState(null);
  const { t } = useTranslation();

  const handover_method = useMemo(() => {
    if (props.vendorData && props.vendorData.order_method != null) {
      let supported_order_methods = props.vendorData.order_method.split('-');
      if (supported_order_methods.length > 0) {
        return supported_order_methods[0];
      }
    }

    return null
  }, [props.vendorData])

  useEffect(() => {
    loadOrders(1, PerPage, true);
  }, [
    props.home_orders_filter.discount,
    props.home_orders_filter.cashback,
    props.home_orders_filter.promotion,
    props.home_orders_filter.split,
    props.home_orders_filter.searchTerm
  ]);

  const getFilers = () => {
    const { discount, cashback, promotion, split, searchTerm } =
      props.home_orders_filter;
    let filters = [];

    filters.push(`vendor_id=${vendor_id}`);
    if (discount) {
      filters.push('discount=1');
    }
    if (cashback) {
      filters.push('cashback=1');
    }
    if (promotion) {
      filters.push('promotion=1');
    }
    if (split) {
      filters.push('split=1');
    }
    if (searchTerm !== '') {
      filters.push('searchTerm=' + searchTerm);
    }
    return filters;
  };

  const loadOrders = (page, perPage) => {
    let filterKeys = getFilers();
    setLoading(true);
    getOrders(page, perPage, filterKeys)
      .then((orderData) => {
        setOrders(orderData.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };


  return (
    <div className={'past-order-screen'}>
      <Seo title={t('web_title.orders')} />
      <div
        className={'vendor-bg overlay-dark '}
        style={{
          backgroundImage: `url('${Config.IMG_BASE_URL}${props.vendorData.profile_path}?w=600&h=600')`
        }}>
        <div className={'flex_between w100 container ph4'}>
          <ShopInfoView
            data={props.vendorData}
            vendor_id={props.vendorData.id}
            is_open={props.vendorData.is_open}
            handover_method={handover_method}
          />
        </div>
      </div>
      <Container fluid className={'container mt4 ph2 max-lg'}>
        <Row>
          {/*<Col xs={12} md={4} lg={3}>*/}
          {/*  <FilterView />*/}
          {/*</Col>*/}
          <Col xs={12} md={12} lg={12}>
            <Row>
              {
              isLoading === true ?
              <div className={'flex_1'}><LoadingSpinner /></div> : 
              isLoading === false && orders.length === 0 ? (
                <NoOrders />
              ) : (
                orders.map((item) => (
                  <Col key={item.id} xs={12} md={6} lg={4}>
                    <OrderItem
                      key={item.id}
                      data={item}
                      order_id={item.id}
                      order_status={item.status}
                    />
                  </Col>
                ))
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <div style={{height: 80}}/>
    </div>
  );
};

VendorPastOrders.propTypes = {
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    isFav: PropTypes.number,
    profile_path: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    is_open: PropTypes.number,
    order_method: PropTypes.string,
    categories: PropTypes.array,
    hash_id: PropTypes.string,
    slug: PropTypes.string,
    vendor_type: PropTypes.string
  }),
  home_orders_filter: PropTypes.shape({
    orders_tab: PropTypes.string,
    searchTerm: PropTypes.string,
    discount: PropTypes.bool,
    cashback: PropTypes.bool,
    promotion: PropTypes.bool,
    split: PropTypes.bool
  }),
  setHomeOrdersFilter: PropTypes.func
};

const mapStateToProps = ({ app, shop }) => ({
  home_orders_filter: app.home_orders_filter,
  vendorData: shop.vendorData,
});

export default connect(mapStateToProps, { setHomeOrdersFilter })(VendorPastOrders);
