import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckBoxBtn from '../../Buttons/CheckBoxBtn';
import { SearchInput } from '../../Inputs';
import { Search } from '@styled-icons/fluentui-system-filled';
import { Theme } from '../../../assets';
import './index.css';
import { setHomeOrdersFilter } from '../../../store/actions/app';
import RoundIconBtn from '../../Buttons/RoundIconBtn';
import { Filter } from '@styled-icons/bootstrap';
import OrdersFilterModal from '../../Modals/OrdersFilterModal';

const FilterView = (props) => {
  const { t } = useTranslation();
  const items = [
    t('orders.filter.discount'),
    t('orders.filter.cashback'),
    t('orders.filter.promotion'),
    t('orders.filter.split_order')
  ];

  const [isFilterModal, setFilterModal] = useState(false)

  const isChecked = (index) => {
    if (index === 0) {
      return props.home_orders_filter.discount === true;
    } else if (index === 1) {
      return props.home_orders_filter.cashback === true;
    } else if (index === 2) {
      return props.home_orders_filter.promotion === true;
    } else if (index === 3) {
      return props.home_orders_filter.split === true;
    }
  };

  const onFilter = (index) => {
    if (index === 0) {
      props.setHomeOrdersFilter({
        discount: !props.home_orders_filter.discount
      });
    } else if (index === 1) {
      props.setHomeOrdersFilter({
        cashback: !props.home_orders_filter.cashback
      });
    } else if (index === 2) {
      props.setHomeOrdersFilter({
        promotion: !props.home_orders_filter.promotion
      });
    } else if (index === 3) {
      props.setHomeOrdersFilter({
        split: !props.home_orders_filter.split
      });
    }
  };

  return (
    <Box role="presentation" className={'orders-filter'}>
      <h1>{t('orders.filter_order')}</h1>
      <div className={'align-middle mb3'}>
        <div className={'flex_1'}>
          <SearchInput
            icon={<Search size={20} color={Theme.colors.gray5} />}
            value={props.home_orders_filter.searchTerm}
            placeholder={t('orders.search_vendors_on_search')}
            onChange={(value) => {
              props.setHomeOrdersFilter({
                searchTerm: value
              });
            }}
          />
        </div>
        <RoundIconBtn
          icon={<Filter size={22} color={Theme.colors.cyan2} />}
          style={{ width: 48, height: 48, marginLeft: 12 }}
          onClick={() => {
            setFilterModal(true);
          }}
        />
      </div>
      <List className={'filter-body'}>
        {items.map((item, index) => (
          <ListItem
            button
            key={index}
            className={'list-btn'}
            onClick={(e) => {
              e.preventDefault();
              onFilter(index);
            }}>
            <ListItemText primary={item} />
            <CheckBoxBtn
              checked={isChecked(index)}
              onClick={() => {
                onFilter(index);
              }}
            />
          </ListItem>
        ))}
      </List>
      {
        isFilterModal &&
        <OrdersFilterModal
          isOpen={isFilterModal}
          onClose={()=>{
            setFilterModal(false)
          }}
        />
      }
    </Box>
  );
};

FilterView.propTypes = {
  home_orders_filter: PropTypes.shape({
    orders_tab: PropTypes.string,
    searchTerm: PropTypes.string,
    discount: PropTypes.bool,
    cashback: PropTypes.bool,
    promotion: PropTypes.bool,
    split: PropTypes.bool
  }),
  setHomeOrdersFilter: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_orders_filter: app.home_orders_filter
});

export default connect(mapStateToProps, { setHomeOrdersFilter })(FilterView);
