import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import LogoImg from '../../assets/images/snapfood-logo.png';
// import LogoImg from '../../assets/images/logo.png';
import LogoWhiteImg from '../../assets/images/snapfood-logo.png';
// import LogoWhiteImg from '../../assets/images/logo-white.png';
import LogoBlackImg from '../../assets/images/snapfood-logo.png';
// import LogoBlackImg from '../../assets/images/logo-black.png';
import { ROUTES_NAMES } from '../../constants';

const Logo = ({ className, type }) => {
  const navigate = useNavigate();
  return (
    <img
      src={type === 'white' ? LogoWhiteImg : type === 'black' ? LogoBlackImg : LogoImg}
      className={className}
      style={{ cursor: 'pointer' }}
      onClick={() => navigate(ROUTES_NAMES.home)}
    />
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.className === nextProps.className && prevProps.type === nextProps.type;
}

Logo.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};
export default React.memo(Logo, arePropsEqual);
