import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import { MemorizedFavBtn } from '../../components/Vendor/FoodItem/ValueItems';
import { toggleProductFavourite } from '../../store/actions/vendors';

const popularProducts = [
  { id: 1, title: 'Kalcone Klasik', price: 450, desc: 'mocarela, proshute, salce' },
  { id: 2, title: 'Kalcone Klasik Plus', price: 550, desc: 'mocarela, proshute, salce' },
  { id: 3, title: 'Kalcone Fiatone', price: 630, desc: 'mocarela, proshute, salce' },
  { id: 4, title: 'Kalcone Raviolone', price: 630, desc: 'mocarela, proshute, salce' },
  { id: 5, title: 'Kalcone Taris', price: 650, desc: 'mocarela, proshute, salce' },
  { id: 6, title: 'Kalcone Farcito', price: 700, desc: 'mocarela, proshute, salce' },
];
// eslint-disable-next-line react/prop-types 
const PopularItems = ({ vendorData }) => {
  const { t } = useTranslation();
  const title = t('landing.popular_items.title');
  const subtitle = t('landing.popular_items.subtitle');
  const popularItems = useMemo(() => {
    let totalProducts = [];
    if (vendorData?.categories?.length > 0) {
      totalProducts = [...vendorData.categories.map((category) =>
        category.products?.length > 0 ? [...category.products] : [])]
    }
    return totalProducts;
  }, [vendorData])


  const onPressFav = (item) => {
    toggleProductFavourite(item?.id, item?.isFav === 1 ? 0 : 1)
      .then(() => { 
      })
      .catch(() => {
      });
  };

  console.log('popularItems' , popularItems)
  return (
    <div className="popular-items">
      <div className="snapfood-title-block">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
      <div className="grids mt5">
        {popularProducts.map((item, index) => (
          <div className="product-item" key={index}>
            <h3>{item.title}</h3>
            <h5>{item.price} L</h5>
            <p>{item.desc}</p>
            <MemorizedFavBtn isFav={item?.isFav} onChange={() => onPressFav(item)} />
          </div>
        ))}
      </div>
    </div>
  );
};

PopularItems.propTypes = {
  vendorData: PropTypes.shape({
    categories: PropTypes.array
  })
};

export default PopularItems;
