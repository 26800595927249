import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import ImgAppIntro from '../../../assets/images/home/app-intro.png';
import './index.css';

const AppIntro = () => {
  const { t } = useTranslation();

  return (
    <div className={'align-middle home-app-intro w100 mt7 ph10'}>
      <div className="left-side">
        <h2 className={'home-new-blocks-title'}>
          {ReactHtmlParser(t('landing.enjoy_with_friend'))}
        </h2>
        <h5 className={'home-new-blocks-desc'}>{ReactHtmlParser(t('landing.enjoy_with_friend_desc'))}</h5>
      </div>
      <div className="right-side">
        <img src={ImgAppIntro} className="home-app-intro-img" />
      </div>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(AppIntro, arePropsEqual);
