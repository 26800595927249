import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Search } from '@styled-icons/evil';
import { CircularProgress, Grid } from '@mui/material';
import UserItem from '../../../../components/Chats/UserItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import './index.css';
import { SearchInput } from '../../../../components/Inputs';
import { Theme } from '../../../../assets';
import { ApiFactory } from '../../../../services';
import Seo from '../../../../components/Seo';
import ChatProfile from '../../../../components/Chats/ChatProfile';
import { isEmpty } from '../../../../utils/common';
import LoadingSpinner from '../../../../components/Spinner';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';

const Snapfooders = (props) => {
  const _isMounted = useRef(true);
  const { snapfooder_id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: null,
    snapfooders: [],
    page: 1,
    totalPages: 1
  });

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getSnapfooders(searchTerm, state.page);
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getSnapfooders = async (search, page) => {
    const params = [`name=${search}`, `page=${page}`];
    if (state.loading == true) {
      return;
    }
    setState({
      ...state,
      loading: true
    });
    ApiFactory.get(`users/snapfooders?${params.join('&')}`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load snapfooders', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const res_snapfooders = data['snapfooders'];
        if (_isMounted.current === true) {
          if (page > 1) {
            const currentSnapfooderIds = state.snapfooders.map((x) => x.id);
            const newItems = res_snapfooders['data'].filter(
              (x) => currentSnapfooderIds.indexOf(x.id) === -1
            );
            setState({
              ...state,
              loading: false,
              snapfooders: state.snapfooders.concat(newItems),
              page: res_snapfooders['current_page'],
              totalPages: res_snapfooders['last_page']
            });
          } else {
            setState({
              ...state,
              loading: false,
              snapfooders: res_snapfooders['data'] || [],
              page: res_snapfooders['current_page'],
              totalPages: res_snapfooders['last_page']
            });
          }
        }
      },
      () => {
        identifyDevice();
        trackEvent('UserSnapfooders', {
          action_category: 'Retrieve Snapfooders',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        setState({
          ...state,
          loading: false
        });
      }
    );
  };

  const updateSnapfoodDetail = (user_id) => {
    ApiFactory.get(`users/snapfooders/${user_id}`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load snapfooder', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const res_snapfooder = data['snapfooder'];
        if (_isMounted.current == true) {
          let tmp = state.snapfooders.slice(0, state.snapfooders.length);
          let found_index = tmp.findIndex((i) => i.id == user_id);
          if (found_index >= 0) {
            tmp[found_index].invite_status = res_snapfooder.invite_status;
            setState({
              ...state,
              snapfooders: tmp
            });
          }
        }
      },
      () => {
        identifyDevice();
        trackEvent('UserSnapfooders', {
          action_category: 'Retrieve Snapfooders',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
      }
    );
  };

  const onSendInvitation = async (item) => {
    ApiFactory.post(`users/friends/update`, {
      user_id: props.user.id,
      friend_id: item.id,
      status: 'invited'
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Update Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        updateSnapfoodDetail(item.id);
      },
      (error) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Update Invites',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = async (item) => {
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: item.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        updateSnapfoodDetail(item.id);
      },
      (error) => {
        identifyDevice();
        trackEvent('UserFriends', {
          action_category: 'Remove Invites',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div style={{ flex: 1 }} className={'snapfooder-list-screen'}>
      <Seo title={t('web_title.chat')} />
      <Grid container spacing={{ xs: 2, md: 2 }} style={{ margin: 0, width: '100%' }}>
        <Grid
          item
          xs={12}
          lg={4}
          className={!isEmpty(snapfooder_id) ? 'hide-md left-side' : 'left-side'}>
          <ChatProfile />
          <div className={'align-col-middle w100 list'}>
            <div className={'align-middle searchview'}>
              <SearchInput
                placeholder={t('social.search.snapfooders')}
                icon={<Search size={20} color={Theme.colors.gray5} />}
                style={{ flex: 1, height: 45 }}
                value={searchTerm}
                onChange={(text) => {
                  setSearchTerm(text);
                  getSnapfooders(text, 1);
                }}
              />
            </div>
            <div className={'scrollview'}>
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                  if (state.page < state.totalPages) {
                    getSnapfooders(searchTerm, state.page + 1);
                  }
                }}
                hasMore={state.page < state.totalPages}
                loader={
                  <div className="align-col-middle mt12 mb20" key={0}>
                    <CircularProgress size={20} color="primary" />
                  </div>
                }
                useWindow={false}>
                {state.loading === true ? (
                  <div className={'flex_1'}>
                    <LoadingSpinner />
                  </div>
                ) : state.loading === false && state.snapfooders.length === 0 ? (
                  <NoFriendList
                    title={t('social.no_snapfooders')}
                    desc={t('social.no_snapfooders_desc')}
                  />
                ) : (
                  state.snapfooders.map((item) => (
                    <UserItem
                      key={item.id}
                      item={item}
                      id={item.id}
                      invite_status={item.invite_status}
                      type="invite_status"
                      onClick={() => {
                        navigate(`/social/snapfoodies/${item.id}`);
                      }}
                      onRightBtnPress={
                        item.invite_status === 'invited'
                          ? () => onCancelInvitation(item)
                          : () => onSendInvitation(item)
                      }
                    />
                  ))
                )}
                <div style={{ height: 40 }} />
              </InfiniteScroll>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className={'align-col-start'} style={{ padding: 0 }}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

Snapfooders.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  })
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  isLoggedIn: app.isLoggedIn
});

export default connect(mapStateToProps, {})(Snapfooders);
