import React from 'react';
import PropTypes from 'prop-types';
import './FeatureItem.css';

const FeatureItem = ({ data, className, style }) => {
  return (
    <div className={'home-app-features-item ' + className} style={style}>
      <div className={'align-start w100 ph1 pv1'}>
        <img src={data.img} />
        <div className={'title fs4 mt1'}>{data.title}</div>
        <div className={'desc fs4 mt1'}>{data.desc}</div>
      </div>
    </div>
  );
};

FeatureItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    img: PropTypes.string,
    desc: PropTypes.string
  }),
  className: PropTypes.string,
  style: PropTypes.object
};
export default FeatureItem;
