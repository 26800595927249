import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode-logo';
import './index.css';
import Img_logo from '../../../assets/images/logo_circle.png';
import getScrollHeight from '../../../utils/getScroll'

const StickyAppDownload = () => {
  const { t } = useTranslation();
  const referralLink = '"https://reward.snapfood.al/referral/?link=https://snapfood.al/reward?apn=com.snapfood.app&isi=1314003561&ibi=com.snapfood.al"'
  const height = getScrollHeight();

  if (height > 80) {
    return (
      <div className={'align-middle download-app-sticky'}>
        <div className={'align-col-middle title'}>
          <h2>{t('get_app.download')}<br/>{t('get_app.snapfood_app')}</h2>
        </div>
        <QRCode value={referralLink} size={window.innerWidth > 576 ? 120 : 80} quietZone={8} removeQrCodeBehindLogo={true} qrStyle={'dots'} logoImage={Img_logo} />
      </div>
    );
  }
  return null;
};

export default StickyAppDownload;
