import React
// { useEffect, useState }
from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import PropTypes from 'prop-types';
import './index.css';
import VendorItem from '../VendorItem';
import { useNavigate } from 'react-router-dom';

const responsive = {
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  md: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  small: {
    breakpoint: { max: 350, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const FeaturedVendors = (props) => {
  const navigate = useNavigate();
  const {restaurants, onFavChange} = props;

  const goVendorDetails = (vendor) => {
    navigate(`/store/${vendor.hash_id}/${vendor.slug}/${vendor.order_method}`);
  };

  return (
    <div className={'w100 featured-vendors-block'}>
      <Carousel responsive={responsive} autoPlay={false}>
        {restaurants.map((vendor) => (
          <VendorItem
            key={vendor.id}
            id={vendor.id}
            isFav={vendor.isFav}
            isOpen={vendor.is_open}
            data={vendor}
            onFavChange={onFavChange}
            onSelect={() => {
              goVendorDetails(vendor);
            }}
          />
        ))}
      </Carousel>
    </div>
  );
};

FeaturedVendors.propTypes = {
  isLoggedIn: PropTypes.bool,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  restaurants: PropTypes.array,
  onFavChange: PropTypes.func
};

export default FeaturedVendors;
