import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthBtn } from '../../../components/Buttons';
import { AuthInput } from '../../../components/Inputs';
import { login, register, setAsLoggedIn, updateProfileDetails } from '../../../store/actions/auth';
import { addDefaultAddress, getAddresses, setAddress } from '../../../store/actions/app';
import Spacing from '../../../components/Spacing';
import { isEmpty, validateUserData, extractErrorMessage } from '../../../utils/common';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { loadUserSetting } from '../../../services/user';
import { ROUTES_NAMES } from '../../../constants';
import { handleFbService } from '../../../services/fb-service';

const RegisterForm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const session_id = location?.state?.session_id;

  const [loading, setLoading] = useState(false);
  const [full_name, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const refferalCode = '';
  const hasLinkedRefferal = false;

  const onSubmit = async (e) => {
    e.preventDefault();
    let pass2 = password;
    handleFbService({
      email,
      event_name: 'New Web User Registered'
    });
    validateUserData({ full_name, email, phone, password, pass2 }, true)
      .then(async () => {
        if (!loading) {
          setLoading(true);
          try {
            let refferal_code = null;
            if (hasLinkedRefferal && !isEmpty(refferalCode)) {
              refferal_code = refferalCode;
            }
            await props.register({ full_name, email, phone, password, refferal_code, session_id });
            const logged_user_data = await props.login({ email, password });

            setLoading(false);
            await loadUserSetting(props, logged_user_data);

            navigate(ROUTES_NAMES.verification);
          } catch (e) {
            setLoading(false);
            confirmAlert({
              title: t('restaurant_details.we_are_sorry'),
              message: extractErrorMessage(e),
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: t('Ok'),
                  className: 'error-ok-btn',
                  onClick: () => {}
                }
              ]
            });
          }
        }
      })
      .catch((err_msg) => {
        confirmAlert({
          title: t('attention'),
          message: t(err_msg),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      });
  };

  return (
    <div className="auth-form">
      <h1>{t('auth_register.header')}</h1>
      <h3>{t('auth_register.header1')}</h3>
      <AuthInput
        placeholder={t('auth_register.full_name')}
        text={full_name}
        onChange={(v) => {
          setFullname(v);
        }}
      />
      <AuthInput
        placeholder={t('auth_register.email')}
        text={email}
        onChange={(v) => {
          setEmail(v);
        }}
      />
      <AuthInput
        placeholder={t('auth_register.cell')}
        text={phone}
        onChange={(v) => {
          setPhone(v);
        }}
      />
      <AuthInput
        placeholder={t('auth_register.password')}
        text={password}
        isSecure={true}
        onChange={(v) => {
          setPassword(v);
        }}
      />
      <Spacing height={28} />
      <AuthBtn isLoading={loading} title={t('auth_register.submit')} onClick={onSubmit} />
      <Spacing height={20} />
      <div className={'center-align auth-bottom-link'}>
        <div className={'text'}>{t('auth_register.already_registered')}</div>
        <div
          className={'btn'}
          onClick={() => {
            props.onChangeView('login');
          }}>
          {t('auth_register.sign_in')}
          <div style={{ height: 50 }}></div>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  onChangeView: PropTypes.func,
  register: PropTypes.func,
  login: PropTypes.func
};
function mapStateToProps({ app }) {
  return {
    user: app.user,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {
  login,
  register,
  setAsLoggedIn,
  updateProfileDetails,
  addDefaultAddress,
  getAddresses,
  setAddress
})(RegisterForm);
