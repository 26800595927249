import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OrderType_Delivery } from '../../../constants/config';
import { Theme } from '../../../assets';
import SmallOrderFeeTooltip from '../../Common/SmallOrderFeeTooltip';
import './index.css';

const PriceBoard = ({
  delivery_type,
  handover_method,
  payment_method = '',
  minOrderPrice,
  small_order_fee,
  delivery_fee,
  cashback,
  discount,
  sub_total,
  total_price,
  tip_rider
}) => {
  const { t } = useTranslation();

  return (
    <div className={'align-col-middle w100 price-board mt2 ph2 pv2'}>
      <div className={'w100 flex_between'}>
        <div className={'key-text fs6'}>{t('cart.subtotal')}</div>
        <div className={'value-text fs6'}>{parseInt(sub_total) + ' L'}</div>
      </div>
      <div className={'w100 flex_between mt2'}>
        <div className={'key-text fs6'}>{t('cart.discount')}</div>
        <div className={'value-text fs6'}>
          {parseInt(discount || 0) === 0 ? '0 L' : `-${parseInt(discount)} L`}
        </div>
      </div>
      <div className={'w100 flex_between mt2'}>
        <div className={'key-text fs6'}>{t('wallet.cashback')}</div>
        <div className={'value-text fs6'}>
          {parseInt(cashback || 0) === 0 ? '0 L' : `-${parseInt(cashback)} L`}
        </div>
      </div>

      {payment_method != 'cash' &&
        handover_method === OrderType_Delivery &&
        delivery_type == 'Snapfood' &&
        tip_rider > 0 && (
          <div className={'w100 flex_between mt2'}>
            <div className={'key-text fs6'}>{t('wallet.leave_rider_tip')}</div>
            <div className={'value-text fs6'}>
              {parseInt(tip_rider || 0) === 0 ? '0 L' : `-${parseInt(tip_rider)} L`}
            </div>
          </div>
        )}

      {handover_method === OrderType_Delivery && small_order_fee > 0 && (
        <div className={'w100 flex_between mt2'}>
          <div className={'key-text align-middle fs6'}>
            {t('cart.small_order_fee')}
            <SmallOrderFeeTooltip
              delivery_minimum_order_price={minOrderPrice}
              small_order_fee={parseInt(small_order_fee)}
            />
          </div>
          <div className={'value-text fs6'}>{parseInt(small_order_fee) + ' L'}</div>
        </div>
      )}
      {handover_method === OrderType_Delivery && (
        <div className={'w100 flex_between mt2'}>
          <div className={'key-text fs6'}>{t('order_details.delivery_fee')}</div>
          <div className={'value-text fs6'}>{parseInt(delivery_fee) + ' L'}</div>
        </div>
      )}
      <div className={'w100 flex_between mt2 top-border'}>
        <div className={'key-text fs6'} style={{ fontFamily: Theme.fonts.bold }}>
          {t('cart.order_total')}
        </div>
        <div className={'value-text fs6'} style={{ fontFamily: Theme.fonts.bold }}>
          {parseInt(total_price) + ' L'}
        </div>
      </div>
    </div>
  );
};

function arePropsEqual() {
  return false;
  // return (
  //   prevProps.handover_method === nextProps.handover_method &&
  //   prevProps.delivery_type === nextProps.delivery_type &&
  //   prevProps.payment_method === nextProps.payment_method &&
  //   prevProps.delivery_fee === nextProps.delivery_fee &&
  //   prevProps.minOrderPrice === nextProps.minOrderPrice &&
  //   prevProps.small_order_fee === nextProps.small_order_fee &&
  //   prevProps.tip_rider === nextProps.tip_rider &&
  //   prevProps.cashback === nextProps.cashback &&
  //   prevProps.discount === nextProps.discount &&
  //   prevProps.sub_total === nextProps.sub_total &&
  //   prevProps.total_price === nextProps.total_price
  // );
}

PriceBoard.propTypes = {
  handover_method: PropTypes.string,
  delivery_type: PropTypes.string,
  payment_method: PropTypes.string,
  delivery_fee: PropTypes.number,
  minOrderPrice: PropTypes.number,
  small_order_fee: PropTypes.number,
  cashback: PropTypes.number,
  tip_rider: PropTypes.number,
  discount: PropTypes.number,
  sub_total: PropTypes.number,
  total_price: PropTypes.number
};

export default React.memo(PriceBoard, arePropsEqual);
