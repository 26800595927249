import React, { useEffect, useState } from 'react';
import Seo from '../../components/Seo';
import VendorMarker from '../../components/Common/SnapfooderMap/VendorMarker';
import VendorSearch from '../../components/Common/VendorSearch';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import GoogleMapReact from 'google-map-react';
import { Config, ROUTES_NAMES } from '../../constants';
import Svg_loc from '../../assets/svgs/markers/pin.svg';
import { OrderType_Delivery, VSort_Closest } from '../../constants/config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHomeVendorFilter } from '../../store/actions/app';
import VendorService from '../../services/apiVendor';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { isEmpty } from '../../utils/common';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

const vertPerPage = 100;
const VendorsMap = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [vendors, setVendors] = useState([]);
  let parsed = querystring.parse(location.search) || {};
  const [isOneVendor, setIsOneVendor] = useState(parsed.is_one_vendor == 1);

  useEffect(() => {
    parsed = querystring.parse(location.search);
    props.setHomeVendorFilter({
      category_id: parsed.category,
      latitude: parsed.latitude || '',
      longitude: parsed.longitude || '',
      formatted_address: parsed.formatted_address || '',
      vendor_type: parsed.vendor_type || 'vendors',
      order_type: parsed.order_type || OrderType_Delivery,
      searchTerm: parsed.searchTerm || ''
    });
    setIsOneVendor(parsed.is_one_vendor == 1);
  }, [location.search]);

  useEffect(() => {
    loadVendors(1, vertPerPage, true);
  }, [
    props.home_vendor_filter.vendor_type,
    props.home_vendor_filter.order_type,
    props.home_vendor_filter.latitude,
    props.home_vendor_filter.longitude,
    props.home_vendor_filter.searchTerm
  ]);

  const getFilers = () => {
    const { vendor_type, order_type, searchTerm } = props.home_vendor_filter;
    let filters = [];
    if (vendor_type === 'vendors') {
      filters.push('vendor_type=Restaurant');
    } else {
      filters.push('vendor_type=Grocery');
    }
    filters.push('order_method=' + order_type);
    if (searchTerm != '') {
      filters.push('name=' + searchTerm);
    }
    return filters;
  };

  const loadVendors = async (page, perPage) => {
    try {
      let { latitude, longitude } = props.home_vendor_filter;
      let filterKeys = getFilers();
      let vendorsData = await VendorService.getVendors(
        page,
        latitude,
        longitude,
        VSort_Closest,
        1,
        perPage,
        filterKeys
      );
      setVendors(vendorsData.data || []);
      identifyDevice();
      trackEvent('Load Vendors FE Web', {
        action_category: 'Load Screen',
        action_outcome: 'success',
        interaction_element: 'screen',
        source: 'sf_web'
      });
    } catch (error) {
      identifyDevice();
      trackEvent('Load Vendors FE Web', {
        action_category: 'Load Screen',
        action_outcome: 'fail',
        interaction_element: 'screen',
        source: 'sf_web'
      });
    }
  };

  const onVendorSearch = (data) => {
    console.log('onVendorSearch', data);
    const parsed = querystring.parse(location.search);

    let params = [];
    if (!isEmpty(parsed.category)) {
      params.push(`category=${parsed.category}`);
    }
    if (!isEmpty(data.curLoc.latitude == null ? null : '' + data.curLoc.latitude)) {
      params.push(`latitude=${data.curLoc.latitude}`);
    }
    if (!isEmpty(data.curLoc.longitude == null ? null : '' + data.curLoc.longitude)) {
      params.push(`longitude=${data.curLoc.longitude}`);
    }
    if (!isEmpty(data.curLoc.formatted_address)) {
      params.push(`formatted_address=${data.curLoc.formatted_address}`);
    }
    if (!isEmpty(data.vendorType)) {
      params.push(`vendor_type=${data.vendorType}`);
    }
    if (!isEmpty(data.orderMethod)) {
      params.push(`order_type=${data.orderMethod}`);
    }
    if (!isEmpty(data.searchTerm)) {
      params.push(`searchTerm=${data.searchTerm}`);
    }

    navigate(`${ROUTES_NAMES.vendorsMap}?${params.join('&')}`, { replace: true });
  };

  const onGoVendor = (vendor) => {
    navigate(`/store/${vendor.hash_id}/${vendor.slug}/${vendor.order_method}`);
  };

  return (
    <div data-testid="view-vendors-map" className={'view-vendors-map'}>
      <Seo title={t('web_title.terms_condition')} />
      <div className={'map-view'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
          center={[
            parseFloat(props.home_vendor_filter.latitude) || Config.TIRANA_CITY_LOCATION.latitude,
            parseFloat(props.home_vendor_filter.longitude) || Config.TIRANA_CITY_LOCATION.longitude
          ]}
          defaultZoom={16}
          options={{
            styles: Config.SocialMapScreenStyles
          }}>
          {vendors.map((vendor) => (
            <VendorMarker
              key={vendor.id}
              lat={vendor.latitude}
              lng={vendor.longitude}
              vendor_id={vendor.id}
              vendor={vendor}
              makerType={'vendor'}
              onGoVendor={() => onGoVendor(vendor)}
            />
          ))}
          {isOneVendor == false &&
            props.home_vendor_filter.latitude &&
            props.home_vendor_filter.longitude && (
              <img
                src={Svg_loc}
                lat={parseFloat(props.home_vendor_filter.latitude)}
                lng={parseFloat(props.home_vendor_filter.longitude)}
              />
            )}
        </GoogleMapReact>
        <div className={'align-col-middle search-bar'}>
          <VendorSearch
            latitude={props.home_vendor_filter.latitude}
            longitude={props.home_vendor_filter.longitude}
            formatted_address={props.home_vendor_filter.formatted_address}
            search_term={props.home_vendor_filter.searchTerm}
            order_method={props.home_vendor_filter.order_type || OrderType_Delivery}
            vendor_type={props.home_vendor_filter.vendor_type || 'vendors'}
            onVendorSearch={onVendorSearch}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn,
  hasLocation: app.hasLocation,
  coordinates: app.coordinates,
  address: app.address || {},
  language: app.language,
  home_vendor_filter: app.home_vendor_filter,
  home_vendor_sort: app.home_vendor_sort,
  vertPage: app.home_page_data.vertPage || 1,
  vertTotalPages: app.home_page_data.vertTotalPages || 1,
  foodCategories: app.foodCategories,
  vendorData: shop.vendorData
});

VendorsMap.propTypes = {
  hasLocation: PropTypes.bool,
  home_vendor_filter: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    formatted_address: PropTypes.string,
    vendor_type: PropTypes.string,
    order_type: PropTypes.string,
    is_meal: PropTypes.bool,
    is_dietary: PropTypes.bool,
    ongoing_offer: PropTypes.bool,
    open_now: PropTypes.bool,
    online_payment: PropTypes.bool,
    low_fee: PropTypes.number,
    high_fee: PropTypes.number,
    searchTerm: PropTypes.string
  }),
  home_vendor_sort: PropTypes.string,
  address: PropTypes.object,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  setHomeVendorFilter: PropTypes.func
};

export default connect(mapStateToProps, {
  setHomeVendorFilter
})(VendorsMap);
