import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Svg_lang from '../../../assets/svgs/language.svg';
import { setAppLang } from '../../../store/actions/app';
import './index.css';
import { connect } from 'react-redux';

const LangBtn = (props) => {
  const {language, height} = props;
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const changeLanguage = (lng) => {
    props.setAppLang(lng);
  };

  const handleText = (ev) => {
    if (ev.currentTarget.textContent === t('english')) {
      changeLanguage('en');
    } else {
      changeLanguage('sq');
    }
  };

  const itemList = () => {
    const list = [t('english'), t('albanian')].map((item) => (
      <div onClick={handleText} className="dropdown__item" key={item.toString()}>
        {item}
      </div>
    ));

    return <div className="dropdown__items"> {list} </div>;
  };

  return (
    <div
      className={isOpen ? 'dropdown active' : 'dropdown'}
      onClick={() => {
        setOpen(!isOpen);
      }}
      style={height && { height: height }}
    >
      <div className="drop-btn" style={height && { height: height - 2 }}>
        <img src={Svg_lang} />
        <h1>{language === 'en' ? 'EN' : 'AL'}</h1>
      </div>
      {itemList()}
    </div>
  );
};

LangBtn.propTypes = {
  height: PropTypes.number,
  language: PropTypes.string,
  setAppLang: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
});

export default connect(mapStateToProps, {
  setAppLang
})(LangBtn);
