import React from 'react';
import './index.css';
import AppleSignin from 'react-apple-signin-auth';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import Svg_apple from '../../assets/svgs/auth/apple.svg';

const LoginApple = ({ onLoginResponse }) => {
  const responseApple = (response) => {
    var decoded_response = jwt_decode(response.authorization.id_token);
    onLoginResponse({
      user: decoded_response.sub,
      identityToken: response.authorization.id_token,
      email: '',
      fullName: ''
    });
  };

  return (
    <AppleSignin
      authOptions={{
        clientId: 'al.snapfoodal.snapfood',
        scope: 'email name',
        redirectURI: window.location.origin,
        usePopup: true
      }} // REQUIRED
      onSuccess={responseApple} // default = undefined
      onError={(error) => console.error(error)} // default = undefined
      render={(props) => (
        <button className={'socialBtn'} {...props}>
          <img src={Svg_apple} style={{ width: 22, height: 22, objectFit: 'contain' }} />
        </button>
      )}
    />
  );
};

LoginApple.propTypes = {
  onLoginResponse: PropTypes.func
};
export default LoginApple;
