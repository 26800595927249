import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import { RadioBtn } from '../../Buttons';
import PropTypes from 'prop-types';
import './index.css';

const CardItem = ({ data, checked, onDelete, onSelect, style }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={
        onSelect
          ? (e) => {
              e.preventDefault();
              onSelect(data);
            }
          : () => {}
      }
      className={' credit-card-item'}
      style={{ ...(checked && { borderColor: Theme.colors.cyan2 }), style }}>
      <div className={'w100 align-middle'}>
        <RadioBtn
          checked={checked}
          onClick={
            onSelect
              ? (e) => {
                  e.preventDefault();
                  onSelect(data);
                }
              : () => {}
          }
        />
        <div className={'primarytxt'} style={checked ? { color: Theme.colors.cyan2 } : {}}>
          {t('primary')}
        </div>
        <div className={'flex_1'} />
        <div
          onClick={
            onDelete
              ? (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onDelete(data);
                }
              : () => {}
          }
          style={{}}>
          <div className={'edit-btn'}>{t('remove')}</div>
        </div>
      </div>
      <div className={'user-name'}>{data.metadata != null ? data.metadata.name : ''}</div>
      {data.card != null && <div className={'card-num'}>**** **** **** {data.card.last4}</div>}
      {data.card != null && (
        <div className={'align-middle w100 mt12'}>
          <div className={'cvv'}>
            {t('card.expiry')}: {data.card.exp_month + '/' + data.card.exp_year}
          </div>
          <div className={'cvv'} style={{textAlign: 'right'}}>CVV: {data.metadata != null ? data.metadata.cvc : ''}</div>
        </div>
      )}
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id && prevProps.checked === nextProps.checked;
}

CardItem.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  data: PropTypes.shape({
    metadata: PropTypes.shape({
      name: PropTypes.string,
      cvc: PropTypes.string
    }),
    card: PropTypes.shape({
      exp_month: PropTypes.number,
      exp_year: PropTypes.number,
      last4: PropTypes.string
    })
  }),
  style: PropTypes.object,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func
};
export default React.memo(CardItem, arePropsEqual);
