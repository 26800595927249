import React from 'react';
import { useTranslation } from 'react-i18next';
import Svg_appstore from '../../../assets/svgs/home/app_store.svg';
import Svg_googleplay from '../../../assets/svgs/home/googleplay.svg';
import './index.css';
import PropTypes from 'prop-types';

const AppIntro = ({android_link, ios_link, style}) => {
  const { t } = useTranslation();

  return (
    <div className={'align-start our-app-intro pv10'} style={style}>
      <div className="content">
        <span className={'craving-something'}>
          {t('landing.ourapp_heading')}&nbsp;&&nbsp;
          <span>{t('landing.ourapp_headingblack')}</span>
        </span>
        {/* <h4 className={'mv4'}>{t('landing.download_app_and_access')}</h4> */}
        <div className="align-middle badge-wrap">
          <a
            href={ios_link}
            rel={'noreferrer'}
            target="_blank"
            className="badge badge-ios"
          >
            <img src={Svg_appstore} />
          </a>
          <a
            href={android_link}
            target="_blank"
            rel={'noreferrer'}
            className="badge badge-android ml4"
          >
            <img src={Svg_googleplay} />
          </a>
        </div>
      </div>
    </div>
  );
};

AppIntro.propTypes = {
  android_link: PropTypes.string,
  ios_link: PropTypes.string,
  style: PropTypes.object
}

function arePropsEqual(prevProps, nextProps) {
  return prevProps.android_link == nextProps.android_link && prevProps.ios_link == nextProps.ios_link;
}

export default React.memo(AppIntro, arePropsEqual);
