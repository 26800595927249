import React, { useState } from 'react';
import { connect } from 'react-redux';
import {  Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import { MainBtn } from '../../Buttons';
import './index.css';
import '../index.css';
import { setHomeOrdersFilter } from '../../../store/actions/app';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxBtn from '../../Buttons/CheckBoxBtn';

const OrdersFilterModal = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(props.home_orders_filter);

  const items = [
    t('orders.filter.discount'),
    t('orders.filter.cashback'),
    t('orders.filter.promotion'),
    t('orders.filter.split_order')
  ];

  React.useEffect(() => {
    setOpen(props.isOpen);
    if (props.isOpen) {
      setFilter(props.home_orders_filter);
    }
  }, [props.isOpen]);

  const onSave = () => {
    props.setHomeOrdersFilter(filter);
    props.onClose()
  };

  const isChecked = (index) => {
    if (index === 0) {
      return filter.discount === true;
    } else if (index === 1) {
      return filter.cashback === true;
    } else if (index === 2) {
      return filter.promotion === true;
    } else if (index === 3) {
      return filter.split === true;
    }
  };

  const onFilter = (index) => {
    if (index === 0) {
      setFilter({
        ...filter,
        discount: !filter.discount
      });
    } else if (index === 1) {
      setFilter({
        ...filter,
        cashback: !filter.cashback
      });
    } else if (index === 2) {
      setFilter({
        ...filter,
        promotion: !filter.promotion
      });
    } else if (index === 3) {
      setFilter({
        ...filter,
        split: !filter.split
      });
    }
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal blog-filter-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative ph2'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h1 className={'title'}>{t('blog.filter_article')}</h1>
        </div>
        <div className={'scroll_view ph2'}>
          <div className={'w100 '}>
            <List className={'filter-body'}>
              {items.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  className={'list-btn'}
                  onClick={(e) => {
                    e.preventDefault();
                    onFilter(index);
                  }}>
                  <ListItemText primary={item} />
                  <CheckBoxBtn
                    checked={isChecked(index)}
                    onClick={() => {
                      onFilter(index);
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <div style={{ height: 15 }} />
          </div>
        </div>
        <div className={'w100 ph2 pt3'}>
          <MainBtn
            className={'save-btn'}
            title={t('save')}
            onClick={onSave}
          />
        </div>
      </div>
    </Dialog>
  );
};


OrdersFilterModal.propTypes = {
  isOpen : PropTypes.bool,
  home_orders_filter: PropTypes.shape({
    orders_tab: PropTypes.string,
    searchTerm: PropTypes.string,
    discount: PropTypes.bool,
    cashback: PropTypes.bool,
    promotion: PropTypes.bool,
    split: PropTypes.bool
  }),
  setHomeOrdersFilter: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_orders_filter: app.home_orders_filter
});

export default connect(mapStateToProps, { setHomeOrdersFilter })(OrdersFilterModal);