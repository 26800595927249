import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyC5UQhb8CRsCehJpxUyMqZ22kX5ql8fLzI',
  authDomain: 'snapfood-3f623.firebaseapp.com',
  databaseURL: 'https://snapfood-3f623.firebaseio.com',
  projectId: 'snapfood-3f623',
  storageBucket: 'snapfood-3f623.appspot.com',
  messagingSenderId: '82651546395',
  appId: '1:82651546395:web:5fe305d2b27ae04c19c9be'
};

// Initialize Firebase
var app = firebase.initializeApp(firebaseConfig);

export const fb_db = app.firestore();
export const auth = firebase.auth();
export const fb_auth = firebase.auth;
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const storage = app.storage();
export const serverTime = firebase.firestore.FieldValue.serverTimestamp();
export const fieldValue = firebase.firestore.FieldValue;
