import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';
import './index.css';

const FoodCategory = ({ id, name, icon, style }) => {
  return (
    <Col key={id} xs={12} sm={6} md={4} xl={3} xxl={2}>
      <div className={'food-category'} style={style}>
        <h3>{name}</h3>
        <div className="img">
          <img src={icon} />
        </div>
      </div>
    </Col>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return !(
    prevProps.id !== nextProps.id ||
    prevProps.name !== nextProps.name ||
    prevProps.icon !== nextProps.icon
  );
}

FoodCategory.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.object
};
export default React.memo(FoodCategory, arePropsEqual);
