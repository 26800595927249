import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';
import PropTypes from 'prop-types';

const CartViewBtn = ({ onClick, cartItems}) => {
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    var total = 0
    cartItems.map((cartItem) => {
      let price = cartItem.price;
      if (cartItem.discount_price != null && cartItem.discount_price > 0) {
        price = cartItem.price - cartItem.discount_price;
        if (price < 0) { price = 0; }
      }
      total = total + price * cartItem.quantity
    })
    setTotalPrice(total)
  }, [cartItems])


  return (
    <div className={'align-middle w100 ph-20 cartview-btn'} onClick={onClick}>
      <div className={'flex_between btn-container'}>
        <div className={''}>
          <div className={'desc-text'}>{t('cart.total')}</div>
          <div className={'total-price'}>{parseInt(totalPrice)} L</div>
        </div>
        <div className={'align-middle'}>
          <div className={'total-price'}>{t('cart.view_cart')}</div>
          <div className={'align-col-middle badge'}>
            <div className={'badge-text'}>{cartItems.length}</div>
          </div>
        </div>
      </div>
      {/*<div className={'anchor'} />*/}
    </div>
  );
};

CartViewBtn.propTypes = {
  style: PropTypes.object,
  cartItems: PropTypes.array,
  onClick: PropTypes.func
};

export default CartViewBtn;
