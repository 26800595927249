import { useEffect, useRef } from 'react';
import { setAllChannels, setChannelsLoading, setNewInvites, setNewMessagesNumber } from '../../../store/actions/chat';
import { connect } from 'react-redux';
import { channel_collection } from '../../../services/chat';

const ChatListener = (props) => {
	const { setAllChannels, setChannelsLoading, userId  } = props;
	const chatchannel_unlistener = useRef(null);
	const mounted = useRef(false);

	const stopLoading = () => setChannelsLoading(false);

	const setChannels = (snapshots) => {
		let channels = [];
		snapshots.forEach((doc) => channels.push(doc.data()));
		setAllChannels(channels);

		try {
			let newMsgs = 0;
			for(let i = 0; i < channels.length; i ++) {
				if (channels[i].unread_cnt != null && channels[i].unread_cnt[userId] != null) {
					newMsgs = newMsgs + channels[i].unread_cnt[userId];
				}
			}
			props.setNewMessagesNumber(newMsgs);
		}
		catch (e) {
			console.log(e)
		}

		stopLoading();
	};

	const getChatChannelsListner = () => {
		if (chatchannel_unlistener.current) chatchannel_unlistener.current();

		if (userId) {
			setChannelsLoading(true);
			chatchannel_unlistener.current = channel_collection()
				.where('users', 'array-contains', userId)
				.orderBy('last_msg.createdAt', 'desc')
				.onSnapshot(setChannels, stopLoading);
		}
	};


	const onUnMount = () => {
		if (chatchannel_unlistener.current) chatchannel_unlistener.current();
	};


	const onMount = () => {
		mounted.current = true;
		getChatChannelsListner();
		return onUnMount;
	};

	useEffect(onMount, []);
	useEffect(getChatChannelsListner, [userId]);

	return null;
};

const mapStateToProps = ({ app }) => ({ userId: app.user.id, activeRoute: app.activeRoute });

export default connect(mapStateToProps, { setAllChannels, setChannelsLoading, setNewInvites , setNewMessagesNumber})(ChatListener);