import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-tooltip-lite';
import { Close } from '@styled-icons/evaicons-solid';
import { ShoppingBag } from '@styled-icons/material-rounded';
import PropTypes from 'prop-types';
import './index.css';
import { RoundIconBtn } from '../../Buttons';
import { Theme } from '../../../assets';

const CartBtn = ({ cartItems, goCart }) => {
  const { t } = useTranslation();
  const [isTooltip, showTooltip] = useState(false);

  useEffect(() => {
    if (cartItems != null && cartItems.length > 0) {
      showTooltip(true);
    }
  }, [cartItems]);

  return (
    <Tooltip
      className={'vendor_cart_btn'}
      eventOn="onClick"
      eventOff="onMouseOut"
      useHover={false}
      direction={'up'}
      forceDirection={true}
      isOpen={isTooltip}
      content={
        <div className={'flex_between'}>
          <p>{t('restaurant_details.check_cart')}</p>
          <Close size={18} color={Theme.colors.text} onClick={() => showTooltip(false)} />
        </div>
      }
      arrowContent={
        <svg style={{ display: 'block' , transform: 'rotate(180deg)'}} viewBox="0 0 21 11" width="20px" height="10px">
          <path
            d="M0,11 L9.43630703,1.0733987 L9.43630703,1.0733987 C10.1266203,0.3284971 11.2459708,0 11.936284,1.0733987 L20,11"
            style={{ stroke: 'gray', fill: 'white' }}
          />
        </svg>
      }>
      <RoundIconBtn
        style={styles.headerBtn}
        icon={<ShoppingBag size={20} color={Theme.colors.text} />}
        onClick={() => {
          if (cartItems != null && cartItems.length > 0) {
            goCart();
          }
        }}
      />
    </Tooltip>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white }
};

CartBtn.propTypes = {
  cartItems: PropTypes.array,
  goCart: PropTypes.func
};
const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  cartItems: shop.items
});
export default connect(mapStateToProps, {})(CartBtn);
