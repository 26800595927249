import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import apiFactory from '../../../../services/apiFactory';
import { CommonTabs } from '../../../../components/TabSelector';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import InvitationHistItem from '../../../../components/Profile/InvitationHistItem';
import { useTranslation } from 'react-i18next';
import RoundIconBtn from '../../../../components/Buttons/RoundIconBtn';
import { ChevronLeft } from '@mui/icons-material';
import Theme from '../../../../assets/theme';
import querystring from 'query-string';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';

const EarnInvitationHistory = () => {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = querystring.parse(location.search);

  const active_only = parsed.active == 1;

  const [opType, setOpType] = useState('sent');
  const [sents, setSents] = useState([]);
  const [receiveds, setReceiveds] = useState([]);

  const [isLoadingSent, setLoadingSent] = useState(null);
  const [isLoadingReceived, setLoadingReceived] = useState(null);

  useEffect(() => {
    _isMounted.current = true;
    getSentInvitations();
    getReceivedInvitations();
    return () => {
      console.log('InvitationHist Screen  unmount');
      _isMounted.current = false;
    };
  }, []);

  const getSentInvitations = async () => {
    setLoadingSent(true);
    apiFactory
      .get('/invite-earn/get-invitation_hist?sent=1' + (active_only ? '&active=1' : ''))
      .then(({ data }) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Retrieve Invitation History',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        if (_isMounted.current == true) {
          setSents(data.invitations || []);
          setLoadingSent(false);
        }
      })
      .catch((err) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Retrieve Invitation History',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        if (_isMounted.current == true) {
          setLoadingSent(false);
          console.log('err getSentInvitations', err);
        }
      });
  };

  const getReceivedInvitations = async () => {
    setLoadingReceived(true);
    apiFactory
      .get('/invite-earn/get-invitation_hist?sent=0' + (active_only ? '&active=1' : ''))
      .then(({ data }) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Retrieve Invitation History',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        if (_isMounted.current == true) {
          setReceiveds(data.invitations || []);
          setLoadingReceived(false);
        }
      })
      .catch((err) => {
        identifyDevice();
        trackEvent('InviteEarn', {
          action_category: 'Retrieve Invitation History',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        if (_isMounted.current == true) {
          setLoadingReceived(false);
          console.log('err getReceivedInvitations', err);
        }
      });
  };

  const _renderOperationTabs = () => {
    return (
      <div className={'align-middle operation-tab ph2 pv2'}>
        <CommonTabs
          className={'w100'}
          items={['sent', 'received']}
          item={opType}
          style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
          onChange={(item) => {
            setOpType(item);
          }}
        />
      </div>
    );
  };

  return (
    <div style={{ flex: 1 }} className={'earn-invitation-history'}>
      <div className={'align-middle header-view'}>
        <RoundIconBtn
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={'title flex_1'}>
          {active_only
            ? t('invitation_earn.active_earninvitations')
            : t('invitation_earn.invitation_hist')}
        </div>
        <div style={{ width: 32 }} />
      </div>
      {_renderOperationTabs()}
      <div className={'scroll-view'}>
        <div style={{ height: 20 }} />
        {(opType == 'sent' ? sents : receiveds).map((item, index) => (
          <InvitationHistItem
            key={index}
            data={item}
            is_received={opType == 'received'}
            style={{ width: '100%', marginBottom: 12 }}
            onSelect={() => {
              navigate(`/profile/earn-invitations/${item.id}`);
            }}
          />
        ))}
        <div style={{ height: 40 }} />
        {((opType == 'sent' && sents.length == 0 && isLoadingSent == false) ||
          (opType != 'sent' && receiveds.length == 0 && isLoadingReceived == false)) && (
          <NoFriendList
            title={t('social.no_invitations')}
            desc={
              opType == 'Sent'
                ? t('social.no_sent_invitations')
                : t('social.no_received_invitations')
            }
          />
        )}
      </div>
    </div>
  );
};

export default EarnInvitationHistory;
