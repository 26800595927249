export const MIN_COMPOSER_HEIGHT = 41;
export const MAX_COMPOSER_HEIGHT = 41;
export const DEFAULT_PLACEHOLDER = 'Type a message...';
export const DATE_FORMAT = 'll';
export const TIME_FORMAT = 'LT';

export const API_AI = {
  content_type: 'application/json',
  path: 'ai',
  has_token: true
};

export const WEB_AI = {
  content_type: 'application/json',
  path: 'web',
  has_token: true
};
export const API_QUIZ = {
  content_type: 'application/json',
  path: 'ai/store-quiz-answers',
  has_token: true
};
