import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Svg_bicycle from '../../assets/svgs/courier/bicycle.svg';
import Svg_bicycle_inactive from '../../assets/svgs/courier/bicycle_inactive.svg';
import Svg_scooter from '../../assets/svgs/courier/scooter_active.svg';
import Svg_scooter_inactive from '../../assets/svgs/courier/scooter.svg';
import Svg_car from '../../assets/svgs/courier/car_active.svg';
import Svg_car_inactive from '../../assets/svgs/courier/car.svg';
import './VehicleType.css';

const VehicleType = ({ style, onChange }) => {
  const { t } = useTranslation();
  const items = [
    {
      type: 'Bike',
      name: t('courier.bicycle'),
      icon: Svg_bicycle,
      inactive_icon: Svg_bicycle_inactive
    },
    {
      type: 'Motorbike',
      name: t('courier.scooter'),
      icon: Svg_scooter,
      inactive_icon: Svg_scooter_inactive
    },
    { type: 'Car', name: t('courier.car'), icon: Svg_car, inactive_icon: Svg_car_inactive }
  ];
  const [type, setType] = useState('Bike');

  return (
    <div className={'vehicle-switch'}>
      {items.map((it) => (
        <div key={it.type} className={'flex_1'}>
          <Button
            variant="contained"
            className={'vehicletype-btn ' + (it.type === type ? 'active' : 'inactive')}
            style={style}
            onClick={() => {
              onChange(it.type);
              setType(it.type);
            }}
          >
            <img src={it.type === type ? it.icon : it.inactive_icon} />
            {it.name}
          </Button>
        </div>
      ))}
    </div>
  );
};

VehicleType.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func
};
export default React.memo(VehicleType);
