import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import './index.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setPriceCart } from '../../../store/actions/shop';
import { AuthInput } from '../../Inputs';

const CashbackInput = (props) => {
  const { t } = useTranslation();
  return (
    <div className={'flex_between  cashback-row mt2'}>
      <div style={{ justifyContent: 'center' }}>
        <div className={'subject-title fs4'}>{t('cart.want_use_cashback')}</div>
        <div className={'balance fs2'}>
          {t('wallet.balance')} {parseInt(props.cashback_amount) || 0} L
        </div>
      </div>
      <AuthInput
        style={{
          width: 120,
          height: 46,
          marginBottom: 0,
          borderWidth: 1,
          borderColor: Theme.colors.gray6,
          backgroundColor: Theme.colors.white,
          textAlign: 'center'
        }}
        placeholder={t('cart.enter_amount')}
        textAlign="center"
        editable={parseInt(props.cashback_amount) > 0}
        type={'number'}
        text={props.cashback === 0 ? '' : '' + props.cashback}
        onChange={(t) => {
          let int_val = t !== '' ? parseInt(t) : 0;
          let balance = props.cashback_amount || 0;

          if (int_val <= balance) {
            props.setPriceCart({
              ...props.cartPrice,
              cashback: int_val
            });
          }
        }}
      />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.cashback_amount === nextProps.cashback_amount &&
    prevProps.cashback === nextProps.cashback
  );
}

const mapStateToProps = ({ app, shop }) => ({
  cartPrice: shop.cartPrice,
  cashback_amount: app.user.cashback_amount,
  cashback: shop.cartPrice.cashback
});

CashbackInput.propTypes = {
  cartPrice: PropTypes.object,
  cashback: PropTypes.number,
  cashback_amount: PropTypes.number,
  setPriceCart: PropTypes.func
};

export default connect(mapStateToProps, {
  setPriceCart
})(React.memo(CashbackInput, arePropsEqual));
