import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkInSameWeek, convertTimestamp2Date, getImageFullURL } from '../../../utils/common';
import './index.css';

const ChatItem = ({ channel_id, user_id, item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _getPhoto = () => {
    if (item.channel_type === 'single') {
      if (user_id === item.creator.id) {
        return getImageFullURL(item.partner.photo);
      } else if (user_id === item.partner.id) {
        return getImageFullURL(item.creator.photo);
      }
    } else {
      return getImageFullURL(item.photo);
    }
    return getImageFullURL('default');
  };
  const _getName = () => {
    if (item.channel_type === 'single') {
      if (user_id === item.creator.id) {
        return item.partner.username || item.partner.full_name;
      } else if (user_id === item.partner.id) {
        return item.creator.username || item.creator.full_name;
      }
    } else {
      return item.username || item.full_name;
    }
    return '';
  };
  const _getLastMsg = () => {
    if (item.last_msg == null) {
      return '';
    }
    if (item.last_msg.user == null) {
      return '';
    }

    if (item.channel_type === 'single') {
      if (item.last_msg.map != null) {
        return t('social.chat.user_shared_location');
      } else if (item.last_msg.emoji != null) {
        return item.last_msg.emoji.length > 0
          ? item.last_msg.emoji.map((_item) => _item.code).join('')
          : '';
      } else if (item.last_msg.images != null) {
        return t('social.chat.user_shared_photo');
      } else if (item.last_msg.audio != null) {
        return t('social.chat.user_shared_audio');
      } else if (item.last_msg.text != null) {
        return item.last_msg.text;
      }
      return '';
    } else {
      let isMe = false;
      let last_msg_user = item.last_msg.user.username || item.last_msg.user.full_name;
      if (user_id === item.last_msg.user._id) {
        last_msg_user = t('you');
        isMe = true;
      }

      if (item.last_msg.map != null) {
        return (
          last_msg_user +
          ': ' +
          (isMe ? t('social.chat.you_shared_location') : t('social.chat.user_shared_location'))
        );
      } else if (item.last_msg.emoji != null) {
        return (
          last_msg_user +
          ': ' +
          (isMe ? t('social.social.you_sent_emoji') : t('social.social.user_sent_emoji'))
        );
        // + (item.last_msg.emoji.code != null ? item.last_msg.emoji.code : item.last_msg.emoji.name)
      } else if (item.last_msg.images != null) {
        return (
          last_msg_user +
          ': ' +
          (isMe ? t('social.chat.you_shared_photo') : t('social.chat.user_shared_photo'))
        );
      } else if (item.last_msg.audio != null) {
        return (
          last_msg_user +
          ': ' +
          (isMe ? t('social.chat.you_shared_audio') : t('social.chat.user_shared_audio'))
        );
      } else if (item.last_msg.text != null) {
        return last_msg_user + ': ' + item.last_msg.text;
      }
      return '';
    }
  };

  const _getTime = () => {
    if (item.last_msg != null && item.last_msg.createdAt != null) {
      let msg_moment = moment(convertTimestamp2Date(item.last_msg.createdAt));
      let cur_moment = moment();
      if (msg_moment.format('DD/MM/YYYY') === cur_moment.format('DD/MM/YYYY')) {
        return moment(convertTimestamp2Date(item.last_msg.createdAt)).format('h:mm A');
      }
      if (checkInSameWeek(convertTimestamp2Date(item.last_msg.createdAt))) {
        return msg_moment.format('dddd');
      }
      return msg_moment.format('DD/MM/YYYY');
    } else {
      return '';
    }
  };
  const _getUnreadCnt = () => {
    if (item.unread_cnt != null) {
      return item.unread_cnt[user_id] || 0;
    }
    return 0;
  };

  const enterChannel = (e) => {
    e.preventDefault();
    navigate(`/social/chat/${channel_id}`);
  };

  return (
    <ButtonBase className={'chat-channel-item ph2 pv2 mb2'} onClick={enterChannel}>
      <img src={_getPhoto()} className={'mr2'} />
      <div style={{ flex: 1 }}>
        <div className={'flex_between'}>
          <div className={'name'}>{_getName()}</div>
          <div className={'time'}>{_getTime()}</div>
        </div>
        <div className={'flex_between mt1'}>
          <div className={'message'}>{_getLastMsg()}</div>
          {_getUnreadCnt() > 0 && (
            <div className={'unreadContainer'}>
              <div className={'unread'}>{_getUnreadCnt()}</div>
            </div>
          )}
        </div>
      </div>
    </ButtonBase>
  );
};

ChatItem.propTypes = {
  user_id: PropTypes.number,
  channel_id: PropTypes.string,
  item: PropTypes.shape({
    channel_type: PropTypes.string,
    photo: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    creator: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      full_name: PropTypes.string,
      photo: PropTypes.string
    }),
    partner: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
      full_name: PropTypes.string,
      photo: PropTypes.string
    }),
    last_msg: PropTypes.shape({
      id: PropTypes.string,
      emoji: PropTypes.array,
      map: PropTypes.object,
      images: PropTypes.array,
      audio: PropTypes.object,
      text: PropTypes.string,
      user: PropTypes.shape({
        _id: PropTypes.number,
        username: PropTypes.string,
        full_name: PropTypes.string,
        photo: PropTypes.string
      }),
      createdAt: PropTypes.object
    }),
    unread_cnt: PropTypes.object
  }),
  style: PropTypes.object
};

export default ChatItem;
