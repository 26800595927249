import React, { useEffect, useState } from 'react';
import Seo from '../../../components/Seo';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './index.css';
import FilterView from '../../../components/Order/FilterView';
import { CommonTabs } from '../../../components/TabSelector';
import { Container, Col, Row } from 'react-grid-system';
import InfiniteScroll from 'react-infinite-scroller';
import { setHomeOrdersFilter, setAppHeaderClass } from '../../../store/actions/app';
import { getOrders } from '../../../store/actions/orders';
import NoOrders from '../../../components/Empty/NoOrders';
import OrderItem from '../../../components/Order/OrderItem';
import LoadingSpinner from '../../../components/Spinner';

const PerPage = 15;

const Orders = (props) => {
  const { t } = useTranslation();
  const [curLoading, setCurLoading] = useState(null);
  const [pastLoading, setPastLoading] = useState(null);
  const [orders, setOrders] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);

  const [curPageTab1, setCurPageTab1] = useState(1);
  const [totalPagesTab1, setTotalPagesTab1] = useState(1);
  const [curPageTab2, setCurPageTab2] = useState(1);
  const [totalPagesTab2, setTotalPagesTab2] = useState(1);

  useEffect(() => {
    props.setAppHeaderClass('app-sticky-header');
    return () => {
      props.setAppHeaderClass('');
    };
  }, []);

  useEffect(() => {
    loadOrders(1, PerPage, true);
  }, [
    props.home_orders_filter.discount,
    props.home_orders_filter.cashback,
    props.home_orders_filter.promotion,
    props.home_orders_filter.split,
    props.home_orders_filter.searchTerm,
    props.home_orders_filter.orders_tab
  ]);

  const getFilers = () => {
    const { discount, cashback, promotion, split, searchTerm, orders_tab } =
      props.home_orders_filter;
    let filters = [];
    if (discount) {
      filters.push('discount=1');
    }
    if (cashback) {
      filters.push('cashback=1');
    }
    if (promotion) {
      filters.push('promotion=1');
    }
    if (split) {
      filters.push('split=1');
    }
    if (searchTerm !== '') {
      filters.push('searchTerm=' + searchTerm);
    }
    if (orders_tab === 'current') {
      filters.push('status=current');
    }
    if (orders_tab === 'past') {
      filters.push('status=past');
    }
    return filters;
  };

  const loadOrders = (page, perPage, forceLoading = false) => {
    const { orders_tab } = props.home_orders_filter;

    if ((curLoading || pastLoading) && forceLoading === false) {
      return;
    }
    if (orders_tab === 'current') {
      setCurLoading(true);
    } else {
      setPastLoading(true);
    }

    let filterKeys = getFilers();

    getOrders(page, perPage, filterKeys)
      .then((orderData) => {
        if (page > 1) {
          if (orders_tab === 'current') {
            const currentOrderIds = orders.map((x) => x.id);
            const newOrders = orderData.data.filter((x) => currentOrderIds.indexOf(x.id) === -1);
            setOrders([...orders, ...newOrders]);
            setCurPageTab1(orderData['current_page']);
            setTotalPagesTab1(orderData['last_page']);
          } else {
            const currentOrderIds = pastOrders.map((x) => x.id);
            const newOrders = orderData.data.filter((x) => currentOrderIds.indexOf(x.id) === -1);
            setPastOrders([...pastOrders, ...newOrders]);
            setCurPageTab2(orderData['current_page']);
            setTotalPagesTab2(orderData['last_page']);

            console.log('current_page ', orderData['current_page'], orderData['last_page']);
          }
        } else {
          if (orders_tab === 'current') {
            setOrders(orderData.data);
            setCurPageTab1(orderData['current_page']);
            setTotalPagesTab1(orderData['last_page']);
          } else {
            setPastOrders(orderData.data);
            setCurPageTab2(orderData['current_page']);
            setTotalPagesTab2(orderData['last_page']);
          }
        }
        if (orders_tab === 'current') {
          setCurLoading(false);
        } else {
          setPastLoading(false);
        }
      })
      .catch(() => {
        if (orders_tab === 'current') {
          setCurLoading(false);
        } else {
          setPastLoading(false);
        }
      });
  };

  const loadNextPage = async (isCurrent = false) => {
    if (isCurrent) {
      loadOrders(curPageTab1 + 1, PerPage, false);
    } else {
      loadOrders(curPageTab2 + 1, PerPage, false);
    }
  };

  return (
    <div className={'order-screen'}>
      <Seo title={t('web_title.orders')} />
      <Container fluid className={'container max-lg'}>
        <h2 className={'ph2'}>{t('orders.your_orders')}</h2>
        <Row className={'align-col-middle tab-wrapper'}>
          <Col xs={12} md={4}>
            <CommonTabs
              className={'w100'}
              items={['current', 'past']}
              item={props.home_orders_filter.orders_tab}
              style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
              onChange={(item) => {
                props.setHomeOrdersFilter({
                  ...props.home_orders_filter,
                  orders_tab: item
                });
              }}
            />
          </Col>
        </Row>
        <Row className={'ph2'}>
          <Col xs={12} md={4} lg={3}>
            <FilterView />
          </Col>
          <Col xs={12} md={8} lg={9}>
            {props.home_orders_filter.orders_tab === 'current' ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => loadNextPage(true)}
                hasMore={curPageTab1 < totalPagesTab1}
                loader={
                  <div className='align-col-middle mt1 mb10' key={0}>
                    <CircularProgress size={20} color='primary' />
                  </div>
                }
                useWindow={true}>
                <Row>
                  {curLoading === true ?
                    <div className={'flex_1'}><LoadingSpinner /></div> :
                  curLoading === false && orders.length === 0 ? (
                    <NoOrders isCurrent={true} />
                  ) : (
                    orders.map((item, index) => (
                      <Col key={index} xs={12} md={6} lg={4} className={'order-item-wrap'}>
                        <OrderItem
                          key={item.id}
                          data={item}
                          order_id={item.id}
                          order_status={item.status}
                        />
                      </Col>
                    ))
                  )}
                </Row>
              </InfiniteScroll>
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => loadNextPage(false)}
                hasMore={curPageTab2 < totalPagesTab2}
                loader={
                  <div className='align-col-middle mt12 mb20' key={0}>
                    <CircularProgress size={20} color='primary' />
                  </div>
                }
                useWindow={true}>
                <Row>
                  {pastLoading === true ?
                  <div className={'flex_1'}><LoadingSpinner /></div> :
                  pastLoading === false && pastOrders.length === 0 ? (
                    <NoOrders />
                  ) : (
                    pastOrders.map((item, index) => (
                      <Col key={index} xs={12} md={6} lg={4} className={'order-item-wrap'}>
                        <OrderItem
                          key={item.id}
                          data={item}
                          order_id={item.id}
                          order_status={item.status}
                        />
                      </Col>
                    ))
                  )}
                </Row>
              </InfiniteScroll>
            )}
          </Col>
        </Row>
        <div style={{height: 80}}/>
      </Container>
    </div>
  );
};

Orders.propTypes = {
  home_orders_filter: PropTypes.shape({
    orders_tab: PropTypes.string,
    searchTerm: PropTypes.string,
    discount: PropTypes.bool,
    cashback: PropTypes.bool,
    promotion: PropTypes.bool,
    split: PropTypes.bool
  }),
  setHomeOrdersFilter: PropTypes.func,
  setAppHeaderClass: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_orders_filter: app.home_orders_filter
});

export default connect(mapStateToProps, { setHomeOrdersFilter, setAppHeaderClass })(Orders);
