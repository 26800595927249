import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';
import PropTypes from 'prop-types';
import { MapMarkerAlt } from '@styled-icons/fa-solid';
import { ClockFill } from '@styled-icons/bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ROUTES_NAMES } from '../../../constants';
import { Theme } from '../../../assets';
import { connect } from 'react-redux';
import { setActiveSnapfoodMarker } from '../../../store/actions/app';
import SmallOrderFeeTooltip from '../../Common/SmallOrderFeeTooltip';

const VendorInfo = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getOpeningTime = () => {
    let tm_str = t('vendor_profile.open_at');
    if (data.vendor_opening_days != null) {
      let today = new Date().getDay().toString();
      let foundIndex = data.vendor_opening_days.findIndex((item) => item.week_day === today);

      if (foundIndex !== -1) {
        tm_str =
          tm_str +
          moment(data.vendor_opening_days[foundIndex].time_open, 'HH:mm:ss').format('H:mm A');
        tm_str =
          tm_str +
          ' - ' +
          moment(data.vendor_opening_days[foundIndex].time_close, 'HH:mm:ss').format('H:mm A');
      }
    }
    return tm_str;
  };

  const openVendorOnMap = () => {
    props.setActiveSnapfoodMarker({
      type: 'vendor-marker',
      id: data.id
    });

    let params = [];
    params.push(`latitude=${data.latitude}`);
    params.push(`longitude=${data.longitude}`);
    params.push(`formatted_address=${data.address} ${data.country}`);
    params.push(`vendor_type=${data.vendor_type == 'Restaurant' ? 'vendors' : 'grocery'}`);
    params.push(`order_type=${data.order_method}`);
    params.push(`searchTerm=${data.title}`);

    params.push(`is_one_vendor=1`);

    navigate(`${ROUTES_NAMES.vendorsMap}?${params.join('&')}`, { replace: true });
  };

  return (
    <div className={'row-start vendor-info w100 ph4 flex_wrap'} style={{ alignItems: 'flex-start' }}>
      <div className={'align-col-middle mt2'}>
        <div className={'w100 align-row-start'}>
          <div className={'align-middle flex_1'} style={{ marginLeft: '-2px', justifyContent : 'flex-start' }}>
            <MapMarkerAlt size={18} color={Theme.colors.text} />
            <p>{data.address}</p>
          </div>
          <a
            onClick={() => openVendorOnMap()}
          >
            {t('vendor_profile.view_on_map')}
          </a>
        </div>
        <div className={'align-row-start w100 mt1'}>
          <ClockFill size={16} color={Theme.colors.text} />
          <p>{getOpeningTime()}</p>
        </div>
      </div>
      <div className={'align-col-middle mt2'}>
        <div className={'align-row-start'}>
          <div className={'title flex_1 pr1'}>
            <div>{t('vendor_profile.min_order')}</div>
            <SmallOrderFeeTooltip
              delivery_minimum_order_price={data.delivery_minimum_order_price ? data.delivery_minimum_order_price : 0}
              small_order_fee={parseInt(data.small_order_fee)}
            />
            :
          </div>
          <div className={'title'}>
            {data.delivery_minimum_order_price ? data.delivery_minimum_order_price : 0} L
          </div>
        </div>
        {data.small_order_fee != null && data.small_order_fee !== 0 && (
          <div className={'align-row-start mt1'}>
            <div className={'title  flex_1  pr1'}>
              <div>{t('vendor_profile.small_order_fee')}</div>
              <SmallOrderFeeTooltip
                delivery_minimum_order_price={data.delivery_minimum_order_price ? data.delivery_minimum_order_price : 0}
                small_order_fee={parseInt(data.small_order_fee)}
              />
              :
            </div>
            <div className={'title'}>{data.small_order_fee} L</div>
          </div>
        )}
      </div>
      <div className={'align-col-middle mt2'}>
        <div className={'title'}>{t('vendor_profile.offers_method')} {t(data.order_method)}</div>
      </div>
    </div>
  );
};

VendorInfo.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    order_method: PropTypes.string,
    delivery_fee: PropTypes.number,
    minimum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    vendor_opening_days: PropTypes.array,
    logo_thumbnail_path: PropTypes.string,
    vendor_type: PropTypes.string,
    country: PropTypes.string,
    is_open: PropTypes.number,
    distance: PropTypes.number
  }),
  setActiveSnapfoodMarker: PropTypes.func
};

const mapStateToProps = ({ app, shop }) => ({
  language: app.language,
  coordinates: app.coordinates,
  vendorData: shop.vendorData,
  isLoggedIn: app.isLoggedIn
});


export default connect(mapStateToProps, {
  setActiveSnapfoodMarker
})(VendorInfo);
