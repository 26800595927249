import React, { useState, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@mui/material';
import { updateProfileDetails } from '../../../store/actions/auth';
import { useTranslation } from 'react-i18next';
import { Edit2Outline } from '@styled-icons/evaicons-outline';
import ImageCapture from 'react-image-data-capture';
import {
  getImageFullURL,
  isEmpty,
  validateEmailAddress,
  validatePhoneNumber
} from '../../../utils/common';
import { Theme } from '../../../assets';
import { AuthInput } from '../../../components/Inputs';
import ImgPickOptionModal from '../../../components/Modals/ImgPickOptionModal';
// import LoadingModal from '../../../components/Modals/LoadingModal';
import moment from 'moment';
import PropTypes from 'prop-types';
import Img_user from '../../../assets/images/user-default.png';
import './index.css';
import '../index.css'
import { confirmAlert } from 'react-confirm-alert';
import { updateChannelUserInfo } from '../../../services/chat';
import AuthBtn from '../../Buttons/AuthBtn';
import CommonTooltip from '../../../components/Common/CommonTooltip';
import TextSelector from '../../../components/DropdownSelectors/TextSelector';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import DateSelector from '../../DropdownSelectors/DateSelector';

const EditProfileModal = (props) => {
  const { t } = useTranslation();

  const file_input = useRef(null);

  const _year = useRef(null);
  const _month = useRef(null);
  const _day = useRef(null);

  const config = useMemo(() => ({ video: true }), []);
  const [isCameraOn, setIsCameraOn] = useState(false);


  const [open, setOpen] = useState(false);
  const [isLoading, ShowLoading] = useState(false);
  const [showPickerModal, setShowPickerModal] = useState(false);
  const [photoData, setPhotoData] = useState(null);
  const [full_name, setFullName] = useState(props.user.full_name);
  const [phone, setPhone] = useState(props.user.phone);
  const [email, setEmail] = useState(props.user.email);
  const [gender, setGender] = useState(props.user.sex);
  const [birthday, setBirthday] = useState(
    props.user.birthdate == null ? null : moment(props.user.birthdate).toDate()
  );
  const [username, setUsername] = useState(
    props.user.username == null ? null : props.user.username
  );

  React.useEffect(() => {
    setOpen(props.isOpen);
    if (props.isOpen) {
      setFullName(props.user.full_name);
      setUsername(props.user.username == null ? null : props.user.username);
      setPhone(props.user.phone);
      setEmail(props.user.email);
      setGender(props.user.sex);
      setBirthday(props.user.birthdate == null ? null : moment(props.user.birthdate).toDate());
    }
  }, [props.isOpen]);

  const onSave = async () => {
    let errorMsg = null;
    if (isEmpty(full_name) || isEmpty(phone)) {
      errorMsg = 'account_details.required_fields';
    }
      else if (!isEmpty(email) && validateEmailAddress(email) === false) {
      errorMsg = 'alerts.wrong_email_format';
    }
    else if (validatePhoneNumber(phone.replace(/\s/g, '')) === false) {
      errorMsg = 'alerts.wrong_phone_format';
    }

    if (errorMsg != null) {
      return confirmAlert({
        title: t('attention'),
        message: t(errorMsg),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }

    try {
      ShowLoading(true);
      const updated_user = await props.updateProfileDetails({
        full_name,
        email,
        phone,
        gender: gender === '' ? null : gender,
        birthday: birthday == null ? null : moment(birthday).format('YYYY-MM-DD'),
        username,
        photo: photoData == null ? null : photoData.split(',')[1]
      });

      await updateChannelUserInfo(updated_user);
      ShowLoading(false);
      confirmAlert({
        title: '',
        message: t('account_details.profile_update_success'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
              props.onClose()
            }
          }
        ]
      });
    } catch (error) {
      const message = error.message || t('checkout.something_is_wrong');
      ShowLoading(false);
      confirmAlert({
        title: t('alerts.error'),
        message: message,
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }
  };

  const onCapture = (imageData) => {
    if (imageData == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageData.blob);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhotoData(reader.result);
      }
    };

    setIsCameraOn(false);
  };

  const onError = useCallback(() => {
    confirmAlert({
      title: '',
      message: t('account_details.no_camera'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t('Ok'),
          className: 'error-ok-btn',
          onClick: () => {
            setIsCameraOn(false);
          }
        }
      ]
    });
  }, []);

  const onHandleGalleryImg = ({ target }) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhotoData(reader.result);
      }
    };
  };

  return (
    <Dialog open={open} className={'align-col-middle w100 modal edit-profile-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h4 className={'title'}>{t('account_details.edit_profile')}</h4>
        </div>
        <div className={'scrollview'}>
          <div className={'align-col-middle'}>
            <div className={'align-col-middle avatar-view'}>
              <div className={'align-col-middle photo-view'}>
                <img
                  src={
                    photoData
                      ? photoData
                      : isEmpty(props.user.photo) || props.user.photo === 'x'
                      ? Img_user
                      : getImageFullURL(props.user.photo)
                  }
                />
              </div>
              <div
                onClick={() => {
                  setShowPickerModal(true);
                }}
                className={'align-col-middle photo-edit'}>
                <Edit2Outline size={14} color={Theme.colors.white} />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <AuthInput
              placeholder={t('auth_register.full_name') + ' (*)'}
              placeholderTextColor={'#DFDFDF'}
              onChange={(full_name) => setFullName(full_name)}
              text={full_name}
              style={{ marginTop: 25, marginBottom: 12, marginRight: 5 }}
            />
            <CommonTooltip
              anchorStyle={{
                marginTop: 8,
                marginBottom: 8,
                paddingLeft: 4,
                fontSize: 15,
                fontFamily: Theme.fonts.semiBold,
                color: Theme.colors.text,
                textDecorationLine: 'underline',
                textDecorationColor: Theme.colors.text
              }}
              style={{
                position: 'relative',
                top: 40
              }}
              title={t('tooltip.full_name_required')}
              description={t('tooltip.full_name_required_description')}
            />
          </div>
          {/*Username*/}
          <div style={{ display: 'flex' }}>
            <AuthInput
              placeholder={t('auth_login.username')}
              placeholderTextColor={'#DFDFDF'}
              onChange={(username) => setUsername(username)}
              text={username}
              style={{ marginBottom: 12, marginRight: 5 }}
            />
            <CommonTooltip
              anchorStyle={{
                marginTop: 8,
                marginBottom: 8,
                paddingLeft: 4,
                fontSize: 15,
                fontFamily: Theme.fonts.semiBold,
                color: Theme.colors.text,
                textDecorationLine: 'underline',
                textDecorationColor: Theme.colors.text
              }}
              style={{
                position: 'relative',
                top: 15
              }}
              title={t('tooltip.username_required')}
              description={t('tooltip.username_required_description')}
            />
          </div>
          <TextSelector
            style={{ marginBottom: 12 }}
            placeholder={t('gender')}
            values={['male', 'female']}
            value={gender}
            handleChange={(g) => {
              setGender(g);
            }}
          />
          <AuthInput
            placeholder={t('auth_register.cell') + ' (*)'}
            placeholderTextColor={'#DFDFDF'}
            onChange={(phone) => setPhone(phone)}
            text={phone}
            style={{ marginBottom: 12 }}
          />
          <AuthInput
            placeholder={t('auth_login.email')}
            placeholderTextColor={'#DFDFDF'}
            onChange={(email) => setEmail(email)}
            text={email}
            style={{ marginBottom: 12 }}
          />
          <div style={{ display: 'flex' }}>
            <div style={{flex: 1, marginRight: 5}}>
              <DateSelector
                year={birthday != null ? ''+birthday.getFullYear() : null}
                month={birthday != null ? ''+birthday.getMonth() : null}
                day={birthday != null ? ''+birthday.getDate() : null}
                spacing={12}
                onYearChange={(year) => {
                  _year.current = year;
                  if (_year.current && _month.current && _day.current) {
                    setBirthday(new Date(_year.current, _month.current, _day.current));
                  }
                }}
                onMonthChange={(month) => {
                  _month.current = month;
                  if (_year.current && _month.current && _day.current) {
                    setBirthday(new Date(_year.current, _month.current, _day.current));
                  }
                }}
                onDayChange={(day) => {
                  _day.current = day;
                  if (_year.current && _month.current && _day.current) {
                    setBirthday(new Date(_year.current, _month.current, _day.current));
                  }
                }}
              />
            </div>
            <CommonTooltip
              anchorStyle={{
                marginTop: 8,
                marginBottom: 8,
                paddingLeft: 4,
                fontSize: 15,
                fontFamily: Theme.fonts.semiBold,
                color: Theme.colors.text,
                textDecorationLine: 'underline',
                textDecorationColor: Theme.colors.text
              }}
              style={{
                position: 'relative',
                top: 15
              }}
              title={t('tooltip.birthday_required')}
              description={t('tooltip.birthday_required_description')}
            />
          </div>
        </div>
        <div className={'flex_1 align-col-middle w100 mt2 actions'}>
          <AuthBtn
            isLoading={isLoading}
            isDisabled={isLoading}
            title={t('account_details.submit')}
            onClick={onSave}
            className={'save-btn'}
          />
        </div>
      </div>
      <ImgPickOptionModal
        isOpen={showPickerModal}
        onClose={() => {
          setShowPickerModal(false);
        }}
        onCapture={() => {
          setShowPickerModal(false);
          setIsCameraOn(true);
        }}
        onGallery={() => {
          setShowPickerModal(false);
          if (file_input.current != null) {
            file_input.current.click();
          }
        }}
      />
      <input
        accept='image/jpeg/png'
        ref={file_input}
        hidden={true}
        type='file'
        onChange={onHandleGalleryImg}
      />
      {/*<LoadingModal showModal={isLoading} />*/}
      {isCameraOn && (
        <div className='align-col-middle camera-view'>
          <ImageCapture
            onCapture={onCapture}
            onError={onError}
            width={window.innerWidth}
            userMediaConfig={config}
          />
        </div>
      )}
    </Dialog>

  )
    ;
};

EditProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  user: PropTypes.shape({
    photo: PropTypes.string,
    birthdate: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    username: PropTypes.string,
    sex: PropTypes.string
  }),
  updateProfileDetails: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {
  updateProfileDetails
})(EditProfileModal);
