import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import FeatureItem from './FeatureItem';
import './index.css';
import Svg_cashback from '../../../assets/svgs/home/cashback.svg';
import Svg_earninvite from '../../../assets/svgs/home/earninvite.svg';
import Svg_referral from '../../../assets/svgs/home/referral.svg';
import Svg_coupon from '../../../assets/svgs/home/coupon.svg';
import FeaturesCarousel from './FeaturesCarousel';

const AppFeatures = () => {
  const { t } = useTranslation();

  const data1 = [
    {img : Svg_cashback, title : t('landing.cashback_title'), desc : t('landing.cashback_desc')},
    {img : Svg_earninvite, title : t('landing.earninvite_title'), desc : t('landing.earninvite_desc')},
  ]
  const data2 = [
    {img : Svg_coupon, title : t('landing.coupon_title'), desc : t('landing.coupon_desc')},
    {img : Svg_referral, title : t('landing.referral_title'),  desc : t('landing.referral_desc')},
  ]

  return (
    <div className={'align-middle home-app-features w100 mt7 ph10 pv8'}>
      <div className="left-side">
        <h2 className={'home-new-blocks-title'}><span>{t('landing.app_feature_title1')}</span>{t('landing.app_feature_title2')}</h2>
        <h5 className={'home-new-blocks-desc'}>{ReactHtmlParser(t('landing.app_feature_desc'))}</h5>
      </div>
      <div className="align-middle right-side desktop-only">
        <div className={'align-col-middle ph2'}>
          {data1.map((item, index) => (
            <FeatureItem
              key={index}
              className={index > 0 ? 'mt4' : ''}
              data={item}
            />
          ))}
        </div>
        <div className={'align-col-middle mt8 ph2'}>
          {data2.map((item, index) => (
            <FeatureItem
              key={index}
              className={index > 0 ? 'mt4' : ''}
              data={item}
            />
          ))}
        </div>
      </div>
      <div className="align-middle right-side mobile-only">
        <FeaturesCarousel/>
      </div>
    </div>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(AppFeatures, arePropsEqual);
