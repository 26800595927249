import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTextWidth } from '@tag0/use-text-width';
import { connect } from 'react-redux';
import Svg_vendor from '../../../assets/svgs/home/snapfooder_vendor.svg';
import { Config } from '../../../constants';
import './index.css';
import { StarFill } from '@styled-icons/bootstrap';
import { MapMarkerAlt } from '@styled-icons/fa-solid';
import { Heart } from '@styled-icons/foundation';
import { Theme } from '../../../assets';
import { PrintLog } from '../../../utils/common';
import { setActiveSnapfoodMarker } from '../../../store/actions/app';

const VendorMarker = (props) => {
  const { vendor, vendor_id, makerType, activeMarker, onGoVendor } = props;
  let width = useTextWidth({ text: vendor.title, font: '14px Yellix-Bold' });
  width = Math.max(width, 120);
  PrintLog('VendorMarker ', width);
  const { t } = useTranslation();
  const [isPopup, ShowPopup] = useState(false);

  useEffect(() => {
    if (activeMarker.id == vendor_id && activeMarker.type == 'vendor-marker') {
      ShowPopup(true);
    } else {
      ShowPopup(false);
    }
  }, [activeMarker.id, activeMarker.type]);

  return (
    <div className={'map-marker vendor-marker'}>
      <div className={'marker-body'}>
        <div className={['popup', !isPopup && 'hidden'].join(' ')} onClick={onGoVendor}>
          <div className={'align-middle vendor-marker-info'}>
            <img src={Config.IMG_BASE_URL + vendor.logo_thumbnail_path} alt={''} />
            <div style={{ flex: 1 }}>
              <p style={{ width: width + 4 }}>{vendor.title}</p>
              {makerType === 'vendor' && (
                <div className={'align-middle'} style={{ justifyContent: 'flex-start' }}>
                  <div className={'align-middle rate-item'}>
                    <StarFill size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
                    <span>{(vendor.rating_interval / 2).toFixed(1)}</span>
                  </div>
                  {vendor.distance != null && parseFloat(vendor.distance) > 0 &&
                  <div className={'align-middle rate-item'}>
                    <span style={{ paddingLeft: 5, paddingRight: 5 }}>{`|`}</span>
                    <MapMarkerAlt size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
                    <span>{(parseFloat(vendor.distance) / 1000).toFixed(2)} Km</span>
                  </div>
                  }
                </div>
              )}
            </div>
          </div>
          {makerType === 'social' && (
            <div
              className={'delivery_range_indicator'}
              style={{
                color:
                  vendor.zone != null ? Theme.colors.cyan2 : Theme.colors.gray7
              }}>
              {vendor.zone != null
                ? t('vendor_item.in_delivery_range')
                : t('vendor_item.out_delivery_range')}
            </div>
          )}
          <div className={'anchor'} />
        </div>
        <img
          src={Svg_vendor}
          onClick={(e) => {
            e.preventDefault();
            if (isPopup) {
              props.setActiveSnapfoodMarker(null);
            } else {
              props.setActiveSnapfoodMarker({
                type: 'vendor-marker',
                id: vendor_id
              });
            }
          }}
        />
        {vendor.is_new == 1 && (
          <div className={'new_tag'} style={{ left: vendor.isFav == 1 ? -22 : -18 }}>
            {t('landing.new')}
          </div>
        )}
        {vendor.isFav == 1 && (
          <div
            className={'fav_tag align-col-middle'}
            style={{ right: vendor.is_new == 1 ? -22 : -18 }}>
            <Heart color={Theme.colors.white} size={9} />
          </div>
        )}
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.vendor_id === nextProps.vendor_id &&
    prevProps.lat === nextProps.lat &&
    prevProps.lng === nextProps.lng &&
    prevProps.activeMarker.id === nextProps.activeMarker.id &&
    prevProps.activeMarker.type === nextProps.activeMarker.type
  );
}

VendorMarker.propTypes = {
  vendor_id: PropTypes.number,
  makerType: PropTypes.string,
  vendor: PropTypes.shape({
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    rating_interval: PropTypes.string,
    is_new: PropTypes.bool,
    isFav: PropTypes.number,
    current_zone: PropTypes.string,
    distance: PropTypes.number,
    zone: PropTypes.object
  }),
  activeMarker: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.number
  }),
  setActiveSnapfoodMarker: PropTypes.func,
  onGoVendor: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  activeMarker: app.activeMarker
});

export default connect(mapStateToProps, {
  setActiveSnapfoodMarker
})(React.memo(VendorMarker, arePropsEqual));
