import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckBoxBtn from '../../Buttons/CheckBoxBtn';
import { SearchInput } from '../../Inputs';
import { Search } from '@styled-icons/fluentui-system-filled';
import { Theme } from '../../../assets';
import './index.css';
import { setProfileBlogFilter } from '../../../store/actions/app';
import RoundIconBtn from '../../Buttons/RoundIconBtn';
import { Filter } from '@styled-icons/bootstrap';
import BlogFilterModal from '../../Modals/BlogFilterModal';

const FilterView = (props) => {
  const { t } = useTranslation();
  const [isFilterModal, setFilterModal] = useState(false);

  return (
    <Box role="presentation" className={'blog-filter'}>
      <h1>{t('blog.filter_article')}</h1>
      <div className={'align-middle mb3'}>
        <div className={'flex_1'}>
          <SearchInput
            icon={<Search size={20} color={Theme.colors.gray5} />}
            value={props.profile_blog_filter.searchTerm}
            placeholder={t('blog.search_placeholder')}
            onChange={(value) => {
              props.setProfileBlogFilter({
                ...props.profile_blog_filter,
                searchTerm: value
              });
            }}
          />
        </div>
        <RoundIconBtn
          icon={<Filter size={22} color={Theme.colors.cyan2} />}
          style={{ width: 48, height: 48, marginLeft: 12 }}
          onClick={() => {
            setFilterModal(true);
          }}
        />
      </div>
      <List className={'filter-body'}>
        <ListItem
          button
          className={'list-btn'}
          onClick={(e) => {
            e.preventDefault();
            props.setProfileBlogFilter({ category_id: null });
          }}>
          <ListItemText primary={t(`blog.all_topic`)} />
          <CheckBoxBtn
            checked={props.profile_blog_filter.category_id == null}
            onClick={() => {
              props.setProfileBlogFilter({ category_id: null });
            }}
          />
        </ListItem>
        {props.blog_categories.map((item, index) => (
          <ListItem
            button
            key={index}
            className={'list-btn'}
            onClick={(e) => {
              e.preventDefault();
              props.setProfileBlogFilter({ category_id: item.id });
            }}>
            <ListItemText primary={props.language == 'en' ? item.title : item.sq_title} />
            <CheckBoxBtn
              checked={props.profile_blog_filter.category_id === item.id}
              onClick={() => {
                props.setProfileBlogFilter({ category_id: item.id });
              }}
            />
          </ListItem>
        ))}
      </List>
      {isFilterModal && (
        <BlogFilterModal
          isOpen={isFilterModal}
          onClose={() => {
            setFilterModal(false);
          }}
        />
      )}
    </Box>
  );
};

FilterView.propTypes = {
  language: PropTypes.string,
  profile_blog_filter: PropTypes.shape({
    category_id: PropTypes.string,
    searchTerm: PropTypes.string
  }),
  blog_categories: PropTypes.array,
  setProfileBlogFilter: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  language: app.language,
  blog_categories: app.blog_categories || [],
  profile_blog_filter: app.profile_blog_filter
});

export default connect(mapStateToProps, {
  setProfileBlogFilter
})(FilterView);
