import React from 'react';
import './index.css';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Svg_fb from '../../assets/svgs/auth/facebook.svg';
import PropTypes from 'prop-types';

const LoginFaceBook = ({ onLoginResponse }) => {
  const responseFacebook = (response) => {
    onLoginResponse(response.accessToken);
  };
  return (
    <FacebookLogin
      appId="298466897411357"
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      disableMobileRedirect={true}
      render={(renderProps) => (
        <button className={'socialBtn'} onClick={renderProps.onClick}>
          <img src={Svg_fb} style={{ width: 22, height: 22, objectFit: 'contain' }} />
        </button>
      )}
    />
  );
};

LoginFaceBook.propTypes = {
  onLoginResponse: PropTypes.func
};
export default LoginFaceBook;
