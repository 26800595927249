import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import apiFactory from '../../../services/apiFactory';
import ListBtn from '../../Buttons/ListBtn';
import Svg_back from '../../../assets/svgs/modals/back.svg';
import Svg_close from '../../../assets/svgs/profile/close.svg';
import './index.css';
import '../index.css';
import OrderFaqs from './orderFaqs';
import { Config } from '../../../constants';
import MessagesScreen from '../../Common/Messages';
import { confirmAlert } from 'react-confirm-alert';
import {
  createOrderSupportChannel,
  getChannelData,
  ORDER_SUPPORT_CHANNEL
} from '../../../services/chat';
import { setOrderMessageTags } from '../../../store/actions/app';
import { SNAPFOOD_AVATAR } from '../../../constants/config';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const OrderSupportModal = (props) => {
  const { order, user, language, onClose } = props;
  if (order.id == null) return null;
  const { t } = useTranslation();

  const isChannelCreating = useRef(false);

  const [page, setPage] = useState('help');
  const [faqs, setFaqs] = useState([]);
  const [members, setMembers] = useState('');

  useEffect(() => {
    props.setOrderMessageTags();
    loadFaqs();
  }, []);

  const loadFaqs = () => {
    apiFactory.get(`orders/get-faqs`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load orders', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        let faqs = data.faqs || [];
        if (language == 'en') {
          faqs = faqs.map((t) => ({ id: t.id, question: t.question_en, answer: t.answer_en }));
        } else {
          faqs = faqs.map((t) => ({ id: t.id, question: t.question_al, answer: t.answer_al }));
        }
        setFaqs(faqs);
      },
      () => {
        identifyDevice();
        trackEvent('OrderReview', {
          action_category: 'Get Order Faqs',
          action_outcome: 'fail',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
      }
    );
  };

  const loadChannelData = async () => {
    let channelData = await getChannelData(ORDER_SUPPORT_CHANNEL, `order_${order.id}`);
    if (channelData == null || channelData.members == null) {
      return '';
    }
    let member_names = '';
    var remaining_cnt = 0;
    let other_members = channelData.members.filter((i) => i.id != user.id);
    other_members.map((item, index) => {
      if (index < 3) {
        member_names = member_names + (item.username || item.full_name) + ', ';
      } else {
        remaining_cnt = remaining_cnt + 1;
      }
    });

    if (remaining_cnt > 0) {
      member_names = member_names + '+' + remaining_cnt;
    }
    setMembers(member_names);
  };

  const onGoOrderChat = async () => {
    if (isChannelCreating.current == true) {
      return;
    }
    isChannelCreating.current = true;
    let channelData = await getChannelData(ORDER_SUPPORT_CHANNEL, `order_${order.id}`);
    if (channelData) {
      isChannelCreating.current = false;
      loadChannelData();
      setPage('order_support_chat');
    } else {
      let channelId = await createOrderSupportChannel(order, user);
      isChannelCreating.current = false;
      if (channelId) {
        loadChannelData();
        setPage('order_support_chat');
      } else {
        return confirmAlert({
          title: t('alerts.error'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    }
  };

  const onBack = () => {
    if (page === 'help') {
      onClose();
    } else if (page === 'order_support_chat') {
      setPage('customer_support');
    } else if (page === 'customer_support') {
      setPage('help');
    } else if (page === 'faqs') {
      setPage('help');
    }
  };

  return (
    <div className={'align-col-middle  modal order-support-modal'}>
      <div className={'align-col-middle content'}>
        <div
          className={'align-middle titleview ph2 pv3'}
          style={{ height: page === 'order_support_chat' ? 100 : 160 }}>
          {page === 'order_support_chat' ? (
            <div className={'align-row-start w100'}>
              <img src={page === 'help' ? Svg_close : Svg_back} onClick={onBack} />
              <img
                src={SNAPFOOD_AVATAR}
                style={{ width: 42, height: 42, marginLeft: 14, marginRight: 4, borderRadius: 5 }}
              />
              <div>
                <h3>{t('order_help.order_chat')}</h3>
                <p className={'members'}>{members}</p>
              </div>
            </div>
          ) : (
            <div className={'align-middle w100'}>
              <img src={page === 'help' ? Svg_close : Svg_back} onClick={onBack} />
              <h2>{t('order_help.title')}</h2>
              <div style={{ width: 45 }} />
            </div>
          )}
        </div>
        <div className={'align-col-middle w100'}>
          {page === 'order_support_chat' ? (
            <div className={'w100 order-support-chat'}>
              <MessagesScreen is_order_support={true} channel_id={'order_' + order.id} />
            </div>
          ) : (
            <div className={'align-col-middle w100 content-view'}>
              <div className={'align-col-middle logo-view'}>
                <img src={Config.IMG_BASE_URL + order.vendor.logo_thumbnail_path} />
              </div>
              <p className={'need-help'}>
                {t('order_help.need_help_from').replace('###', order.vendor.title)}
              </p>
              {page === 'help' && (
                <div className={'align-col-middle w100 ph2'}>
                  <ListBtn
                    title={t('order_help.customer_support')}
                    style={{ marginBottom: 15 }}
                    onClick={() => {
                      setPage('customer_support');
                    }}
                  />
                  <ListBtn
                    title={t('order_help.faqs')}
                    onClick={() => {
                      setPage('faqs');
                    }}
                  />
                </div>
              )}
              {page === 'customer_support' && (
                <div className={'align-col-middle w100 ph2'}>
                  {/* {order.vendor.phone_number && (
                    <>
                      {' '}
                      <p className={'call-vendor'}>
                        {t('order_help.call_vendor').replace('###', order.vendor.phone_number)}
                      </p>
                      <div className={'align-col-middle or-divider'}>
                        <div />
                        <div>{t('or')}</div>
                      </div>
                    </>
                  )} */}
                  <div style={{ height: 25 }}></div>
                  <ListBtn title={t('order_help.chat_with_us')} onClick={onGoOrderChat} />
                </div>
              )}
              {page === 'faqs' && (
                <div className={'align-col-middle w100'}>
                  <p className={'call-vendor'}>{t('order_help.faqs')}</p>
                  <OrderFaqs faqs={faqs} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal && prevProps.order_id === nextProps.order_id;
}

OrderSupportModal.propTypes = {
  order: {
    id: PropTypes.number
  },
  language: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    full_name: PropTypes.string
  }),
  setOrderMessageTags: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  order: app.tmp_order
});

export default connect(mapStateToProps, { setOrderMessageTags })(
  React.memo(OrderSupportModal, arePropsEqual)
);
