import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-grid-system';
import VendorSearch from '../../components/Common/VendorSearch';
import VendorItem from '../../components/Vendor/VendorItem';
import { useTranslation } from 'react-i18next';
import './index.css';
import Seo from '../../components/Seo';
import FilterView from '../../components/Vendor/FilterView';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHomeData, setHomeVendorFilter, setHomeVendorSort } from '../../store/actions/app';
import { setVendorCart } from '../../store/actions/shop';
import {
  OrderType_Delivery,
  VSort_Closest,
  VSort_FastDelivery,
  VSort_HighRating,
  VSort_Low2HighPrice,
  VSort_PopularFirst,
  VSort_Title
} from '../../constants/config';
import VendorService from '../../services/apiVendor';
import { useNavigate, useLocation } from 'react-router-dom';
import NoLocation from '../../components/Empty/NoLocation';
import { isEmpty } from '../../utils/common';
import NoRestaurants from '../../components/Empty/NoRestaurants';
import Breadcrumb from '../../components/Vendor/Breadcrumb';
import querystring from 'query-string';
import { Grid } from '@mui/material';
import LoadingSpinner from '../../components/Spinner';
import FeaturedVendors from '../../components/Vendor/FeaturedVendors';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

const expectedBlocks = [
  { key: 'suggested', icon: 'top' },
  { key: 'new', icon: 'new' },
  { key: 'exclusive', icon: 'collision' },
  { key: 'is_grocery', icon: null },
  { key: 'free_delivery', icon: null }
  // { key: 'all', icon: null },
];
const vertPerPage = 15;
const Vendors = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { foodCategories, featuredBlocks, allvendors, defaultPayload } = props;

  const catsLoaded = useRef(false);
  const featureLoaded = useRef(false);
  const vendorsLoaded = useRef(false);

  const [initialized, setInitialized] = useState(false);
  const [vertLoading, setVertLoading] = useState(false);
  const [curCategory, setCurCategory] = useState({});

  useEffect(() => {
    const parsed = querystring.parse(location.search);
    props.setHomeVendorFilter({
      category_id: parsed.category,
      latitude: defaultPayload?.lat || parsed.latitude || '',
      longitude: defaultPayload?.lng || parsed.longitude || '',
      formatted_address: defaultPayload?.formatted_address || parsed.formatted_address || '',
      vendor_type: defaultPayload?.vendor_type || parsed.vendor_type || 'vendors',
      order_type: defaultPayload?.order_type || parsed.order_type || OrderType_Delivery,
      searchTerm: parsed.searchTerm || ''
    });

    if (parsed.category) {
      setCurCategory(foodCategories.find((c) => c.id == parsed.category) || {});
    }
  }, [location.search]);

  useEffect(() => {
    loadVendors(1, vertPerPage, true);
    loadFeaturedBlocks();
  }, [
    props.home_vendor_filter.category_id,
    props.home_vendor_filter.vendor_type,
    props.home_vendor_filter.order_type,
    props.home_vendor_filter.latitude,
    props.home_vendor_filter.longitude,
    props.home_vendor_filter.searchTerm,
    props.home_vendor_filter.is_meal,
    props.home_vendor_filter.is_dietary,
    props.home_vendor_filter.ongoing_offer,
    props.home_vendor_filter.open_now,
    props.home_vendor_filter.online_payment,
    props.home_vendor_filter.delivery_by_snapfood,
    props.home_vendor_filter.low_fee,
    props.home_vendor_filter.high_fee,
    props.home_vendor_sort,
    defaultPayload
  ]);

  const getFilers = (flag) => {
    const {
      category_id,
      vendor_type,
      order_type,
      is_meal,
      is_dietary,
      ongoing_offer,
      open_now,
      online_payment,
      delivery_by_snapfood,
      low_fee,
      high_fee,
      searchTerm
    } = props.home_vendor_filter;
    let filters = [];

    if (vendor_type === 'vendors') {
      filters.push('vendor_type=Restaurant');
    } else {
      filters.push('vendor_type=Grocery');
    }
    filters.push('order_method=' + order_type);

    if (flag === 1) {
      return filters;
    }

    if (category_id != null && category_id != '') {
      filters.push(`food_category_ids[]=${category_id}`);
    }
    if (flag === 2) {
      return filters;
    }
    if (is_meal) {
      filters.push('is_meal=1');
    }
    if (is_dietary) {
      filters.push('is_dietary=1');
    }
    if (ongoing_offer) {
      filters.push('promotions=1');
    }
    if (open_now) {
      filters.push('open_now=1');
    }
    if (online_payment) {
      filters.push('online_payment=1');
    }
    if (delivery_by_snapfood) {
      filters.push('delivery_by_snapfood=1');
    }
    if (low_fee != null) {
      filters.push(`low_fee=${low_fee}`);
    }
    if (high_fee != null) {
      filters.push(`high_fee=${high_fee}`);
    }
    if (searchTerm != '') {
      filters.push('name=' + searchTerm);
    }
    return filters;
  };

  const getSortDir = (sort_type) => {
    if (
      sort_type === VSort_Title ||
      sort_type === VSort_FastDelivery ||
      sort_type === VSort_Closest ||
      sort_type === VSort_Low2HighPrice
    ) {
      return 1;
    } else if (sort_type === VSort_HighRating || sort_type === VSort_PopularFirst) {
      return -1;
    }
    return 1;
  };

  const hasFilter = () => {
    return (
      props.home_vendor_filter.is_meal === true ||
      props.home_vendor_filter.is_dietary === true ||
      props.home_vendor_filter.ongoing_offer === true ||
      props.home_vendor_filter.open_now === true ||
      props.home_vendor_filter.online_payment === true ||
      props.home_vendor_filter.low_fee != null ||
      props.home_vendor_filter.high_fee != null
    );
  };

  const checkDataLoading = () => {
    if (
      catsLoaded.current === true &&
      featureLoaded.current === true &&
      vendorsLoaded.current === true
    ) {
      props.setHomeData({ isInitialized: true });
    }
  };

  const loadVendors = async (page, perPage, forceLoading = false) => {
    try {
      if (!vertLoading || forceLoading) {
        let { latitude, longitude } = props.home_vendor_filter;
        if (
          isEmpty(latitude == null ? null : `` + latitude) ||
          isEmpty(longitude == null ? null : `` + longitude)
        ) {
          return;
        }

        vendorsLoaded.current = false;
        if (forceLoading) {
          setVertLoading(true);
        }

        let filterKeys = getFilers();
        let order_dir = getSortDir(props.home_vendor_sort);

        let vendorsData = [];
        await VendorService.getVendors(
          page,
          latitude,
          longitude,
          props.home_vendor_sort,
          order_dir,
          perPage,
          filterKeys,
          true
        )
          .then((res) => {
            vendorsData = res.data ?? [];
            identifyDevice();
            trackEvent('Vendor', {
              action_category: 'Vendors',
              action_outcome: 'success',
              interaction_element: 'screen',
              source: 'sf_web'
            });
          })
          .catch(() => {
            identifyDevice();
            trackEvent('Vendor', {
              action_category: 'Vendors',
              action_outcome: 'fail',
              interaction_element: 'screen',
              source: 'sf_web'
            });
          });

        if (page > 1) {
          const currentVendorIds = allvendors.map((x) => x.id);
          const newVendors = vendorsData.filter((x) => currentVendorIds.indexOf(x.id) === -1);

          props.setHomeData({
            vertPage: vendorsData['current_page'],
            vertTotalPages: vendorsData['last_page'],
            allvendors: [...allvendors, ...newVendors]
          });
        } else {
          props.setHomeData({
            vertPage: vendorsData['current_page'],
            vertTotalPages: vendorsData['last_page'],
            allvendors: vendorsData
          });
        }

        setInitialized(true);
        vendorsLoaded.current = true;
        setVertLoading(false);
        checkDataLoading();
      }
    } catch (error) {
      setInitialized(true);
      vendorsLoaded.current = true;
      setVertLoading(false);
      checkDataLoading();
    }
  };

  const loadFeaturedBlocks = async () => {
    try {
      featureLoaded.current = false;
      let { latitude, longitude } = props.home_vendor_filter;
      if (
        isEmpty(latitude == null ? null : `` + latitude) ||
        isEmpty(longitude == null ? null : `` + longitude)
      ) {
        return;
      }
      let filterKeys = getFilers(2);
      // eslint-disable-next-line react/prop-types
      let _featuredBlocks = await VendorService.getFeaturedBlocks(latitude, longitude, filterKeys)
        .then(() => {
          identifyDevice();
          trackEvent('Vendor', {
            action_category: 'Feature Blocks',
            action_outcome: 'success',
            interaction_element: 'screen',
            source: 'sf_web'
          });
        })
        .catch(() => {
          identifyDevice();
          trackEvent('Vendor', {
            action_category: 'Feature Blocks',
            action_outcome: 'fail',
            interaction_element: 'screen',
            source: 'sf_web'
          });
        });

      props.setHomeData({
        featuredBlocks: _featuredBlocks
      });

      featureLoaded.current = true;
      checkDataLoading();
    } catch (error) {
      featureLoaded.current = true;
      checkDataLoading();
    }
  };

  const onVendorSearch = (data) => {
    const parsed = querystring.parse(location.search);

    let params = [];
    if (!isEmpty(parsed.category)) {
      params.push(`category=${parsed.category}`);
    }
    if (!isEmpty(data.curLoc.latitude == null ? null : '' + data.curLoc.latitude)) {
      params.push(`latitude=${data.curLoc.latitude}`);
    }
    if (!isEmpty(data.curLoc.longitude == null ? null : '' + data.curLoc.longitude)) {
      params.push(`longitude=${data.curLoc.longitude}`);
    }
    if (!isEmpty(data.curLoc.formatted_address)) {
      params.push(`formatted_address=${data.curLoc.formatted_address}`);
    }
    if (!isEmpty(data.vendorType)) {
      params.push(`vendor_type=${data.vendorType}`);
    }
    if (!isEmpty(data.orderMethod)) {
      params.push(`order_type=${data.orderMethod}`);
    }
    if (!isEmpty(data.searchTerm)) {
      params.push(`searchTerm=${data.searchTerm}`);
    }

    navigate(`/stores?${params.join('&')}`, { replace: true });
  };

  const goVendorDetails = (vendor) => {
    navigate(`/store/${vendor.hash_id}/${vendor.slug}/${vendor.order_method}`);
  };

  const onFavChange = (data) => {
    let found_all_index = allvendors.findIndex((i) => i.id === data.id);
    if (found_all_index !== -1) {
      let tmp = allvendors.slice(0, allvendors.length);
      tmp[found_all_index].isFav = data.isFav;

      props.setHomeData({
        allvendors: tmp
      });
    }

    let _featuredBlocks = Object.assign({}, featuredBlocks);

    expectedBlocks.map(({ key }) => {
      if (
        _featuredBlocks[key] != null &&
        _featuredBlocks[key].vendors != null &&
        _featuredBlocks[key].vendors.length > 0
      ) {
        let found_index = _featuredBlocks[key].vendors.findIndex((i) => i.id === data.id);
        if (found_index !== -1) {
          _featuredBlocks[key].vendors[found_index].isFav = data.isFav;
        }
      }
    });

    props.setHomeData({
      featuredBlocks: _featuredBlocks
    });
  };

  const _renderBody = () => {
    if (!initialized) {
      return <LoadingSpinner />;
    }
    if (vertLoading) {
      return <LoadingSpinner />;
    }
    if (isEmpty(props.home_vendor_filter.formatted_address) && !defaultPayload) {
      return (
        <div className={'w100 align-col-middle'}>
          <NoLocation />
        </div>
      );
    }

    return (
      <Grid container spacing={{ xs: 2, md: 5 }} className={'w100'}>
        <Grid item xs={12} md={4} lg={3} style={{}}>
          {(hasFilter() || allvendors.length > 0) && <FilterView />}
        </Grid>
        <Grid item xs={12} md={8} lg={9} style={{}}>
          {allvendors.length === 0 ? (
            <div className={'w100 align-col-middle'}>
              <NoRestaurants
                desc={
                  props.home_vendor_filter.vendor_type === 'vendors'
                    ? t('no_restaurant_search')
                    : t('no_grocery_search')
                }
                style={{ marginTop: 60, marginBottom: 20 }}
              />
            </div>
          ) : (
            <>
              {expectedBlocks.map(({ key }) => {
                if (
                  featuredBlocks[key] &&
                  featuredBlocks[key].block &&
                  featuredBlocks[key].block['is_active']
                ) {
                  const restaurants = featuredBlocks[key].vendors;
                  if (!restaurants || restaurants.length === 0) {
                    return null;
                  }
                  return (
                    <div>
                      <div className={'featured-block-title'}>
                        {props.language == 'sq'
                          ? featuredBlocks[key].block.title_sq
                          : featuredBlocks[key].block.title_en}
                      </div>
                      <Row className={'featured-vendor-list'}>
                        {restaurants.map((vendor) => (
                          <VendorItem
                            key={vendor.id}
                            id={vendor.id}
                            isFav={vendor.isFav}
                            isOpen={vendor.is_open}
                            data={vendor}
                            onFavChange={onFavChange}
                            onSelect={() => {
                              goVendorDetails(vendor);
                            }}
                          />
                        ))}
                      </Row>
                      <div className={'w100 featured-mobile-view'}>
                        <FeaturedVendors restaurants={restaurants} onFavChange={onFavChange} />
                      </div>
                    </div>
                  );
                }
              })}
              <div className={'featured-block-title mt2'}>
                {props.home_vendor_filter.vendor_type == 'vendors'
                  ? t('all_vendors')
                  : t('all_grocery')}
              </div>
              <Row>
                {allvendors.map((vendor) => (
                  <VendorItem
                    key={vendor.id}
                    id={vendor.id}
                    isFav={vendor.isFav}
                    isOpen={vendor.is_open}
                    data={vendor}
                    onFavChange={onFavChange}
                    onSelect={() => {
                      goVendorDetails(vendor);
                    }}
                  />
                ))}
              </Row>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div data-testid="view-vendors" className={'view-vendors'}>
      <Seo title={t('web_title.default')} />
      <div className={'flex_1'}>
        <div className={'align-col-middle header-bg'}>
          <h4>
            {t('vendors_list.header1')}
            <br />
            {t('vendors_list.header2')}
          </h4>
        </div>
        <div className={'search-view'}>
          <VendorSearch
            latitude={props.home_vendor_filter.latitude}
            longitude={props.home_vendor_filter.longitude}
            formatted_address={props.home_vendor_filter.formatted_address}
            search_term={props.home_vendor_filter.searchTerm}
            order_method={props.home_vendor_filter.order_type || OrderType_Delivery}
            vendor_type={props.home_vendor_filter.vendor_type || 'vendors'}
            onVendorSearch={onVendorSearch}
          />
        </div>
        <div className={'flex_1 align-col-middle main'}>
          <div className={'vendors-body'}>
            {curCategory.id != null ? (
              <Breadcrumb
                language={props.language}
                curCategory={curCategory}
                formatted_address={props.home_vendor_filter.formatted_address}
                clearAddress={() => {
                  onVendorSearch({
                    curLoc: {},
                    searchTerm: props.home_vendor_filter.searchTerm,
                    orderMethod: props.home_vendor_filter.order_type || OrderType_Delivery,
                    vendorType: props.home_vendor_filter.vendor_type || 'vendors'
                  });
                }}
              />
            ) : (
              <div style={{ height: 50 }} />
            )}
            {_renderBody()}
          </div>
        </div>
      </div>
      <div style={{ height: 120 }} />
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn,
  hasLocation: app.hasLocation,
  coordinates: app.coordinates,
  address: app.address || {},
  language: app.language,
  home_vendor_filter: app.home_vendor_filter,
  home_vendor_sort: app.home_vendor_sort,
  home_scroll_offset: app.home_page_data.home_scroll_offset || 0,
  isInitialized: app.home_page_data.isInitialized || false,
  curFoodCatID: app.home_page_data.curFoodCatID,
  featuredBlocks: app.home_page_data.featuredBlocks || [],
  allvendors: app.home_page_data.allvendors || [],
  vertPage: app.home_page_data.vertPage || 1,
  vertTotalPages: app.home_page_data.vertTotalPages || 1,
  foodCategories: app.foodCategories,
  vendorData: shop.vendorData
});

Vendors.propTypes = {
  hasLocation: PropTypes.bool,
  home_scroll_offset: PropTypes.number,
  isInitialized: PropTypes.bool,
  curFoodCatID: PropTypes.number,
  foodCategories: PropTypes.array,
  featuredBlocks: PropTypes.array,
  allvendors: PropTypes.array,
  defaultPayload: PropTypes.object,
  vertPage: PropTypes.number,
  vertTotalPages: PropTypes.number,
  home_vendor_filter: PropTypes.shape({
    category_id: PropTypes.number,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    formatted_address: PropTypes.string,
    vendor_type: PropTypes.string,
    order_type: PropTypes.string,
    is_meal: PropTypes.bool,
    is_dietary: PropTypes.bool,
    ongoing_offer: PropTypes.bool,
    open_now: PropTypes.bool,
    online_payment: PropTypes.bool,
    delivery_by_snapfood: PropTypes.bool,
    low_fee: PropTypes.number,
    high_fee: PropTypes.number,
    searchTerm: PropTypes.string
  }),
  vendorData: PropTypes.shape({
    isFav: PropTypes.number
  }),
  address: PropTypes.object,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  user: PropTypes.shape({
    photo: PropTypes.string,
    cashback_amount: PropTypes.number
  }),
  language: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  home_vendor_sort: PropTypes.string,
  setVendorCart: PropTypes.func,
  setHomeVendorFilter: PropTypes.func,
  setHomeData: PropTypes.func
};

export default connect(mapStateToProps, {
  setHomeVendorFilter,
  setHomeVendorSort,
  setVendorCart,
  setHomeData
})(Vendors);
