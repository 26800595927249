import React, { useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import apiFactory from '../../../services/apiFactory';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { Theme } from '../../../assets';
import './index.css';
import PropTypes from 'prop-types';
import { CheckCircleFill } from '@styled-icons/bootstrap';
import { Check } from '@styled-icons/entypo';
import { extractErrorMessage } from '../../../utils/common';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const CouponInput = ({ vendorId, subTotal, applyCoupon }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    coupon: '',
    loading_coupon: false,
    has_valid_coupon: false
  });

  const _validateCoupon = async (vendorId, total) => {
    const { coupon } = state;
    return new Promise((resolve, reject) => {
      setState({
        ...state,
        loading_coupon: true
      });
      apiFactory.get(`/coupons?subtotal=${total}&&vendor_id=${vendorId}&code=${coupon}`).then(
        async ({ data }) => {
          identifyDevice();
          trackEvent('Load Coupons', {
            action_category: 'Load Screen',
            action_outcome: 'success',
            interaction_element: 'screen',
            source: 'sf_web'
          });
          await setState({
            ...state,
            loading_coupon: false,
            has_valid_coupon: true
          });
          resolve(data.coupon);
        },
        async (error) => {
          identifyDevice();
          trackEvent('Load Coupons', {
            action_category: 'Load Screen',
            action_outcome: 'fail',
            interaction_element: 'screen',
            source: 'sf_web'
          });
          await setState({
            ...state,
            loading_coupon: false,
            has_valid_coupon: false
          });
          const message = extractErrorMessage(error);
          reject(message);
        }
      );
    });
  };

  const _checkCoupon = async () => {
    try {
      if (state.loading_coupon) {
        return;
      }
      let couponData = await _validateCoupon(vendorId, subTotal);
      applyCoupon(couponData);
    } catch (message) {
      if (typeof message === 'string') {
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    }
  };

  const _removeCoupon = () => {
    setState({ ...state, has_valid_coupon: false, coupon: '' });
    applyCoupon(null);
  };

  if (!state.has_valid_coupon) {
    return (
      <div className={'coupon-input'}>
        <input
          value={state.coupon}
          placeholder={t('cart.coupon.placeholder')}
          onChange={(e) => {
            setState({ ...state, coupon: e.target.value });
          }}
          autoCapitalize={'none'}
          autoCorrect={'none'}
        />
        {state.coupon != null && state.coupon.length > 0 && (
          <IconButton
            style={{
              position: 'absolute',
              right: 10,
              padding: 0
            }}
            onClick={_checkCoupon}>
            {state.loading_coupon ? (
              <CircularProgress size={16} />
            ) : (
              <Check
                size={16}
                color={state.has_valid_coupon ? Theme.colors.cyan2 : Theme.colors.placeholder}
              />
            )}
          </IconButton>
        )}
      </div>
    );
  }

  if (state.coupon != null && state.coupon.length > 0 && state.has_valid_coupon) {
    return (
      <div className={'coupon-input'}>
        <div
          className={'align-row-start'}
          style={{ flex: 1, paddingVertical: 18, paddingLeft: 10 }}>
          <div className={'code-text'}> {state.coupon} </div>
          <CheckCircleFill size={16} color={'#00C22D'} />
        </div>
        <div style={{ position: 'absolute', right: 10 }}>
          {state.loading_coupon ? (
            <CircularProgress size={16} />
          ) : (
            <div className={'remove btn-style'} onClick={() => _removeCoupon()}>
              {t('remove')}
            </div>
          )}
        </div>
      </div>
    );
  }
  return null;
};

CouponInput.propTypes = {
  vendorId: PropTypes.number,
  subTotal: PropTypes.number,
  applyCoupon: PropTypes.func
};

export default React.memo(CouponInput);
