import React from 'react';
import { useTranslation } from 'react-i18next';
import Faq from '../../components/Common/Faq';
import './Faqs.css';
import { Grid } from '@mui/material';

const Faqs = () => {
  const { t } = useTranslation();

  // const [faqs, setFaqs] = useState([]);
  // const [expanded, setExpanded] = React.useState('');

  // const handleChange = (panel) => (event, isExpanded) => {
  //   let fqs = faqs;
  //   fqs[panel].expanded = !fqs[panel].expanded;
  //   setFaqs(fqs);
  //   console.log(faqs)

  //   // setExpanded(isExpanded ? panel : false);
  // };
  // console.log(expanded)

  // useEffect(() => {
  //   let tmp = [];
  //   for (let i = 1; i <= 3; i++) {
  //     tmp.push({
  //       id: i,
  //       q: t('partner.faq_question_' + i),
  //       a: t('partner.faq_answer_' + i),
  //     });
  //   }
  //   setFaqs(tmp);
  // }, []);

  return (
    <Grid container spacing={{ xs: 2, md: 5 }} className='faqs pt2 pb8 ph2 align-middle'>
      <Grid item xs={12} md={8}>
        <div className={'align-col-middle w100'}>
          <h3>{t('partner.got_questions')}</h3>
          <div className={'items'}>
            {/* {faqs.map((faq) => ( */}
              <Faq
                key={"faq_1"}
                question={t('partner.faq_question_1')}
                answer={t('partner.faq_answer_1')}
              />
              <Faq
               key={"faq_2"}
               question={t('partner.faq_question_2')}
               answer={t('partner.faq_answer_2')}
              />
              <Faq
                key={"faq_3"}
                question={t('partner.faq_question_3')}
                answer={t('partner.faq_answer_3')}
              />
            {/* ))} */}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(Faqs, arePropsEqual);
