import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import {
  setVendorCart,
  resetCart,
  setCutleryCart,
  getDiscount,
  setCouponCart,
  setPriceCart,
  setPaymentInfoCart,
  setDeliveryInfoCart,
  setConfirmLegalAge
} from '../../store/actions/shop';
import { getReferralsRewardsSetting, setTmpFood } from '../../store/actions/app';
import {
  Stepper,
  CartView,
  CouponInput,
  SuggestedProducts,
  PriceBoard,
  CutleryInput,
  InviteCodeInput,
  CashbackInput,
  PromotionView
} from '../../components/Checkout';
import { MainBtn } from '../../components/Buttons';
import { calculateCartTotal } from '../../utils/common';
import { getVendorDetail } from '../../store/actions/vendors';
import { clearCart, setCommentCart } from '../../store/actions/shop';
import { getAddresses } from '../../store/actions/app';
import apiFactory from '../../services/apiFactory';
import { OrderType_Delivery,
  OrderType_Reserve
} from '../../constants/config';
import DeliveryInfo from '../../components/Checkout/DeliveryInfo';
// import { useNavigate } from 'react-router-dom';
import PaymentMethodsStep from './step2';
import PlaceOrderStep from './step3';
import { confirmAlert } from 'react-confirm-alert';
import RadioBtn from '../../components/Buttons/RadioBtn';
import { isEmpty } from '../../utils/common';
import OrderForInput from '../../components/Checkout/OrderForInput';

const CheckoutScreen = (props) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  const deliveryFee = useRef(0);
  const couponObj = useRef(null);
  const discountObj = useRef(null);

  const [step, setStep] = useState(0);
  const [state, setState] = useState({
    maxDeliveryTime: 0,
    minOrderPrice: 0,
    smallOrderFee: 0,
    outOfDeliveryArea: false
  });
  const [legal_age_error, setLegalAgeError] = useState(false);

  const [delivery_fee, setDeliveryFee] = useState(0);
  const [promo_code, setInvitationCode] = useState('');
  const [promoState, setPromoState] = useState({
    promoFreeObj: null,
    discount: 0
  });
  const [showOrderFor, setShowOrderFor] = useState(false);

  const hasLegalAgeProduct = useMemo(() => {
    let find = props.cartItems.findIndex(c => c.age_18 == 1);
    return find != -1;
  }, [props.cartItems]);

  const getVendorId = useMemo(() => {
    if (props.cartItems.length > 0) {
      return props.cartItems[0].vendor_id;
    }
    return null;
  }, [props.cartItems]);

  const checkMultiPromoApply = useMemo(() => {
    let cnt = 0;
    if (isEmpty(promo_code) != true) {
      cnt = cnt + 1;
    }
    if (props.cartPrice.cashback > 0) {
      cnt = cnt + 1;
    }

    if (props.order_data.coupon != null && !isEmpty(props.order_data.coupon.code)) {
      cnt = cnt + 1;
    }
    return cnt > 1;
  }, [promo_code, props.cartPrice.cashback, props.order_data.coupon]);

  useEffect(() => {
    setStep(0);
    props.getAddresses();
    props.setCouponCart(null);
    props.getReferralsRewardsSetting();
  }, []);

  useEffect(() => {
    loadDiscount();
  }, [getVendorId, props.cartItems]);

  useEffect(() => {
    let vendor_id = getVendorId;
    if (vendor_id != null) {
      if (
        props.vendorData == null ||
        props.vendorData.id == null ||
        props.vendorData.id != vendor_id
      ) {
        _loadVendorData(vendor_id);
      }
    }
  }, [props.cartItems, props.coordinates]);

  useEffect(() => {
    _getDeliveryFees(
      props.vendorData.id,
      props.delivery_info.address.lat,
      props.delivery_info.address.lng
    );
  }, [props.delivery_info.address]);

  const _loadVendorData = (vendor_id) => {
    let { latitude, longitude } = props.coordinates;
    getVendorDetail(vendor_id, latitude, longitude)
      .then((data) => {
        props.setVendorCart(data);

        if (props.delivery_info.address != null) {
          _getDeliveryFees(
            vendor_id,
            props.delivery_info.address.lat,
            props.delivery_info.address.lng
          ).then();
        }
      })
      .catch(() => {
      });
  };

  const _getDeliveryFees = async (vendor_id, latitude, longitude) => {
    try {
      if (vendor_id == null || vendor_id === '' || latitude == null || longitude == null) {
        props.setPriceCart({
          ...props.cartPrice,
          delivery_fee: 0
        });
        return;
      }

      const params = [`vendor_id=${vendor_id}`];
      params.push(`lat=${latitude}`);
      params.push(`lng=${longitude}`);

      const queryParams = params.join('&');

      let response = await apiFactory.get(`checkout/delivery-fee?${queryParams}`);
      let data = response.data;

      setState({
        ...state,
        maxDeliveryTime: data['deliveryTime'] || state.maxDeliveryTime,
        minOrderPrice: data['minimumOrder'] || state.minOrderPrice,
        outOfDeliveryArea: data['outOfDeliveryArea'] || false,
        smallOrderFee: data['small_order_fee'] || state.smallOrderFee
      });

      deliveryFee.current = data['deliveryFee'] || 0;

      if (discountObj.current == null || discountObj.current.type != 'free_delivery') {
        setDeliveryFee(data['deliveryFee'] || 0);
      }

      // eslint-disable-next-line no-empty
    } catch (error) {
    }
  };

  const loadDiscount = () => {
    let vendorId = getVendorId;
    if (vendorId == null) {
      return;
    }
    let subTotal = _getSubTotal();
    getDiscount(vendorId, null, subTotal)
      .then((res) => {
        console.log('discount ', res.discount);
        discountObj.current = res.discount;
        if (couponObj.current == null) {
          applyPromo(discountObj.current);
        }
      })
      .catch((err) => {
        discountObj.current = null;
        applyPromo(discountObj.current);
        console.log('load load Discount', err);
      });
  };

  const applyPromo = (promoData) => {
    if (promoData == null) {
      setPromoState((pre) => ({
        ...pre,
        promoFreeObj: null,
        discount: 0
      }));

      setDeliveryFee(deliveryFee.current);
    } else if (promoData.type == 'fixed') {
      setPromoState((pre) => ({
        ...pre,
        promoFreeObj: null,
        discount: promoData.value ? promoData.value : 0
      }));
      setDeliveryFee(deliveryFee.current);
    } else if (promoData.type == 'percentage') {
      setPromoState((pre) => ({
        ...pre,
        promoFreeObj: null,
        discount: promoData.value ? promoData.value : 0
      }));
      setDeliveryFee(deliveryFee.current);
    } else if (promoData.type == 'item') {
      setPromoState((pre) => ({
        ...pre,
        promoFreeObj: promoData,
        discount: 0
      }));
      setDeliveryFee(deliveryFee.current);
    } else if (promoData.type == 'free_delivery') {
      setPromoState((pre) => ({ ...pre, promoFreeObj: promoData, discount: 0 }));
      setDeliveryFee(0);
    }

    if (promoData != null && promoData.sibling_promotion == 1) {
      setShowOrderFor(true);
    } else {
      setShowOrderFor(false);
    }
  };

  const _getSubTotal = () => {
    let sub_total = calculateCartTotal(props.cartItems);
    return sub_total;
  };

  const _getSmallOrderFee = () => {
    if (props.delivery_info.handover_method == OrderType_Delivery) {
      let subTotal = _getSubTotal();
      if (subTotal < state.minOrderPrice) {
        if (state.smallOrderFee != null) {
          return parseInt(state.smallOrderFee) || 0;
        }
      }
    }
    return 0;
  };

  const _getTotalPrice = () => {
    const { cashback } = props.cartPrice;

    let subtotal = _getSubTotal();
    let total = subtotal - promoState.discount - cashback + _getSmallOrderFee();

    if (props.delivery_info.handover_method == OrderType_Delivery) {
      total = total + delivery_fee;
    }

    return total;
  };
  // end api

  const _renderCoupon = () => {
    return (
      <div className={'flex_between  mt2'}>
        <div className={'subject-title fs4 flex_1'}>{t('cart.coupon.have_one')}</div>
        <CouponInput
          vendorId={props.vendorData.id}
          subTotal={_getSubTotal()}
          applyCoupon={(couponData) => {
            props.setCouponCart(couponData);
            couponObj.current = couponData;
            applyPromo(couponData);
          }}
        />
      </div>
    );
  };

  const _renderInviteCodeInput = () => {
    if (
      props.referralsRewardsSetting == null ||
      props.referralsRewardsSetting.show_earn_invitation_module != true
    ) {
      return;
    }
    return (
      <div className={'flex_between  mt2'}>
        <div className={'subject-title fs4 flex_1'}>{t('cart.promo_code.have_one')}</div>
        <InviteCodeInput
          applyInvitationCode={(code) => {
            setInvitationCode(code);
          }}
        />
      </div>
    );
  };

  const onProceed = () => {
    if (props.delivery_info.handover_method == OrderType_Delivery) {
      if (props.delivery_info.address == null) {
        return confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('checkout.validate_select_address'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {
              }
            }
          ]
        });
      }
      if (state.outOfDeliveryArea == true) {
        return confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('restaurant_details.no_in_vendor_support_zone'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {
              }
            }
          ]
        });
      }
    } else if (props.delivery_info.handover_method == OrderType_Reserve) {
      if (props.user == null) {
        return;
      }
      props.setDeliveryInfoCart({
        reserve_for: props.user
      });
    }

    if (_getTotalPrice() < 0) {
      return confirmAlert({
        title: t('restaurant_details.we_are_sorry'),
        message: t('cart.order_is_under_0'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }

    if (checkMultiPromoApply) {
      return;
    }

    if (hasLegalAgeProduct && props.confirm_legal_age != true) {
      // setLegalAgeError(true);
      return confirmAlert({
        title: null,
        message: t('cart.check_legal_age'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }

    props.setPriceCart({
      ...props.cartPrice,
      subtotal: _getSubTotal(),
      small_order_fee: _getSmallOrderFee(),
      order_total: _getTotalPrice(),
      min_order_price: state.minOrderPrice,
      discount: promoState.discount || 0,
      delivery_fee: delivery_fee,
      promo_code: promo_code
    });

    props.setPaymentInfoCart({
      ...props.payment_info,
      splits: []
    });

    setStep(1);
  };

  return (
    <div className={'checkout-screen mb10'}>
      <Row className={'align-col-middle mv3'} style={{ marginLeft: 0, marginRight: 0, justifyContent: 'center' }}>
        <Col xs={12} lg={9}>
          <Stepper step={step} setStep={setStep} />
        </Col>
      </Row>
      {step == 0 && (
        <Row className={'align-col-middle ph2'} style={{ marginLeft: 0, marginRight: 0, justifyContent: 'center' }}>
          <Col xs={12} md={7} className={'left-view  mt5'}>
            <CartView />
            <CutleryInput />
            <SuggestedProducts />
            <div className={'handover-view mt5'}>
              <h5 className={'subject-title flex_1'}>{t('cart.delivery_info')}</h5>
              <DeliveryInfo outOfDeliveryArea={state.outOfDeliveryArea} />
            </div>
          </Col>
          <Col xs={12} md={5} className={'right-view  mt5'}>
            <div className={'w100 show-md'}>
              <MainBtn
                className={'proceed-btn'}
                title={t('cart.continue_to_payment')}
                onClick={onProceed}
              />
            </div>
            {
              checkMultiPromoApply &&
              <div className={'error_msg_view mv2'}>
                {t('cart.not_possible_use_all_in_a_order')}
              </div>
            }
            <CashbackInput />
            {_renderCoupon()}
            {_renderInviteCodeInput()}
            {
              showOrderFor && <OrderForInput />
            }
            <PromotionView promoFreeObj={promoState.promoFreeObj} />
            <PriceBoard
              minOrderPrice={state.minOrderPrice}
              delivery_fee={delivery_fee}
              discount={promoState.discount}
              cashback={props.cartPrice.cashback}
              small_order_fee={_getSmallOrderFee()}
              sub_total={_getSubTotal()}
              total_price={_getTotalPrice()}
              handover_method={props.delivery_info.handover_method}
            />
            {
              hasLegalAgeProduct &&
              <div
                className={'align-middle radio-legal-age mt2'}
                onClick={() => {
                  props.setConfirmLegalAge(props.confirm_legal_age != true ? true : false);
                }}
              >
                <RadioBtn
                  checked={props.confirm_legal_age}
                  onClick={() => {
                    props.setConfirmLegalAge(props.confirm_legal_age != true ? true : false);
                    setLegalAgeError(false);
                  }}
                  hasError={props.confirm_legal_age != true && legal_age_error}
                />
                <span style={{ paddingLeft: 10 }}>{t('cart.confirm_legal_age')}</span>
              </div>
            }
            <div className={'w100 mt3 hide-md'}>
              <MainBtn
                className={'proceed-btn'}
                title={t('cart.continue_to_payment')}
                onClick={onProceed}
              />
            </div>
          </Col>
        </Row>
      )}
      {step == 1 && <PaymentMethodsStep setStep={setStep} />}
      {step == 2 && <PlaceOrderStep />}
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates,
  order_data: shop,
  cartItems: shop.items || [],
  comments: shop.comments,
  confirm_legal_age: shop.confirm_legal_age,
  cartPrice: shop.cartPrice,
  vendorData: shop.vendorData || {},
  referralsRewardsSetting: app.referralsRewardsSetting || {},

  delivery_info: shop.delivery_info,
  payment_info: shop.payment_info
});

CheckoutScreen.propTypes = {
  outOfDeliveryArea: PropTypes.bool,
  cartItems: PropTypes.array,
  referralsRewardsSetting: PropTypes.shape({
    show_earn_invitation_module: PropTypes.bool
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    }),
    tip_rider: PropTypes.number,
    num_guests: PropTypes.number,
    contactless_delivery: PropTypes.bool,
    pickup_date: PropTypes.string,
    pickup_time: PropTypes.string,
    reserve_for: PropTypes.shape({
      id: PropTypes.number
    }),
    is_schedule: PropTypes.number,
    schedule_time: PropTypes.string
  }),

  order_data: PropTypes.shape({
    items: PropTypes.array,

    cutlery: PropTypes.number,
    comments: PropTypes.string,
    coupon: PropTypes.object,

    vendorData: PropTypes.shape({
      id: PropTypes.number,
      delivery_type: PropTypes.string,
      online_payment: PropTypes.number
    })
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    order_method: PropTypes.string,
    maximum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    categories: PropTypes.array,
    can_schedule: PropTypes.number
  }),
  cartPrice: PropTypes.shape({
    cashback: PropTypes.number,
    discount: PropTypes.number,
    delivery_fee: PropTypes.number
  }),
  confirm_legal_age: PropTypes.bool,
  payment_info: PropTypes.object,
  cutlery: PropTypes.number,
  comments: PropTypes.string,
  user: PropTypes.object,
  updateCartItems: PropTypes.func,
  setDeliveryInfoCart: PropTypes.func,
  setCommentCart: PropTypes.func,
  setCutleryCart: PropTypes.func,
  setPaymentInfoCart: PropTypes.func,
  setCouponCart: PropTypes.func,
  setVendorCart: PropTypes.func,
  setPriceCart: PropTypes.func,
  clearCart: PropTypes.func,
  getAddresses: PropTypes.func,
  getReferralsRewardsSetting: PropTypes.func,
  setConfirmLegalAge: PropTypes.func
};

export default connect(mapStateToProps, {
  setVendorCart,
  setTmpFood,
  resetCart,
  setCutleryCart,
  setCouponCart,
  clearCart,
  setCommentCart,
  getAddresses,
  getReferralsRewardsSetting,
  setPriceCart,
  setPaymentInfoCart,
  setDeliveryInfoCart,
  setConfirmLegalAge
})(CheckoutScreen);
