import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { MainBtn } from '../../../../components/Buttons';
import AddressItem from '../../../../components/Profile/AddressItem';
import ConfirmModal from '../../../../components/Modals/ConfirmModal';
import {
  getAddresses,
  saveAddress,
  deleteAddress,
  setLocallyAddresses,
  setTmpLocationPicked
} from '../../../../store/actions/app';
import './index.css';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../../components/Spinner';

const Addresses = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = querystring.parse(location.search);
  const isFromCart = parsed.from_cart === 'true';

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isConfirmModal, ShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        setLoading(true);
        await props.getAddresses();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchAddresses();
  }, []);

  const onEditAddress = (addressItem) => {
    let tmpAddress = {
      ...addressItem,
      coords: {
        latitude: addressItem.lat,
        longitude: addressItem.lng
      }
    };
    props.setTmpLocationPicked(tmpAddress);
    navigate(`/profile/address?is_edit=true&from_cart=${isFromCart}`);
  };

  const onSelectAddress = async (addressItem) => {
    try {
      let item = { ...addressItem };
      item.favourite = 1;
      await saveAddress(item);
      await props.getAddresses();

      if (isFromCart) {
        navigate(-1);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onDeleteAddress = async () => {
    try {
      ShowConfirmModal(false);
      if (selectedAddress == null) {
        return;
      }
      await props.deleteAddress(selectedAddress.id);
      await props.getAddresses();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className={'align-col-start addresses-screen'}>
      <div className={'scrollview'}>
        {loading === true ? (
          <LoadingSpinner />
        ) : (
          props.addresses.map((address) => (
            <AddressItem
              key={address.id}
              id={address.id}
              favourite={address.favourite}
              data={address}
              style={{ marginBottom: 16 }}
              onEdit={() => {
                onEditAddress(address);
              }}
              onClick={() => {
                onSelectAddress(address);
              }}
              onDelete={() => {
                setSelectedAddress(address);
                ShowConfirmModal(true);
              }}
            />
          ))
        )}
        <div style={{ height: 20 }} />
      </div>
      <div className={'ph-20 w100'} style={{ marginBottom: 40 }}>
        <MainBtn
          title={t('address_list.add_new_address')}
          onClick={() => {
            props.setTmpLocationPicked({});
            navigate(`/profile/address`);
          }}
        />
      </div>
      <ConfirmModal
        showModal={isConfirmModal}
        title={t('address_list.delete_confirm')}
        yes={t('address_list.delete_confirm_yes')}
        no={t('address_list.delete_confirm_no')}
        onYes={onDeleteAddress}
        onClose={() => ShowConfirmModal(false)}
      />
    </div>
  );
};

Addresses.propTypes = {
  addresses: PropTypes.array,
  getAddresses: PropTypes.func,
  deleteAddress: PropTypes.func,
  setTmpLocationPicked: PropTypes.func,
  setLocallyAddresses: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  addresses: app.addresses || []
});

export default connect(mapStateToProps, {
  getAddresses,
  deleteAddress,
  setTmpLocationPicked,
  setLocallyAddresses
})(Addresses);
