import React from 'react';
import { MinusCircle, PlusCircle } from '@styled-icons/boxicons-solid';
import { Theme } from '../../../assets';
import { IconButton } from '@mui/material';
import './index.css';
import PropTypes from 'prop-types';

const Counter = ({ onPlus, onMinus, value, style, value_style, btnColor, btnSize }) => {
  return (
    <div className={'align-middle cart-counter'} style={style}>
      <IconButton style={{ padding: 0 }} onClick={onMinus}>
        <MinusCircle
          size={btnSize ? btnSize : 28}
          color={btnColor ? btnColor : Theme.colors.text}
        />
      </IconButton>
      <div className={'text'} style={value_style}>
        {value}
      </div>
      <IconButton style={{ padding: 0 }} onClick={onPlus}>
        <PlusCircle size={btnSize ? btnSize : 28} color={btnColor ? btnColor : Theme.colors.text} />
      </IconButton>
    </div>
  );
};

Counter.propTypes = {
  btnColor: PropTypes.string,
  btnSize: PropTypes.number,
  value_style: PropTypes.object,
  value: PropTypes.number,
  style: PropTypes.object,
  onPlus: PropTypes.func,
  onMinus: PropTypes.func
};

export default React.memo(Counter);
