import React, { useEffect, useRef, useState } from 'react';
import Seo from '../../../components/Seo';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import CommonTabs from '../../../components/TabSelector/CommonTabs';
import './index.css';
import NoPromoList from '../../../components/Empty/NoPromoList';
import PromotionItem from '../../../components/Vendor/PromotionItem';
import apiFactory from '../../../services/apiFactory';
import { setAppHeaderClass } from '../../../store/actions/app';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../components/Spinner';

const Promotions = (props) => {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const [tab, setTab] = useState('current');

  const [currents, setCurrents] = useState([]);
  const [pasts, setPasts] = useState([]);

  const [currentsLoading, setCurrentsLoading] = useState(null);
  const [pastsLoading, setPastsLoading] = useState(null);

  useEffect(() => {
    props.setAppHeaderClass('app-sticky-header');
    getActivePromotions();
    getPromotions();
    return () => {
      _isMounted.current = false;
      props.setAppHeaderClass('');
    };
  }, []);

  const getActivePromotions = () => {
    setCurrentsLoading(true);
    apiFactory
      .get('/promotions/active')
      .then(({ data }) => {
        if (_isMounted.current === true) {
          setCurrents(data.promotions || []);
          setCurrentsLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setCurrentsLoading(false);
        }
      });
  };

  const getPromotions = () => {
    setPastsLoading(true);
    apiFactory
      .get('/promotions/used')
      .then(({ data }) => {
        if (_isMounted.current === true) {
          const promotions = data.data;
          setPasts(promotions || []);
          setPastsLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setPastsLoading(false);
        }
      });
  };

  return (
    <div data-testid='view-promotions' className={'view-promotions ph4'}>
      <Seo title={t('web_title.favorites')} />
      <Container fluid className={'container max-lg'}>
        <Row>
          <h1>{t('account.promotions_menu')}</h1>
        </Row>
        <Row>
          <div className={'align-middle tab-wrapper'}>
            <CommonTabs
              items={['current', 'past']}
              item={tab}
              onChange={(type) => {
                setTab(type);
              }}
              style={{ minWidth: 160 }}
            />
          </div>
        </Row>
        <Row style={{ marginTop: 20, marginBottom: 80 }} justify={'between'}>
          {(tab === 'current' && currentsLoading === true) ?
            <div className={'flex_1'}><LoadingSpinner /></div> :
            (tab === 'past' && pastsLoading === true) ?
              <div className={'flex_1'}><LoadingSpinner /></div> :
              (tab === 'current' && currentsLoading === false && currents.length === 0) ||
              (tab === 'past' && pastsLoading === false && pasts.length === 0) ? (
                <NoPromoList />
              ) : (
                (tab === 'current' ? currents : pasts).map((item) => (
                  <Col key={item.id} xs={12} sm={6} lg={4}>
                    <PromotionItem id={item.id} isPast={tab != 'current'} data={item} onSelect={() => {
                    }} />
                  </Col>
                ))
              )}
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn
});

Promotions.propTypes = {
  user: PropTypes.shape({
    photo: PropTypes.string,
    cashback_amount: PropTypes.number
  }),
  setAppHeaderClass: PropTypes.func
};

export default connect(mapStateToProps, {
  setAppHeaderClass
})(Promotions);
