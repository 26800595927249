import { ACTION_TYPES } from '../../constants';
import { Storage } from '../../services';
import apiFactory from '../../services/apiFactory';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

export const AddProduct2Cart = (cartItem) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let items = getState().shop.items.slice(0, getState().shop.items.length);
      let foundIndex = items.findIndex((i) => i.id === cartItem.id);
      if (foundIndex === -1) {
        items.push(cartItem);
      } else {
        items[foundIndex].quantity = cartItem.quantity;
        items[foundIndex].comments = cartItem.comments;
        items[foundIndex].options = cartItem.options;
      }

      Storage.setCartItems(items);
      dispatch({
        type: ACTION_TYPES.UPDATE_CART_ITEMS,
        payload: items
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const AddProductVendorCheck = () => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      let items = getState().shop.items.slice(0, getState().shop.items.length);
      let foundIndex = items.findIndex((i) => i.vendor_id !== getState().shop.vendorData.id);

      if (foundIndex === -1) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (e) {
      reject(e);
    }
  });
};

export const removeProductFromCart =
  (cartItem, isAll = false) =>
  async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      try {
        let items = getState().shop.items.slice(0, getState().shop.items.length);
        if (isAll === true) {
          items = items.filter((i) => i.id !== cartItem.id);
        } else {
          let foundIndex = items.findIndex((i) => i.id === cartItem.id);
          if (foundIndex !== -1) {
            if (items[foundIndex].quantity <= 1) {
              items = items.filter((i) => i.id !== cartItem.id);
            } else {
              items[foundIndex].quantity = items[foundIndex].quantity - 1;
            }
          }
        }

        Storage.setCartItems(items);
        dispatch({
          type: ACTION_TYPES.UPDATE_CART_ITEMS,
          payload: items
        });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

export const updateCartItems = (items) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Storage.setCartItems(items);
      dispatch({
        type: ACTION_TYPES.UPDATE_CART_ITEMS,
        payload: items
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const setCutleryCart = (cutlery) => {
  return { type: ACTION_TYPES.SET_CUTLERY_CART, payload: cutlery };
};
export const setCommentCart = (comments) => {
  return { type: ACTION_TYPES.SET_COMMENT_CART, payload: comments };
};
export const setCouponCart = (coupon) => {
  return { type: ACTION_TYPES.SET_COUPON_CART, payload: coupon };
};
export const setPriceCart = (prices) => {
  return { type: ACTION_TYPES.SET_PRICE_CART, payload: prices };
};

export const setDeliveryInfoCart = (payload) => {
  return { type: ACTION_TYPES.SET_DELIVERY_INFO_CART, payload: payload };
};

export const setPaymentInfoCart = (payload) => {
  return { type: ACTION_TYPES.SET_PAYMENT_INFO_CART, payload: payload };
};

export const setVendorCart = (payload) => {
  return { type: ACTION_TYPES.SET_VENDOR_CART, payload: payload };
};

export const clearCart = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Storage.setCartItems([]);
      dispatch({
        type: ACTION_TYPES.CLEAR_CART,
        payload: []
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const resetCart = (payload) => {
  return { type: ACTION_TYPES.RESET_CART, payload: payload };
};

export const sendOrder = (orderData) => {
  return new Promise((resolve, reject) => {
    apiFactory.post('checkout', orderData).then(
      async ({ data }) => {
        try {
          identifyDevice();
          trackEvent('OrderProcessing', {
            action_category: 'Create checkout',
            action_outcome: 'success',
            interaction_element: 'button clicking',
            source: 'sf_web'
          });
          resolve(data.order);
        } catch (e) {
          reject(e);
          identifyDevice();
          trackEvent('OrderProcessing', {
            action_category: 'Create checkout',
            action_outcome: 'fail',
            interaction_element: 'button clicking',
            source: 'sf_web'
          });
        }
      },
      (e) => {
        reject(e);
      }
    );
  });
};

export const reOrder = (order, restaurant) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    apiFactory.get(`orders/${order.id}/reorder`).then(
      async ({ data }) => {
        identifyDevice();
        trackEvent('Load Orders', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        // if (!data['vendor_is_open']) {
        // 	reject(translate('restaurant_details.restaurant_closed'));
        // }
        const cartProducts = data.products;
        const items = cartProducts.map((cartProduct) => {
          const orderProduct = order.products.find((p) => p['product_id'] === cartProduct.id);
          const quantity = orderProduct ? orderProduct.quantity : 1;
          const options = orderProduct ? orderProduct.options : [];
          const comments = orderProduct ? orderProduct.item_instructions : '';

          return {
            ...cartProduct,
            options,
            comments,
            quantity
          };
        });

        Storage.setCartItems(items);
        dispatch({
          type: ACTION_TYPES.CLEAR_CART,
          payload: items
        });
        if (getState().shop.vendorData == null || getState().shop.vendorData.id !== restaurant.id) {
          dispatch({ type: ACTION_TYPES.SET_VENDOR_CART, payload: restaurant });
        }
        resolve(items);
      },
      (error) => reject(error)
    );
  });
};

export const getDiscount = (vendorId, order_by, total) => {
  return new Promise((resolve, reject) => {
    let endpoint = `discounts?vendor_id=${vendorId}`;
    if (total) {
      endpoint = endpoint + `&subtotal=${total}`;
    }
    if (order_by) {
      endpoint = endpoint + `&order_by=${order_by}`;
    }
    apiFactory.get(endpoint).then(
      ({ data }) => {
        resolve(data);
      },
      (err) => {
        reject(err);
      }
    );
  });
};

export const setOrderFor = (payload) => {
  return { type: ACTION_TYPES.APP_SET_ORDER_FOR, payload: payload };
};

export const setConfirmLegalAge = (payload) => {
  return { type: ACTION_TYPES.APP_SET_CONFIRM_LEGAL_AGE, payload: payload };
};
