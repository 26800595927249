import React, { useState, useEffect } from 'react';
import PopularMenus from '../../components/Home/PopularMenus';
import AppIntro from '../../components/Ourapp/AppIntro';
import AppIntrolast from '../../components/Ourapp/AppIntrolast';
import FoodTabs from '../../components/Ourapp/FoodTabs';
// import Live from '../../components/Ourapp/Live';
import './index.css';
import Seo from '../../components/Seo';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { isEmpty } from '../../utils/common';
import ApiFactory from '../../services/apiFactory';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

const DownloadApp = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const parsed = querystring.parse(location.search) || {};

  const [ios_link, setIosLink] = useState(
    'https://itunes.apple.com/al/app/snapfood-food%20delivery/id1314003561?l=en&amp;mt=8'
  );
  const [android_link, setAndroidLink] = useState(
    'https://play.google.com/store/apps/details?id=com.snapfood.app&amp;hl=en'
  );

  useEffect(() => {
    if (!isEmpty(parsed.code)) {
      setAndroidLink(
        `https://referral.snapfood.al/referral/?link=https://snapfood.al/referral?referralCode=${parsed.code}&apn=com.snapfood.app&isi=1314003561&ibi=com.snapfood.al&efr=1`
      );
      ApiFactory.get(`get-referral-link?referral_code=${parsed.code}`).then(
        ({ data }) => {
          identifyDevice();
          trackEvent('Get Referral Link', {
            action_category: 'Retrieve Link',
            action_outcome: 'success',
            interaction_element: 'Screen',
            source: 'sf_web'
          });
          if (!isEmpty(data?.referral_link)) {
            setIosLink(data?.referral_link);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [parsed.code]);

  return (
    <div data-testid="view-download-app" className={'view-download-app'}>
      <Seo title={t('web_title.download_app')} />
      <AppIntro ios_link={ios_link} android_link={android_link} />
      <FoodTabs />
      <div className={'w100 align-col-middle'}>
        <PopularMenus />
      </div>
      {/* <Live /> */}
      <AppIntrolast ios_link={ios_link} android_link={android_link} />
    </div>
  );
};

export default DownloadApp;
