import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Seo from '../../../components/Seo';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import Svg_mail from '../../../assets/svgs/profile/email.svg';
import Svg_phone from '../../../assets/svgs/profile/phone.svg';
import Svg_edit from '../../../assets/svgs/profile/edit.svg';
import Svg_location from '../../../assets/svgs/profile/location.svg';
import Svg_birthday from '../../../assets/svgs/profile/birthday.svg';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EditProfileModal from '../../../components/Modals/EditProfileModal';
import { TIRANA_CITY_LOCATION } from '../../../constants/config';
import { findZodiacSign, getImageFullURL } from '../../../utils/common';
import { Grid } from '@mui/material';
import { getRewardsSetting } from '../../../store/actions/app';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Profile = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pages = [
    'profile/addresses',
    'profile/pay-methods',
    'profile/wallet',
    'profile/earn-invitation'
  ];
  const [value, setValue] = useState(0);
  const [isEditModal, showEditModal] = useState(false);

  useEffect(() => {
    if (pathname.includes('profile/address') || pathname === '/profile') {
      setValue(0);
    } else if (pathname.includes('profile/pay-method')) {
      setValue(1);
    } else if (pathname.includes('profile/wallet')) {
      setValue(2);
    } else if (
      pathname.includes('profile/earn-invitation') ||
      pathname.includes('profile/earninvite-friend') ||
      pathname.includes('profile/earninvite-snapfooder')
    ) {
      setValue(3);
    }
  }, [pathname]);

  useEffect(() => {
    const fetchRewardsSetting = async () => {
      await props.getRewardsSetting();
    };
    fetchRewardsSetting();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue < pages.length) {
      navigate(`/${pages[newValue]}`);
    }
  };

  return (
    <div data-testid="view-profile" className={'view-profile'}>
      <Seo title={t('web_title.profile')} />
      <div className="profile ph2 mt6">
        <div className="user-profile-info-img">
          <img src={getImageFullURL(props.user.photo)} />
        </div>
        <div className="user-profile-info">
          <div>
            <h3>
              <span>{props.user.full_name} </span>
              {props.user.birthdate != null && (
                <img src={findZodiacSign(props.user.birthdate)} className={'zodiac'} />
              )}
            </h3>
            <ul>
              {props.user.birthdate != null && (
                <li>
                  <Link to="">
                    <img src={Svg_birthday} />
                    {moment(props.user.birthdate).format('D MMM, YYYY')} (
                    {moment().diff(moment(props.user.birthdate), 'years')}y)
                  </Link>
                </li>
              )}
              <li>
                <Link to="">
                  <img src={Svg_location} />
                  {props.address.city || TIRANA_CITY_LOCATION.city},{' '}
                  {props.address.country || TIRANA_CITY_LOCATION.country}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="cont-info">
          <h3>{t('account.contact_info')}</h3>
          <ul>
            <li>
              <Link to="">
                <img src={Svg_mail} />
                {props.user.email}
              </Link>
            </li>
            <li>
              <Link to="">
                <img src={Svg_phone} />
                {props.user.phone}
              </Link>
            </li>
          </ul>
        </div>
        <div className="edit-info">
          <Link
            to=""
            onClick={() => {
              showEditModal(true);
            }}>
            <img src={Svg_edit} />
          </Link>
        </div>
      </div>
      <Box className="tabs-main" sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs"
            className="lis"
            position="static"
            variant="scrollable">
            <Tab label={t('account.addresses')} {...a11yProps(0)} />
            <Tab label={t('account.payment_method')} {...a11yProps(1)} />
            <Tab label={t('account.wallet')} {...a11yProps(2)} />
            <Tab label={t('Invite_Earn')} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <Grid
          container
          className={'align-middle ph2'}
          style={{ margin: 0, marginTop: 20, width: '100%' }}>
          <Grid item xs={12} md={6} lg={4} style={{}}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
      <EditProfileModal isOpen={isEditModal} onClose={() => showEditModal(false)} />
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({
    photo: PropTypes.string,
    birthdate: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string
  }),
  getRewardsSetting: PropTypes.func,
  logout: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  address: app.address || {}
});

export default connect(mapStateToProps, { getRewardsSetting })(Profile);
