import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './index.css';

const AddressItem = ({
  favourite,
  data,
  phone,
  editable,
  style,
  onClick,
  onEdit,
  onDelete
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={'address-item'}
      style={style}
     >
      {editable !== false && (
        <div className={'flex_between title-view'}>
          <div className={'align-middle'}>
            <div className={'address-type'}>{t(data.address_type)}</div>
            {favourite === 1 ?
              <div className={'primary-text'}>{t('primary')}</div>
              :
              <p className={'makeprimary-link'}
                 onClick={(e) => {
                   e.preventDefault();
                   if (onClick) {
                     onClick();
                   }
                 }}
              >{t('make_primary')}</p>
            }
          </div>
          <div className={'align-middle'}>
            <p
              className={'edit-link'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEdit();
              }}>
              {t('edit')}
            </p>
            <p
              className={'delete-link ml2'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete();
              }}>
              {t('remove')}
            </p>
          </div>
        </div>
      )}
      <div className={'info'}>
        {/*<h3>{data.full_name}</h3>*/}
        <p>{phone}</p>
        <p>
          {data.street} {data.city}, {data.country}
        </p>
      </div>
    </div>
  );
};

AddressItem.propTypes = {
  id: PropTypes.number,
  favourite: PropTypes.number,
  data: PropTypes.shape({
    address_type: PropTypes.string,
    favourite: PropTypes.number,
    username: PropTypes.string,
    full_name: PropTypes.string,
    phone: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    notes: PropTypes.string
  }),
  phone: PropTypes.string,
  editable: PropTypes.bool,
  showNotes: PropTypes.bool,
  outOfDeliveryArea: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete : PropTypes.func
};
export default AddressItem;
