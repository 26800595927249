import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import apiFactory from '../../../services/apiFactory';
import Svg_close from '../../../assets/svgs/profile/close.svg';
import './index.css';
import '../index.css';
import { setOrderMessageTags } from '../../../store/actions/app';
import RadioBtn from '../../Buttons/RadioBtn';
import ToggleBtn from '../../Buttons/ToggleBtn';
import ChooseFriendModal from '../ChooseFriendModal';
import Svg_male from '../../../assets/svgs/markers/snapfooder_male.svg';

const MapSettingModal = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const [isChooseModal, setChooseModal] = useState(false);
  const [type, setType] = useState(null);
  const [state, setState] = useState({
    loading: false,
    offlineMode: false,
    hideSnapfooder: false,
    whoCanSeeMyLocation: null,
    selectedFriends: []
  });

  useEffect(() => {
    getMapSetting();
  }, []);

  const getMapSetting = () => {
    apiFactory.get(`users/map-setting`).then(
      ({ data }) => {
        setState({
          ...state,
          offlineMode: data.map_visible == 0,
          hideSnapfooder: data.hide_as_snapfooder == 1,
          whoCanSeeMyLocation: data.map_visible_type,
          selectedFriends: data.map_visible_details || []
        });
      },
      () => {}
    );
  };

  const updateMapSetting = (visible, hide, type) => {
    apiFactory
      .post(`users/update-map-setting`, {
        visible: visible,
        hide_snapfooder: hide,
        type: type
      })
      .then(
        ({ data }) => {
          if (data.user) {
            setState({
              ...state,
              offlineMode: data.user.map_visible == 0,
              hideSnapfooder: data.user.hide_as_snapfooder == 1,
              whoCanSeeMyLocation: data.user.map_visible_type
            });
          }
        },
        () => {}
      );
  };

  const goExcept = () => {
    setType('except_friend');
    setChooseModal(true);
  };

  const goOnly = () => {
    setType('only_friend');
    setChooseModal(true);
  };

  const renderOfflineMode = () => {
    return (
      <div className={'section mt0'}>
        <div className={'w100'}>
          <div className={'headerTitle'}>{t('map_settings.my_location')}</div>
          <div className={'headerSubTitle'}>{t('map_settings.my_location_subtitle')}</div>
        </div>
        <div className={'sectionContent mt1'}>
          <div style={{ paddingRight: 14 }}>
            <img src={Svg_male} style={{ marginTop: 10 }} />
          </div>
          <div className={'sectionCol'}>
            <div className={'sectionTitle'}>{t('map_settings.offline_mode')}</div>
            <div className={'sectionSubTitle'}>
              {state.offlineMode
                ? t('map_settings.offline_mode_subtitle_enabled')
                : t('map_settings.offline_mode_subtitle')}
            </div>
          </div>
          <ToggleBtn
            onClick={() => {
              updateMapSetting(state.offlineMode ? 1 : 0);
            }}
            isOn={state.offlineMode == true}
          />
        </div>
      </div>
    );
  };

  const renderHideSnapfooderMode = () => {
    return (
      <div className={'section mt0'}>
        <div className={'sectionContent'}>
          <div className={'sectionCol'}>
            <div className={'sectionTitle'}>{t('map_settings.hide_snapfooder')}</div>
            <div className={'sectionSubTitle'}>
              {state.hideSnapfooder
                ? t('map_settings.offline_mode_subtitle_enabled')
                : t('map_settings.hide_snapfooder_subtitle')}
            </div>
          </div>
          <ToggleBtn
            onClick={() => {
              updateMapSetting(state.offlineMode ? 0 : 1, state.hideSnapfooder ? 0 : 1);
            }}
            isOn={state.hideSnapfooder == true}
          />
        </div>
      </div>
    );
  };

  const renderWhoCanSeeMyLocation = () => {
    const getSelectedDesc = () => {
      if (!state.offlineMode) {
        if (state.selectedFriends.length > 2) {
          return (
            (state.selectedFriends[0].username || state.selectedFriends[0].full_name) +
            ` +${state.selectedFriends.length - 1}`
          );
        } else if (state.selectedFriends.length == 2) {
          return (
            (state.selectedFriends[0].username || state.selectedFriends[0].full_name) +
            ', ' +
            (state.selectedFriends[1].username || state.selectedFriends[1].full_name)
          );
        } else if (state.selectedFriends.length == 1) {
          return state.selectedFriends[0].username || state.selectedFriends[0].full_name;
        }
      }
      return '';
    };

    return (
      <div className={'section'}>
        <div className={'sectionTitle mt2'}>{t('map_settings.see_my_location')}</div>
        <div
          disabled={state.offlineMode}
          onClick={() => {
            if (state.whoCanSeeMyLocation != 'all_friend') {
              updateMapSetting(1, null, 'all_friend');
            }
          }}
          className={'sectionContent'}>
          <div className={'sectionCol'}>
            <div className={'sectionRadioTitle'}>{t('map_settings.see_my_location_friends')}</div>
          </div>
          <RadioBtn
            disabled={state.offlineMode}
            onClick={() => {
              if (state.whoCanSeeMyLocation != 'all_friend') {
                updateMapSetting(1, null, 'all_friend');
              }
            }}
            checked={!state.offlineMode && state.whoCanSeeMyLocation === 'all_friend'}
          />
        </div>
        <div disabled={state.offlineMode} onClick={goExcept} className={'sectionContent'}>
          <div className={'sectionCol'}>
            <div className={'sectionRadioTitle'}>
              {t('map_settings.see_my_location_friends_except')}
            </div>
            {!state.offlineMode && state.whoCanSeeMyLocation === 'except_friend' && (
              <div className={'selectedFriends'}>{getSelectedDesc()}</div>
            )}
          </div>
          <RadioBtn
            disabled={state.offlineMode}
            onClick={goExcept}
            checked={!state.offlineMode && state.whoCanSeeMyLocation === 'except_friend'}
          />
        </div>
        <div disabled={state.offlineMode} onClick={goOnly} className={'sectionContent'}>
          <div className={'sectionCol'}>
            <div className={'sectionRadioTitle'}>
              {t('map_settings.see_my_location_friends_only')}
            </div>
            {!state.offlineMode && state.whoCanSeeMyLocation === 'only_friend' && (
              <div className={'selectedFriends'}>{getSelectedDesc()}</div>
            )}
          </div>
          <RadioBtn
            disabled={state.offlineMode}
            onClick={goOnly}
            checked={!state.offlineMode && state.whoCanSeeMyLocation === 'only_friend'}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={'align-col-middle  modal map-setting-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'align-middle titleview ph2 pv3'}>
          <div className={'align-middle w100'}>
            <img
              src={Svg_close}
              onClick={() => {
                onClose();
              }}
            />
            <h2>{t('map_settings.title')}</h2>
            <div style={{ width: 45 }} />
          </div>
        </div>
        <div className={'align-col-middle w100 scrollview'}>
          {renderOfflineMode()}
          {renderHideSnapfooderMode()}
          {renderWhoCanSeeMyLocation()}
        </div>
      </div>
      {isChooseModal && (
        <ChooseFriendModal
          isOpen={isChooseModal}
          type={type}
          selected={state.whoCanSeeMyLocation == type ? state.selectedFriends : []}
          onClose={(isSuccess) => {
            setChooseModal(false);
            if (isSuccess) {
              getMapSetting();
            }
          }}
        />
      )}
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal;
}

MapSettingModal.propTypes = {
  showModal: PropTypes.bool,
  users: PropTypes.array,
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  onClose: PropTypes.func,
  onShowDetail: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language
});

export default connect(mapStateToProps, { setOrderMessageTags })(
  React.memo(MapSettingModal, arePropsEqual)
);
