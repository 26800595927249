import React from 'react';
import { getImageFullURL, findZodiacSign } from '../../../utils/common';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';

const SnapfooderAvatar = ({ full_name, photo, birthdate }) => {
  return (
    <div className={'align-col-middle snapfooder-avatar'} style={styles.avatarView}>
      <div className={'align-col-middle photoview'} style={styles.photoView}>
        <img src={getImageFullURL(photo)} className={'avatarimg'} style={styles.avatarImg} />
      </div>
      <div className={'align-middle'} style={{ marginTop: 10 }}>
        <div style={styles.name}>{full_name}</div>
        {birthdate != null && <img src={findZodiacSign(birthdate)} className={'zodiac'} />}
      </div>
      {/*<div style={styles.infoTxt}>{country}</div>*/}
    </div>
  );
};

const styles = {
  avatarView: { marginTop: 0 },
  photoView: {
    height: 150,
    width: 150,
    borderWidth: 1,
    borderColor: Theme.colors.gray9,
    borderRadius: 80,
    backgroundColor: '#E8D7D0'
  },
  avatarImg: { width: 150, height: 150, objectFit: 'cover', borderRadius: 80 },
  name: { marginRight: 4, fontSize: 18, fontFamily: Theme.fonts.bold, color: Theme.colors.text },
  infoTxt: {
    marginTop: 6,
    fontSize: 14,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.gray7
  }
};

SnapfooderAvatar.propTypes = {
  full_name: PropTypes.string,
  photo: PropTypes.string,
  birthdate: PropTypes.string,
  country: PropTypes.string
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.full_name === nextProps.full_name &&
    prevProps.photo === nextProps.photo &&
    prevProps.birthdate === nextProps.birthdate &&
    prevProps.country === nextProps.country
  );
}

export default React.memo(SnapfooderAvatar, arePropsEqual);
