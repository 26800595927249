import React from 'react';
import PropTypes from 'prop-types';
import Svg_loc from '../../assets/svgs/markers/pin.svg';
import './index.css';

const LocationMarker = () => {
  return (
    <div className={'map-marker location-marker'}>
      <img src={Svg_loc} />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return !(prevProps.lat !== nextProps.lat || prevProps.lng !== nextProps.lng);
}

LocationMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number
};
export default React.memo(LocationMarker, arePropsEqual);
