import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const RestaurantCard = ({
  imageSrc,
  smallImg,
  overlineText,
  headingText,
  descriptionText,
  buttonText,
  link,
  icon,
  isOrderButton
}) => {
  return (
    <div className="feature">
      <div className="image-container">
        <div className="child-layer">
          <img src={imageSrc} alt="Restaurant" className="restaurant-image" />
          <img
            src={smallImg ?? imageSrc}
            alt="RestaurantSmall"
            className="restaurant-image-mobile"
          />
        </div>
      </div>
      <a href={isOrderButton ? link : "#"} style={{textDecoration: "none"}}>
      <div className="text-container">
        <div className="overline">{overlineText}</div>
        <div className="heading">{headingText}</div>
        <div className="description">{descriptionText}</div>
        <div className='order-button'>
          <img src={icon} />
          <span><b>{buttonText}</b></span>
        </div>
      </div>
      </a>
    </div>
  );
};

RestaurantCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  smallImg: PropTypes.string,
  overlineText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.object,
  isOrderButton: PropTypes.bool,
};

export default RestaurantCard;
