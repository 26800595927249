import { ApiFactory } from './index';

const API_LIST = {
  getPastOrders: 'orders?vendor_id=',
  favourites: 'vendors/favourites'
};

const VendorService = {
  getPastOrders(vendor_id) {
    return ApiFactory.get(API_LIST.getPastOrders + vendor_id);
  },
  getVendorDetail(id, latitude = 41.328939, longitude = 19.813715, order_method) {
    let url = `vendors/${id}?lat=${latitude}&lng=${longitude}`;
    if (order_method != null) {
      url = `${url}&order_method=${order_method}`;
    }
    return ApiFactory.get(url);
  },
  toggleFavourite(vendorId, isFavourite) {
    return new Promise((resolve, reject) => {
      ApiFactory.post(API_LIST.favourites, {
        vendor_id: vendorId,
        favourite: isFavourite
      }).then(
        () => {
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  getVendors(
    page = 1,
    latitude = 41.328939,
    longitude = 19.813715,
    orderBy,
    orderDirection,
    perPage = 15,
    filterKeys,
    defaultPayload = false
  ) {
    if (defaultPayload) {
      const url =
        'https://prodapi.snapfood.al/api/vendors?lat=41.3177519&lng=19.8146872&page=1&per_page=60&ordering_attribute=title&ordering_order=1&vendor_type=Restaurant&order_method=Delivery';
      return new Promise((resolve, reject) => {
        ApiFactory.get(url).then(
          ({ data }) => {
            resolve(data.vendors);
          },
          (error) => {
            reject(error);
          }
        );
      });
    }

    const params = [`lat=${latitude}`, `lng=${longitude}`, `page=${page}`, `per_page=${perPage}`];
    if (orderBy) {
      params.push(`ordering_attribute=${orderBy}`);
      params.push(`ordering_order=${orderDirection}`);
    }

    if (!!filterKeys && filterKeys.length > 0) {
      for (let i = 0; i < filterKeys.length; i++) {
        params.push(filterKeys[i]);
        if (filterKeys[i].includes('ordering_attribute')) {
          params.push('ordering_order=1');
        }
      }
    }

    return new Promise((resolve, reject) => {
      ApiFactory.get(`vendors?${params.join('&')}`).then(
        ({ data }) => {
          resolve(data.vendors);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  getFeaturedBlocks(latitude = 41.328939, longitude = 19.813715, filterKeys) {
    const params = [`lat=${latitude}`, `lng=${longitude}`];

    if (!!filterKeys && filterKeys.length > 0) {
      for (let i = 0; i < filterKeys.length; i++) {
        params.push(filterKeys[i]);
      }
    }

    return new Promise((resolve, reject) => {
      ApiFactory.get(`vendors/mobile-feature-blocks?${params.join('&')}`).then(
        ({ data }) => {
          resolve(data.result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
};

export default VendorService;
