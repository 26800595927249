import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AddProduct2Cart, updateCartItems } from '../../../store/actions/shop';
import { setTmpFood } from '../../../store/actions/app';
import SuggestedItem from './SuggestedItem';
import './index.css';
const responsive = {
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  md: {
    breakpoint: { max: 2000, min: 1024 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 766 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 766, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  small: {
    breakpoint: { max: 350, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const SuggestedProducts = (props) => {
  const { t } = useTranslation();
  const [suggestedItems, setSuggestedItems] = useState([]);

  useEffect(() => {
    _loadSuggestedItems().then();
  }, [props.cartItems, props.vendorData]);

  const _loadSuggestedItems = async () => {
    const { cartItems, vendorData } = props;
    try {
      if (cartItems == null || vendorData == null) {
        return;
      }
      let categories = vendorData.categories || [];
      let suggestedItems = [];
      cartItems.map((cartItm) => {
        let category = categories.find((cat) => cat.id === cartItm.category_id);

        if (category != null && category.products != null) {
          let tmpProducts = [];
          category.products.slice(0, 30).map((p) => {
            let foundCart = cartItems.find((i) => i.id === p.id);
            let foundSuggested = suggestedItems.find((i) => i.id === p.id);
            if (foundCart == null && foundSuggested == null) {
              tmpProducts.push(p);
            }
          });

          tmpProducts.sort(function (a, b) {
            return Math.abs(a.price - cartItm.price) - Math.abs(b.price - cartItm.price);
          });
          suggestedItems.push(...tmpProducts.slice(0, 6));
        }
      });

      suggestedItems
        .sort(function (a, b) {
          return a.price - b.price;
        })
        .slice(0, 10);

      setSuggestedItems(suggestedItems);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const onAddCart = async (data) => {
    try {
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id === data.id);
      if (foundIndex !== -1) {
        tmp[foundIndex].quantity = tmp[foundIndex].quantity + 1;
      } else {
        let cartItem = data;
        cartItem.quantity = 1;
        cartItem.comments = '';
        cartItem.options = [];

        tmp.push(cartItem);
      }
      await props.updateCartItems(tmp);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  if (suggestedItems.length === 0) {
    return null;
  }
  return (
    <div className={'w100 suggested-products mt2'}>
      <div className={'flex_1 subject-title mb2'}>{t('cart.suggested_items')}</div>
      <Carousel responsive={responsive} autoPlay={false}>
        {suggestedItems.map((item, index) => (
          <SuggestedItem
            key={index}
            data={item}
            vendor_logo_thumbnail_path={props.vendorData.logo_thumbnail_path}
            hideFav={true}
            style={{ width: 140, minWidth: 140, padding: 10, marginRight: 8 }}
            onAdd={onAddCart}
          />
        ))}
      </Carousel>
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  tmpFoodData: app.tmpFoodData,
  coordinates: app.coordinates,
  cartItems: shop.items,
  vendorData: shop.vendorData
});

SuggestedProducts.propTypes = {
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    }),
    num_guests: PropTypes.number,
    is_schedule: PropTypes.number,
    schedule_time: PropTypes.string
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    order_method: PropTypes.string,
    maximum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    categories: PropTypes.array,
    can_schedule: PropTypes.number
  }),
  AddProduct2Cart: PropTypes.func,
  updateCartItems: PropTypes.func
};

export default connect(mapStateToProps, {
  AddProduct2Cart,
  updateCartItems,
  setTmpFood
})(SuggestedProducts);
