import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { SearchInput } from '../../../../components/Inputs';
import { Search } from '@styled-icons/evil';
import UserItem from '../../../../components/Chats/UserItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import './index.css';
import { Theme } from '../../../../assets';
import { getFriends } from '../../../../store/actions/app';
import Seo from '../../../../components/Seo';
import { Grid } from '@mui/material';
import ChatProfile from '../../../../components/Chats/ChatProfile';
import { isEmpty } from '../../../../utils/common';
import LoadingSpinner from '../../../../components/Spinner';

const MyFriends = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { snapfooder_id } = useParams();

  const [state, setState] = useState({
    loading: null,
    friends: []
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAllFriends();
  }, []);

  const getAllFriends = (searchTerm) => {
    setState({ ...state, loading: true });

    getFriends('accepted', searchTerm)
      .then((data) => {
        setState({ ...state, loading: false, friends: data });
      })
      .catch(() => {
        setState({ ...state, loading: false });
      });
  };

  return (
    <div style={{ flex: 1 }} className={'myfriends-screen'}>
      <Seo title={t('web_title.friends')} />
      <Grid container spacing={{ xs: 2, md: 2 }} style={{ margin: 0, width: '100%' }}>
        <Grid item xs={12} lg={4} className={!isEmpty(snapfooder_id) ? 'hide-md left-side' : 'left-side'}>
          <ChatProfile />
          <div className={'align-col-middle w100 list'}>
            <div className={'align-middle searchview'}>
              <SearchInput
                placeholder={t('social.search.friends')}
                icon={<Search size={20} color={Theme.colors.gray5} />}
                style={{ flex: 1, height: 45 }}
                value={searchTerm}
                onChange={(text) => {
                  setSearchTerm(text);
                  getAllFriends(text);
                }}
              />
            </div>
            <div className={'scrollview'}>
            {state.loading === true ?
              <div className={'flex_1'}>
                <LoadingSpinner />
              </div> : 
            state.loading === false && state.friends.length === 0 ? (
                <NoFriendList title={t('social.no_friends')} desc={t('social.no_friends_desc')} />
              ) : (
                state.friends.map((item) => (
                  <UserItem
                    key={item.id}
                    item={item}
                    id={item.id}
                    onClick={() => {
                      navigate(`/social/friends/${item.id}`);
                    }}
                  />
                ))
              )}
              <div style={{ height: 20 }} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className={'align-col-start'} style={{ padding: 0 }}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(MyFriends);
