import React, { useState, useRef, useEffect } from 'react';
import './index.css';
import PropTypes from 'prop-types';

const HScrollMenu = ({ items, onItemSelected, selectedItem }) => {
  const [selected, setSelected] = useState(0);
  const scrollView = useRef(null);

  useEffect(() => {
    if (selectedItem < items.length) {
      setSelected(selectedItem);
      let offsetX = document.getElementById(
        `vendor-food-horizontal-category-${selectedItem}`
      ).offsetLeft;
      if (scrollView.current) {
        scrollView.current.scrollLeft = offsetX - 20;
      }
    }
  }, [selectedItem]);

  const _renderMenuItems = () => {
    return items.map((item, index) => (
      <div
        key={index}
        id={`vendor-food-horizontal-category-${index}`}
        className={selected === index ? 'active-cat-item' : 'inactive-cat-item'}
        onClick={(e) => {
          e.preventDefault();
          setSelected(index);
          onItemSelected(index);
        }}>
        {item.title}
      </div>
    ));
  };

  return (
    <div className={'w100 align-col-start vendor-hscroll-menu'}>
      <div className={'align-row-start hscrollview'} ref={scrollView}>
        {_renderMenuItems()}
      </div>
      {/*<div className={'scrollview_hider'} />*/}
    </div>
  );
};

HScrollMenu.propTypes = {
  vendor_id: PropTypes.number,
  selectedItem: PropTypes.number,
  items: PropTypes.array,
  onItemSelected: PropTypes.func
};

export default React.memo(HScrollMenu);
