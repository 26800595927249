import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import apiFactory from '../../../services/apiFactory';
import Svg_close from '../../../assets/svgs/profile/close.svg';
import './index.css';
import '../index.css';
import { setOrderMessageTags } from '../../../store/actions/app';
import UserItem from '../../Chats/UserItem';
import ApiFactory from '../../../services/apiFactory';
import { confirmAlert } from 'react-confirm-alert';
// import { createSingleChannel, findSingleChannel } from '../../../services/chat';
import LoadingModal from '../LoadingModal';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const SnapfoodersGroupModal = (props) => {
  const { showModal, users, onClose, onShowDetail } = props;
  if (showModal != true || users.length == 0) return null;
  const { t } = useTranslation();

  const [isLoading, ShowLoading] = useState(false);
  const [snapfooders, setSnapfooders] = useState([]);

  useEffect(() => {
    ShowLoading(false);
    setSnapfooders([]);
    loadUsers();
  }, [showModal, users]);

  const loadUsers = () => {
    const user_ids = users.map((i) => i.id);
    apiFactory
      .post(`users/snapfoods-status`, {
        user_ids: user_ids
      })
      .then(
        ({ data }) => {
          setSnapfooders(data.snapfooders || []);
        },
        (error) => {
          setSnapfooders([]);
          console.log('openGroup ', error);
        }
      );
  };

  const updateSnapfoodDetail = (user_id) => {
    ApiFactory.get(`users/snapfooders/${user_id}`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load snapfooder', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const res_snapfooder = data['snapfooder'];
        let tmp = snapfooders.slice(0, snapfooders.length);
        let found_index = tmp.findIndex((i) => i.id == user_id);
        if (found_index >= 0) {
          tmp[found_index].invite_status = res_snapfooder.invite_status;
          setSnapfooders(tmp);
        }
      },
      () => {}
    );
  };

  const onSendInvitation = async (item) => {
    ApiFactory.post(`users/friends/update`, {
      user_id: props.user.id,
      friend_id: item.id,
      status: 'invited'
    }).then(
      () => {
        identifyDevice();
        trackEvent('FriendUpdate', {
          action_category: 'Update Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        updateSnapfoodDetail(item.id);
      },
      (error) => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = async (item) => {
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: item.id
    }).then(
      () => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'success',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        updateSnapfoodDetail(item.id);
      },
      (error) => {
        identifyDevice();
        trackEvent('FriendRemove', {
          action_category: 'Remove Invite',
          action_outcome: 'fail',
          interaction_element: 'button clicking',
          source: 'sf_web'
        });
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div className={'align-col-middle  modal snapfooders-group-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'align-middle titleview ph2 pv3'}>
          <div className={'align-middle w100'}>
            <img
              src={Svg_close}
              onClick={() => {
                onClose();
              }}
            />
            <h2>{t('Snapfooders')}</h2>
            <div style={{ width: 45 }} />
          </div>
        </div>
        <div className={'align-col-middle w100 scrollview'}>
          {snapfooders.map((item) => (
            <UserItem
              key={item.id}
              item={item}
              id={item.id}
              invite_status={item.invite_status}
              isFriend={item.is_friend == 1}
              type="invite_status"
              onClick={() => {
                onShowDetail(item);
              }}
              onRightBtnPress={
                item.invite_status === 'invited'
                  ? () => onCancelInvitation(item)
                  : () => onSendInvitation(item)
              }
            />
          ))}
        </div>
      </div>
      <LoadingModal showModal={isLoading} />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  let flag = true;
  if (prevProps.users.length != nextProps.users.length) {
    flag = false;
  } else {
    for (let i = 0; i < prevProps.users.length; i++) {
      if (prevProps.users[i].id != nextProps.users[i]) {
        flag = false;
      }
    }
  }
  console.log('flag ', flag);
  return prevProps.showModal === nextProps.showModal && flag;
}

SnapfoodersGroupModal.propTypes = {
  showModal: PropTypes.bool,
  users: PropTypes.array,
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  onClose: PropTypes.func,
  onShowDetail: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language
});

export default connect(mapStateToProps, { setOrderMessageTags })(
  React.memo(SnapfoodersGroupModal, arePropsEqual)
);
