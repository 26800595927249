import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import { SearchInput } from '../../Inputs';
import { BuildingShop } from '@styled-icons/fluentui-system-filled';
import { Theme } from '../../../assets';
import ApiFactory from '../../../services/apiFactory';
import { getTweakSearch } from '../../../utils/common';
import { identifyDevice, trackEvent } from '../../../utils/mixpanelUtil';

const AutoSuggestionSearchInput = ({ value, style, handleChange }) => {
  const [isOpen, setOpen] = useState(false);
  const [suggestedList, setSuggestedList] = useState([]);
  const [curVal, setValue] = useState(value || '');

  const { t } = useTranslation();
  const ref = useRef(null);
  const _cur_keyword = useRef('');

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    setValue(value || '');
  }, [value]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getSearchSuggestions = async (keyword) => {
    _cur_keyword.current = keyword;
    if (keyword != '') {
      ApiFactory.get(`/search/keywords-suggestions?search=${keyword}`)
        .then(({ data }) => {
          identifyDevice();
          trackEvent('Search', {
            action_category: 'Keyword Suggestions',
            action_outcome: 'success',
            interaction_element: 'search_input',
            source: 'sf_web'
          });
          setSuggestedList(data['suggestions'] || []);
          setOpen(true);
        })
        .catch(() => {
          trackEvent('Search', {
            action_category: 'Keyword Suggestions',
            action_outcome: 'fail',
            interaction_element: 'search_input',
            source: 'sf_web'
          });
        });
    } else {
      setSuggestedList([]);
      setOpen(false);
      handleChange('');
    }
  };

  const itemList = () => {
    if (suggestedList.length === 0) {
      return null;
    }

    return (
      <div className="vendor-dropdown-items">
        {suggestedList.map((item, index) => {
          let cur_value = getTweakSearch(_cur_keyword.current);
          let pre = '';
          let tail = '';
          if (item.keyword) {
            const splits = item.keyword.split(cur_value, 1);
            if (splits.length > 0) {
              pre = splits[0];
              tail = item.keyword.replace(pre + cur_value, '');
            }
          }

          return (
            <div
              onClick={() => {
                setOpen(false);
                setValue(item.keyword);
                handleChange(item.keyword);
              }}
              className="vendor-dropdown-item flex_between"
              key={index}
              style={{ borderBottomWidth: index === suggestedList.length - 1 ? 0 : 1 }}>
              <div className={'align-middle'}>
                <div className={'count'}>{item.search_count}</div>
                <div className={'suggested'}>
                  {pre}
                  <span>{cur_value}</span>
                  {tail}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div ref={ref} className={isOpen ? 'vendor-dropdown active' : 'vendor-dropdown'} style={style}>
      <SearchInput
        value={curVal}
        icon={<BuildingShop size={20} color={Theme.colors.gray5} />}
        placeholder={t('vendor_search.search_by_shop_name')}
        onChange={(text) => {
          getSearchSuggestions(text);
        }}
      />
      {itemList()}
    </div>
  );
};

AutoSuggestionSearchInput.propTypes = {
  value: PropTypes.string,
  style: PropTypes.object,
  handleChange: PropTypes.func
};

export default AutoSuggestionSearchInput;
