import React from 'react';
import PropTypes from 'prop-types';
import Svg_loc from '../../../assets/svgs/chats/ic_locpin.svg';
import { useTranslation } from 'react-i18next';

const UserMarker = ({ showPopup }) => {
  const { t } = useTranslation();

  return (
    <div className={'map-marker user-marker'}>
      <div className={'marker-body'}>
        {
          showPopup == true &&
          <div className={['align-middle', 'popup'].join(' ')}>
            <div className={'align-middle flex_1 user-info'}>
              <p>{t('you')}</p>
            </div>
            <div className={'anchor'} />
          </div>
        }
        <img src={Svg_loc} />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.lat == nextProps.lat && prevProps.lng == nextProps.lng && prevProps.showPopup == nextProps.showPopup;
}

UserMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  showPopup : PropTypes.bool
};
export default React.memo(UserMarker, arePropsEqual);
