import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';

const CardView = ({ cvv, expiry_month, expiry_year, card_num, name }) => {
  const { t } = useTranslation();
  return (
    <div className={'payment-methods-card-view'}>
      <h3>{name}</h3>
      <h1>{card_num}</h1>
      <div className={'flex_between'}>
        <p>CVV: {cvv}</p>
        <p>
          {t('card.expiry')}:{' '}
          {expiry_month !== '' || expiry_year !== '' ? expiry_month + '/' + expiry_year : ''}
        </p>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.name === nextProps.name &&
    prevProps.card_num === nextProps.card_num &&
    prevProps.cvv === nextProps.cvv &&
    prevProps.expiry_month === nextProps.expiry_month &&
    prevProps.expiry_year === nextProps.expiry_year
  );
}

CardView.propTypes = {
  cvv: PropTypes.string,
  expiry_month: PropTypes.number,
  expiry_year: PropTypes.number,
  card_num: PropTypes.string,
  name: PropTypes.string
};
export default React.memo(CardView, arePropsEqual);
