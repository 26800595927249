import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Svg_close from '../../../assets/svgs/modals/close.svg';
import { MainBtn, RadioBtn } from '../../Buttons';
import './index.css';
import '../index.css';
import { setHomeVendorFilter, setHomeVendorSort } from '../../../store/actions/app';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxBtn from '../../Buttons/CheckBoxBtn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  VSort_Closest,
  VSort_FastDelivery,
  VSort_HighRating,
  VSort_PopularFirst
} from '../../../constants/config';

const MAX_DELIVERY_FEE = 300;

const VendorFilterModal = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(props.home_vendor_filter);
  const [vendorSort, setVendorSort] = useState(props.home_vendor_sort);

  const FILTER_MENUS = [
    { id: 'is_meal', label: t('vendor_filter.meal') },
    { id: 'is_dietary', label: t('vendor_filter.dietary') },
    { id: 'ongoing_offer', label: t('vendor_filter.ongoing_offer') },
    { id: 'open_now', label: t('vendor_filter.open_now') },
    { id: 'online_payment', label: t('vendor_filter.accept_online_payment') },
    { id: 'delivery_by_snapfood', label: t('vendor_filter.delivery_by_snapfood') }
  ];

  const SORT_MENUS = [
    {
      id: VSort_FastDelivery,
      name: t('vendor_filter.fastest')
    },
    {
      id: VSort_HighRating,
      name: t('vendor_filter.high_rating')
    },
    {
      id: VSort_Closest,
      name: t('vendor_filter.closest')
    },
    {
      id: VSort_PopularFirst,
      name: t('vendor_filter.most_popular_first'),
      type: 'radio'
    }
  ];

  React.useEffect(() => {
    setOpen(props.isOpen);
    if (props.isOpen) {
      setFilter(props.home_vendor_filter);
    }
  }, [props.isOpen]);

  const onSave = () => {
    props.setHomeVendorFilter(filter);
    props.setHomeVendorSort(vendorSort);
    props.onClose()
  };

  console.log(props.home_vendor_filter, filter)

  return (
    <Dialog open={open} className={'align-col-middle w100 modal vendor-filter-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'w100 pos_relative ph2'}>
          <img src={Svg_close} className={'close-btn'} onClick={() => props.onClose()} />
          <h1 className={'title'}>{t('vendor_filter.filter_stores')}</h1>
        </div>
        <div className={'scroll_view ph2'}>
          <div className={'w100 '}>
            <List>
              {FILTER_MENUS.map((item) => (
                <ListItem
                  button
                  key={item.id}
                  className={'list-btn'}
                  onClick={(e) => {
                    e.preventDefault();
                    setFilter({
                      ...filter,
                      [item.id]: filter[item.id] === true ? false : true
                    });
                  }}>
                  <ListItemText primary={item.label} />
                  <CheckBoxBtn
                    checked={filter[item.id] === true}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        [item.id]: filter[item.id] === true ? false : true
                      });
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'>
                <span>{t('vendor_filter.max_delivery_fee')}</span>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  className={'w100'}
                  style={{
                    height: 65,
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center'
                  }}>
                  <Slider
                    max={MAX_DELIVERY_FEE}
                    getAriaLabel={() => t('vendor_filter.max_delivery_fee')}
                    value={[
                      filter.low_fee || 0,
                      filter.high_fee || MAX_DELIVERY_FEE
                    ]}
                    onChange={(event, newValue) => {
                      setFilter({
                        ...filter,
                        low_fee: newValue[0] === 0 ? null : newValue[0],
                        high_fee: newValue[1] === MAX_DELIVERY_FEE ? null : newValue[1]
                      });
                    }}
                    valueLabelDisplay='on'
                    disableSwap
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <div style={{ height: 15 }} />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'>
                <span>{t('vendor_filter.sort')}</span>
              </AccordionSummary>
              <AccordionDetails>
                {SORT_MENUS.map((menu) => (
                  <div
                    key={menu.id}
                    className={'flex_between w100 sort-btn'}
                    onClick={() => {
                      setVendorSort(menu.id);
                    }}>
                    <span>{menu.name}</span>
                    <RadioBtn
                      checked={menu.id === vendorSort}
                      onClick={() => {
                        setVendorSort(menu.id);
                      }}
                    />
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className={'w100 ph2 pt3'}>
          <MainBtn
            className={'save-btn'}
            title={t('vendor_filter.filter_stores')}
            onClick={onSave}
          />
        </div>
      </div>
    </Dialog>
  );
};


VendorFilterModal.propTypes = {
  isOpen: PropTypes.bool,
  home_vendor_filter: PropTypes.shape({
    is_meal: PropTypes.bool,
    is_dietary: PropTypes.bool,
    ongoing_offer: PropTypes.bool,
    open_now: PropTypes.bool,
    online_payment: PropTypes.bool,
    delivery_by_snapfood: PropTypes.bool,
    low_fee: PropTypes.number,
    high_fee: PropTypes.number
  }),
  home_vendor_sort: PropTypes.string,
  setHomeVendorFilter: PropTypes.func,
  setHomeVendorSort: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_vendor_filter: app.home_vendor_filter,
  home_vendor_sort: app.home_vendor_sort
});

export default connect(mapStateToProps, { setHomeVendorFilter, setHomeVendorSort })(VendorFilterModal);
