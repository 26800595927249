import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { AuthBtn } from '../../../../components/Buttons';
import { LocationInput } from '../../../../components/Inputs';
import { saveAddress, getAddresses, setTmpLocationPicked } from '../../../../store/actions/app';
import { updateProfileDetails } from '../../../../store/actions/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.css';
import TextSelector from '../../../../components/DropdownSelectors/TextSelector';
import { CommentInput } from '../../../../components/Inputs';
// import { ROUTES_NAMES } from '../../../../constants';
import RoundIconBtn from '../../../../components/Buttons/RoundIconBtn';
import { ChevronLeft } from '@mui/icons-material';
import Theme from '../../../../assets/theme';
import { confirmAlert } from "react-confirm-alert";
import AddressLabelModal from '../../../../components/Modals/AddressLabelModal'

const NewAddressScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = querystring.parse(location.search);
  const [loading, setLoading] = useState(false);
  const [isCustomLabelModal, showCustomLabelModal] = React.useState(false);
  const isEdit = parsed.is_edit === 'true';
  const isFromCart = parsed.from_cart === 'true';
  const [address_text, setAddressText] = useState('');
  const _tmp_new_address = useRef(props.tmp_new_address)

  useEffect(() => {
    let text = props.tmp_new_address.street || '';
    if (props.tmp_new_address.city != null && props.tmp_new_address.city !== '') {
      text = `${text} ${props.tmp_new_address.city}`;
    }
    if (props.tmp_new_address.country != null && props.tmp_new_address.country !== '') {
      text = `${text}, ${props.tmp_new_address.country}`;
    }
    setAddressText(text);
  }, [props.tmp_new_address.street, props.tmp_new_address.city, props.tmp_new_address.country]);


  useEffect(() => {
    _tmp_new_address.current = props.tmp_new_address;
  }, [props.tmp_new_address]);

  const onSaveAddress = async () => {
    if (props.tmp_new_address == null || props.tmp_new_address.coords == null) {
      return console.error(t('attention'), t('add_new_address.please_enter_address'));
    }

    let lat = props.tmp_new_address.coords.latitude || props.coordinates.latitude;
    let lng = props.tmp_new_address.coords.longitude || props.coordinates.longitude;

    let country = props.tmp_new_address.country || props.address.country;
    let city = props.tmp_new_address.city || props.address.city;

    let new_address = {
      id: props.tmp_new_address.id,
      address_type: props.tmp_new_address.address_type || 'Home',
      notes: props.tmp_new_address.notes,
      lat: lat,
      lng: lng,
      country: country,
      city: city,
      street: props.tmp_new_address.street || ',',
      building: props.tmp_new_address.building,
      floor: props.tmp_new_address.floor,
      apartment: props.tmp_new_address.apartment,
      favourite: 1
    };

    try {
      setLoading(true);
      await saveAddress(new_address);
      await props.getAddresses();
      setLoading(false);

      if (isFromCart) {
        navigate(-2);
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
      confirmAlert({
        title: t('alerts.error'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }
  };


  return (
    <div className={'add-address-screen'}>
      <div className={'align-middle header-view'}>
        <RoundIconBtn
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}

        />
        <div className={'title flex_1'}>{isEdit ? t('address_edit.header_title') : t('address_new.header_title')}</div>
        <div style={{ width: 32 }} />
      </div>
      <div className={'w100 align-col-middle mt2 form'}>
        <div className={'row'}>
          <div className={'flex_1 label'}>{t('address.address_name')}</div>
          <div className={'flex_1 input-wrapper'}>
            <TextSelector
              values={['home', 'work', 'custom']}
              value={props.tmp_new_address.address_type || 'home'}
              handleChange={(type) => {
                if (type == 'custom') {
                  showCustomLabelModal(true)
                }
                else {
                  props.setTmpLocationPicked({
                    ..._tmp_new_address.current,
                    address_type: type
                  });
                }
              }}
            />
          </div>
        </div>
        <div className={'w100 align-col-start mt20'}>
          <div style={{ width: '100%', paddingBottom: 20 }}>
            <div className={'label'} style={{ marginBottom: 12 }}>
              {t('address_new.street')}
            </div>
            <LocationInput
              defaultInput={address_text}
              placeholder={t('address_new.search_location')}
              onChange={(loc) => {
                let tmpAddress = {
                  coords: {
                    latitude: loc.latitude,
                    longitude: loc.longitude
                  },
                  street: loc.street,
                  building: loc.building,
                  country: loc.country,
                  city: loc.city
                };
                props.setTmpLocationPicked({
                  ..._tmp_new_address.current,
                  ...tmpAddress
                });
              }}
            />
            <div style={{ height: 12 }} />
            <div className={'label'} style={{ marginBottom: 12 }}>
              {t('address_new.note')}
            </div>
            <CommentInput
              placeholder={t('address_new.note_placeholder')}
              text={props.tmp_new_address.notes}
              onChange={(text) => {
                props.setTmpLocationPicked({
                  ..._tmp_new_address.current,
                  notes: text
                });
              }}
            />
          </div>
          {/* TODO: 1.1 */}
          {/*<p className={'find-btn'} onClick={() => {*/}
          {/*  navigate(ROUTES_NAMES.findAddressOnMap);*/}
          {/*}}>*/}
          {/*  {(props.tmp_new_address.street || '') !== '' ||*/}
          {/*  (props.tmp_new_address.building || '') !== ''*/}
          {/*    ? t('address_new.relocate_on_map')*/}
          {/*    : t('address_new.find_on_map')}*/}
          {/*</p>*/}
        </div>
      </div>
      <div className={'flex_1 align-col-middle w100 mt20 actions'}>
        <AuthBtn
          isDisabled={loading}
          isLoading={loading}
          title={t('address.save_address')}
          onClick={onSaveAddress}
          className={'save-btn'}
        />
      </div>
      <AddressLabelModal 
        isOpen={isCustomLabelModal}
        onClose={()=>showCustomLabelModal(false)}
      />
    </div>
  );
};

NewAddressScreen.propTypes = {
  address: PropTypes.shape({
    country: PropTypes.string,
    city: PropTypes.string
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  tmp_new_address: PropTypes.shape({
    address_type: PropTypes.string,
    notes: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number,
    floor: PropTypes.string,
    apartment: PropTypes.string,
    coords: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    })
  }),

  getAddresses: PropTypes.func,
  setTmpLocationPicked: PropTypes.func,
  updateProfileDetails: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  tmp_new_address: app.tmp_new_address,
  coordinates: app.coordinates,
  address: app.address
});

export default connect(mapStateToProps, {
  updateProfileDetails,
  getAddresses,
  setTmpLocationPicked
})(NewAddressScreen);
