import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@mui/material';
import Seo from '../../components/Seo';
import './index.css';
import { Row, Col } from 'react-grid-system';
import BlogItem from '../../components/Blog/BlogItem';
import FilterView from '../../components/Blog/FilterView';
import NoBlogList from '../../components/Empty/NoBlogList';
import apiFactory from '../../services/apiFactory';
import { setProfileBlogFilter, setProfileBlogs, setBlogData } from '../../store/actions/app';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import { identifyDevice, trackEvent } from '../../utils/mixpanelUtil';

const Blog = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const _isMounted = useRef(true);
  const _curPage = useRef(1);
  const _totalPages = useRef(0);
  const [state, setState] = useState({
    blog: [],
    page: 1,
    totalPages: 0,
    show_loading: null
  });

  useEffect(() => {
    _isMounted.current = true;
    getBlog();
    getCategories();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    _isMounted.current = true;
    _curPage.current = 1;
    getBlog();
  }, [props.profile_blog_filter.searchTerm, props.profile_blog_filter.category_id]);

  const getBlog = async () => {
    const page = _curPage.current;
    const { searchTerm, category_id } = props.profile_blog_filter;

    const params = [
      `title=${searchTerm}`,
      `page=${page}`,
      `category_id=${category_id ? category_id : ''}`
    ];
    setState({ ...state, page: page, show_loading: true });
    apiFactory.get(`blogs?${params.join('&')}`).then(
      ({ data }) => {
        identifyDevice();
        trackEvent('Load Blogs', {
          action_category: 'Load screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        const blog = data['blogs'];
        let blogItems = blog['data'];

        if (_isMounted.current === true) {
          _totalPages.current = blog['last_page'];
          setState({
            ...state,
            blog: blogItems,
            page: page,
            totalPages: blog['last_page'],
            show_loading: false
          });
        }
      },
      (error) => {
        const message = error.message || t('generic_error');
        if (_isMounted.current === true) {
          setState({
            ...state,
            show_loading: false
          });
          console.log(t('alerts.error'), message);
        }
      }
    );
  };

  const getCategories = () => {
    apiFactory.get('blogs/categories').then(({ data }) => {
      identifyDevice();
      trackEvent('Load Blogs categories', {
        action_category: 'Load screen',
        action_outcome: 'success',
        interaction_element: 'Screen',
        source: 'sf_web'
      });
      props.setProfileBlogs(data.categories);
    });
  };

  const loadNextPage = async (page) => {
    if (page >= 0 && page <= _totalPages.current) {
      _curPage.current = page;
      getBlog();
    }
  };

  const onGoBlogDetail = (data) => {
    props.setBlogData(data);
    navigate(`/blog/${data.hash_id}/${data.slug}`);
  };

  return (
    <div data-testid="view-blog" className={'view-blog'}>
      <Seo title={t('web_title.blog')} />
      <div className={'flex_1'}>
        <div className={'header-bg'} />
        <div className={'flex_1 align-col-middle main ph4'}>
          <div className={'bloglist-body w100 pv4 ph4'}>
            <Row>
              <Col xs={12} md={4} lg={3}>
                <FilterView onFilterChange={() => {}} />
              </Col>
              <Col xs={12} md={8} lg={9}>
                <Row>
                  {state.show_loading === true ? (
                    <div className={'flex_1'}>
                      <LoadingSpinner />
                    </div>
                  ) : state.show_loading === false && state.blog.length === 0 ? (
                    <NoBlogList />
                  ) : (
                    state.blog.map((blog, index) => (
                      <Col key={index} xs={12} md={6} lg={4} className={'blog-item-wrap'}>
                        <BlogItem
                          id={blog.id}
                          data={blog}
                          language={props.language}
                          onClick={() => onGoBlogDetail(blog)}
                        />
                      </Col>
                    ))
                  )}
                </Row>
                <Pagination
                  count={state.totalPages}
                  page={state.page}
                  onChange={(ev, page) => {
                    loadNextPage(page);
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

Blog.propTypes = {
  profile_blog_filter: PropTypes.shape({
    searchTerm: PropTypes.string,
    category_id: PropTypes.string
  }),
  language: PropTypes.string,
  setProfileBlogs: PropTypes.func,
  setProfileBlogFilter: PropTypes.func,
  setBlogData: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  profile_blog_filter: app.profile_blog_filter
});

export default connect(mapStateToProps, { setProfileBlogFilter, setProfileBlogs, setBlogData })(
  Blog
);
