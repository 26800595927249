import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthBtn } from '../../components/Buttons';
import PartnerInput from '../../components/Inputs/PartnerInput';
import { CountrySelector, TextSelector } from '../../components/DropdownSelectors';
import { ROUTES_NAMES } from '../../constants';
import './ApplyForm.css';
import { isEmpty, validateEmailAddress, validatePhoneNumber1 } from '../../utils/common';
import { confirmAlert } from 'react-confirm-alert';
import apiFactory from '../../services/apiFactory';

const ApplyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const phoneCode = { AL: '+355' };
  const [selected_country, setSelectCountry] = useState('AL');
  const [isLoading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    store_name: '',
    country: '',
    city: '',
    state: '',
    vendor_type: t('vendor_type.resto'),
    full_name: '',
    phone: '',
    email: ''
  });

  const onSubmit = () => {
    if (isEmpty(info.store_name) || isEmpty(info.full_name) || isEmpty(info.phone) || isEmpty(info.email) || isEmpty(info.country) || isEmpty(info.city) || isEmpty(info.state)) {
      return confirmAlert({
        title: null,
        message: t('alerts.fill_all_fields'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }
    if (validateEmailAddress(info.email) === false) {
      return confirmAlert({
        title: null,
        message: t('auth_login.validate_email_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }
    if (validatePhoneNumber1(info.phone) === false) {
      return confirmAlert({
        title: null,
        message: t('auth_register.validate_cell_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
            }
          }
        ]
      });
    }

    setLoading(true);
    apiFactory
      .post(`apply-partner`, info)
      .then(
        () => {
          setLoading(false);
          confirmAlert({
            title: t('partner.apply_success'),
            message: t('partner.apply_success_desc'),
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {
                  navigate('/');
                }
              }
            ]
          });
        },
        (error) => {
          console.log(error);
          setLoading(false);
          const message = error.message || t('checkout.something_is_wrong');
          confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {
                }
              }
            ]
          });
        }
      );
  };

  return (
    <form className='apply-form'>
      <h2>{t('partner.apply_now')}</h2>
      <div className={'row w100 flex_wrap'} style={{ zIndex: 3 }}>
        <div className={'label'}>{t('partner.store_info')}</div>
        <div className={'input-wrapper '}>
          <PartnerInput
            placeholder={t('partner.store_name')}
            onChange={(value) => {
              setInfo({ ...info, store_name: value });
            }}
          />
          <div className={'w100 mt2'}>
          <TextSelector
                values={[t('vendor_type.resto'), t('vendor_type.grocery'), t('vendor_type.pharmacy'), t('vendor_type.other')]}
                value={info.vendor_type}
                handleChange={(text) => {
                  setInfo({ ...info, vendor_type: text });
                }}
              />
          </div>
        </div>
      </div>
      <div className={'row w100 flex_wrap'} style={{ zIndex: 2 }}>
        <div className={'label'}>{t('partner.store_location')}</div>
        <div className={'input-wrapper'}>
          <div className={'align-middle w100'} style={{zIndex: 2}}>
            <div className={'flex_1'}>
              <TextSelector
                placeholder={t('partner.country')}
                values={[t('Albania')]}
                value={info.country}
                handleChange={(text) => {
                  setInfo({ ...info, country: text });
                }}
              />
            </div>
            <div style={{ width: 20 }} />
            <div className={'flex_1'}>
              <TextSelector
                placeholder={t('partner.city')}
                values={[t('Tirana')]}
                value={info.city}
                handleChange={(text) => {
                  setInfo({ ...info, city: text });
                }}
              />
            </div>
          </div>
          <div className={'w100 mt2'} style={{zIndex: 1}}>
            <TextSelector
              placeholder={t('partner.state_province')}
              values={[t('Tirana')]}
              value={info.state}
              handleChange={(text) => {
                setInfo({ ...info, state: text });
              }}
            />
          </div>
        </div>
      </div>
      <div className={'row w100 flex_wrap'} >
        <div className={'label'}>{t('partner.owner_details')}</div>
        <div className={'input-wrapper'}>
          <PartnerInput
            placeholder={t('partner.full_name')}
            value={info.full_name}
            onChange={(value) => {
              setInfo({ ...info, full_name: value });
            }}
          />
          <div className={'align-middle w100 mv2'}>
            <div>
              <CountrySelector
                country={selected_country}
                style={{ height: 48 }}
                handleChange={(c) => {
                  setSelectCountry(c);
                }}
              />
            </div>
            <div style={{ width: 20 }} />
            <div className={'flex_1'}>
              <PartnerInput
                prefix={phoneCode[selected_country]}
                // placeholder={t('partner.cell')}
                value={info.phone}
                onChange={(value) => {
                  setInfo({ ...info, phone: value });
                }}
              />
            </div>
          </div>
          <PartnerInput
            placeholder={t('partner.email_address')}
            value={info.email}
            onChange={(value) => {
              setInfo({ ...info, email: value });
            }}
          />
        </div>
      </div>
      <div className={'row terms-txt'}>
        <p>
          {t('partner.apply_terms1')}
          <Link to={ROUTES_NAMES.privacyPolicy.partner}>{t('partner.vendor_privacy_policy')}</Link>
          {t('partner.apply_terms2')}
        </p>
      </div>
      <div className={'align-col-middle'}>
        <AuthBtn
          isLoading={isLoading}
          title={t('partner.apply')}
          onClick={onSubmit}
          style={{
            width: 240,
            height: 48
          }}
        />
      </div>
    </form>
  );
};

function arePropsEqual() {
  return true;
}

export default React.memo(ApplyForm, arePropsEqual);
