import React from 'react';
import { Message } from '../react-web-gifted-chat';
import MessageBubble from './MessageBubble';
import { Theme } from '../../../assets';
import SystemMessage from '../react-web-gifted-chat/SystemMessage';

export const renderMessage = (props) => (
  <Message
    {...props}
    containerStyle={{
      left: { backgroundColor: '#fff', display: 'flex' },
      right: { backgroundColor: '#fff', display: 'flex' }
    }}
    textStyle={{ fontSize: 13, textAlign: 'center' }}
  />
);

export const renderBubble = (props, isGroup, onLongPress, onPressMsg, onDoublePress, onShowGalleryMsgs, onLikeChange, onPopupPress, onCopyPress) => {
  return (
    <MessageBubble
      {...props}
      isGroup={isGroup}
      // renderTime={() => <Text>Time</Text>}
      // renderTicks={() => <Text>Ticks</Text>}
      containerStyle={{
        left: { paddingLeft: 12, marginTop: 8 },
        right: { paddingRight: 12, marginTop: 8 }
      }}
      bottomContainerStyle={{
        left: { display: 'none' },
        right: { display: 'none' }
      }}
      tickStyle={{}}
      // usernameStyle={{ color: 'tomato', fontWeight: '100' }}
      containerToNextStyle={{
        left: {},
        right: {}
      }}
      containerToPreviousStyle={{
        left: {},
        right: {}
      }}
      onPressMsg={onPressMsg}
      onDoublePress={onDoublePress}
      onLongPress={onLongPress}
      onShowGalleryMsgs={onShowGalleryMsgs}
      onLikeChange={onLikeChange}
      onPopupPress={onPopupPress}
      onCopyPress={onCopyPress}
    />
  );
};

export const renderSystemMessage = (props) => (
  <SystemMessage
    {...props}
    containerStyle={{ backgroundColor: 'transparent', paddingHorizontal: 20 }}
    wrapperStyle={{
      backgroundColor: Theme.colors.red1,
      padding: props.currentMessage && props.currentMessage.text ? 14 : 0,
      borderRadius: 8
    }}
    textStyle={{ color: Theme.colors.white, fontSize: 16, fontFamily: Theme.fonts.semiBold }}
  />
);
