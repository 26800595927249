import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import translationEN from '../assets/locales/en.json';
import translationSQ from '../assets/locales/sq.json';
import { Storage } from '../services';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  sq: {
    translation: translationSQ
  }
};

i18n
  .use(initReactI18next) // passes localisations down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false // not needed for react!!
    }
  });
export default i18n;

export const getCurrentLanguage = () => {
  return i18n.language;
};

export const setLanguage = (lang) => {
  i18n.changeLanguage(lang).then();
  Storage.setLanguage(lang);
  moment.locale('en');
};

export const setI18nConfig = () => {
  let lang = Storage.getLanguage();
  i18n.changeLanguage(lang).then();
  moment.locale('en');
};

export const appMoment = moment;
